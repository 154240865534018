import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles,
  Tabs,
  Tab,
  FormGroup,
} from '@material-ui/core';
import {
  useGetCamerasMonitored,
  useGetPcsService,
  useSetCamerasMonitored,
  useSetPcsService,
  useNotificationSettings,
} from 'src/hooks/subscriberHooks';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import CameraGrid from '../verify-respond/CameraGrid';
import { useNavigate } from 'react-router';
import CameraStatus from './CameraStatus';
import CameraDetails from './CameraDetails';
import { SERVICE_FEATURES } from 'src/utils/subscriptionInfo';
import { useGetRepMe } from 'src/hooks/repHooks';
import { SsSubscription } from 'src/types';
import DenseCardHeader from 'src/components/DenseCardHeader';

const useStyles = makeStyles({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
});

type Props = {
  className?: string;
  location: SsSubscription;
  playerState: any;
};

const Location = ({ className, location, playerState, ...rest }: Props) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);

  // SERVICE
  const [serviceFeatures, setServiceFeatures] = React.useState({});
  const [servicePlan, setServicePlan] = React.useState<{ sku: string; type: string; status: string; }>({ sku: '', type: '', status: '' });
  const getServiceQuery = useGetPcsService(location.uid, location.sid, true);
  const setServiceMutation = useSetPcsService(location.uid, location.sid);
  const { data: repData } = useGetRepMe();
  const [saving, setSaving] = React.useState(false);

  // Notifications
  const notificationSettings = useNotificationSettings(location.sid);

  React.useEffect(() => {
    if (getServiceQuery.isSuccess && getServiceQuery.data?.features && getServiceQuery.data?.plan_sku) {
      setServiceFeatures(getServiceQuery.data.features);
      setServicePlan({ sku: getServiceQuery.data?.plan_sku, type: getServiceQuery.data?.plan_type, status: getServiceQuery.data?.plan_status });
    }
    return () => {
      // cleanup
    };
  }, [getServiceQuery.isSuccess, getServiceQuery.data]);

  // CAMERAS
  const [camerasMonitored, setCamerasMonitored] = React.useState([]);
  const getCamerasQuery = useGetCamerasMonitored(location.sid);
  const setCamerasMutation = useSetCamerasMonitored();

  React.useEffect(() => {
    if (getCamerasQuery.isSuccess) {
      const monitoredCameras = getCamerasQuery.data
        .filter((camera) => camera.monitored)
        .map((camera) => camera.uuid);
      setCamerasMonitored(monitoredCameras);
    }
    return () => {
      // cleanup
    };
  }, [getCamerasQuery.isSuccess, getCamerasQuery.data]);

  const handleSave = async () => {
    // Save service
    setSaving(true);

    try {
      await setServiceMutation.mutateAsync({
        sid: location.sid,
        user_id: location.uid,
        // features: serviceFeatures.map(feature => ({ id: feature, value: true }))
        features: serviceFeatures,
      });

      // Save cameras
      const cameraList = location.location.system.cameras.map((camera) => ({
        uuid: camera.uuid,
        user_id: location.uid,
        sid: location.sid,
        model: camera.model,
        monitored: camerasMonitored.indexOf(camera.uuid) !== -1,
      }));

      await setCamerasMutation.mutateAsync({
        sid: location.sid,
        cameras: cameraList,
      });
    } catch (err) {
      console.error(err);
    }

    setSaving(false);
  };

  const handleSelectAll = (event) => {
    let newSelected;

    if (event.target.checked) {
      newSelected = location.location.system.cameras.map(
        (camera) => camera.uuid
      );
    } else {
      newSelected = [];
    }

    setCamerasMonitored(newSelected);
  };

  const ShowCameras = () => {
    return (
      <>
        {getCamerasQuery.isLoading && <LoadingSpinner />}
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <Typography color="textPrimary" gutterBottom variant="h6">
                Cameras Monitored
              </Typography>

              <Box className={classes.item} pl={2}>
                {location.location.system?.cameras?.map((camera) => (
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h6"
                    key={camera.uuid}
                  >
                    <Button
                      variant="outlined"
                      onClick={() =>
                        navigate(
                          `/app/camera?userId=${location.uid}&sid=${location.sid}&uuid=${camera.uuid}`
                        )
                      }
                    >
                      {camera.cameraSettings.cameraName}
                    </Button>
                  </Typography>
                ))}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              navigate(
                `/app/verify-respond?userId=${location.uid}&sid=${location.sid}`
              )
            }
          >
            Live View of Location
          </Button>
        </Box>
      </>
    );
  };

  const SetupService = () => {
    if (!getServiceQuery.isSuccess) return <LoadingSpinner />;
    return (
      <>
        {getServiceQuery.isLoading && <LoadingSpinner />}
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            {/* SELECT SERVICE FEATURES */}
            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <Typography color="textPrimary" gutterBottom variant="h6">
                PCS Service Features
              </Typography>
              {getServiceQuery.data.serviceMap.map((planFeature) => {
                return (
                  <FormControlLabel
                    key={planFeature.id}
                    label={planFeature.label}
                    control={
                      <Checkbox
                        checked={
                          serviceFeatures?.[planFeature.id]?.value === true ||
                          serviceFeatures?.[planFeature.id] === true
                        }
                        color="primary"
                        name={planFeature.id}
                        value="true"
                        onChange={(event) => {
                          setServiceFeatures({
                            ...serviceFeatures,
                            [event.target.name]: {
                              value: event.target.checked,
                            },
                          });
                        }}
                        disabled={
                          planFeature.id === SERVICE_FEATURES.QA_RECORDING
                        }
                      />
                    }
                  />
                );
              })}
            </Grid>

            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <Typography color="textPrimary" gutterBottom variant="h6">
                Cameras Monitored
              </Typography>

              {/* SELECT ALL CAMERAS */}
              {location.location.system.cameras.length > 0 && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        camerasMonitored.length ===
                        location.location.system.cameras.length
                      }
                      color="primary"
                      indeterminate={
                        camerasMonitored.length > 0 &&
                        camerasMonitored.length <
                        location.location.system.cameras.length
                      }
                      onChange={handleSelectAll}
                    />
                  }
                  label="Select All"
                />
              )}

              {/* CAMERAS */}
              <Box className={classes.item} pl={2}>
                {location.location.system?.cameras?.map((camera) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={camerasMonitored.indexOf(camera.uuid) !== -1}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setCamerasMonitored([
                              ...camerasMonitored,
                              event.target.name,
                            ]);
                          } else {
                            setCamerasMonitored([
                              ...camerasMonitored.filter(
                                (x) => x !== event.target.name
                              ),
                            ]);
                          }
                        }}
                        value="true"
                        name={camera.uuid}
                      />
                    }
                    label={camera.cameraSettings.cameraName}
                    key={camera.uuid}
                  />
                ))}
              </Box>
            </Grid>
            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <Box>
                <Typography color="textPrimary" gutterBottom variant="h6">
                  Simplisafe Plan
                </Typography>
                <Typography>Plan type: {location?.planName}</Typography>
              </Box>
              <Box mt={2}>
                <Typography color="textPrimary" gutterBottom variant="h6">
                  CRM Subscription
                </Typography>
                <Typography>
                  {location?.status.isActive ? 'Monthly' : 'Deactivated'}
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography color="textPrimary" gutterBottom variant="h6">
                  PCS Database SKU
                </Typography>
                <Typography>
                  {servicePlan?.sku}
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography color="textPrimary" gutterBottom variant="h6">
                  PCS Database Type
                </Typography>
                <Typography>
                  {servicePlan?.type}
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography color="textPrimary" gutterBottom variant="h6">
                  PCS Database Status
                </Typography>
                <Typography>
                  {servicePlan?.status}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
            disabled={saving || repData?.role !== 'admin'}
          >
            Save
          </Button>
        </Box>
      </>
    );
  };

  const CamStatus = () => {
    return (
      <>
        {getCamerasQuery.isLoading && <LoadingSpinner />}
        <Divider />
        <CardContent>
          <Box className={classes.item} pl={2}>
            {location.location.system?.cameras?.map((camera) => (
              <>
                <CameraStatus camera={camera} key={camera.uuid} />
                <CameraDetails camera={camera} key={`detail-${camera.uuid}`} />
              </>
            ))}
          </Box>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              navigate(
                `/app/verify-respond?userId=${location.uid}&sid=${location.sid}`
              )
            }
          >
            Live View of Location
          </Button>
        </Box>
      </>
    );
  };

  const NotificationSettings = (settings) => {
    if (notificationSettings.isLoading) return <LoadingSpinner />;

    return (
      <>
        {notificationSettings.isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Divider />
            <CardContent>
              <Box className={classes.item} pl={2}>
                {notificationSettings.error ? (
                  <Typography>
                    There's no notification preferences set for this location
                  </Typography>
                ) : (
                  <Grid container spacing={6}>
                    <Grid item xs={3}>
                      <Card>
                        <DenseCardHeader title="Push Notifications" />

                        <Box p={1}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    notificationSettings.data.push.common_events
                                      .all
                                  }
                                  readOnly
                                  color="primary"
                                />
                              }
                              label="Common"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    notificationSettings.data.push.person_events
                                      .all
                                  }
                                  readOnly
                                  color="primary"
                                />
                              }
                              label="Person"
                            />
                          </FormGroup>
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card>
                        <DenseCardHeader title="Email Notifications" />
                        <Box p={1}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    notificationSettings.data.email
                                      .common_events.all
                                  }
                                  readOnly
                                  color="primary"
                                />
                              }
                              label="Common"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    notificationSettings.data.email
                                      .person_events.all
                                  }
                                  readOnly
                                  color="primary"
                                />
                              }
                              label="Person"
                            />
                          </FormGroup>
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              EST
            </Box>
          </>
        )}
      </>
    );
  };

  const navigate = useNavigate();
  const cameras = location.location?.system?.cameras;
  const GridView = () => {
    if (!getServiceQuery.isSuccess) return <LoadingSpinner />;
    return (
      <>
        {getServiceQuery.isLoading && <LoadingSpinner />}
        <Divider />
        <CameraGrid
          sid={location.sid}
          cameras={cameras}
          playerState={playerState}
          timestamp=""
          onCameraClick={(e) => {
            navigate(
              `/app/camera?userId=${location.uid}&sid=${location.sid
              }&uuid=${e.currentTarget.getAttribute('value')}`
            );
          }}
          className={'fs-exclude'}
        />
      </>
    );
  };

  return (
    <form className={clsx(classes.root, className)} {...rest}>
      <Card>
        <Tabs
          value={activeTab}
          onChange={(e, val) => {
            setActiveTab(val);
          }}
        >
          <Tab label="Select Service" />
          <Tab label="Setup Cameras" disabled={repData?.role !== 'admin'} />
          <Tab label="Grid View" disabled={repData?.role !== 'admin'} />
          <Tab label="Cam Status" />
          <Tab label="Notifications settings" />
          <Tab style={{ flexGrow: 1 }} disabled />
          <Tab
            disabled
            label={
              <>
                <Typography>{location.location.locationName}</Typography>
                <Typography>{`SID: ${location.sid}, Account: ${location.location.account}`}</Typography>
              </>
            }
            className={'fs-exclude'}
          />
        </Tabs>
        {/* <CardHeader
          subheader={`SID: ${location.sid}, Account: ${location.location.account}`}
          title={location.location.locationName}
        /> */}

        {activeTab === 0 && <SetupService />}
        {activeTab === 1 && <ShowCameras />}
        {activeTab === 2 && <GridView />}
        {activeTab === 3 && <CamStatus />}
        {activeTab === 4 && <NotificationSettings />}
      </Card>
    </form>
  );
};

Location.propTypes = {
  className: PropTypes.string,
  location: PropTypes.object,
  playerState: PropTypes.object,
};

export default Location;
