import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { CircularProgress, IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  useSubscriberExport,
  useGetSubscribersInfo,
} from 'src/hooks/subscriberHooks';
import { useGetRepMe } from 'src/hooks/repHooks';

export const getAuthenticated = (customer) =>
  customer.last_active
    ? moment(customer.last_active * 1000).format('MM/DD/YYYY HH:MM')
    : '';

export const getLastAuthFailure = (customer) =>
  customer.auth_fail_last > 0
    ? moment(customer.auth_fail_last * 1000).format('MM/DD/YYYY HH:MM')
    : '';

const ExportLink = ({ searchValue, isEnabled }) => {
  const [fetchSubInfo, setFetchSubInfo] = useState(false);
  let { data: subInfo = '' } = useGetSubscribersInfo(fetchSubInfo);
  const { data: repData } = useGetRepMe();
  const csvRef = useRef();
  const csvSubInfoRef = useRef();
  const [fetchData, setFetchData] = useState(false);

  let { data = [], done } = useSubscriberExport(searchValue, fetchData);

  // Because of isEnabled, we are sure that data will not be empty.
  useEffect(() => {
    if (fetchData && done) {
      csvRef.current.link.click();
      setFetchData(false);
    }
  }, [csvRef, setFetchData, done, fetchData]);

  useEffect(() => {
    if (fetchSubInfo && subInfo) {
      csvSubInfoRef.current.link.click();
      setFetchSubInfo(false);
    }
  }, [csvSubInfoRef, subInfo]);

  const isDisabled = !isEnabled || repData?.role !== 'admin' || fetchData;

  const csvData = useMemo(
    () =>
      data.map((item) => ({
        'User (user id)': item.user_id,
        Email: item.email,
        Authenticated: getAuthenticated(item),
        'Auth Failures': item.auth_fail_count,
        'Last Failure': getLastAuthFailure(item),
        Service: item.service,
      })),
    [data]
  );

  return (
    <>
      <IconButton
        onClick={() => setFetchData(true)}
        color="primary"
        variant="contained"
        disabled={isDisabled}
      >
        {fetchData && <CircularProgress size={20} />}
        <GetAppIcon />
        Export
      </IconButton>

      <IconButton
        onClick={() => setFetchSubInfo(true)}
        color="primary"
        variant="contained"
        disabled={fetchSubInfo}
      >
        {fetchSubInfo && <CircularProgress size={20} />}
        <GetAppIcon />
        Export Subscribers Info
      </IconButton>

      <CSVLink data={csvData} filename={'subscribers.csv'} ref={csvRef} />
      <CSVLink
        data={subInfo}
        filename={'subscribers-info.csv'}
        ref={csvSubInfoRef}
      />
    </>
  );
};

ExportLink.propTypes = {
  searchValue: PropTypes.string,
  isEnabled: PropTypes.bool,
};

export default ExportLink;
