export const makeMarkers = (tracks, moves, faces) => {
  const markers = [];
  if (tracks) {
    tracks.forEach((track) => {
      markers.push({
        category: 'tracks',
        id: `track-${track.pts_seconds}`,
        time: track.pts_seconds,
        color: '#ff0000',
        title: track.class_name,
      });
    });
  }
  if (moves) {
    moves.forEach((track) => {
      markers.push({
        category: 'moves',
        id: `move-${track.pts_seconds}`,
        time: track.pts_seconds,
        color: '#0000ff',
        title: 'motion',
      });
    });
  }
  if (faces) {
    faces.forEach((track) => {
      markers.push({
        category: 'faces',
        id: `face-${track.pts_seconds}`,
        time: track.pts_seconds,
        color: '#f29705', // orange
        title: 'face',
      });
    });
  }
  return markers;
};
