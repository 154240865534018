// Setup the state and callbacks for subscription data

// import * as React from 'react';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import { ReadyState } from 'react-use-websocket';
import { usePcsClient } from 'src/context/pcs-auth-context';
import { useSsUserSocket } from './socketHooks';

function useAlarmEvents({ sid, userId }) {
  const client = usePcsClient();
  const { readyState } = useSsUserSocket(userId);
  const { data, isLoading, error } = useQuery({
    queryKey: ['ss_events', sid],
    queryFn: () => client(`subscriptions/${sid}/events`),
    config: {
      refetchInterval: readyState !== ReadyState.OPEN ? 5 * 1000 : null,
    },
  });
  return { events: data?.events, isLoading, error };
}

function useEventDispositionInfo({ eventIds, isEnabled }) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery({
    queryKey: ['disposition-info', eventIds],
    queryFn: () =>
      client(`events/disposition-info?eventIds=${eventIds.toString()}`),
    enabled: isEnabled,
    onError: (err) => {
      enqueueSnackbar(`Endpoint: events/disposition-info. ${err}`, {
        variant: 'error',
      });
    },
  });
  return query;
}

export { useAlarmEvents, useEventDispositionInfo };
