import { PcsSession } from './PcsSession';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import moment from 'moment';
import { MutableRefObject, useState } from 'react';

export function KvsSessionInfo({
  pcsSession,
}: {
  pcsSession: MutableRefObject<PcsSession>;
}) {
  const [showTable, setShowTable] = useState(false);
  return (
    <>
      <Button variant="outlined" onClick={() => setShowTable((x) => !x)}>
        {showTable ? 'Hide Info' : 'Show Info'}
      </Button>
      {showTable && (
        <div style={{ overflow: 'scroll' }}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Session Duration
                  </TableCell>
                  <TableCell align="right">
                    {moment
                      .utc(pcsSession.current.getSessionDuration())
                      .format('HH:mm:ss')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Livestream Attempts
                  </TableCell>
                  <TableCell align="right">
                    {pcsSession.current.livestreamAttempts}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Successful Attempts
                  </TableCell>
                  <TableCell align="right">
                    {pcsSession.current.livestreamSuccessfulAttempts}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Current Stream Duration
                  </TableCell>
                  <TableCell align="right">
                    {pcsSession.current.stream_start
                      ? moment
                          .utc(pcsSession.current.getCurrentStreamDuration())
                          .format('HH:mm:ss')
                      : '00:00:00'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Livestream Pauses
                  </TableCell>
                  <TableCell align="right">
                    {pcsSession.current.livestreamPauses}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Livestream Stops
                  </TableCell>
                  <TableCell align="right">
                    {pcsSession.current.livestreamStops}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Freeze Detections
                  </TableCell>
                  <TableCell align="right">
                    {pcsSession.current.livestreamFreezeDetections}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    TTLS
                  </TableCell>
                  <TableCell align="right">
                    {pcsSession.current.getTTLs().toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    TTLS Avg
                  </TableCell>
                  <TableCell align="right">
                    {pcsSession.current.getAvgTTLs().toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {pcsSession.current.videoStats.length ? (
              <>
                <Typography variant="h5" style={{ padding: 8 }}>
                  Video
                </Typography>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Property
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        Value
                      </TableCell>
                    </TableRow>
                    {pcsSession.current.videoStats.map(
                      ({ property, value }) => (
                        <TableRow key={property}>
                          <TableCell scope="row">{property}</TableCell>
                          <TableCell align="right" scope="row">
                            {value}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </>
            ) : null}
            {pcsSession.current.audioStats.length ? (
              <>
                <Typography variant="h5" style={{ padding: 8 }}>
                  Audio
                </Typography>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Property
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        Value
                      </TableCell>
                    </TableRow>
                    {pcsSession.current.audioStats.map(
                      ({ property, value }) => (
                        <TableRow key={property}>
                          <TableCell scope="row">{property}</TableCell>
                          <TableCell align="right" scope="row">
                            {value}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </>
            ) : null}
          </TableContainer>
        </div>
      )}
    </>
  );
}
