import React, { useContext, useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './EventListResults';
import Toolbar from './Toolbar';
import { SearchEventContext } from './SearchEventContext';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const EventListView = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [filtersError, setFiltersError] = useState({
    until: false,
    sid: false,
    user_id: false,
    eventId: false,
  });

  const { searchQueriesForEventList, setSearchQueriesForEventList } =
    useContext(SearchEventContext);

  const handleChangeDate = (date, name) => {
    setSearchQueriesForEventList({
      ...searchQueriesForEventList,
      [name]: date,
    });
  };
  const handleChangeFilterInfo = (event) => {
    const target = event.target;

    setFiltersError({
      ...filtersError,
      [target.name]: false,
    });

    const exceptions = ['detection_filter', 'pcs_status', 'handling'];
    const checkExceptions = exceptions.includes(target.name);
    if (checkExceptions) {
      setSearchQueriesForEventList({
        ...searchQueriesForEventList,
        [target.name]: target.value,
      });
    } else {
      !isNaN(+target.value) && !target.value.includes(' ')
        ? setSearchQueriesForEventList({
            ...searchQueriesForEventList,
            [target.name]: target.value,
          })
        : setFiltersError({
            ...filtersError,
            [target.name]: true,
          });
    }
  };
  return (
    <Page className={classes.root} title="Event List">
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary">
          Event List
        </Typography>
        <Toolbar
          clearFilterEnabled
          handleChangeFilterInfo={handleChangeFilterInfo}
          filtersError={filtersError}
          setFiltersErrors={setSearchQueriesForEventList}
          handleChangeDate={handleChangeDate}
        />
        <Paper>
          <Box mt={3} p={1}>
            <Tabs value={activeTab} onChange={(e, val) => setActiveTab(val)}>
              <Tab label="All" />
              <Tab label="Handling" />
              <Tab label="Late" />
              <Tab label="Expired" />
            </Tabs>
            {activeTab === 0 && (
              <Results
                searchValue={searchQueriesForEventList.search}
                filterValues={searchQueriesForEventList}
              />
            )}
            {activeTab === 1 && (
              <Results
                filter="handling"
                searchValue={searchQueriesForEventList.search}
                filterValues={searchQueriesForEventList}
              />
            )}
            {activeTab === 2 && (
              <Results
                filter="late"
                searchValue={searchQueriesForEventList.search}
                filterValues={searchQueriesForEventList}
              />
            )}
            {activeTab === 3 && (
              <Results
                filter="expired"
                searchValue={searchQueriesForEventList.search}
                filterValues={searchQueriesForEventList}
              />
            )}
          </Box>
        </Paper>
      </Container>
    </Page>
  );
};

export default EventListView;
