import React from 'react';
import { Navigate } from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout/MainLayout';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import { Auth0CallbackView } from 'src/views/subscribers/Auth0CallbackView';
import { Auth0CallbackV2View } from 'src/views/subscribers/Auth0CallbackV2View';
import { Auth0LoginCallbackView } from 'src/views/auth/Auth0LoginCallbackView';

const routes = [
  {
    // Auth0 callback url
    path: 'callback',
    element: <Auth0CallbackView />,
  },
  {
    // Auth0 callback v2 url
    path: 'v2/callback',
    element: <Auth0CallbackV2View />,
  },
  {
    // Auth0 login callback
    path: 'auth0/callback',
    element: <Auth0LoginCallbackView />,
  },
  {
    path: 'app',
    element: <MainLayout />,
    children: [{ path: '*', element: <LoginView />, title: 'TBD' }],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },

      // catch all others
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
];

export default routes;
