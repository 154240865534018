import { Avatar, Box, Grid, makeStyles } from '@material-ui/core';
import React, { FC, memo } from 'react';

import { useGetPcsEventCluster } from '../../../../hooks/eventHooks';
import { apiURL } from '../../constants';

interface IProps {
  cluster_id: string;
}

const useStyles = makeStyles({
  modelWrapper: {
    display: 'flex',
    height: '100%',
    overflow: 'scroll',
  },
  modelImage: {
    border: '1px solid transparent',
    width: '150px',
    height: '100%',
  },
});

const Cluster: FC<IProps> = ({ cluster_id }) => {
  const classes = useStyles();

  const clusterImages = useGetPcsEventCluster(cluster_id);

  return (
    <Grid item xs={6}>
      <Box className={classes.modelWrapper}>
        {clusterImages?.data?.cluster?.map((clusterObj) => (
          <Avatar
            key={clusterObj.img_file}
            src={`${apiURL}/${clusterObj.img_file}`}
            className={classes.modelImage}
            variant="square"
          />
        ))}
      </Box>
    </Grid>
  );
};

export default memo(Cluster);
