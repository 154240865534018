import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    speedDial: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  })
);

const actions = [
  { icon: <FormatShapesIcon />, id: 'annotate', name: 'Annotate' },
  { icon: <BrokenImageIcon />, id: 'motion_zone', name: 'Motion Zone' },
  // { icon: <SaveIcon />, name: 'Save' },
  // { icon: <PrintIcon />, name: 'Print' },
  // { icon: <ShareIcon />, name: 'Share' },
  // { icon: <FavoriteIcon />, name: 'Like' }
];

interface Props {
  onClickAction: (actionId: string) => void;
}

export default function SpeedDialTooltipOpen({ onClickAction }: Props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  // Could use this to hide unless mouse is over the video...
  // const [hidden, setHidden] = React.useState(false);
  // const handleVisibility = () => {
  //   setHidden(prevHidden => !prevHidden);
  // };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (actionId: string) => {
    setOpen(false);
    onClickAction(actionId);
  };

  return (
    <SpeedDial
      ariaLabel="SpeedDial tooltip example"
      className={classes.speedDial}
      icon={<SpeedDialIcon />}
      direction="down"
      FabProps={{ size: 'small' }}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      // hidden={hidden}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          tooltipOpen
          onClick={() => handleClick(action.id)}
        />
      ))}
    </SpeedDial>
  );
}
