import React from 'react';
import PropTypes from 'prop-types';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import FlagIcon from '@material-ui/icons/Flag';
import { IconButton } from '@material-ui/core';
import { useFlagEventForReview } from 'src/hooks/eventHooks';

const FlagEventButton = ({ event }) => {
  const flagEvent = useFlagEventForReview(event.eventId);

  return (
    <IconButton
      onClick={() => flagEvent.mutate({ flag: !event.flaggedForReview })}
    >
      {event.flaggedForReview === true ? <FlagIcon /> : <FlagOutlinedIcon />}
    </IconButton>
  );
};

FlagEventButton.propTypes = {
  event: PropTypes.any,
};

export default FlagEventButton;
