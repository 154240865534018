import React, { useEffect, useState } from 'react';
import {
  STOPWATCH_HIGH_ALERT_COLOR,
  STOPWATCH_HIGH_ALERT_SECONDS_GREATER_THAN,
  STOPWATCH_LOW_ALERT_COLOR,
  STOPWATCH_MEDIUM_ALERT_COLOR,
  STOPWATCH_MEDIUM_ALERT_SECONDS_GREATER_THAN,
} from 'src/components/DataStream/constants';

const Stopwatch = () => {
  const [secondsSinceLoaded, setSecondsSinceLoaded] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSecondsSinceLoaded((current) => current + 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <span
      aria-live="polite"
      aria-label="Stopwatch"
      style={{
        color:
          secondsSinceLoaded > STOPWATCH_HIGH_ALERT_SECONDS_GREATER_THAN
            ? STOPWATCH_HIGH_ALERT_COLOR
            : secondsSinceLoaded > STOPWATCH_MEDIUM_ALERT_SECONDS_GREATER_THAN
            ? STOPWATCH_MEDIUM_ALERT_COLOR
            : STOPWATCH_LOW_ALERT_COLOR,
      }}
    >
      {`${secondsSinceLoaded} second${
        !secondsSinceLoaded || secondsSinceLoaded > 1 ? 's' : ''
      } ha${!secondsSinceLoaded || secondsSinceLoaded > 1 ? 've' : 's'} passed`}
    </span>
  );
};

export default Stopwatch;
