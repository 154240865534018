import React from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import SubscriberList from './SubscriberList';
import { Role } from 'src/models';
import { Navigate } from 'react-router';
import { useGetRepMe } from 'src/hooks/repHooks';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export const SubscriberListView = () => {
  const classes = useStyles();
  const { data: repData } = useGetRepMe();

  if (
    repData?.role === Role.QA ||
    repData?.role === Role.AGENT_OPS ||
    repData?.role === Role.AGENT_SUPERVISOR
  )
    return <Navigate to="/app/dashboard" />;
  return (
    <Page className={classes.root} title="Subscribers">
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary">
          PCS Subscribers
        </Typography>

        <Box mt={3}>
          <SubscriberList />
        </Box>
      </Container>
    </Page>
  );
};
