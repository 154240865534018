import React, { createContext, useState, useEffect } from 'react';
import { getLocalStorage, setLocalStorage } from 'src/utils/localstorage';

export const SearchEventContext = createContext();

export const EVENT_LIST_FILTER_INITIAL_DATA = {
  pcs_status: '',
  search: '',
  handling: '',
  sid: '',
  user_id: '',
  detection_filter: '',
  endDatetime: null,
  startDatetime: null,
  eventId: '',
};

const SearchEventContextProvider = ({ children }) => {
  const [searchQueriesForEventList, setSearchQueriesForEventList] = useState(
    () =>
      getLocalStorage(
        'searchQueriesForEventList',
        EVENT_LIST_FILTER_INITIAL_DATA
      )
  );
  const [searchQueriesForAuditList, setSearchQueriesForAuditList] = useState({
    search: '',
    edit_uid: '',
    user_id: '',
    sid: '',
    type: '',
    endDatetime: null,
    startDatetime: null,
  });

  useEffect(() => {
    setLocalStorage('searchQueriesForEventList', searchQueriesForEventList);
  }, [searchQueriesForEventList]);

  return (
    <SearchEventContext.Provider
      value={{
        searchQueriesForEventList,
        setSearchQueriesForEventList,
        searchQueriesForAuditList,
        setSearchQueriesForAuditList,
      }}
    >
      {children}
    </SearchEventContext.Provider>
  );
};

export default SearchEventContextProvider;
