import moment from 'moment';

export const getLocalDateFormatted = ({ timestampUTC, locationOffsetMinutes }: { timestampUTC: number, locationOffsetMinutes: number }) => {
    return moment.unix(timestampUTC + locationOffsetMinutes * 60).utc().format('MM/DD/YYYY');
}

export const getLocalTimeFormatted = ({ timestampUTC, locationOffsetMinutes }: { timestampUTC: number, locationOffsetMinutes: number }) => {
    return moment.unix(timestampUTC + locationOffsetMinutes * 60).utc().format('h:mm:ss a')
}


