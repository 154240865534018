import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Label from 'src/components/Label';
import NavBar from './NavBar/NavBar';
import TopBar from './TopBar';
import { useGetEventStats } from 'src/hooks/reportHooks';
import { usePcsAuthRep } from 'src/context/pcs-auth-context';
import { HandledStatuses } from 'src/models';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 25,
    [theme.breakpoints.up('lg')]: {
      // paddingLeft: 256
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    // height: '100%',
    height: '98vh',
    // height: 'calc(100% - 26px)',
    overflow: 'auto',
  },
}));

const AppLayout = ({ title }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { id } = usePcsAuthRep();

  const myStats = useGetEventStats(0, 60 * 60 * 12, 0, id);
  const handled = myStats.data
    ?.filter((stat) => HandledStatuses.includes(stat.pcs_status))
    .reduce((sum, stat) => sum + stat.count, 0);
  const newEvents =
    myStats.data?.find((stat) => stat.pcs_status === 0)?.count || 0;
  const watching =
    myStats.data?.find((stat) => stat.pcs_status === 10)?.count || 0;
  const verifying =
    myStats.data?.find((stat) => stat.pcs_status === 20)?.count || 0;
  const dispatching =
    myStats.data?.find((stat) => stat.pcs_status === 30)?.count || 0;
  const finishing =
    myStats.data?.find((stat) => stat.pcs_status === 40)?.count || 0;

  const vdfColor =
    verifying + dispatching + finishing > 1 ? 'error' : 'primary';

  const statsText = (
    <>
      <Label color="primary">N: {newEvents}</Label>{' '}
      <Label color={watching > 9 ? 'error' : 'primary'}>W: {watching}</Label>{' '}
      <Label color={vdfColor}>V: {verifying}</Label>{' '}
      <Label color={vdfColor}>D: {dispatching}</Label>{' '}
      <Label color={vdfColor}>F: {finishing}</Label>{' '}
      <Label color="primary">H: {handled}</Label>{' '}
    </>
  );
  return (
    <div className={classes.root}>
      <TopBar
        title={title}
        stats={statsText}
        onMobileNavOpen={() => setMobileNavOpen(!isMobileNavOpen)}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;

AppLayout.propTypes = {
  title: PropTypes.string,
};
