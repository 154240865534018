import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useQueryParams } from 'src/utils/useQueryParams';

export function useMostRecent() {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { pathname, userId, sid, eventId, uuid } = queryParams;
  const prevEventRef = useRef(null);
  useEffect(() => {
    // Use sid as flag that we're saving or getting
    if (sid) {
      localStorage.setItem(
        pathname,
        JSON.stringify({ userId, sid, eventId, uuid })
      );
    } else {
      const prevEvent = JSON.parse(localStorage.getItem(pathname));
      prevEventRef.current = prevEvent;
      if (prevEvent) {
        navigate(
          `${pathname}?userId=${prevEvent.userId}&sid=${prevEvent.sid}&uuid=${prevEvent.uuid}&eventId=${prevEvent.eventId}`,
          { replace: true }
        );
      } else {
        navigate('/app/dashboard');
      }
    }
  }, [userId, sid, eventId, uuid, navigate, pathname]);
  if (prevEventRef.current) {
    return prevEventRef.current;
  } else {
    return queryParams;
  }
}
