import { useMutation, useQuery, useQueryClient } from 'react-query';
import { usePcsClient } from 'src/context/pcs-auth-context';
import { useSnackbar } from 'notistack';

// TYPES
export enum PersonCategory {
  FRIENDLY = 'friendly',
  NEUTRAL = 'neutral',
  UNKNOWN = 'unknown',
  BANNED = 'banned',
}

export interface PersonCreate {
  user_id: number;
  sid: number;
  name: string;
  category: PersonCategory;
  faces?: Array<Face>;
}

export interface Person {
  person_id: string; // uuid4
  user_id: number;
  sid: number;
  name: string;
  main_photo: string;
  locations: [];
  category: PersonCategory;
  faces: Face[];
  photos?: Photo[];
}

interface BoundingBox {
  x: number;
  y: number;
  w: number;
  h: number;
}

interface FaceLandmarks {
  left_eye: Array<number>; // [x,y]
  right_eye: Array<number>;
  nose: Array<number>;
  left_lip: Array<number>;
  right_lip: Array<number>;
}

export interface Photo {
  img_id: string;
  img_file: string;
}

export interface Face {
  type: string; // ie, retinaface-facenet
  id: string; // UUID4 = uuid.uuid4()  # Unique ID for this face
  pts_seconds?: number;
  img_file?: string;
  face_confidence?: number;
  dominant_emotion?: string;
  emotion_score?: number;
  embedding?: Array<number>;
  group_id?: string; // Matched face in this cluster (event)
  group_distance?: number;
  match_id?: string; // Matched face in faces database
  match_distance?: number;
  bbox?: BoundingBox;
  landmarks?: FaceLandmarks;
  face_img?: any;
}

// PEOPLE QUERY HOOKS

export function useCreatePerson() {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (person: PersonCreate) =>
      client(`people/new`, {
        method: 'POST',
        data: person,
      }),
    {
      onSuccess: () => {
        cache.invalidateQueries('people');
      },
      onError: (error) => {
        enqueueSnackbar(`Endpoint: people/new. ${error}`, {
          variant: 'error',
        });
      },
    }
  );
  return mutation;
}

export function useUpdatePerson() {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (person: Person) =>
      client(`people/update/${person.person_id}`, {
        method: 'POST',
        data: person,
      }),
    {
      onSuccess: () => {
        cache.invalidateQueries('people');
      },
      onError: (error) => {
        enqueueSnackbar(`Endpoint: people/update/{person_id}. ${error}`, {
          variant: 'error',
        });
      },
    }
  );
  return mutation;
}

export function useAddFaceToPerson() {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (data: { eventId: number; person_id: string; face_id: string }) =>
      client(
        `people/add-face/${data.person_id}?eventId=${data.eventId}&face_id=${data.face_id}`,
        {
          method: 'POST',
        }
      ),
    {
      onSuccess: () => {
        cache.invalidateQueries('people');
      },
      onError: (error) => {
        enqueueSnackbar(`Endpoint: people/add-face/. ${error}`, {
          variant: 'error',
        });
      },
    }
  );
  return mutation;
}

export function useSetPersonForEventFace() {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (data: { eventId: number; face_id: string; person_id: string }) =>
      client(
        `people/set-person/${data.eventId}?face_id=${data.face_id}&person_id=${data.person_id}`,
        { method: 'POST' }
      ),
    {
      onSuccess: () => {
        cache.invalidateQueries('people');
        cache.invalidateQueries('pcs_event');
        cache.invalidateQueries('pcs_events');
      },
      onError: (error) => {
        enqueueSnackbar(`Endpoint: people/set-person/{eventId}. ${error}`, {
          variant: 'error',
        });
      },
    }
  );
  return mutation;
}

export function useGetPeople(
  userId: number,
) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();

  const query = useQuery(['people', userId], () => client(`people/get/sub/${userId}`), {
    onError: (error) => {
      enqueueSnackbar(`Endpoint: people/get/sub/{userId}. ${error}`, {
        variant: 'error',
      });
    },
  });
  return query;
}
