import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ReplayIcon from '@material-ui/icons/Replay';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';
import React, { FC, SyntheticEvent, useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#111',
    height: '100%',
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex',
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

interface IProps {
  message?: string;
  actionText?: string;
  className?: string;
  onClick?: () => void;
}

const PrivateCamera: FC<IProps> = ({
  message = 'Privacy Shutter Closed',
  actionText = 'No access allowed',
  className,
  onClick,
  ...rest
}) => {
  const classes = useStyles();

  const handleClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    onClick();
  }, [onClick]);
  return (
    <Card className={clsx(classes.root, className)} onClick={handleClick} {...rest}>
      <CardContent className="aspectRatio">
        <Box display="flex" justifyContent="center" mb={3}>
          <VisibilityOffIcon />
        </Box>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          {message}
        </Typography>
        <Typography align="center" color="textPrimary" variant="body1">
          {actionText}
        </Typography>
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
      <Box px={2} py={0.8} onClick={(e) => e.stopPropagation()}>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid className={classes.statsItem} item>
            <AccessTimeIcon className={classes.statsIcon} color="action" />
            <Typography color="textSecondary" display="inline" variant="body2">
              Updated
            </Typography>
          </Grid>
          <Grid className={classes.statsItem} item>
            <ReplayIcon className={classes.statsIcon} color="action" />
            <Typography color="textSecondary" display="inline" variant="body2">
              Retry
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default PrivateCamera;
