import React, { useEffect, useState } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import {
  useGetSpeedTestData,
  useRequestSpeedTest,
} from 'src/hooks/cameraHooks';
import { useParams } from 'react-router-dom';
import CameraSpeedTestResults from './CameraSpeedTestResults';
import { LoadingSpinner } from 'src/components/LoadingSpinner';

const CameraSpeedTestDetails = ({ camera }) => {
  const { userId } = useParams();
  const { data, isLoading, error, refetch, isFetching } = useGetSpeedTestData(
    camera?.sid,
    camera?.uuid,
    userId
  );

  const requestSpeedTestMutation = useRequestSpeedTest(
    camera?.sid,
    camera?.uuid,
    'start',
    userId
  );

  const [speedTestResult, setSpeedTestResult] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (!isLoading && !error && data?.results?.length) {
      setSpeedTestResult([data.results[0]]);
    }
  }, [data, isLoading, error]);

  useEffect(() => {
    if (!isFetching) {
      setIsRefreshing(false);
    }
  }, [isFetching]);

  const refreshSpeedTestResult = () => {
    refetch();
    setIsRefreshing(true);
  };

  const requestSpeedTest = () => {
    requestSpeedTestMutation.mutate();
  };

  return (
    <>
      {isLoading || isRefreshing ? (
        <LoadingSpinner />
      ) : (
        <>
          <Typography
            variant="h2"
            style={{ color: 'rgba(255, 255, 255, 0.7)', marginBottom: 10 }}
          >
            Speed Test Results
          </Typography>

          {speedTestResult.length ? (
            <>
              <CameraSpeedTestResults speedTestResult={speedTestResult} />
            </>
          ) : (
            <Typography>
              No results to report. After requesting a test, it takes around 2
              minutes for the result to arrive.
            </Typography>
          )}
        </>
      )}
      <Box display={'flex'} gridGap={10} mt={3}>
        <Button
          color="primary"
          variant="contained"
          style={{ textTransform: 'none', fontSize: 15 }}
          onClick={refreshSpeedTestResult}
          disabled={isLoading}
        >
          Refresh Speed Test Data
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={requestSpeedTestMutation.isLoading}
          style={{ textTransform: 'none', fontSize: 15 }}
          onClick={requestSpeedTest}
        >
          Request Speed Test
        </Button>
      </Box>
    </>
  );
};

export default CameraSpeedTestDetails;
