import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { usePcsClient } from 'src/context/pcs-auth-context';
import { useQueryParams } from 'src/utils/useQueryParams';

type ListInvitesParams = {
  page: number;
  page_size: number;
  search?: string;
};

export function useListInvites(params: ListInvitesParams) {
  console.log('useListInvites', params);
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery(
    ['invites', params],
    () => client(`invites`, { params: params }),
    {
      onError: (error) => {
        enqueueSnackbar(`Endpoint: invites. ${error}`, {
          variant: 'error',
        });
      },
      refetchInterval: 20000,
    }
  );
  return query;
}

type InviteUserParams = {
  email: string;
};

export function useInviteUsers() {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (params: InviteUserParams) =>
      client(`invites/bulk`, {
        method: 'POST',
        data: params,
      }),
    {
      onSuccess: (data) => {
        cache.invalidateQueries('invites');
        enqueueSnackbar(
          `Imported ${data.count} invites. Skipped: ${data.skipped}`,
          {
            variant: 'success',
          }
        );
      },
      onError: (error) => {
        enqueueSnackbar(`Endpoint: /invites. ${error}`, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
}
