// Setup the state and callbacks for subscription data

// import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ReadyState } from 'react-use-websocket';
import { useSsUserSocket } from './socketHooks';
import { usePcsClient } from 'src/context/pcs-auth-context';

export function useSetAlarmState({ sid, account, userId }) {
  const client = usePcsClient();
  // TODO: need to check Yoda query status... can't use untill isSuccess
  const cache = useQueryClient();
  const mutation = useMutation(
    (state) =>
      client(`subscriptions/${sid}/${account}/alarmState`, {
        method: 'POST',
        data: {
          state,
        },
      }),
    {
      onSuccess: (data, variables) => {
        cache.invalidateQueries(['subscriptions', userId, sid]);
        cache.invalidateQueries(['alarm_state', sid, account]);
        cache.setQueryData(['alarm_state', sid, account], data);
      },
    }
  );

  return mutation;
}

export function useAlarmState({ sid, userId, account, forceUpdate = 'false' }) {
  const client = usePcsClient();
  const { readyState } = useSsUserSocket(userId);
  const query = useQuery({
    queryKey: ['alarm_state', sid, account],
    queryFn: () =>
      client(
        `subscriptions/${sid}/${account}/alarmState?forceUpdate=${forceUpdate}`
      ),
    config: {
      refetchInterval: readyState !== ReadyState.OPEN ? 2000 : null,
    },
    enabled: !!(sid && account),
  });
  return query;
  // return {
  //   state: data?.state,
  //   stateUpdated: data?.stateUpdate,
  //   exitDelay: data?.exitDelay,
  //   isLoading,
  //   error,
  // };
}
