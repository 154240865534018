import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import { useListAuditQuery } from 'src/hooks/auditHooks';
import { LoadingSpinner } from 'src/components/LoadingSpinner';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({ className, filterValues, searchValue }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const params = {
    event_id: filterValues?.event_id,
    skip: page * limit,
    limit: limit,
    edit_uid: filterValues?.edit_uid || '',
    user_id: filterValues?.user_id || '',
    sid: filterValues?.sid || '',
    type: filterValues?.type || '',
    endDatetime:
      filterValues?.endDatetime === undefined
        ? ''
        : filterValues?.endDatetime.ts,
    startDatetime:
      filterValues?.startDatetime === undefined
        ? ''
        : filterValues?.startDatetime.ts,
    searchBy: searchValue ?? '',
  };
  for (let key of Object.keys(params)) {
    if (params[key] === '') {
      delete params[key];
    }
  }

  const eventsQuery = useListAuditQuery(params);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = eventsQuery.data.map(
        (customer) => customer.history_id
      );
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  function checkType(event) {
    if ('people' in event) {
      return [
        <TableCell>{'People'}</TableCell>,
        <TableCell>
          <pre>
            {JSON.stringify(event.people.change)?.replaceAll(',', '\n')}
          </pre>{' '}
        </TableCell>,
      ];
    }
    if ('event' in event) {
      return [
        <TableCell>{'Event'}</TableCell>,
        <TableCell>
          <pre>{JSON.stringify(event.event.change)?.replaceAll(',', '\n')}</pre>
        </TableCell>,
      ];
    }
    if ('camera' in event) {
      return [
        <TableCell>{'Camera'}</TableCell>,
        <TableCell>
          <pre>
            {JSON.stringify(event.camera.change)?.replaceAll(',', '\n')}
          </pre>
        </TableCell>,
      ];
    }
    if ('service' in event) {
      return [
        <TableCell>{'Service'}</TableCell>,
        <TableCell>
          <pre>
            {JSON.stringify(event.service.change)?.replaceAll(',', '\n')}
          </pre>
        </TableCell>,
      ];
    }
    if ('documentation' in event) {
      return [
        <TableCell>{'Documentation'}</TableCell>,
        <TableCell>
          <pre>{event.documentation?.change?.documentation}</pre>
        </TableCell>,
      ];
    }
  }

  if (eventsQuery.isLoading) return <LoadingSpinner />;
  return (
    <Card className={clsx(classes.root, className)}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      selectedCustomerIds.length !== 0 &&
                      selectedCustomerIds.length === eventsQuery.data.length
                    }
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0 &&
                      selectedCustomerIds.length < eventsQuery.data.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Event ID</TableCell>
                <TableCell>Camera uuid</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>EditUID</TableCell>
                <TableCell>UserID</TableCell>
                <TableCell>SID</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Change</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventsQuery.data?.slice(0, limit).map((event) => {
                let eventId = '';
                if ('event' in event) {
                  eventId = event.event.eventId;
                } else if ('documentation' in event) {
                  eventId = event.documentation.eventId;
                }

                return (
                  <TableRow
                    hover
                    key={event.history_id}
                    selected={
                      selectedCustomerIds.indexOf(event.history_id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedCustomerIds.indexOf(event.history_id) !== -1
                        }
                        onChange={(e) => handleSelectOne(e, event.history_id)}
                        value="true"
                      />
                    </TableCell>

                    <TableCell className={'fs-exclude'}>{eventId}</TableCell>
                    <TableCell className={'fs-exclude'}>
                      {'camera' in event ? event.camera.uuid.slice(-11) : ''}
                    </TableCell>
                    <TableCell>
                      {moment
                        .unix(event.history_timestamp)
                        .local()
                        .format('MM/DD/YYYY h:mm:ss A')}
                    </TableCell>
                    <TableCell className={'fs-exclude'}>
                      {event.edit_uid}
                    </TableCell>
                    <TableCell className={'fs-exclude'}>
                      {event.user_id}
                    </TableCell>
                    <TableCell className={'fs-exclude'}>{event.sid}</TableCell>
                    {checkType(event)}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={-1}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 100, 200]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.string,
};

export default Results;
