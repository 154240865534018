import React from 'react';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import { useGetRepMe } from 'src/hooks/repHooks';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import AudioSettings from './AudioSettings';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Account = () => {
  const classes = useStyles();

  const repQuery = useGetRepMe();

  if (!repQuery.isSuccess)
    return <LoadingSpinner message="Getting rep profile details" />;

  return (
    <Page className={classes.root} title="Account">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Profile rep={repQuery.data} />
              </Grid>
              <Grid item xs={12}>
                <AudioSettings />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <ProfileDetails rep={repQuery.data} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
