import moment from 'moment';

const BUFFER_LIMIT = 500;
const BUFFER = [] as Array<LogItem>;

export type LogItem = {
  message: string;
  data: Record<string, any>;
};

function getStackTrace(e?: Error) {
  const error = e && e.stack && e.stack.split ? e : new Error();
  return error.stack
    .split('\n')
    .slice(2)
    .map((line) => line.replace(/\s+at\s+/, ''))
    .join('\n');
}

export function log({ message, data }: LogItem, e?: Error) {
  const ts = Date.now();
  BUFFER.push({
    message: `${moment(ts).toISOString()} - ${message}`,
    data: {
      ...data,
      ts,
      stack: getStackTrace(e),
    },
  });
  if (BUFFER.length > BUFFER_LIMIT) {
    BUFFER.splice(0, BUFFER.length - BUFFER_LIMIT);
  }
}

export type LogRequest = {
  logs: Array<LogItem>;
};

async function logPost(token: string, data: LogRequest) {
  const endpoint = '/front-metrics/logs/upload';
  const url = process.env.REACT_APP_PCS_ADMIN_URL + endpoint;
  const config = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  return window.fetch(url, config).then((response) => {
    if (response.status === 401) {
      console.log(`401 Not Authorized on PCS Client`);
      return Promise.reject(
        new Error(`Error caught: Unauthorized PCS call to ${endpoint}`)
      );
    }
    if (!response.ok) {
      return response.text().then((text) => {
        throw new Error(text);
      });
    }
    return response.json();
  });
}

async function logTick() {
  if (BUFFER.length > 0) {
    const token = localStorage.getItem('token');
    if (token) {
      const logs = BUFFER.splice(0, BUFFER.length);
      try {
        await logPost(token, {
          logs,
        });
      } catch (e) {
        console.error(`Error sending logs: ${JSON.stringify(logs)}`, e);
      }
    }
  }
}

setInterval(logTick, 5000);
