import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useRoutes } from 'react-router-dom';
import Route from 'src/route';
import { usePcsAuthRep } from 'src/context/pcs-auth-context';
import unAuthRoutes from 'src/routes-no-auth';
import FullPageSpinner from 'src/components/FullPageSpinner';
import VersionCheck from 'src/components/VersionCheck';
import ScreenContextProvider from './context/ScreenRecordingContext';
import SearchEventContextProvider from './views/events/SearchEventContext';

const App = () => {
  const { id } = usePcsAuthRep();
  // ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, {
  ReactGA.initialize(
    [
      {
        trackingId: process.env.REACT_APP_GA_MEASUREMENT_ID,
        gaOptions: {
          userId: id,
          clientId: id,
        },
      },
    ],
    {}
  );

  const unAuthRouting = useRoutes(unAuthRoutes);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      userId: id,
      clientId: id,
    });
  }, [location.href]);

  return (
    <>
      <React.Suspense fallback={<FullPageSpinner message="Loading App..." />}>
        <ScreenContextProvider>
          <SearchEventContextProvider>
            {id ? <Route /> : unAuthRouting}
          </SearchEventContextProvider>
        </ScreenContextProvider>
        <VersionCheck />
      </React.Suspense>
    </>
  );
};

export default App;
