export async function playAudio(
  path: string,
  destination: AudioNode,
  context: AudioContext,
): Promise<void> {
  try {
    await context.resume();
  } catch (_e) {
    // ignore
  }
  return new Promise((res, rej) => {
    const audio = new Audio(path);
    const src = context.createMediaElementSource(audio);
    src.connect(destination);
    audio.play();
    audio.addEventListener('ended', async () => {
      try {
        src.disconnect(destination);
        res();
      } catch (e) {
        rej(e);
      }
    });
  });
}

export async function playSiren(destination: AudioNode, context) {
  return playAudio('/static/siren.wav', destination, context);
}

export async function playStart(destination: AudioNode, context) {
  return playAudio('/static/chirp-start.wav', destination, context);
}

export async function playStop(destination: AudioNode, context) {
  return playAudio('/static/chirp-stop.wav', destination, context);
}

export async function getLocalStreamSource(
  constraints: { video?: boolean; audio?: boolean },
  context = new AudioContext()
): Promise<MediaStreamAudioSourceNode> {
  const localStream = await navigator.mediaDevices.getUserMedia(constraints);
  return context.createMediaStreamSource(localStream);
}

export async function playVideoComponent(player: HTMLVideoElement) {
  return player
    .play()
    .then(() => { })
    .catch((error) => {
      const text = String(error).toLowerCase();
      if (
        text.includes('the play() request was interrupted by a call to pause()')
      ) {
        // do nothing, we catch only this exception https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
      } else if (
        text.includes(
          "play() failed because the user didn't interact with the document first"
        )
      ) {
        // do nothing, failed because there is no user interaction yet
        console.warn('Trying to play without user interaction');
      } else {
        throw error;
      }
    });
}
