import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tabs,
  Tab,
  makeStyles,
} from '@material-ui/core';
import {
  useChangeActiveStatus,
  useChangeUserInfo,
  useChangeRole,
  useSaveQueueSittings,
  useGetRepMe,
} from 'src/hooks/repHooks';
import { AlarmState } from 'src/views/queue/MonitorView';
import { useSnackbar } from 'notistack';
import { Role } from 'src/models';
import { useNavigate } from 'react-router';
import { useGetRepById } from 'src/hooks/repHooks';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { useParams } from 'react-router-dom';
import { useGetQueueSettings } from 'src/hooks/repHooks';

const useStyles = makeStyles({
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    minWidth: 115,
  },
});

const ManageReps = () => {
  const classes = useStyles();
  const { userId } = useParams();
  const [activeTab, setActiveTab] = React.useState(0);
  const { data: currentRep } = useGetRepMe();
  let rep = useGetRepById(userId);
  const setRepsStatusMutation = useChangeActiveStatus();
  const setRepsRoleMutation = useChangeRole();
  const setRepsInfoMutation = useChangeUserInfo();
  const setQueueSittings = useSaveQueueSittings();
  const navigate = useNavigate();
  const pcs_watch_settings = useGetQueueSettings(rep?.data?.id);

  const loadData = () => {
    if (rep && rep.data) {
      setValuesUserInfo({
        email: rep.data.email,
        firstName: rep.data.firstName,
        lastName: rep.data.lastName,
      });
      setValueStatus(rep.data.is_active);
      setValueRole({
        role: rep.data.role,
      });
    }
  };
  const setQueueSettings = () => {
    if (pcs_watch_settings.data) {
      setValueAccept(pcs_watch_settings.data.acceptNew);
      setValues({
        acceptNew: pcs_watch_settings.data.acceptNew,
        limit: pcs_watch_settings.data.limit,
        autoExpire: pcs_watch_settings.data.autoExpire,
        pcsStatusThreshold: pcs_watch_settings.data.pcsStatusThreshold,
        threatLevel: pcs_watch_settings.data.threatLevel,
        lookback: pcs_watch_settings.data.lookback,
        armstate: pcs_watch_settings.data.armstate,
      });
    }
  };
  useEffect(() => {
    setQueueSettings();
  }, [pcs_watch_settings.data]);

  useEffect(() => {
    loadData();
  }, [rep.data]);

  const [valuesUserInfo, setValuesUserInfo] = useState({
    email: '',
    firstName: '',
    lastName: '',
  });

  const [valuesStatus, setValueStatus] = useState(false);
  const [valuesAccept, setValueAccept] = useState(false);
  const [valuesRole, setValueRole] = useState({
    role: '',
  });

  const [values, setValues] = useState({
    acceptNew: false,
    limit: 0,
    autoExpire: 0,
    pcsStatusThreshold: 0,
    threatLevel: 0,
    lookback: 0,
    armstate: 0,
  });
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeUserInfo = (event) => {
    setValuesUserInfo({
      ...valuesUserInfo,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeStatus = (event) => {
    setValueStatus(event.target.checked);
  };
  const handleChangeAccepsNew = (event) => {
    setValueAccept(event.target.checked);
  };
  const { enqueueSnackbar } = useSnackbar();
  React.useEffect(() => {
    if (
      setRepsInfoMutation.status !== 'loading' &&
      setRepsRoleMutation.status !== 'loading' &&
      setRepsStatusMutation.status !== 'loading'
    ) {
      if (
        setRepsRoleMutation.status === 'error' ||
        setRepsStatusMutation.status === 'error' ||
        setRepsInfoMutation.status === 'error'
      ) {
        enqueueSnackbar(
          `${
            setRepsRoleMutation.status === 'error'
              ? setRepsRoleMutation.error.message
              : ''
          } ${
            setRepsStatusMutation.status === 'error'
              ? 'Status saving failed. '
              : ''
          } ${
            setRepsInfoMutation.status === 'error'
              ? 'User info saving failed (first name, last name, email). '
              : ''
          }`,
          {
            variant: 'error',
          }
        );
      } else if (
        setRepsRoleMutation.isSuccess &&
        setRepsStatusMutation.isSuccess &&
        setRepsInfoMutation.isSuccess
      ) {
        enqueueSnackbar(`Successfully saved`, {
          variant: 'success',
        });
        navigate('/app/reps-list');
      }
      return () => {};
    }
  }, [
    setRepsRoleMutation.isError,
    setRepsRoleMutation.isSuccess,
    setRepsStatusMutation.isError,
    setRepsStatusMutation.isSuccess,
    setRepsInfoMutation.isError,
    setRepsInfoMutation.isSuccess,
    setRepsRoleMutation.isLoading,
    setRepsStatusMutation.isLoading,
    setRepsInfoMutation.isLoading,
    enqueueSnackbar,
  ]);
  const handleSaveQueueSettings = () => {
    setQueueSittings.mutate({
      id: rep.data.id,
      acceptNew: valuesAccept,
      limit: values.limit,
      autoExpire: values.autoExpire,
      pcsStatusThreshold: values.pcsStatusThreshold,
      threatLevel: values.threatLevel,
      lookback: values.lookback,
      armstate: values.armstate,
    });
  };
  const handleSaveRepsInfo = () => {
    setRepsStatusMutation.mutate({
      id: rep.data.id,
      rep_status: valuesStatus,
    });
    setRepsRoleMutation.mutate({
      id: rep.data.id,
      role: valuesRole.role,
    });
    setRepsInfoMutation.mutate({
      id: rep.data.id,
      email: valuesUserInfo.email !== '' ? valuesUserInfo.email : undefined,
      firstName:
        valuesUserInfo.firstName !== '' ? valuesUserInfo.firstName : undefined,
      lastName:
        valuesUserInfo.lastName !== '' ? valuesUserInfo.lastName : undefined,
    });
  };

  const changeRole = () => {
    return (
      <>
        <Divider />
        <CardContent>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="First name"
              name="firstName"
              onChange={handleChangeUserInfo}
              value={valuesUserInfo.firstName}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Last name"
              name="lastName"
              onChange={handleChangeUserInfo}
              value={valuesUserInfo.lastName}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              onChange={handleChangeUserInfo}
              value={valuesUserInfo.email}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="overline"> Status</Typography>

            <Checkbox
              color="primary"
              checked={valuesStatus}
              onChange={handleChangeStatus}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel>Role</InputLabel>
              <Select
                value={valuesRole.role}
                label="Role"
                onChange={(event) =>
                  setValueRole({
                    ...valuesRole,
                    role: event.target.value,
                  })
                }
              >
                <MenuItem value={Role.ADMIN}>Admin</MenuItem>
                <MenuItem value={Role.QA}>QA</MenuItem>
                <MenuItem value={Role.ENGINEER}>Engineer</MenuItem>
                <MenuItem value={Role.AGENT_SUPERVISOR}>
                  Agent supervisor
                </MenuItem>
                <MenuItem value={Role.AGENT_OPS}>Agent ops</MenuItem>
                <MenuItem value={Role.CUMSOMER}>Customer</MenuItem>
                <MenuItem value={Role.CX_ORDER_REPORT}>
                  CX order report
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSaveRepsInfo}
          >
            Save
          </Button>
        </Box>
      </>
    );
  };

  const queueSettings = () => {
    return (
      <>
        <Divider />
        <CardContent>
          <Grid item md={6} xs={12}>
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel>Max Onscreen</InputLabel>
              <Select
                value={values.limit}
                label="Limit"
                name="limit"
                onChange={handleChange}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={9}>9</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel>Auto Expire</InputLabel>
              <Select
                value={values.autoExpire}
                label="Auto Expire"
                name="autoExpire"
                onChange={handleChange}
              >
                <MenuItem value={30}>30 sec</MenuItem>
                <MenuItem value={45}>45 sec</MenuItem>
                <MenuItem value={1 * 60}>1 min</MenuItem>
                <MenuItem value={2 * 60}>2 min</MenuItem>
                <MenuItem value={3 * 60}>3 min</MenuItem>
                <MenuItem value={4 * 60}>4 min</MenuItem>
                <MenuItem value={5 * 60}>5 min</MenuItem>
                <MenuItem value={10 * 60}>10 min</MenuItem>
                <MenuItem value={60 * 60}>60 min</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {currentRep.role != Role.AGENT_SUPERVISOR ? (
            <Grid item md={6} xs={12}>
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel>PCS Status</InputLabel>
                <Select
                  value={values.pcsStatusThreshold}
                  label="Pcs Status"
                  name="pcsStatusThreshold"
                  onChange={handleChange}
                >
                  <MenuItem value={-30}>Rep Handled</MenuItem>
                  <MenuItem value={-20}>PCS AI Handled</MenuItem>
                  <MenuItem value={-10}>Expired</MenuItem>
                  <MenuItem value={0}>New</MenuItem>
                  <MenuItem value={10}>Watching</MenuItem>
                  <MenuItem value={20}>Verifying</MenuItem>
                  <MenuItem value={30}>Dispatching</MenuItem>
                  <MenuItem value={40}>Follow Up</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          ) : null}
          <Grid item md={6} xs={12}>
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel>Threat Level</InputLabel>
              <Select
                value={values.threatLevel}
                label="Threat Level"
                name="threatLevel"
                onChange={handleChange}
              >
                <MenuItem value={0}>None</MenuItem>
                <MenuItem value={10}>Motion</MenuItem>
                <MenuItem value={20}>Person</MenuItem>
                <MenuItem value={30}>TBD</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {currentRep.role != Role.AGENT_SUPERVISOR ? (
            <Grid item md={6} xs={12}>
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel>Lookback</InputLabel>
                <Select
                  value={values.lookback}
                  label="Lookback"
                  name="lookback"
                  onChange={handleChange}
                >
                  <MenuItem value={1 * 60}>1 min</MenuItem>
                  <MenuItem value={5 * 60}>5 min</MenuItem>
                  <MenuItem value={10 * 60}>10 min</MenuItem>
                  <MenuItem value={60 * 60}>60 min</MenuItem>
                  <MenuItem value={120 * 60}>2 hr</MenuItem>
                  <MenuItem value={60 * 60 * 24}>1 day</MenuItem>
                  <MenuItem value={60 * 60 * 24 * 2}>2 days</MenuItem>
                  <MenuItem value={60 * 60 * 24 * 6}>6 days</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          ) : null}
          {currentRep.role != Role.AGENT_SUPERVISOR ? (
            <Grid item md={6} xs={12}>
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel>Arm State</InputLabel>
                <Select
                  value={values.armstate}
                  label="Arm State"
                  name="armstate"
                  onChange={handleChange}
                >
                  <MenuItem value={AlarmState.ALL}>All</MenuItem>
                  <MenuItem value={AlarmState.UNARMED}>Unarmed</MenuItem>
                  <MenuItem value={AlarmState.ARMED}>Armed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          ) : null}
          <Grid item md={6} xs={12}>
            <Typography variant="overline"> Accept New</Typography>
            <Checkbox
              color="primary"
              label="Accept New"
              name="acceptNew"
              checked={valuesAccept}
              onChange={handleChangeAccepsNew}
            />
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSaveQueueSettings}
          >
            Save
          </Button>
        </Box>
      </>
    );
  };
  return (
    <form>
      {rep.isLoading ? (
        <LoadingSpinner />
      ) : (
        <Card>
          <Tabs value={activeTab} onChange={(e, val) => setActiveTab(val)}>
            <Tab label="Rep info" />
            <Tab label="Queue settings" />
          </Tabs>

          {activeTab === 0 && changeRole()}
          {activeTab === 1 && queueSettings()}
        </Card>
      )}
    </form>
  );
};

export default ManageReps;
