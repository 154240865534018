import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, makeStyles, Box } from '@material-ui/core';
import DenseCardHeader from '../../../components/DenseCardHeader';

const useStyles = makeStyles(() => ({
  root: {},
}));

const AlarmChat = ({ height, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box height={height}>
        <DenseCardHeader title="Chat" />
      </Box>
    </Card>
  );
};

AlarmChat.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
};

export default AlarmChat;
