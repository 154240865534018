import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import DenseCardHeader from 'src/components/DenseCardHeader';
import ReplayIcon from '@material-ui/icons/Replay';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';

const useStyles = makeStyles((theme) => ({
  root: {},
  boxCenter: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
  card: {
    height: '100%',
    position: 'relative',
  },
}));

const EmptyMonitorItem = ({ slot }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <DenseCardHeader title={`[${slot}] No Event`} />
      <CardContent className={classes.card}>
        <Grid className={classes.card} container item xs={12} align="center">
          <Grid item container display="flex" justifyContent="center">
            <Box display="flex" justifyContent="center" mb={3}>
              <VideocamOffIcon />
            </Box>
          </Grid>
          <Grid item container display="flex" justifyContent="center">
            <Box className={classes.boxCenter}>
              <Typography
                align="center"
                color="textPrimary"
                gutterBottom
                variant="h4"
              >
                No Event
              </Typography>
              <Typography align="center" color="textPrimary" variant="body1">
                Waiting for new videos to review
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

EmptyMonitorItem.propTypes = {
  slot: PropTypes.number,
};

export default EmptyMonitorItem;
