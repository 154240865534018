import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Typography,
} from '@material-ui/core';
import {
  Person,
  Face,
  useGetPeople,
  // useAddFaceToPerson,
  useSetPersonForEventFace,
} from 'src/hooks/peopleHooks';
import { useQueryParams } from 'src/utils/useQueryParams';
import { LoadingSpinner } from 'src/components/LoadingSpinner';

export function SelectPerson({ face }: Props) {
  // NOTE: face prop is the "unknown" face being reviewed, not the known face of the person

  const { userId, sid, eventId } = useQueryParams();
  const peopleQuery = useGetPeople(userId);
  // const [selectedPerson, setSelectedPerson] = React.useState();

  // const addFaceMutation = useAddFaceToPerson();
  const setPersonMutation = useSetPersonForEventFace();
  const handleSubmit = (person: Person) => {
    console.log('face_d, person_id', face.id, person.person_id);
    setPersonMutation.mutate({
      eventId: eventId,
      face_id: face.id,
      person_id: person.person_id,
    });
    // addFaceMutation.mutate({ person_id: person.person_id, face });
  };

  if (!userId || !sid) return <>'Missing userId or sid'</>;
  if (!peopleQuery.isSuccess) return <LoadingSpinner />;
  return (
    <Box display="flex" alignItems="flex-end" overflow="scroll">
      {peopleQuery.data.map((person: Person) => (
        // <Button type="submit" variant="outlined">
        <Box
          key={`${person.person_id}-${face.id}`}
          display="flex"
          m={0.5}
          alignContent="center"
          textAlign="center"
        >
          <Card style={{ padding: '2px' }}>
            <CardActionArea onClick={() => handleSubmit(person)}>
              <Avatar
                src={
                  person.faces?.[0]?.img_file
                    ? `${process.env.REACT_APP_PCS_ADMIN_URL}/${person.faces?.[0]?.img_file}`
                    : ''
                }
                style={{ height: '50px' }}
                variant="rounded"
              />
              <Typography variant="caption" className="fs-exclude">
                {person.name}
              </Typography>
            </CardActionArea>
          </Card>
        </Box>
        // </Button>
      ))}
    </Box>
  );
}

interface Props {
  face: Face;
}
