import { Card } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import Overlay from 'src/components/VideoOverlay/Overlay';
import VideoPlayer from 'src/components/VideoPlayer/index';
import { usePcsAuthRep } from 'src/context/pcs-auth-context';
import { useSubscriber } from 'src/context/sub-auth-context';
import { makeMarkers } from 'src/utils/markers';

const PcsCamera = ({
  eventId,
  activeItem,
  playerState,
  view = '',
  liveIndicator = false,
  hide = false,
  onReady = () => {},
  onProgress = () => {},
  eventQuery,
  onItemClick,
  disableVolumeAndMicrophone = false,
}) => {
  const userId = eventQuery.data?.userId;
  const sid = eventQuery.data?.sid;
  const eventTimestamp = eventQuery.data?.eventTimestamp;
  const uuid = eventQuery.data?.uuid;
  const tracks = eventQuery.data?.tracks;
  const moves = eventQuery.data?.moves;
  const faces = eventQuery.data?.faces;
  const duration = eventQuery.data?.duration;
  const video_path = eventQuery.data?.video_path;

  const videoPlayer = useRef(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [pauseTrigger, setPauseTrigger] = useState(false);
  const [selected, setSelected] = useState(false);
  const [overlay, setOverlay] = useState(true);
  const shortTimestamp = String(eventTimestamp).slice(0, -5);
  const markers = makeMarkers(tracks, moves, faces);
  // const markersMemo = React.useMemo(() => markers);
  const timeStart = activeItem
    ? activeItem.pts_seconds + activeItem.rand / 1000
    : 0;

  useEffect(() => {
    setPauseTrigger(true);
    setSelected(activeItem);

    // This just clears it in case we need to click another one and trigger pause again
    setTimeout(() => setPauseTrigger(false), 50);
  }, [activeItem]);

  const resetPauseTrigger = () => {
    // When we hit play, unpuase and clear out selected
    setPauseTrigger(false);
    setSelected(null);
  };

  function updateWidths() {
    if (videoPlayer && videoPlayer.current) {
      setWidth(videoPlayer.current?.clientWidth);
      setHeight(videoPlayer.current?.clientHeight);
    }
  }

  useEffect(() => {
    updateWidths();

    window.addEventListener('resize', updateWidths);

    return () => {
      window.removeEventListener('resize', updateWidths);
    };
  }, []);

  const { authHeader: realAuthHeader } = useSubscriber(userId);
  const { token } = usePcsAuthRep();
  const [checkZoom, setCheckZoom] = useState(false);

  const authHeader = eventQuery.data?.isFake ? 'Bearer Fake' : realAuthHeader;

  if (!eventQuery.isSuccess || !authHeader || !eventQuery.data?.video_path)
    return <LoadingSpinner />;
  return (
    <Card
      style={{
        height: 'calc(100% - 20px)',
        display: hide ? 'none' : '',
        position: 'relative',
      }}
      className="fs-exclude"
    >
      <div
        id={`player-${uuid}-${eventId}`}
        style={{ height: '100%' }}
        ref={videoPlayer}
      >
        <VideoPlayer
          {...playerState}
          authHeader={authHeader}
          checkZoom={checkZoom}
          duration={duration}
          eventId={eventId}
          isLive={liveIndicator}
          liveIndicator={liveIndicator}
          disableVolumeAndMicrophone={disableVolumeAndMicrophone}
          markers={markers}
          mesuredView={view}
          onItemClick={onItemClick}
          onPlay={resetPauseTrigger}
          onProgress={onProgress}
          onReady={onReady}
          pauseTrigger={pauseTrigger}
          setCheckZoom={setCheckZoom}
          setOverlay={setOverlay}
          timeStart={timeStart}
          type="flv"
          url={`${process.env.REACT_APP_PCS_ADMIN_WS_URL}/video/ws?file_path=${video_path}&token=${token}`}
          uuid={uuid}
        />
      </div>
      {selected && overlay ? (
        <Overlay width={width} height={height} selected={selected} />
      ) : null}
    </Card>
  );
};

PcsCamera.propTypes = {
  eventId: PropTypes.number.isRequired,
  activeItem: PropTypes.object,
  playerState: PropTypes.object,
  hide: PropTypes.bool,
  liveIndicator: PropTypes.bool,
  onReady: PropTypes.func,
  onProgress: PropTypes.func,
  onItemClick: PropTypes.func,
};

export default PcsCamera;
