/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function (global, factory) {
  /* global define, require, module */

  /* AMD */ if (typeof define === 'function' && define.amd)
    define(['protobufjs/minimal'], factory);
  /* CommonJS */ else if (
    typeof require === 'function' &&
    typeof module === 'object' &&
    module &&
    module.exports
  )
    module.exports = factory(require('protobufjs/minimal'));
})(this, function ($protobuf) {
  'use strict';

  // Common aliases
  var $Reader = $protobuf.Reader,
    $Writer = $protobuf.Writer,
    $util = $protobuf.util;

  // Exported root namespace
  var $root = $protobuf.roots['default'] || ($protobuf.roots['default'] = {});

  $root.simplisafe = (function () {
    /**
     * Namespace simplisafe.
     * @exports simplisafe
     * @namespace
     */
    var simplisafe = {};

    simplisafe.cameras = (function () {
      /**
       * Namespace cameras.
       * @memberof simplisafe
       * @namespace
       */
      var cameras = {};

      cameras.messages = (function () {
        /**
         * Namespace messages.
         * @memberof simplisafe.cameras
         * @namespace
         */
        var messages = {};

        messages.v1 = (function () {
          /**
           * Namespace v1.
           * @memberof simplisafe.cameras.messages
           * @namespace
           */
          var v1 = {};

          v1.CameraMessage = (function () {
            /**
             * Properties of a CameraMessage.
             * @memberof simplisafe.cameras.messages.v1
             * @interface ICameraMessage
             * @property {simplisafe.v1.IHeader|null} [header] CameraMessage header
             * @property {simplisafe.cameras.states.v1.IClientState|null} [clientState] CameraMessage clientState
             * @property {simplisafe.cameras.states.v1.ICameraState|null} [cameraState] CameraMessage cameraState
             * @property {simplisafe.cameras.states.v1.IForwardedApertureMessage|null} [forwardedApertureMessage] CameraMessage forwardedApertureMessage
             * @property {simplisafe.cameras.states.v1.IRoomState|null} [roomState] CameraMessage roomState
             */

            /**
             * Constructs a new CameraMessage.
             * @memberof simplisafe.cameras.messages.v1
             * @classdesc Represents a CameraMessage.
             * @implements ICameraMessage
             * @constructor
             * @param {simplisafe.cameras.messages.v1.ICameraMessage=} [properties] Properties to set
             */
            function CameraMessage(properties) {
              if (properties)
                for (
                  var keys = Object.keys(properties), i = 0;
                  i < keys.length;
                  ++i
                )
                  if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
            }

            /**
             * CameraMessage header.
             * @member {simplisafe.v1.IHeader|null|undefined} header
             * @memberof simplisafe.cameras.messages.v1.CameraMessage
             * @instance
             */
            CameraMessage.prototype.header = null;

            /**
             * CameraMessage clientState.
             * @member {simplisafe.cameras.states.v1.IClientState|null|undefined} clientState
             * @memberof simplisafe.cameras.messages.v1.CameraMessage
             * @instance
             */
            CameraMessage.prototype.clientState = null;

            /**
             * CameraMessage cameraState.
             * @member {simplisafe.cameras.states.v1.ICameraState|null|undefined} cameraState
             * @memberof simplisafe.cameras.messages.v1.CameraMessage
             * @instance
             */
            CameraMessage.prototype.cameraState = null;

            /**
             * CameraMessage forwardedApertureMessage.
             * @member {simplisafe.cameras.states.v1.IForwardedApertureMessage|null|undefined} forwardedApertureMessage
             * @memberof simplisafe.cameras.messages.v1.CameraMessage
             * @instance
             */
            CameraMessage.prototype.forwardedApertureMessage = null;

            /**
             * CameraMessage roomState.
             * @member {simplisafe.cameras.states.v1.IRoomState|null|undefined} roomState
             * @memberof simplisafe.cameras.messages.v1.CameraMessage
             * @instance
             */
            CameraMessage.prototype.roomState = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * CameraMessage message.
             * @member {"clientState"|"cameraState"|"forwardedApertureMessage"|"roomState"|undefined} message
             * @memberof simplisafe.cameras.messages.v1.CameraMessage
             * @instance
             */
            Object.defineProperty(CameraMessage.prototype, 'message', {
              get: $util.oneOfGetter(
                ($oneOfFields = [
                  'clientState',
                  'cameraState',
                  'forwardedApertureMessage',
                  'roomState',
                ])
              ),
              set: $util.oneOfSetter($oneOfFields),
            });

            /**
             * Creates a new CameraMessage instance using the specified properties.
             * @function create
             * @memberof simplisafe.cameras.messages.v1.CameraMessage
             * @static
             * @param {simplisafe.cameras.messages.v1.ICameraMessage=} [properties] Properties to set
             * @returns {simplisafe.cameras.messages.v1.CameraMessage} CameraMessage instance
             */
            CameraMessage.create = function create(properties) {
              return new CameraMessage(properties);
            };

            /**
             * Encodes the specified CameraMessage message. Does not implicitly {@link simplisafe.cameras.messages.v1.CameraMessage.verify|verify} messages.
             * @function encode
             * @memberof simplisafe.cameras.messages.v1.CameraMessage
             * @static
             * @param {simplisafe.cameras.messages.v1.ICameraMessage} message CameraMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CameraMessage.encode = function encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.header != null &&
                Object.hasOwnProperty.call(message, 'header')
              )
                $root.simplisafe.v1.Header.encode(
                  message.header,
                  writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
                ).ldelim();
              if (
                message.clientState != null &&
                Object.hasOwnProperty.call(message, 'clientState')
              )
                $root.simplisafe.cameras.states.v1.ClientState.encode(
                  message.clientState,
                  writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
                ).ldelim();
              if (
                message.cameraState != null &&
                Object.hasOwnProperty.call(message, 'cameraState')
              )
                $root.simplisafe.cameras.states.v1.CameraState.encode(
                  message.cameraState,
                  writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
                ).ldelim();
              if (
                message.forwardedApertureMessage != null &&
                Object.hasOwnProperty.call(message, 'forwardedApertureMessage')
              )
                $root.simplisafe.cameras.states.v1.ForwardedApertureMessage.encode(
                  message.forwardedApertureMessage,
                  writer.uint32(/* id 4, wireType 2 =*/ 34).fork()
                ).ldelim();
              if (
                message.roomState != null &&
                Object.hasOwnProperty.call(message, 'roomState')
              )
                $root.simplisafe.cameras.states.v1.RoomState.encode(
                  message.roomState,
                  writer.uint32(/* id 5, wireType 2 =*/ 42).fork()
                ).ldelim();
              return writer;
            };

            /**
             * Encodes the specified CameraMessage message, length delimited. Does not implicitly {@link simplisafe.cameras.messages.v1.CameraMessage.verify|verify} messages.
             * @function encodeDelimited
             * @memberof simplisafe.cameras.messages.v1.CameraMessage
             * @static
             * @param {simplisafe.cameras.messages.v1.ICameraMessage} message CameraMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CameraMessage.encodeDelimited = function encodeDelimited(
              message,
              writer
            ) {
              return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CameraMessage message from the specified reader or buffer.
             * @function decode
             * @memberof simplisafe.cameras.messages.v1.CameraMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {simplisafe.cameras.messages.v1.CameraMessage} CameraMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CameraMessage.decode = function decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              var end = length === undefined ? reader.len : reader.pos + length,
                message =
                  new $root.simplisafe.cameras.messages.v1.CameraMessage();
              while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.header = $root.simplisafe.v1.Header.decode(
                      reader,
                      reader.uint32()
                    );
                    break;
                  }
                  case 2: {
                    message.clientState =
                      $root.simplisafe.cameras.states.v1.ClientState.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 3: {
                    message.cameraState =
                      $root.simplisafe.cameras.states.v1.CameraState.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 4: {
                    message.forwardedApertureMessage =
                      $root.simplisafe.cameras.states.v1.ForwardedApertureMessage.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 5: {
                    message.roomState =
                      $root.simplisafe.cameras.states.v1.RoomState.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  default:
                    reader.skipType(tag & 7);
                    break;
                }
              }
              return message;
            };

            /**
             * Decodes a CameraMessage message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof simplisafe.cameras.messages.v1.CameraMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {simplisafe.cameras.messages.v1.CameraMessage} CameraMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CameraMessage.decodeDelimited = function decodeDelimited(reader) {
              if (!(reader instanceof $Reader)) reader = new $Reader(reader);
              return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CameraMessage message.
             * @function verify
             * @memberof simplisafe.cameras.messages.v1.CameraMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CameraMessage.verify = function verify(message) {
              if (typeof message !== 'object' || message === null)
                return 'object expected';
              var properties = {};
              if (message.header != null && message.hasOwnProperty('header')) {
                var error = $root.simplisafe.v1.Header.verify(message.header);
                if (error) return 'header.' + error;
              }
              if (
                message.clientState != null &&
                message.hasOwnProperty('clientState')
              ) {
                properties.message = 1;
                {
                  var error =
                    $root.simplisafe.cameras.states.v1.ClientState.verify(
                      message.clientState
                    );
                  if (error) return 'clientState.' + error;
                }
              }
              if (
                message.cameraState != null &&
                message.hasOwnProperty('cameraState')
              ) {
                if (properties.message === 1) return 'message: multiple values';
                properties.message = 1;
                {
                  var error =
                    $root.simplisafe.cameras.states.v1.CameraState.verify(
                      message.cameraState
                    );
                  if (error) return 'cameraState.' + error;
                }
              }
              if (
                message.forwardedApertureMessage != null &&
                message.hasOwnProperty('forwardedApertureMessage')
              ) {
                if (properties.message === 1) return 'message: multiple values';
                properties.message = 1;
                {
                  var error =
                    $root.simplisafe.cameras.states.v1.ForwardedApertureMessage.verify(
                      message.forwardedApertureMessage
                    );
                  if (error) return 'forwardedApertureMessage.' + error;
                }
              }
              if (
                message.roomState != null &&
                message.hasOwnProperty('roomState')
              ) {
                if (properties.message === 1) return 'message: multiple values';
                properties.message = 1;
                {
                  var error =
                    $root.simplisafe.cameras.states.v1.RoomState.verify(
                      message.roomState
                    );
                  if (error) return 'roomState.' + error;
                }
              }
              return null;
            };

            /**
             * Creates a CameraMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof simplisafe.cameras.messages.v1.CameraMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {simplisafe.cameras.messages.v1.CameraMessage} CameraMessage
             */
            CameraMessage.fromObject = function fromObject(object) {
              if (
                object instanceof
                $root.simplisafe.cameras.messages.v1.CameraMessage
              )
                return object;
              var message =
                new $root.simplisafe.cameras.messages.v1.CameraMessage();
              if (object.header != null) {
                if (typeof object.header !== 'object')
                  throw TypeError(
                    '.simplisafe.cameras.messages.v1.CameraMessage.header: object expected'
                  );
                message.header = $root.simplisafe.v1.Header.fromObject(
                  object.header
                );
              }
              if (object.clientState != null) {
                if (typeof object.clientState !== 'object')
                  throw TypeError(
                    '.simplisafe.cameras.messages.v1.CameraMessage.clientState: object expected'
                  );
                message.clientState =
                  $root.simplisafe.cameras.states.v1.ClientState.fromObject(
                    object.clientState
                  );
              }
              if (object.cameraState != null) {
                if (typeof object.cameraState !== 'object')
                  throw TypeError(
                    '.simplisafe.cameras.messages.v1.CameraMessage.cameraState: object expected'
                  );
                message.cameraState =
                  $root.simplisafe.cameras.states.v1.CameraState.fromObject(
                    object.cameraState
                  );
              }
              if (object.forwardedApertureMessage != null) {
                if (typeof object.forwardedApertureMessage !== 'object')
                  throw TypeError(
                    '.simplisafe.cameras.messages.v1.CameraMessage.forwardedApertureMessage: object expected'
                  );
                message.forwardedApertureMessage =
                  $root.simplisafe.cameras.states.v1.ForwardedApertureMessage.fromObject(
                    object.forwardedApertureMessage
                  );
              }
              if (object.roomState != null) {
                if (typeof object.roomState !== 'object')
                  throw TypeError(
                    '.simplisafe.cameras.messages.v1.CameraMessage.roomState: object expected'
                  );
                message.roomState =
                  $root.simplisafe.cameras.states.v1.RoomState.fromObject(
                    object.roomState
                  );
              }
              return message;
            };

            /**
             * Creates a plain object from a CameraMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof simplisafe.cameras.messages.v1.CameraMessage
             * @static
             * @param {simplisafe.cameras.messages.v1.CameraMessage} message CameraMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CameraMessage.toObject = function toObject(message, options) {
              if (!options) options = {};
              var object = {};
              if (options.defaults) object.header = null;
              if (message.header != null && message.hasOwnProperty('header'))
                object.header = $root.simplisafe.v1.Header.toObject(
                  message.header,
                  options
                );
              if (
                message.clientState != null &&
                message.hasOwnProperty('clientState')
              ) {
                object.clientState =
                  $root.simplisafe.cameras.states.v1.ClientState.toObject(
                    message.clientState,
                    options
                  );
                if (options.oneofs) object.message = 'clientState';
              }
              if (
                message.cameraState != null &&
                message.hasOwnProperty('cameraState')
              ) {
                object.cameraState =
                  $root.simplisafe.cameras.states.v1.CameraState.toObject(
                    message.cameraState,
                    options
                  );
                if (options.oneofs) object.message = 'cameraState';
              }
              if (
                message.forwardedApertureMessage != null &&
                message.hasOwnProperty('forwardedApertureMessage')
              ) {
                object.forwardedApertureMessage =
                  $root.simplisafe.cameras.states.v1.ForwardedApertureMessage.toObject(
                    message.forwardedApertureMessage,
                    options
                  );
                if (options.oneofs) object.message = 'forwardedApertureMessage';
              }
              if (
                message.roomState != null &&
                message.hasOwnProperty('roomState')
              ) {
                object.roomState =
                  $root.simplisafe.cameras.states.v1.RoomState.toObject(
                    message.roomState,
                    options
                  );
                if (options.oneofs) object.message = 'roomState';
              }
              return object;
            };

            /**
             * Converts this CameraMessage to JSON.
             * @function toJSON
             * @memberof simplisafe.cameras.messages.v1.CameraMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CameraMessage.prototype.toJSON = function toJSON() {
              return this.constructor.toObject(
                this,
                $protobuf.util.toJSONOptions
              );
            };

            /**
             * Gets the default type url for CameraMessage
             * @function getTypeUrl
             * @memberof simplisafe.cameras.messages.v1.CameraMessage
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CameraMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
              if (typeUrlPrefix === undefined) {
                typeUrlPrefix = 'type.googleapis.com';
              }
              return (
                typeUrlPrefix + '/simplisafe.cameras.messages.v1.CameraMessage'
              );
            };

            return CameraMessage;
          })();

          return v1;
        })();

        return messages;
      })();

      cameras.states = (function () {
        /**
         * Namespace states.
         * @memberof simplisafe.cameras
         * @namespace
         */
        var states = {};

        states.v1 = (function () {
          /**
           * Namespace v1.
           * @memberof simplisafe.cameras.states
           * @namespace
           */
          var v1 = {};

          v1.CameraState = (function () {
            /**
             * Properties of a CameraState.
             * @memberof simplisafe.cameras.states.v1
             * @interface ICameraState
             * @property {simplisafe.cameras.v1.IRoomParticipants|null} [participants] CameraState participants
             * @property {simplisafe.cameras.v1.DisconnectReason|null} [disconnectReason] CameraState disconnectReason
             * @property {simplisafe.cameras.v1.SirenState|null} [sirenState] CameraState sirenState
             */

            /**
             * Constructs a new CameraState.
             * @memberof simplisafe.cameras.states.v1
             * @classdesc Represents a CameraState.
             * @implements ICameraState
             * @constructor
             * @param {simplisafe.cameras.states.v1.ICameraState=} [properties] Properties to set
             */
            function CameraState(properties) {
              if (properties)
                for (
                  var keys = Object.keys(properties), i = 0;
                  i < keys.length;
                  ++i
                )
                  if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
            }

            /**
             * CameraState participants.
             * @member {simplisafe.cameras.v1.IRoomParticipants|null|undefined} participants
             * @memberof simplisafe.cameras.states.v1.CameraState
             * @instance
             */
            CameraState.prototype.participants = null;

            /**
             * CameraState disconnectReason.
             * @member {simplisafe.cameras.v1.DisconnectReason|null|undefined} disconnectReason
             * @memberof simplisafe.cameras.states.v1.CameraState
             * @instance
             */
            CameraState.prototype.disconnectReason = null;

            /**
             * CameraState sirenState.
             * @member {simplisafe.cameras.v1.SirenState|null|undefined} sirenState
             * @memberof simplisafe.cameras.states.v1.CameraState
             * @instance
             */
            CameraState.prototype.sirenState = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * CameraState _participants.
             * @member {"participants"|undefined} _participants
             * @memberof simplisafe.cameras.states.v1.CameraState
             * @instance
             */
            Object.defineProperty(CameraState.prototype, '_participants', {
              get: $util.oneOfGetter(($oneOfFields = ['participants'])),
              set: $util.oneOfSetter($oneOfFields),
            });

            /**
             * CameraState _disconnectReason.
             * @member {"disconnectReason"|undefined} _disconnectReason
             * @memberof simplisafe.cameras.states.v1.CameraState
             * @instance
             */
            Object.defineProperty(CameraState.prototype, '_disconnectReason', {
              get: $util.oneOfGetter(($oneOfFields = ['disconnectReason'])),
              set: $util.oneOfSetter($oneOfFields),
            });

            /**
             * CameraState _sirenState.
             * @member {"sirenState"|undefined} _sirenState
             * @memberof simplisafe.cameras.states.v1.CameraState
             * @instance
             */
            Object.defineProperty(CameraState.prototype, '_sirenState', {
              get: $util.oneOfGetter(($oneOfFields = ['sirenState'])),
              set: $util.oneOfSetter($oneOfFields),
            });

            /**
             * Creates a new CameraState instance using the specified properties.
             * @function create
             * @memberof simplisafe.cameras.states.v1.CameraState
             * @static
             * @param {simplisafe.cameras.states.v1.ICameraState=} [properties] Properties to set
             * @returns {simplisafe.cameras.states.v1.CameraState} CameraState instance
             */
            CameraState.create = function create(properties) {
              return new CameraState(properties);
            };

            /**
             * Encodes the specified CameraState message. Does not implicitly {@link simplisafe.cameras.states.v1.CameraState.verify|verify} messages.
             * @function encode
             * @memberof simplisafe.cameras.states.v1.CameraState
             * @static
             * @param {simplisafe.cameras.states.v1.ICameraState} message CameraState message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CameraState.encode = function encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.participants != null &&
                Object.hasOwnProperty.call(message, 'participants')
              )
                $root.simplisafe.cameras.v1.RoomParticipants.encode(
                  message.participants,
                  writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
                ).ldelim();
              if (
                message.disconnectReason != null &&
                Object.hasOwnProperty.call(message, 'disconnectReason')
              )
                writer
                  .uint32(/* id 2, wireType 0 =*/ 16)
                  .int32(message.disconnectReason);
              if (
                message.sirenState != null &&
                Object.hasOwnProperty.call(message, 'sirenState')
              )
                writer
                  .uint32(/* id 3, wireType 0 =*/ 24)
                  .int32(message.sirenState);
              return writer;
            };

            /**
             * Encodes the specified CameraState message, length delimited. Does not implicitly {@link simplisafe.cameras.states.v1.CameraState.verify|verify} messages.
             * @function encodeDelimited
             * @memberof simplisafe.cameras.states.v1.CameraState
             * @static
             * @param {simplisafe.cameras.states.v1.ICameraState} message CameraState message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CameraState.encodeDelimited = function encodeDelimited(
              message,
              writer
            ) {
              return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CameraState message from the specified reader or buffer.
             * @function decode
             * @memberof simplisafe.cameras.states.v1.CameraState
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {simplisafe.cameras.states.v1.CameraState} CameraState
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CameraState.decode = function decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              var end = length === undefined ? reader.len : reader.pos + length,
                message = new $root.simplisafe.cameras.states.v1.CameraState();
              while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.participants =
                      $root.simplisafe.cameras.v1.RoomParticipants.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 2: {
                    message.disconnectReason = reader.int32();
                    break;
                  }
                  case 3: {
                    message.sirenState = reader.int32();
                    break;
                  }
                  default:
                    reader.skipType(tag & 7);
                    break;
                }
              }
              return message;
            };

            /**
             * Decodes a CameraState message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof simplisafe.cameras.states.v1.CameraState
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {simplisafe.cameras.states.v1.CameraState} CameraState
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CameraState.decodeDelimited = function decodeDelimited(reader) {
              if (!(reader instanceof $Reader)) reader = new $Reader(reader);
              return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CameraState message.
             * @function verify
             * @memberof simplisafe.cameras.states.v1.CameraState
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CameraState.verify = function verify(message) {
              if (typeof message !== 'object' || message === null)
                return 'object expected';
              var properties = {};
              if (
                message.participants != null &&
                message.hasOwnProperty('participants')
              ) {
                properties._participants = 1;
                {
                  var error =
                    $root.simplisafe.cameras.v1.RoomParticipants.verify(
                      message.participants
                    );
                  if (error) return 'participants.' + error;
                }
              }
              if (
                message.disconnectReason != null &&
                message.hasOwnProperty('disconnectReason')
              ) {
                properties._disconnectReason = 1;
                switch (message.disconnectReason) {
                  default:
                    return 'disconnectReason: enum value expected';
                  case 0:
                  case 1:
                  case 2:
                  case 3:
                    break;
                }
              }
              if (
                message.sirenState != null &&
                message.hasOwnProperty('sirenState')
              ) {
                properties._sirenState = 1;
                switch (message.sirenState) {
                  default:
                    return 'sirenState: enum value expected';
                  case 0:
                  case 1:
                  case 2:
                  case 3:
                    break;
                }
              }
              return null;
            };

            /**
             * Creates a CameraState message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof simplisafe.cameras.states.v1.CameraState
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {simplisafe.cameras.states.v1.CameraState} CameraState
             */
            CameraState.fromObject = function fromObject(object) {
              if (
                object instanceof $root.simplisafe.cameras.states.v1.CameraState
              )
                return object;
              var message =
                new $root.simplisafe.cameras.states.v1.CameraState();
              if (object.participants != null) {
                if (typeof object.participants !== 'object')
                  throw TypeError(
                    '.simplisafe.cameras.states.v1.CameraState.participants: object expected'
                  );
                message.participants =
                  $root.simplisafe.cameras.v1.RoomParticipants.fromObject(
                    object.participants
                  );
              }
              switch (object.disconnectReason) {
                default:
                  if (typeof object.disconnectReason === 'number') {
                    message.disconnectReason = object.disconnectReason;
                    break;
                  }
                  break;
                case 'DISCONNECT_REASON_UNSPECIFIED':
                case 0:
                  message.disconnectReason = 0;
                  break;
                case 'DISCONNECT_REASON_AGENT_TAKEOVER':
                case 1:
                  message.disconnectReason = 1;
                  break;
                case 'DISCONNECT_REASON_ALARM_CLEAR':
                case 2:
                  message.disconnectReason = 2;
                  break;
                case 'DISCONNECT_REASON_EXISTING_AGENT':
                case 3:
                  message.disconnectReason = 3;
                  break;
              }
              switch (object.sirenState) {
                default:
                  if (typeof object.sirenState === 'number') {
                    message.sirenState = object.sirenState;
                    break;
                  }
                  break;
                case 'SIREN_STATE_UNSPECIFIED':
                case 0:
                  message.sirenState = 0;
                  break;
                case 'SIREN_STATE_INACTIVE':
                case 1:
                  message.sirenState = 1;
                  break;
                case 'SIREN_STATE_ACTIVE':
                case 2:
                  message.sirenState = 2;
                  break;
                case 'SIREN_STATE_PAUSED':
                case 3:
                  message.sirenState = 3;
                  break;
              }
              return message;
            };

            /**
             * Creates a plain object from a CameraState message. Also converts values to other types if specified.
             * @function toObject
             * @memberof simplisafe.cameras.states.v1.CameraState
             * @static
             * @param {simplisafe.cameras.states.v1.CameraState} message CameraState
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CameraState.toObject = function toObject(message, options) {
              if (!options) options = {};
              var object = {};
              if (
                message.participants != null &&
                message.hasOwnProperty('participants')
              ) {
                object.participants =
                  $root.simplisafe.cameras.v1.RoomParticipants.toObject(
                    message.participants,
                    options
                  );
                if (options.oneofs) object._participants = 'participants';
              }
              if (
                message.disconnectReason != null &&
                message.hasOwnProperty('disconnectReason')
              ) {
                object.disconnectReason =
                  options.enums === String
                    ? $root.simplisafe.cameras.v1.DisconnectReason[
                        message.disconnectReason
                      ] === undefined
                      ? message.disconnectReason
                      : $root.simplisafe.cameras.v1.DisconnectReason[
                          message.disconnectReason
                        ]
                    : message.disconnectReason;
                if (options.oneofs)
                  object._disconnectReason = 'disconnectReason';
              }
              if (
                message.sirenState != null &&
                message.hasOwnProperty('sirenState')
              ) {
                object.sirenState =
                  options.enums === String
                    ? $root.simplisafe.cameras.v1.SirenState[
                        message.sirenState
                      ] === undefined
                      ? message.sirenState
                      : $root.simplisafe.cameras.v1.SirenState[
                          message.sirenState
                        ]
                    : message.sirenState;
                if (options.oneofs) object._sirenState = 'sirenState';
              }
              return object;
            };

            /**
             * Converts this CameraState to JSON.
             * @function toJSON
             * @memberof simplisafe.cameras.states.v1.CameraState
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CameraState.prototype.toJSON = function toJSON() {
              return this.constructor.toObject(
                this,
                $protobuf.util.toJSONOptions
              );
            };

            /**
             * Gets the default type url for CameraState
             * @function getTypeUrl
             * @memberof simplisafe.cameras.states.v1.CameraState
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CameraState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
              if (typeUrlPrefix === undefined) {
                typeUrlPrefix = 'type.googleapis.com';
              }
              return (
                typeUrlPrefix + '/simplisafe.cameras.states.v1.CameraState'
              );
            };

            return CameraState;
          })();

          v1.ClientState = (function () {
            /**
             * Properties of a ClientState.
             * @memberof simplisafe.cameras.states.v1
             * @interface IClientState
             * @property {simplisafe.cameras.v1.FullDuplexMode|null} [fullDuplexMode] ClientState fullDuplexMode
             * @property {simplisafe.cameras.v1.QuietMode|null} [quietMode] ClientState quietMode
             */

            /**
             * Constructs a new ClientState.
             * @memberof simplisafe.cameras.states.v1
             * @classdesc Represents a ClientState.
             * @implements IClientState
             * @constructor
             * @param {simplisafe.cameras.states.v1.IClientState=} [properties] Properties to set
             */
            function ClientState(properties) {
              if (properties)
                for (
                  var keys = Object.keys(properties), i = 0;
                  i < keys.length;
                  ++i
                )
                  if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
            }

            /**
             * ClientState fullDuplexMode.
             * @member {simplisafe.cameras.v1.FullDuplexMode} fullDuplexMode
             * @memberof simplisafe.cameras.states.v1.ClientState
             * @instance
             */
            ClientState.prototype.fullDuplexMode = 0;

            /**
             * ClientState quietMode.
             * @member {simplisafe.cameras.v1.QuietMode} quietMode
             * @memberof simplisafe.cameras.states.v1.ClientState
             * @instance
             */
            ClientState.prototype.quietMode = 0;

            /**
             * Creates a new ClientState instance using the specified properties.
             * @function create
             * @memberof simplisafe.cameras.states.v1.ClientState
             * @static
             * @param {simplisafe.cameras.states.v1.IClientState=} [properties] Properties to set
             * @returns {simplisafe.cameras.states.v1.ClientState} ClientState instance
             */
            ClientState.create = function create(properties) {
              return new ClientState(properties);
            };

            /**
             * Encodes the specified ClientState message. Does not implicitly {@link simplisafe.cameras.states.v1.ClientState.verify|verify} messages.
             * @function encode
             * @memberof simplisafe.cameras.states.v1.ClientState
             * @static
             * @param {simplisafe.cameras.states.v1.IClientState} message ClientState message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientState.encode = function encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.fullDuplexMode != null &&
                Object.hasOwnProperty.call(message, 'fullDuplexMode')
              )
                writer
                  .uint32(/* id 1, wireType 0 =*/ 8)
                  .int32(message.fullDuplexMode);
              if (
                message.quietMode != null &&
                Object.hasOwnProperty.call(message, 'quietMode')
              )
                writer
                  .uint32(/* id 2, wireType 0 =*/ 16)
                  .int32(message.quietMode);
              return writer;
            };

            /**
             * Encodes the specified ClientState message, length delimited. Does not implicitly {@link simplisafe.cameras.states.v1.ClientState.verify|verify} messages.
             * @function encodeDelimited
             * @memberof simplisafe.cameras.states.v1.ClientState
             * @static
             * @param {simplisafe.cameras.states.v1.IClientState} message ClientState message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientState.encodeDelimited = function encodeDelimited(
              message,
              writer
            ) {
              return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ClientState message from the specified reader or buffer.
             * @function decode
             * @memberof simplisafe.cameras.states.v1.ClientState
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {simplisafe.cameras.states.v1.ClientState} ClientState
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientState.decode = function decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              var end = length === undefined ? reader.len : reader.pos + length,
                message = new $root.simplisafe.cameras.states.v1.ClientState();
              while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.fullDuplexMode = reader.int32();
                    break;
                  }
                  case 2: {
                    message.quietMode = reader.int32();
                    break;
                  }
                  default:
                    reader.skipType(tag & 7);
                    break;
                }
              }
              return message;
            };

            /**
             * Decodes a ClientState message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof simplisafe.cameras.states.v1.ClientState
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {simplisafe.cameras.states.v1.ClientState} ClientState
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientState.decodeDelimited = function decodeDelimited(reader) {
              if (!(reader instanceof $Reader)) reader = new $Reader(reader);
              return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ClientState message.
             * @function verify
             * @memberof simplisafe.cameras.states.v1.ClientState
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientState.verify = function verify(message) {
              if (typeof message !== 'object' || message === null)
                return 'object expected';
              if (
                message.fullDuplexMode != null &&
                message.hasOwnProperty('fullDuplexMode')
              )
                switch (message.fullDuplexMode) {
                  default:
                    return 'fullDuplexMode: enum value expected';
                  case 0:
                  case 1:
                  case 2:
                    break;
                }
              if (
                message.quietMode != null &&
                message.hasOwnProperty('quietMode')
              )
                switch (message.quietMode) {
                  default:
                    return 'quietMode: enum value expected';
                  case 0:
                  case 1:
                  case 2:
                    break;
                }
              return null;
            };

            /**
             * Creates a ClientState message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof simplisafe.cameras.states.v1.ClientState
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {simplisafe.cameras.states.v1.ClientState} ClientState
             */
            ClientState.fromObject = function fromObject(object) {
              if (
                object instanceof $root.simplisafe.cameras.states.v1.ClientState
              )
                return object;
              var message =
                new $root.simplisafe.cameras.states.v1.ClientState();
              switch (object.fullDuplexMode) {
                default:
                  if (typeof object.fullDuplexMode === 'number') {
                    message.fullDuplexMode = object.fullDuplexMode;
                    break;
                  }
                  break;
                case 'FULL_DUPLEX_MODE_UNSPECIFIED':
                case 0:
                  message.fullDuplexMode = 0;
                  break;
                case 'FULL_DUPLEX_MODE_ENABLED':
                case 1:
                  message.fullDuplexMode = 1;
                  break;
                case 'FULL_DUPLEX_MODE_DISABLED':
                case 2:
                  message.fullDuplexMode = 2;
                  break;
              }
              switch (object.quietMode) {
                default:
                  if (typeof object.quietMode === 'number') {
                    message.quietMode = object.quietMode;
                    break;
                  }
                  break;
                case 'QUIET_MODE_UNSPECIFIED':
                case 0:
                  message.quietMode = 0;
                  break;
                case 'QUIET_MODE_ENABLED':
                case 1:
                  message.quietMode = 1;
                  break;
                case 'QUIET_MODE_DISABLED':
                case 2:
                  message.quietMode = 2;
                  break;
              }
              return message;
            };

            /**
             * Creates a plain object from a ClientState message. Also converts values to other types if specified.
             * @function toObject
             * @memberof simplisafe.cameras.states.v1.ClientState
             * @static
             * @param {simplisafe.cameras.states.v1.ClientState} message ClientState
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientState.toObject = function toObject(message, options) {
              if (!options) options = {};
              var object = {};
              if (options.defaults) {
                object.fullDuplexMode =
                  options.enums === String ? 'FULL_DUPLEX_MODE_UNSPECIFIED' : 0;
                object.quietMode =
                  options.enums === String ? 'QUIET_MODE_UNSPECIFIED' : 0;
              }
              if (
                message.fullDuplexMode != null &&
                message.hasOwnProperty('fullDuplexMode')
              )
                object.fullDuplexMode =
                  options.enums === String
                    ? $root.simplisafe.cameras.v1.FullDuplexMode[
                        message.fullDuplexMode
                      ] === undefined
                      ? message.fullDuplexMode
                      : $root.simplisafe.cameras.v1.FullDuplexMode[
                          message.fullDuplexMode
                        ]
                    : message.fullDuplexMode;
              if (
                message.quietMode != null &&
                message.hasOwnProperty('quietMode')
              )
                object.quietMode =
                  options.enums === String
                    ? $root.simplisafe.cameras.v1.QuietMode[
                        message.quietMode
                      ] === undefined
                      ? message.quietMode
                      : $root.simplisafe.cameras.v1.QuietMode[message.quietMode]
                    : message.quietMode;
              return object;
            };

            /**
             * Converts this ClientState to JSON.
             * @function toJSON
             * @memberof simplisafe.cameras.states.v1.ClientState
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientState.prototype.toJSON = function toJSON() {
              return this.constructor.toObject(
                this,
                $protobuf.util.toJSONOptions
              );
            };

            /**
             * Gets the default type url for ClientState
             * @function getTypeUrl
             * @memberof simplisafe.cameras.states.v1.ClientState
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ClientState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
              if (typeUrlPrefix === undefined) {
                typeUrlPrefix = 'type.googleapis.com';
              }
              return (
                typeUrlPrefix + '/simplisafe.cameras.states.v1.ClientState'
              );
            };

            return ClientState;
          })();

          v1.ForwardedApertureMessage = (function () {
            /**
             * Properties of a ForwardedApertureMessage.
             * @memberof simplisafe.cameras.states.v1
             * @interface IForwardedApertureMessage
             * @property {simplisafe.cameras.v1.IVideoVerificationTimeout|null} [videoVerificationTimeout] ForwardedApertureMessage videoVerificationTimeout
             */

            /**
             * Constructs a new ForwardedApertureMessage.
             * @memberof simplisafe.cameras.states.v1
             * @classdesc Represents a ForwardedApertureMessage.
             * @implements IForwardedApertureMessage
             * @constructor
             * @param {simplisafe.cameras.states.v1.IForwardedApertureMessage=} [properties] Properties to set
             */
            function ForwardedApertureMessage(properties) {
              if (properties)
                for (
                  var keys = Object.keys(properties), i = 0;
                  i < keys.length;
                  ++i
                )
                  if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
            }

            /**
             * ForwardedApertureMessage videoVerificationTimeout.
             * @member {simplisafe.cameras.v1.IVideoVerificationTimeout|null|undefined} videoVerificationTimeout
             * @memberof simplisafe.cameras.states.v1.ForwardedApertureMessage
             * @instance
             */
            ForwardedApertureMessage.prototype.videoVerificationTimeout = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ForwardedApertureMessage message.
             * @member {"videoVerificationTimeout"|undefined} message
             * @memberof simplisafe.cameras.states.v1.ForwardedApertureMessage
             * @instance
             */
            Object.defineProperty(
              ForwardedApertureMessage.prototype,
              'message',
              {
                get: $util.oneOfGetter(
                  ($oneOfFields = ['videoVerificationTimeout'])
                ),
                set: $util.oneOfSetter($oneOfFields),
              }
            );

            /**
             * Creates a new ForwardedApertureMessage instance using the specified properties.
             * @function create
             * @memberof simplisafe.cameras.states.v1.ForwardedApertureMessage
             * @static
             * @param {simplisafe.cameras.states.v1.IForwardedApertureMessage=} [properties] Properties to set
             * @returns {simplisafe.cameras.states.v1.ForwardedApertureMessage} ForwardedApertureMessage instance
             */
            ForwardedApertureMessage.create = function create(properties) {
              return new ForwardedApertureMessage(properties);
            };

            /**
             * Encodes the specified ForwardedApertureMessage message. Does not implicitly {@link simplisafe.cameras.states.v1.ForwardedApertureMessage.verify|verify} messages.
             * @function encode
             * @memberof simplisafe.cameras.states.v1.ForwardedApertureMessage
             * @static
             * @param {simplisafe.cameras.states.v1.IForwardedApertureMessage} message ForwardedApertureMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ForwardedApertureMessage.encode = function encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.videoVerificationTimeout != null &&
                Object.hasOwnProperty.call(message, 'videoVerificationTimeout')
              )
                $root.simplisafe.cameras.v1.VideoVerificationTimeout.encode(
                  message.videoVerificationTimeout,
                  writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
                ).ldelim();
              return writer;
            };

            /**
             * Encodes the specified ForwardedApertureMessage message, length delimited. Does not implicitly {@link simplisafe.cameras.states.v1.ForwardedApertureMessage.verify|verify} messages.
             * @function encodeDelimited
             * @memberof simplisafe.cameras.states.v1.ForwardedApertureMessage
             * @static
             * @param {simplisafe.cameras.states.v1.IForwardedApertureMessage} message ForwardedApertureMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ForwardedApertureMessage.encodeDelimited = function encodeDelimited(
              message,
              writer
            ) {
              return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ForwardedApertureMessage message from the specified reader or buffer.
             * @function decode
             * @memberof simplisafe.cameras.states.v1.ForwardedApertureMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {simplisafe.cameras.states.v1.ForwardedApertureMessage} ForwardedApertureMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ForwardedApertureMessage.decode = function decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              var end = length === undefined ? reader.len : reader.pos + length,
                message =
                  new $root.simplisafe.cameras.states.v1.ForwardedApertureMessage();
              while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.videoVerificationTimeout =
                      $root.simplisafe.cameras.v1.VideoVerificationTimeout.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  default:
                    reader.skipType(tag & 7);
                    break;
                }
              }
              return message;
            };

            /**
             * Decodes a ForwardedApertureMessage message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof simplisafe.cameras.states.v1.ForwardedApertureMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {simplisafe.cameras.states.v1.ForwardedApertureMessage} ForwardedApertureMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ForwardedApertureMessage.decodeDelimited = function decodeDelimited(
              reader
            ) {
              if (!(reader instanceof $Reader)) reader = new $Reader(reader);
              return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ForwardedApertureMessage message.
             * @function verify
             * @memberof simplisafe.cameras.states.v1.ForwardedApertureMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ForwardedApertureMessage.verify = function verify(message) {
              if (typeof message !== 'object' || message === null)
                return 'object expected';
              var properties = {};
              if (
                message.videoVerificationTimeout != null &&
                message.hasOwnProperty('videoVerificationTimeout')
              ) {
                properties.message = 1;
                {
                  var error =
                    $root.simplisafe.cameras.v1.VideoVerificationTimeout.verify(
                      message.videoVerificationTimeout
                    );
                  if (error) return 'videoVerificationTimeout.' + error;
                }
              }
              return null;
            };

            /**
             * Creates a ForwardedApertureMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof simplisafe.cameras.states.v1.ForwardedApertureMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {simplisafe.cameras.states.v1.ForwardedApertureMessage} ForwardedApertureMessage
             */
            ForwardedApertureMessage.fromObject = function fromObject(object) {
              if (
                object instanceof
                $root.simplisafe.cameras.states.v1.ForwardedApertureMessage
              )
                return object;
              var message =
                new $root.simplisafe.cameras.states.v1.ForwardedApertureMessage();
              if (object.videoVerificationTimeout != null) {
                if (typeof object.videoVerificationTimeout !== 'object')
                  throw TypeError(
                    '.simplisafe.cameras.states.v1.ForwardedApertureMessage.videoVerificationTimeout: object expected'
                  );
                message.videoVerificationTimeout =
                  $root.simplisafe.cameras.v1.VideoVerificationTimeout.fromObject(
                    object.videoVerificationTimeout
                  );
              }
              return message;
            };

            /**
             * Creates a plain object from a ForwardedApertureMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof simplisafe.cameras.states.v1.ForwardedApertureMessage
             * @static
             * @param {simplisafe.cameras.states.v1.ForwardedApertureMessage} message ForwardedApertureMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ForwardedApertureMessage.toObject = function toObject(
              message,
              options
            ) {
              if (!options) options = {};
              var object = {};
              if (
                message.videoVerificationTimeout != null &&
                message.hasOwnProperty('videoVerificationTimeout')
              ) {
                object.videoVerificationTimeout =
                  $root.simplisafe.cameras.v1.VideoVerificationTimeout.toObject(
                    message.videoVerificationTimeout,
                    options
                  );
                if (options.oneofs) object.message = 'videoVerificationTimeout';
              }
              return object;
            };

            /**
             * Converts this ForwardedApertureMessage to JSON.
             * @function toJSON
             * @memberof simplisafe.cameras.states.v1.ForwardedApertureMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ForwardedApertureMessage.prototype.toJSON = function toJSON() {
              return this.constructor.toObject(
                this,
                $protobuf.util.toJSONOptions
              );
            };

            /**
             * Gets the default type url for ForwardedApertureMessage
             * @function getTypeUrl
             * @memberof simplisafe.cameras.states.v1.ForwardedApertureMessage
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ForwardedApertureMessage.getTypeUrl = function getTypeUrl(
              typeUrlPrefix
            ) {
              if (typeUrlPrefix === undefined) {
                typeUrlPrefix = 'type.googleapis.com';
              }
              return (
                typeUrlPrefix +
                '/simplisafe.cameras.states.v1.ForwardedApertureMessage'
              );
            };

            return ForwardedApertureMessage;
          })();

          v1.RoomState = (function () {
            /**
             * Properties of a RoomState.
             * @memberof simplisafe.cameras.states.v1
             * @interface IRoomState
             * @property {simplisafe.cameras.v1.FullDuplexMode|null} [fullDuplexMode] RoomState fullDuplexMode
             * @property {simplisafe.cameras.v1.QuietMode|null} [quietMode] RoomState quietMode
             * @property {simplisafe.cameras.v1.IRoomParticipants|null} [participants] RoomState participants
             * @property {simplisafe.cameras.v1.IMuteState|null} [muteState] RoomState muteState
             * @property {simplisafe.cameras.v1.DisconnectReason|null} [disconnectReason] RoomState disconnectReason
             */

            /**
             * Constructs a new RoomState.
             * @memberof simplisafe.cameras.states.v1
             * @classdesc Represents a RoomState.
             * @implements IRoomState
             * @constructor
             * @param {simplisafe.cameras.states.v1.IRoomState=} [properties] Properties to set
             */
            function RoomState(properties) {
              if (properties)
                for (
                  var keys = Object.keys(properties), i = 0;
                  i < keys.length;
                  ++i
                )
                  if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
            }

            /**
             * RoomState fullDuplexMode.
             * @member {simplisafe.cameras.v1.FullDuplexMode} fullDuplexMode
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @instance
             */
            RoomState.prototype.fullDuplexMode = 0;

            /**
             * RoomState quietMode.
             * @member {simplisafe.cameras.v1.QuietMode|null|undefined} quietMode
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @instance
             */
            RoomState.prototype.quietMode = null;

            /**
             * RoomState participants.
             * @member {simplisafe.cameras.v1.IRoomParticipants|null|undefined} participants
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @instance
             */
            RoomState.prototype.participants = null;

            /**
             * RoomState muteState.
             * @member {simplisafe.cameras.v1.IMuteState|null|undefined} muteState
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @instance
             */
            RoomState.prototype.muteState = null;

            /**
             * RoomState disconnectReason.
             * @member {simplisafe.cameras.v1.DisconnectReason|null|undefined} disconnectReason
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @instance
             */
            RoomState.prototype.disconnectReason = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * RoomState _quietMode.
             * @member {"quietMode"|undefined} _quietMode
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @instance
             */
            Object.defineProperty(RoomState.prototype, '_quietMode', {
              get: $util.oneOfGetter(($oneOfFields = ['quietMode'])),
              set: $util.oneOfSetter($oneOfFields),
            });

            /**
             * RoomState _participants.
             * @member {"participants"|undefined} _participants
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @instance
             */
            Object.defineProperty(RoomState.prototype, '_participants', {
              get: $util.oneOfGetter(($oneOfFields = ['participants'])),
              set: $util.oneOfSetter($oneOfFields),
            });

            /**
             * RoomState _muteState.
             * @member {"muteState"|undefined} _muteState
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @instance
             */
            Object.defineProperty(RoomState.prototype, '_muteState', {
              get: $util.oneOfGetter(($oneOfFields = ['muteState'])),
              set: $util.oneOfSetter($oneOfFields),
            });

            /**
             * RoomState _disconnectReason.
             * @member {"disconnectReason"|undefined} _disconnectReason
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @instance
             */
            Object.defineProperty(RoomState.prototype, '_disconnectReason', {
              get: $util.oneOfGetter(($oneOfFields = ['disconnectReason'])),
              set: $util.oneOfSetter($oneOfFields),
            });

            /**
             * Creates a new RoomState instance using the specified properties.
             * @function create
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @static
             * @param {simplisafe.cameras.states.v1.IRoomState=} [properties] Properties to set
             * @returns {simplisafe.cameras.states.v1.RoomState} RoomState instance
             */
            RoomState.create = function create(properties) {
              return new RoomState(properties);
            };

            /**
             * Encodes the specified RoomState message. Does not implicitly {@link simplisafe.cameras.states.v1.RoomState.verify|verify} messages.
             * @function encode
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @static
             * @param {simplisafe.cameras.states.v1.IRoomState} message RoomState message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RoomState.encode = function encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.fullDuplexMode != null &&
                Object.hasOwnProperty.call(message, 'fullDuplexMode')
              )
                writer
                  .uint32(/* id 1, wireType 0 =*/ 8)
                  .int32(message.fullDuplexMode);
              if (
                message.quietMode != null &&
                Object.hasOwnProperty.call(message, 'quietMode')
              )
                writer
                  .uint32(/* id 2, wireType 0 =*/ 16)
                  .int32(message.quietMode);
              if (
                message.participants != null &&
                Object.hasOwnProperty.call(message, 'participants')
              )
                $root.simplisafe.cameras.v1.RoomParticipants.encode(
                  message.participants,
                  writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
                ).ldelim();
              if (
                message.muteState != null &&
                Object.hasOwnProperty.call(message, 'muteState')
              )
                $root.simplisafe.cameras.v1.MuteState.encode(
                  message.muteState,
                  writer.uint32(/* id 4, wireType 2 =*/ 34).fork()
                ).ldelim();
              if (
                message.disconnectReason != null &&
                Object.hasOwnProperty.call(message, 'disconnectReason')
              )
                writer
                  .uint32(/* id 5, wireType 0 =*/ 40)
                  .int32(message.disconnectReason);
              return writer;
            };

            /**
             * Encodes the specified RoomState message, length delimited. Does not implicitly {@link simplisafe.cameras.states.v1.RoomState.verify|verify} messages.
             * @function encodeDelimited
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @static
             * @param {simplisafe.cameras.states.v1.IRoomState} message RoomState message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RoomState.encodeDelimited = function encodeDelimited(
              message,
              writer
            ) {
              return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RoomState message from the specified reader or buffer.
             * @function decode
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {simplisafe.cameras.states.v1.RoomState} RoomState
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RoomState.decode = function decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              var end = length === undefined ? reader.len : reader.pos + length,
                message = new $root.simplisafe.cameras.states.v1.RoomState();
              while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.fullDuplexMode = reader.int32();
                    break;
                  }
                  case 2: {
                    message.quietMode = reader.int32();
                    break;
                  }
                  case 3: {
                    message.participants =
                      $root.simplisafe.cameras.v1.RoomParticipants.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 4: {
                    message.muteState =
                      $root.simplisafe.cameras.v1.MuteState.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 5: {
                    message.disconnectReason = reader.int32();
                    break;
                  }
                  default:
                    reader.skipType(tag & 7);
                    break;
                }
              }
              return message;
            };

            /**
             * Decodes a RoomState message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {simplisafe.cameras.states.v1.RoomState} RoomState
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RoomState.decodeDelimited = function decodeDelimited(reader) {
              if (!(reader instanceof $Reader)) reader = new $Reader(reader);
              return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RoomState message.
             * @function verify
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RoomState.verify = function verify(message) {
              if (typeof message !== 'object' || message === null)
                return 'object expected';
              var properties = {};
              if (
                message.fullDuplexMode != null &&
                message.hasOwnProperty('fullDuplexMode')
              )
                switch (message.fullDuplexMode) {
                  default:
                    return 'fullDuplexMode: enum value expected';
                  case 0:
                  case 1:
                  case 2:
                    break;
                }
              if (
                message.quietMode != null &&
                message.hasOwnProperty('quietMode')
              ) {
                properties._quietMode = 1;
                switch (message.quietMode) {
                  default:
                    return 'quietMode: enum value expected';
                  case 0:
                  case 1:
                  case 2:
                    break;
                }
              }
              if (
                message.participants != null &&
                message.hasOwnProperty('participants')
              ) {
                properties._participants = 1;
                {
                  var error =
                    $root.simplisafe.cameras.v1.RoomParticipants.verify(
                      message.participants
                    );
                  if (error) return 'participants.' + error;
                }
              }
              if (
                message.muteState != null &&
                message.hasOwnProperty('muteState')
              ) {
                properties._muteState = 1;
                {
                  var error = $root.simplisafe.cameras.v1.MuteState.verify(
                    message.muteState
                  );
                  if (error) return 'muteState.' + error;
                }
              }
              if (
                message.disconnectReason != null &&
                message.hasOwnProperty('disconnectReason')
              ) {
                properties._disconnectReason = 1;
                switch (message.disconnectReason) {
                  default:
                    return 'disconnectReason: enum value expected';
                  case 0:
                  case 1:
                  case 2:
                  case 3:
                    break;
                }
              }
              return null;
            };

            /**
             * Creates a RoomState message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {simplisafe.cameras.states.v1.RoomState} RoomState
             */
            RoomState.fromObject = function fromObject(object) {
              if (
                object instanceof $root.simplisafe.cameras.states.v1.RoomState
              )
                return object;
              var message = new $root.simplisafe.cameras.states.v1.RoomState();
              switch (object.fullDuplexMode) {
                default:
                  if (typeof object.fullDuplexMode === 'number') {
                    message.fullDuplexMode = object.fullDuplexMode;
                    break;
                  }
                  break;
                case 'FULL_DUPLEX_MODE_UNSPECIFIED':
                case 0:
                  message.fullDuplexMode = 0;
                  break;
                case 'FULL_DUPLEX_MODE_ENABLED':
                case 1:
                  message.fullDuplexMode = 1;
                  break;
                case 'FULL_DUPLEX_MODE_DISABLED':
                case 2:
                  message.fullDuplexMode = 2;
                  break;
              }
              switch (object.quietMode) {
                default:
                  if (typeof object.quietMode === 'number') {
                    message.quietMode = object.quietMode;
                    break;
                  }
                  break;
                case 'QUIET_MODE_UNSPECIFIED':
                case 0:
                  message.quietMode = 0;
                  break;
                case 'QUIET_MODE_ENABLED':
                case 1:
                  message.quietMode = 1;
                  break;
                case 'QUIET_MODE_DISABLED':
                case 2:
                  message.quietMode = 2;
                  break;
              }
              if (object.participants != null) {
                if (typeof object.participants !== 'object')
                  throw TypeError(
                    '.simplisafe.cameras.states.v1.RoomState.participants: object expected'
                  );
                message.participants =
                  $root.simplisafe.cameras.v1.RoomParticipants.fromObject(
                    object.participants
                  );
              }
              if (object.muteState != null) {
                if (typeof object.muteState !== 'object')
                  throw TypeError(
                    '.simplisafe.cameras.states.v1.RoomState.muteState: object expected'
                  );
                message.muteState =
                  $root.simplisafe.cameras.v1.MuteState.fromObject(
                    object.muteState
                  );
              }
              switch (object.disconnectReason) {
                default:
                  if (typeof object.disconnectReason === 'number') {
                    message.disconnectReason = object.disconnectReason;
                    break;
                  }
                  break;
                case 'DISCONNECT_REASON_UNSPECIFIED':
                case 0:
                  message.disconnectReason = 0;
                  break;
                case 'DISCONNECT_REASON_AGENT_TAKEOVER':
                case 1:
                  message.disconnectReason = 1;
                  break;
                case 'DISCONNECT_REASON_ALARM_CLEAR':
                case 2:
                  message.disconnectReason = 2;
                  break;
                case 'DISCONNECT_REASON_EXISTING_AGENT':
                case 3:
                  message.disconnectReason = 3;
                  break;
              }
              return message;
            };

            /**
             * Creates a plain object from a RoomState message. Also converts values to other types if specified.
             * @function toObject
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @static
             * @param {simplisafe.cameras.states.v1.RoomState} message RoomState
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RoomState.toObject = function toObject(message, options) {
              if (!options) options = {};
              var object = {};
              if (options.defaults)
                object.fullDuplexMode =
                  options.enums === String ? 'FULL_DUPLEX_MODE_UNSPECIFIED' : 0;
              if (
                message.fullDuplexMode != null &&
                message.hasOwnProperty('fullDuplexMode')
              )
                object.fullDuplexMode =
                  options.enums === String
                    ? $root.simplisafe.cameras.v1.FullDuplexMode[
                        message.fullDuplexMode
                      ] === undefined
                      ? message.fullDuplexMode
                      : $root.simplisafe.cameras.v1.FullDuplexMode[
                          message.fullDuplexMode
                        ]
                    : message.fullDuplexMode;
              if (
                message.quietMode != null &&
                message.hasOwnProperty('quietMode')
              ) {
                object.quietMode =
                  options.enums === String
                    ? $root.simplisafe.cameras.v1.QuietMode[
                        message.quietMode
                      ] === undefined
                      ? message.quietMode
                      : $root.simplisafe.cameras.v1.QuietMode[message.quietMode]
                    : message.quietMode;
                if (options.oneofs) object._quietMode = 'quietMode';
              }
              if (
                message.participants != null &&
                message.hasOwnProperty('participants')
              ) {
                object.participants =
                  $root.simplisafe.cameras.v1.RoomParticipants.toObject(
                    message.participants,
                    options
                  );
                if (options.oneofs) object._participants = 'participants';
              }
              if (
                message.muteState != null &&
                message.hasOwnProperty('muteState')
              ) {
                object.muteState =
                  $root.simplisafe.cameras.v1.MuteState.toObject(
                    message.muteState,
                    options
                  );
                if (options.oneofs) object._muteState = 'muteState';
              }
              if (
                message.disconnectReason != null &&
                message.hasOwnProperty('disconnectReason')
              ) {
                object.disconnectReason =
                  options.enums === String
                    ? $root.simplisafe.cameras.v1.DisconnectReason[
                        message.disconnectReason
                      ] === undefined
                      ? message.disconnectReason
                      : $root.simplisafe.cameras.v1.DisconnectReason[
                          message.disconnectReason
                        ]
                    : message.disconnectReason;
                if (options.oneofs)
                  object._disconnectReason = 'disconnectReason';
              }
              return object;
            };

            /**
             * Converts this RoomState to JSON.
             * @function toJSON
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RoomState.prototype.toJSON = function toJSON() {
              return this.constructor.toObject(
                this,
                $protobuf.util.toJSONOptions
              );
            };

            /**
             * Gets the default type url for RoomState
             * @function getTypeUrl
             * @memberof simplisafe.cameras.states.v1.RoomState
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RoomState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
              if (typeUrlPrefix === undefined) {
                typeUrlPrefix = 'type.googleapis.com';
              }
              return typeUrlPrefix + '/simplisafe.cameras.states.v1.RoomState';
            };

            return RoomState;
          })();

          return v1;
        })();

        return states;
      })();

      cameras.v1 = (function () {
        /**
         * Namespace v1.
         * @memberof simplisafe.cameras
         * @namespace
         */
        var v1 = {};

        /**
         * DisconnectReason enum.
         * @name simplisafe.cameras.v1.DisconnectReason
         * @enum {number}
         * @property {number} DISCONNECT_REASON_UNSPECIFIED=0 DISCONNECT_REASON_UNSPECIFIED value
         * @property {number} DISCONNECT_REASON_AGENT_TAKEOVER=1 DISCONNECT_REASON_AGENT_TAKEOVER value
         * @property {number} DISCONNECT_REASON_ALARM_CLEAR=2 DISCONNECT_REASON_ALARM_CLEAR value
         * @property {number} DISCONNECT_REASON_EXISTING_AGENT=3 DISCONNECT_REASON_EXISTING_AGENT value
         */
        v1.DisconnectReason = (function () {
          var valuesById = {},
            values = Object.create(valuesById);
          values[(valuesById[0] = 'DISCONNECT_REASON_UNSPECIFIED')] = 0;
          values[(valuesById[1] = 'DISCONNECT_REASON_AGENT_TAKEOVER')] = 1;
          values[(valuesById[2] = 'DISCONNECT_REASON_ALARM_CLEAR')] = 2;
          values[(valuesById[3] = 'DISCONNECT_REASON_EXISTING_AGENT')] = 3;
          return values;
        })();

        /**
         * FullDuplexMode enum.
         * @name simplisafe.cameras.v1.FullDuplexMode
         * @enum {number}
         * @property {number} FULL_DUPLEX_MODE_UNSPECIFIED=0 FULL_DUPLEX_MODE_UNSPECIFIED value
         * @property {number} FULL_DUPLEX_MODE_ENABLED=1 FULL_DUPLEX_MODE_ENABLED value
         * @property {number} FULL_DUPLEX_MODE_DISABLED=2 FULL_DUPLEX_MODE_DISABLED value
         */
        v1.FullDuplexMode = (function () {
          var valuesById = {},
            values = Object.create(valuesById);
          values[(valuesById[0] = 'FULL_DUPLEX_MODE_UNSPECIFIED')] = 0;
          values[(valuesById[1] = 'FULL_DUPLEX_MODE_ENABLED')] = 1;
          values[(valuesById[2] = 'FULL_DUPLEX_MODE_DISABLED')] = 2;
          return values;
        })();

        /**
         * MuteMode enum.
         * @name simplisafe.cameras.v1.MuteMode
         * @enum {number}
         * @property {number} MUTE_MODE_UNSPECIFIED=0 MUTE_MODE_UNSPECIFIED value
         * @property {number} MUTE_MODE_MUTED=1 MUTE_MODE_MUTED value
         * @property {number} MUTE_MODE_UNMUTED=2 MUTE_MODE_UNMUTED value
         */
        v1.MuteMode = (function () {
          var valuesById = {},
            values = Object.create(valuesById);
          values[(valuesById[0] = 'MUTE_MODE_UNSPECIFIED')] = 0;
          values[(valuesById[1] = 'MUTE_MODE_MUTED')] = 1;
          values[(valuesById[2] = 'MUTE_MODE_UNMUTED')] = 2;
          return values;
        })();

        v1.MuteState = (function () {
          /**
           * Properties of a MuteState.
           * @memberof simplisafe.cameras.v1
           * @interface IMuteState
           * @property {simplisafe.cameras.v1.MuteMode|null} [users] MuteState users
           * @property {simplisafe.cameras.v1.MuteMode|null} [agents] MuteState agents
           */

          /**
           * Constructs a new MuteState.
           * @memberof simplisafe.cameras.v1
           * @classdesc Represents a MuteState.
           * @implements IMuteState
           * @constructor
           * @param {simplisafe.cameras.v1.IMuteState=} [properties] Properties to set
           */
          function MuteState(properties) {
            if (properties)
              for (
                var keys = Object.keys(properties), i = 0;
                i < keys.length;
                ++i
              )
                if (properties[keys[i]] != null)
                  this[keys[i]] = properties[keys[i]];
          }

          /**
           * MuteState users.
           * @member {simplisafe.cameras.v1.MuteMode} users
           * @memberof simplisafe.cameras.v1.MuteState
           * @instance
           */
          MuteState.prototype.users = 0;

          /**
           * MuteState agents.
           * @member {simplisafe.cameras.v1.MuteMode} agents
           * @memberof simplisafe.cameras.v1.MuteState
           * @instance
           */
          MuteState.prototype.agents = 0;

          /**
           * Creates a new MuteState instance using the specified properties.
           * @function create
           * @memberof simplisafe.cameras.v1.MuteState
           * @static
           * @param {simplisafe.cameras.v1.IMuteState=} [properties] Properties to set
           * @returns {simplisafe.cameras.v1.MuteState} MuteState instance
           */
          MuteState.create = function create(properties) {
            return new MuteState(properties);
          };

          /**
           * Encodes the specified MuteState message. Does not implicitly {@link simplisafe.cameras.v1.MuteState.verify|verify} messages.
           * @function encode
           * @memberof simplisafe.cameras.v1.MuteState
           * @static
           * @param {simplisafe.cameras.v1.IMuteState} message MuteState message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          MuteState.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.users != null &&
              Object.hasOwnProperty.call(message, 'users')
            )
              writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.users);
            if (
              message.agents != null &&
              Object.hasOwnProperty.call(message, 'agents')
            )
              writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.agents);
            return writer;
          };

          /**
           * Encodes the specified MuteState message, length delimited. Does not implicitly {@link simplisafe.cameras.v1.MuteState.verify|verify} messages.
           * @function encodeDelimited
           * @memberof simplisafe.cameras.v1.MuteState
           * @static
           * @param {simplisafe.cameras.v1.IMuteState} message MuteState message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          MuteState.encodeDelimited = function encodeDelimited(
            message,
            writer
          ) {
            return this.encode(message, writer).ldelim();
          };

          /**
           * Decodes a MuteState message from the specified reader or buffer.
           * @function decode
           * @memberof simplisafe.cameras.v1.MuteState
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @param {number} [length] Message length if known beforehand
           * @returns {simplisafe.cameras.v1.MuteState} MuteState
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          MuteState.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length,
              message = new $root.simplisafe.cameras.v1.MuteState();
            while (reader.pos < end) {
              var tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.users = reader.int32();
                  break;
                }
                case 2: {
                  message.agents = reader.int32();
                  break;
                }
                default:
                  reader.skipType(tag & 7);
                  break;
              }
            }
            return message;
          };

          /**
           * Decodes a MuteState message from the specified reader or buffer, length delimited.
           * @function decodeDelimited
           * @memberof simplisafe.cameras.v1.MuteState
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @returns {simplisafe.cameras.v1.MuteState} MuteState
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          MuteState.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
          };

          /**
           * Verifies a MuteState message.
           * @function verify
           * @memberof simplisafe.cameras.v1.MuteState
           * @static
           * @param {Object.<string,*>} message Plain object to verify
           * @returns {string|null} `null` if valid, otherwise the reason why it is not
           */
          MuteState.verify = function verify(message) {
            if (typeof message !== 'object' || message === null)
              return 'object expected';
            if (message.users != null && message.hasOwnProperty('users'))
              switch (message.users) {
                default:
                  return 'users: enum value expected';
                case 0:
                case 1:
                case 2:
                  break;
              }
            if (message.agents != null && message.hasOwnProperty('agents'))
              switch (message.agents) {
                default:
                  return 'agents: enum value expected';
                case 0:
                case 1:
                case 2:
                  break;
              }
            return null;
          };

          /**
           * Creates a MuteState message from a plain object. Also converts values to their respective internal types.
           * @function fromObject
           * @memberof simplisafe.cameras.v1.MuteState
           * @static
           * @param {Object.<string,*>} object Plain object
           * @returns {simplisafe.cameras.v1.MuteState} MuteState
           */
          MuteState.fromObject = function fromObject(object) {
            if (object instanceof $root.simplisafe.cameras.v1.MuteState)
              return object;
            var message = new $root.simplisafe.cameras.v1.MuteState();
            switch (object.users) {
              default:
                if (typeof object.users === 'number') {
                  message.users = object.users;
                  break;
                }
                break;
              case 'MUTE_MODE_UNSPECIFIED':
              case 0:
                message.users = 0;
                break;
              case 'MUTE_MODE_MUTED':
              case 1:
                message.users = 1;
                break;
              case 'MUTE_MODE_UNMUTED':
              case 2:
                message.users = 2;
                break;
            }
            switch (object.agents) {
              default:
                if (typeof object.agents === 'number') {
                  message.agents = object.agents;
                  break;
                }
                break;
              case 'MUTE_MODE_UNSPECIFIED':
              case 0:
                message.agents = 0;
                break;
              case 'MUTE_MODE_MUTED':
              case 1:
                message.agents = 1;
                break;
              case 'MUTE_MODE_UNMUTED':
              case 2:
                message.agents = 2;
                break;
            }
            return message;
          };

          /**
           * Creates a plain object from a MuteState message. Also converts values to other types if specified.
           * @function toObject
           * @memberof simplisafe.cameras.v1.MuteState
           * @static
           * @param {simplisafe.cameras.v1.MuteState} message MuteState
           * @param {$protobuf.IConversionOptions} [options] Conversion options
           * @returns {Object.<string,*>} Plain object
           */
          MuteState.toObject = function toObject(message, options) {
            if (!options) options = {};
            var object = {};
            if (options.defaults) {
              object.users =
                options.enums === String ? 'MUTE_MODE_UNSPECIFIED' : 0;
              object.agents =
                options.enums === String ? 'MUTE_MODE_UNSPECIFIED' : 0;
            }
            if (message.users != null && message.hasOwnProperty('users'))
              object.users =
                options.enums === String
                  ? $root.simplisafe.cameras.v1.MuteMode[message.users] ===
                    undefined
                    ? message.users
                    : $root.simplisafe.cameras.v1.MuteMode[message.users]
                  : message.users;
            if (message.agents != null && message.hasOwnProperty('agents'))
              object.agents =
                options.enums === String
                  ? $root.simplisafe.cameras.v1.MuteMode[message.agents] ===
                    undefined
                    ? message.agents
                    : $root.simplisafe.cameras.v1.MuteMode[message.agents]
                  : message.agents;
            return object;
          };

          /**
           * Converts this MuteState to JSON.
           * @function toJSON
           * @memberof simplisafe.cameras.v1.MuteState
           * @instance
           * @returns {Object.<string,*>} JSON object
           */
          MuteState.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(
              this,
              $protobuf.util.toJSONOptions
            );
          };

          /**
           * Gets the default type url for MuteState
           * @function getTypeUrl
           * @memberof simplisafe.cameras.v1.MuteState
           * @static
           * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
           * @returns {string} The default type url
           */
          MuteState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
              typeUrlPrefix = 'type.googleapis.com';
            }
            return typeUrlPrefix + '/simplisafe.cameras.v1.MuteState';
          };

          return MuteState;
        })();

        /**
         * ParticipantType enum.
         * @name simplisafe.cameras.v1.ParticipantType
         * @enum {number}
         * @property {number} PARTICIPANT_TYPE_UNSPECIFIED=0 PARTICIPANT_TYPE_UNSPECIFIED value
         * @property {number} PARTICIPANT_TYPE_USER=1 PARTICIPANT_TYPE_USER value
         * @property {number} PARTICIPANT_TYPE_AGENT=2 PARTICIPANT_TYPE_AGENT value
         */
        v1.ParticipantType = (function () {
          var valuesById = {},
            values = Object.create(valuesById);
          values[(valuesById[0] = 'PARTICIPANT_TYPE_UNSPECIFIED')] = 0;
          values[(valuesById[1] = 'PARTICIPANT_TYPE_USER')] = 1;
          values[(valuesById[2] = 'PARTICIPANT_TYPE_AGENT')] = 2;
          return values;
        })();

        v1.Participant = (function () {
          /**
           * Properties of a Participant.
           * @memberof simplisafe.cameras.v1
           * @interface IParticipant
           * @property {string|null} [name] Participant name
           * @property {simplisafe.cameras.v1.ParticipantType|null} [type] Participant type
           */

          /**
           * Constructs a new Participant.
           * @memberof simplisafe.cameras.v1
           * @classdesc Represents a Participant.
           * @implements IParticipant
           * @constructor
           * @param {simplisafe.cameras.v1.IParticipant=} [properties] Properties to set
           */
          function Participant(properties) {
            if (properties)
              for (
                var keys = Object.keys(properties), i = 0;
                i < keys.length;
                ++i
              )
                if (properties[keys[i]] != null)
                  this[keys[i]] = properties[keys[i]];
          }

          /**
           * Participant name.
           * @member {string} name
           * @memberof simplisafe.cameras.v1.Participant
           * @instance
           */
          Participant.prototype.name = '';

          /**
           * Participant type.
           * @member {simplisafe.cameras.v1.ParticipantType} type
           * @memberof simplisafe.cameras.v1.Participant
           * @instance
           */
          Participant.prototype.type = 0;

          /**
           * Creates a new Participant instance using the specified properties.
           * @function create
           * @memberof simplisafe.cameras.v1.Participant
           * @static
           * @param {simplisafe.cameras.v1.IParticipant=} [properties] Properties to set
           * @returns {simplisafe.cameras.v1.Participant} Participant instance
           */
          Participant.create = function create(properties) {
            return new Participant(properties);
          };

          /**
           * Encodes the specified Participant message. Does not implicitly {@link simplisafe.cameras.v1.Participant.verify|verify} messages.
           * @function encode
           * @memberof simplisafe.cameras.v1.Participant
           * @static
           * @param {simplisafe.cameras.v1.IParticipant} message Participant message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          Participant.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.name != null &&
              Object.hasOwnProperty.call(message, 'name')
            )
              writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.name);
            if (
              message.type != null &&
              Object.hasOwnProperty.call(message, 'type')
            )
              writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.type);
            return writer;
          };

          /**
           * Encodes the specified Participant message, length delimited. Does not implicitly {@link simplisafe.cameras.v1.Participant.verify|verify} messages.
           * @function encodeDelimited
           * @memberof simplisafe.cameras.v1.Participant
           * @static
           * @param {simplisafe.cameras.v1.IParticipant} message Participant message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          Participant.encodeDelimited = function encodeDelimited(
            message,
            writer
          ) {
            return this.encode(message, writer).ldelim();
          };

          /**
           * Decodes a Participant message from the specified reader or buffer.
           * @function decode
           * @memberof simplisafe.cameras.v1.Participant
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @param {number} [length] Message length if known beforehand
           * @returns {simplisafe.cameras.v1.Participant} Participant
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          Participant.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length,
              message = new $root.simplisafe.cameras.v1.Participant();
            while (reader.pos < end) {
              var tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.name = reader.string();
                  break;
                }
                case 2: {
                  message.type = reader.int32();
                  break;
                }
                default:
                  reader.skipType(tag & 7);
                  break;
              }
            }
            return message;
          };

          /**
           * Decodes a Participant message from the specified reader or buffer, length delimited.
           * @function decodeDelimited
           * @memberof simplisafe.cameras.v1.Participant
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @returns {simplisafe.cameras.v1.Participant} Participant
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          Participant.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
          };

          /**
           * Verifies a Participant message.
           * @function verify
           * @memberof simplisafe.cameras.v1.Participant
           * @static
           * @param {Object.<string,*>} message Plain object to verify
           * @returns {string|null} `null` if valid, otherwise the reason why it is not
           */
          Participant.verify = function verify(message) {
            if (typeof message !== 'object' || message === null)
              return 'object expected';
            if (message.name != null && message.hasOwnProperty('name'))
              if (!$util.isString(message.name)) return 'name: string expected';
            if (message.type != null && message.hasOwnProperty('type'))
              switch (message.type) {
                default:
                  return 'type: enum value expected';
                case 0:
                case 1:
                case 2:
                  break;
              }
            return null;
          };

          /**
           * Creates a Participant message from a plain object. Also converts values to their respective internal types.
           * @function fromObject
           * @memberof simplisafe.cameras.v1.Participant
           * @static
           * @param {Object.<string,*>} object Plain object
           * @returns {simplisafe.cameras.v1.Participant} Participant
           */
          Participant.fromObject = function fromObject(object) {
            if (object instanceof $root.simplisafe.cameras.v1.Participant)
              return object;
            var message = new $root.simplisafe.cameras.v1.Participant();
            if (object.name != null) message.name = String(object.name);
            switch (object.type) {
              default:
                if (typeof object.type === 'number') {
                  message.type = object.type;
                  break;
                }
                break;
              case 'PARTICIPANT_TYPE_UNSPECIFIED':
              case 0:
                message.type = 0;
                break;
              case 'PARTICIPANT_TYPE_USER':
              case 1:
                message.type = 1;
                break;
              case 'PARTICIPANT_TYPE_AGENT':
              case 2:
                message.type = 2;
                break;
            }
            return message;
          };

          /**
           * Creates a plain object from a Participant message. Also converts values to other types if specified.
           * @function toObject
           * @memberof simplisafe.cameras.v1.Participant
           * @static
           * @param {simplisafe.cameras.v1.Participant} message Participant
           * @param {$protobuf.IConversionOptions} [options] Conversion options
           * @returns {Object.<string,*>} Plain object
           */
          Participant.toObject = function toObject(message, options) {
            if (!options) options = {};
            var object = {};
            if (options.defaults) {
              object.name = '';
              object.type =
                options.enums === String ? 'PARTICIPANT_TYPE_UNSPECIFIED' : 0;
            }
            if (message.name != null && message.hasOwnProperty('name'))
              object.name = message.name;
            if (message.type != null && message.hasOwnProperty('type'))
              object.type =
                options.enums === String
                  ? $root.simplisafe.cameras.v1.ParticipantType[
                      message.type
                    ] === undefined
                    ? message.type
                    : $root.simplisafe.cameras.v1.ParticipantType[message.type]
                  : message.type;
            return object;
          };

          /**
           * Converts this Participant to JSON.
           * @function toJSON
           * @memberof simplisafe.cameras.v1.Participant
           * @instance
           * @returns {Object.<string,*>} JSON object
           */
          Participant.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(
              this,
              $protobuf.util.toJSONOptions
            );
          };

          /**
           * Gets the default type url for Participant
           * @function getTypeUrl
           * @memberof simplisafe.cameras.v1.Participant
           * @static
           * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
           * @returns {string} The default type url
           */
          Participant.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
              typeUrlPrefix = 'type.googleapis.com';
            }
            return typeUrlPrefix + '/simplisafe.cameras.v1.Participant';
          };

          return Participant;
        })();

        /**
         * QuietMode enum.
         * @name simplisafe.cameras.v1.QuietMode
         * @enum {number}
         * @property {number} QUIET_MODE_UNSPECIFIED=0 QUIET_MODE_UNSPECIFIED value
         * @property {number} QUIET_MODE_ENABLED=1 QUIET_MODE_ENABLED value
         * @property {number} QUIET_MODE_DISABLED=2 QUIET_MODE_DISABLED value
         */
        v1.QuietMode = (function () {
          var valuesById = {},
            values = Object.create(valuesById);
          values[(valuesById[0] = 'QUIET_MODE_UNSPECIFIED')] = 0;
          values[(valuesById[1] = 'QUIET_MODE_ENABLED')] = 1;
          values[(valuesById[2] = 'QUIET_MODE_DISABLED')] = 2;
          return values;
        })();

        v1.RoomParticipants = (function () {
          /**
           * Properties of a RoomParticipants.
           * @memberof simplisafe.cameras.v1
           * @interface IRoomParticipants
           * @property {number|null} [customers] RoomParticipants customers
           * @property {number|null} [monitoringAgents] RoomParticipants monitoringAgents
           */

          /**
           * Constructs a new RoomParticipants.
           * @memberof simplisafe.cameras.v1
           * @classdesc Represents a RoomParticipants.
           * @implements IRoomParticipants
           * @constructor
           * @param {simplisafe.cameras.v1.IRoomParticipants=} [properties] Properties to set
           */
          function RoomParticipants(properties) {
            if (properties)
              for (
                var keys = Object.keys(properties), i = 0;
                i < keys.length;
                ++i
              )
                if (properties[keys[i]] != null)
                  this[keys[i]] = properties[keys[i]];
          }

          /**
           * RoomParticipants customers.
           * @member {number} customers
           * @memberof simplisafe.cameras.v1.RoomParticipants
           * @instance
           */
          RoomParticipants.prototype.customers = 0;

          /**
           * RoomParticipants monitoringAgents.
           * @member {number} monitoringAgents
           * @memberof simplisafe.cameras.v1.RoomParticipants
           * @instance
           */
          RoomParticipants.prototype.monitoringAgents = 0;

          /**
           * Creates a new RoomParticipants instance using the specified properties.
           * @function create
           * @memberof simplisafe.cameras.v1.RoomParticipants
           * @static
           * @param {simplisafe.cameras.v1.IRoomParticipants=} [properties] Properties to set
           * @returns {simplisafe.cameras.v1.RoomParticipants} RoomParticipants instance
           */
          RoomParticipants.create = function create(properties) {
            return new RoomParticipants(properties);
          };

          /**
           * Encodes the specified RoomParticipants message. Does not implicitly {@link simplisafe.cameras.v1.RoomParticipants.verify|verify} messages.
           * @function encode
           * @memberof simplisafe.cameras.v1.RoomParticipants
           * @static
           * @param {simplisafe.cameras.v1.IRoomParticipants} message RoomParticipants message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          RoomParticipants.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.customers != null &&
              Object.hasOwnProperty.call(message, 'customers')
            )
              writer
                .uint32(/* id 1, wireType 0 =*/ 8)
                .uint32(message.customers);
            if (
              message.monitoringAgents != null &&
              Object.hasOwnProperty.call(message, 'monitoringAgents')
            )
              writer
                .uint32(/* id 2, wireType 0 =*/ 16)
                .uint32(message.monitoringAgents);
            return writer;
          };

          /**
           * Encodes the specified RoomParticipants message, length delimited. Does not implicitly {@link simplisafe.cameras.v1.RoomParticipants.verify|verify} messages.
           * @function encodeDelimited
           * @memberof simplisafe.cameras.v1.RoomParticipants
           * @static
           * @param {simplisafe.cameras.v1.IRoomParticipants} message RoomParticipants message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          RoomParticipants.encodeDelimited = function encodeDelimited(
            message,
            writer
          ) {
            return this.encode(message, writer).ldelim();
          };

          /**
           * Decodes a RoomParticipants message from the specified reader or buffer.
           * @function decode
           * @memberof simplisafe.cameras.v1.RoomParticipants
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @param {number} [length] Message length if known beforehand
           * @returns {simplisafe.cameras.v1.RoomParticipants} RoomParticipants
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          RoomParticipants.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length,
              message = new $root.simplisafe.cameras.v1.RoomParticipants();
            while (reader.pos < end) {
              var tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.customers = reader.uint32();
                  break;
                }
                case 2: {
                  message.monitoringAgents = reader.uint32();
                  break;
                }
                default:
                  reader.skipType(tag & 7);
                  break;
              }
            }
            return message;
          };

          /**
           * Decodes a RoomParticipants message from the specified reader or buffer, length delimited.
           * @function decodeDelimited
           * @memberof simplisafe.cameras.v1.RoomParticipants
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @returns {simplisafe.cameras.v1.RoomParticipants} RoomParticipants
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          RoomParticipants.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
          };

          /**
           * Verifies a RoomParticipants message.
           * @function verify
           * @memberof simplisafe.cameras.v1.RoomParticipants
           * @static
           * @param {Object.<string,*>} message Plain object to verify
           * @returns {string|null} `null` if valid, otherwise the reason why it is not
           */
          RoomParticipants.verify = function verify(message) {
            if (typeof message !== 'object' || message === null)
              return 'object expected';
            if (
              message.customers != null &&
              message.hasOwnProperty('customers')
            )
              if (!$util.isInteger(message.customers))
                return 'customers: integer expected';
            if (
              message.monitoringAgents != null &&
              message.hasOwnProperty('monitoringAgents')
            )
              if (!$util.isInteger(message.monitoringAgents))
                return 'monitoringAgents: integer expected';
            return null;
          };

          /**
           * Creates a RoomParticipants message from a plain object. Also converts values to their respective internal types.
           * @function fromObject
           * @memberof simplisafe.cameras.v1.RoomParticipants
           * @static
           * @param {Object.<string,*>} object Plain object
           * @returns {simplisafe.cameras.v1.RoomParticipants} RoomParticipants
           */
          RoomParticipants.fromObject = function fromObject(object) {
            if (object instanceof $root.simplisafe.cameras.v1.RoomParticipants)
              return object;
            var message = new $root.simplisafe.cameras.v1.RoomParticipants();
            if (object.customers != null)
              message.customers = object.customers >>> 0;
            if (object.monitoringAgents != null)
              message.monitoringAgents = object.monitoringAgents >>> 0;
            return message;
          };

          /**
           * Creates a plain object from a RoomParticipants message. Also converts values to other types if specified.
           * @function toObject
           * @memberof simplisafe.cameras.v1.RoomParticipants
           * @static
           * @param {simplisafe.cameras.v1.RoomParticipants} message RoomParticipants
           * @param {$protobuf.IConversionOptions} [options] Conversion options
           * @returns {Object.<string,*>} Plain object
           */
          RoomParticipants.toObject = function toObject(message, options) {
            if (!options) options = {};
            var object = {};
            if (options.defaults) {
              object.customers = 0;
              object.monitoringAgents = 0;
            }
            if (
              message.customers != null &&
              message.hasOwnProperty('customers')
            )
              object.customers = message.customers;
            if (
              message.monitoringAgents != null &&
              message.hasOwnProperty('monitoringAgents')
            )
              object.monitoringAgents = message.monitoringAgents;
            return object;
          };

          /**
           * Converts this RoomParticipants to JSON.
           * @function toJSON
           * @memberof simplisafe.cameras.v1.RoomParticipants
           * @instance
           * @returns {Object.<string,*>} JSON object
           */
          RoomParticipants.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(
              this,
              $protobuf.util.toJSONOptions
            );
          };

          /**
           * Gets the default type url for RoomParticipants
           * @function getTypeUrl
           * @memberof simplisafe.cameras.v1.RoomParticipants
           * @static
           * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
           * @returns {string} The default type url
           */
          RoomParticipants.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
              typeUrlPrefix = 'type.googleapis.com';
            }
            return typeUrlPrefix + '/simplisafe.cameras.v1.RoomParticipants';
          };

          return RoomParticipants;
        })();

        /**
         * SirenState enum.
         * @name simplisafe.cameras.v1.SirenState
         * @enum {number}
         * @property {number} SIREN_STATE_UNSPECIFIED=0 SIREN_STATE_UNSPECIFIED value
         * @property {number} SIREN_STATE_INACTIVE=1 SIREN_STATE_INACTIVE value
         * @property {number} SIREN_STATE_ACTIVE=2 SIREN_STATE_ACTIVE value
         * @property {number} SIREN_STATE_PAUSED=3 SIREN_STATE_PAUSED value
         */
        v1.SirenState = (function () {
          var valuesById = {},
            values = Object.create(valuesById);
          values[(valuesById[0] = 'SIREN_STATE_UNSPECIFIED')] = 0;
          values[(valuesById[1] = 'SIREN_STATE_INACTIVE')] = 1;
          values[(valuesById[2] = 'SIREN_STATE_ACTIVE')] = 2;
          values[(valuesById[3] = 'SIREN_STATE_PAUSED')] = 3;
          return values;
        })();

        v1.VideoVerificationTimeout = (function () {
          /**
           * Properties of a VideoVerificationTimeout.
           * @memberof simplisafe.cameras.v1
           * @interface IVideoVerificationTimeout
           */

          /**
           * Constructs a new VideoVerificationTimeout.
           * @memberof simplisafe.cameras.v1
           * @classdesc Represents a VideoVerificationTimeout.
           * @implements IVideoVerificationTimeout
           * @constructor
           * @param {simplisafe.cameras.v1.IVideoVerificationTimeout=} [properties] Properties to set
           */
          function VideoVerificationTimeout(properties) {
            if (properties)
              for (
                var keys = Object.keys(properties), i = 0;
                i < keys.length;
                ++i
              )
                if (properties[keys[i]] != null)
                  this[keys[i]] = properties[keys[i]];
          }

          /**
           * Creates a new VideoVerificationTimeout instance using the specified properties.
           * @function create
           * @memberof simplisafe.cameras.v1.VideoVerificationTimeout
           * @static
           * @param {simplisafe.cameras.v1.IVideoVerificationTimeout=} [properties] Properties to set
           * @returns {simplisafe.cameras.v1.VideoVerificationTimeout} VideoVerificationTimeout instance
           */
          VideoVerificationTimeout.create = function create(properties) {
            return new VideoVerificationTimeout(properties);
          };

          /**
           * Encodes the specified VideoVerificationTimeout message. Does not implicitly {@link simplisafe.cameras.v1.VideoVerificationTimeout.verify|verify} messages.
           * @function encode
           * @memberof simplisafe.cameras.v1.VideoVerificationTimeout
           * @static
           * @param {simplisafe.cameras.v1.IVideoVerificationTimeout} message VideoVerificationTimeout message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          VideoVerificationTimeout.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create();
            return writer;
          };

          /**
           * Encodes the specified VideoVerificationTimeout message, length delimited. Does not implicitly {@link simplisafe.cameras.v1.VideoVerificationTimeout.verify|verify} messages.
           * @function encodeDelimited
           * @memberof simplisafe.cameras.v1.VideoVerificationTimeout
           * @static
           * @param {simplisafe.cameras.v1.IVideoVerificationTimeout} message VideoVerificationTimeout message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          VideoVerificationTimeout.encodeDelimited = function encodeDelimited(
            message,
            writer
          ) {
            return this.encode(message, writer).ldelim();
          };

          /**
           * Decodes a VideoVerificationTimeout message from the specified reader or buffer.
           * @function decode
           * @memberof simplisafe.cameras.v1.VideoVerificationTimeout
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @param {number} [length] Message length if known beforehand
           * @returns {simplisafe.cameras.v1.VideoVerificationTimeout} VideoVerificationTimeout
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          VideoVerificationTimeout.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length,
              message =
                new $root.simplisafe.cameras.v1.VideoVerificationTimeout();
            while (reader.pos < end) {
              var tag = reader.uint32();
              switch (tag >>> 3) {
                default:
                  reader.skipType(tag & 7);
                  break;
              }
            }
            return message;
          };

          /**
           * Decodes a VideoVerificationTimeout message from the specified reader or buffer, length delimited.
           * @function decodeDelimited
           * @memberof simplisafe.cameras.v1.VideoVerificationTimeout
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @returns {simplisafe.cameras.v1.VideoVerificationTimeout} VideoVerificationTimeout
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          VideoVerificationTimeout.decodeDelimited = function decodeDelimited(
            reader
          ) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
          };

          /**
           * Verifies a VideoVerificationTimeout message.
           * @function verify
           * @memberof simplisafe.cameras.v1.VideoVerificationTimeout
           * @static
           * @param {Object.<string,*>} message Plain object to verify
           * @returns {string|null} `null` if valid, otherwise the reason why it is not
           */
          VideoVerificationTimeout.verify = function verify(message) {
            if (typeof message !== 'object' || message === null)
              return 'object expected';
            return null;
          };

          /**
           * Creates a VideoVerificationTimeout message from a plain object. Also converts values to their respective internal types.
           * @function fromObject
           * @memberof simplisafe.cameras.v1.VideoVerificationTimeout
           * @static
           * @param {Object.<string,*>} object Plain object
           * @returns {simplisafe.cameras.v1.VideoVerificationTimeout} VideoVerificationTimeout
           */
          VideoVerificationTimeout.fromObject = function fromObject(object) {
            if (
              object instanceof
              $root.simplisafe.cameras.v1.VideoVerificationTimeout
            )
              return object;
            return new $root.simplisafe.cameras.v1.VideoVerificationTimeout();
          };

          /**
           * Creates a plain object from a VideoVerificationTimeout message. Also converts values to other types if specified.
           * @function toObject
           * @memberof simplisafe.cameras.v1.VideoVerificationTimeout
           * @static
           * @param {simplisafe.cameras.v1.VideoVerificationTimeout} message VideoVerificationTimeout
           * @param {$protobuf.IConversionOptions} [options] Conversion options
           * @returns {Object.<string,*>} Plain object
           */
          VideoVerificationTimeout.toObject = function toObject() {
            return {};
          };

          /**
           * Converts this VideoVerificationTimeout to JSON.
           * @function toJSON
           * @memberof simplisafe.cameras.v1.VideoVerificationTimeout
           * @instance
           * @returns {Object.<string,*>} JSON object
           */
          VideoVerificationTimeout.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(
              this,
              $protobuf.util.toJSONOptions
            );
          };

          /**
           * Gets the default type url for VideoVerificationTimeout
           * @function getTypeUrl
           * @memberof simplisafe.cameras.v1.VideoVerificationTimeout
           * @static
           * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
           * @returns {string} The default type url
           */
          VideoVerificationTimeout.getTypeUrl = function getTypeUrl(
            typeUrlPrefix
          ) {
            if (typeUrlPrefix === undefined) {
              typeUrlPrefix = 'type.googleapis.com';
            }
            return (
              typeUrlPrefix + '/simplisafe.cameras.v1.VideoVerificationTimeout'
            );
          };

          return VideoVerificationTimeout;
        })();

        return v1;
      })();

      return cameras;
    })();

    simplisafe.media = (function () {
      /**
       * Namespace media.
       * @memberof simplisafe
       * @namespace
       */
      var media = {};

      media.controls = (function () {
        /**
         * Namespace controls.
         * @memberof simplisafe.media
         * @namespace
         */
        var controls = {};

        controls.v1 = (function () {
          /**
           * Namespace v1.
           * @memberof simplisafe.media.controls
           * @namespace
           */
          var v1 = {};

          v1.ControlRequest = (function () {
            /**
             * Properties of a ControlRequest.
             * @memberof simplisafe.media.controls.v1
             * @interface IControlRequest
             * @property {simplisafe.v1.IHeader|null} [header] ControlRequest header
             * @property {simplisafe.media.controls.v1.IRecordingEvent|null} [recordingEvent] ControlRequest recordingEvent
             */

            /**
             * Constructs a new ControlRequest.
             * @memberof simplisafe.media.controls.v1
             * @classdesc Represents a ControlRequest.
             * @implements IControlRequest
             * @constructor
             * @param {simplisafe.media.controls.v1.IControlRequest=} [properties] Properties to set
             */
            function ControlRequest(properties) {
              if (properties)
                for (
                  var keys = Object.keys(properties), i = 0;
                  i < keys.length;
                  ++i
                )
                  if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
            }

            /**
             * ControlRequest header.
             * @member {simplisafe.v1.IHeader|null|undefined} header
             * @memberof simplisafe.media.controls.v1.ControlRequest
             * @instance
             */
            ControlRequest.prototype.header = null;

            /**
             * ControlRequest recordingEvent.
             * @member {simplisafe.media.controls.v1.IRecordingEvent|null|undefined} recordingEvent
             * @memberof simplisafe.media.controls.v1.ControlRequest
             * @instance
             */
            ControlRequest.prototype.recordingEvent = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ControlRequest message.
             * @member {"recordingEvent"|undefined} message
             * @memberof simplisafe.media.controls.v1.ControlRequest
             * @instance
             */
            Object.defineProperty(ControlRequest.prototype, 'message', {
              get: $util.oneOfGetter(($oneOfFields = ['recordingEvent'])),
              set: $util.oneOfSetter($oneOfFields),
            });

            /**
             * Creates a new ControlRequest instance using the specified properties.
             * @function create
             * @memberof simplisafe.media.controls.v1.ControlRequest
             * @static
             * @param {simplisafe.media.controls.v1.IControlRequest=} [properties] Properties to set
             * @returns {simplisafe.media.controls.v1.ControlRequest} ControlRequest instance
             */
            ControlRequest.create = function create(properties) {
              return new ControlRequest(properties);
            };

            /**
             * Encodes the specified ControlRequest message. Does not implicitly {@link simplisafe.media.controls.v1.ControlRequest.verify|verify} messages.
             * @function encode
             * @memberof simplisafe.media.controls.v1.ControlRequest
             * @static
             * @param {simplisafe.media.controls.v1.IControlRequest} message ControlRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ControlRequest.encode = function encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.header != null &&
                Object.hasOwnProperty.call(message, 'header')
              )
                $root.simplisafe.v1.Header.encode(
                  message.header,
                  writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
                ).ldelim();
              if (
                message.recordingEvent != null &&
                Object.hasOwnProperty.call(message, 'recordingEvent')
              )
                $root.simplisafe.media.controls.v1.RecordingEvent.encode(
                  message.recordingEvent,
                  writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
                ).ldelim();
              return writer;
            };

            /**
             * Encodes the specified ControlRequest message, length delimited. Does not implicitly {@link simplisafe.media.controls.v1.ControlRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof simplisafe.media.controls.v1.ControlRequest
             * @static
             * @param {simplisafe.media.controls.v1.IControlRequest} message ControlRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ControlRequest.encodeDelimited = function encodeDelimited(
              message,
              writer
            ) {
              return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ControlRequest message from the specified reader or buffer.
             * @function decode
             * @memberof simplisafe.media.controls.v1.ControlRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {simplisafe.media.controls.v1.ControlRequest} ControlRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ControlRequest.decode = function decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              var end = length === undefined ? reader.len : reader.pos + length,
                message =
                  new $root.simplisafe.media.controls.v1.ControlRequest();
              while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.header = $root.simplisafe.v1.Header.decode(
                      reader,
                      reader.uint32()
                    );
                    break;
                  }
                  case 2: {
                    message.recordingEvent =
                      $root.simplisafe.media.controls.v1.RecordingEvent.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  default:
                    reader.skipType(tag & 7);
                    break;
                }
              }
              return message;
            };

            /**
             * Decodes a ControlRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof simplisafe.media.controls.v1.ControlRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {simplisafe.media.controls.v1.ControlRequest} ControlRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ControlRequest.decodeDelimited = function decodeDelimited(reader) {
              if (!(reader instanceof $Reader)) reader = new $Reader(reader);
              return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ControlRequest message.
             * @function verify
             * @memberof simplisafe.media.controls.v1.ControlRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ControlRequest.verify = function verify(message) {
              if (typeof message !== 'object' || message === null)
                return 'object expected';
              var properties = {};
              if (message.header != null && message.hasOwnProperty('header')) {
                var error = $root.simplisafe.v1.Header.verify(message.header);
                if (error) return 'header.' + error;
              }
              if (
                message.recordingEvent != null &&
                message.hasOwnProperty('recordingEvent')
              ) {
                properties.message = 1;
                {
                  var error =
                    $root.simplisafe.media.controls.v1.RecordingEvent.verify(
                      message.recordingEvent
                    );
                  if (error) return 'recordingEvent.' + error;
                }
              }
              return null;
            };

            /**
             * Creates a ControlRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof simplisafe.media.controls.v1.ControlRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {simplisafe.media.controls.v1.ControlRequest} ControlRequest
             */
            ControlRequest.fromObject = function fromObject(object) {
              if (
                object instanceof
                $root.simplisafe.media.controls.v1.ControlRequest
              )
                return object;
              var message =
                new $root.simplisafe.media.controls.v1.ControlRequest();
              if (object.header != null) {
                if (typeof object.header !== 'object')
                  throw TypeError(
                    '.simplisafe.media.controls.v1.ControlRequest.header: object expected'
                  );
                message.header = $root.simplisafe.v1.Header.fromObject(
                  object.header
                );
              }
              if (object.recordingEvent != null) {
                if (typeof object.recordingEvent !== 'object')
                  throw TypeError(
                    '.simplisafe.media.controls.v1.ControlRequest.recordingEvent: object expected'
                  );
                message.recordingEvent =
                  $root.simplisafe.media.controls.v1.RecordingEvent.fromObject(
                    object.recordingEvent
                  );
              }
              return message;
            };

            /**
             * Creates a plain object from a ControlRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof simplisafe.media.controls.v1.ControlRequest
             * @static
             * @param {simplisafe.media.controls.v1.ControlRequest} message ControlRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ControlRequest.toObject = function toObject(message, options) {
              if (!options) options = {};
              var object = {};
              if (options.defaults) object.header = null;
              if (message.header != null && message.hasOwnProperty('header'))
                object.header = $root.simplisafe.v1.Header.toObject(
                  message.header,
                  options
                );
              if (
                message.recordingEvent != null &&
                message.hasOwnProperty('recordingEvent')
              ) {
                object.recordingEvent =
                  $root.simplisafe.media.controls.v1.RecordingEvent.toObject(
                    message.recordingEvent,
                    options
                  );
                if (options.oneofs) object.message = 'recordingEvent';
              }
              return object;
            };

            /**
             * Converts this ControlRequest to JSON.
             * @function toJSON
             * @memberof simplisafe.media.controls.v1.ControlRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ControlRequest.prototype.toJSON = function toJSON() {
              return this.constructor.toObject(
                this,
                $protobuf.util.toJSONOptions
              );
            };

            /**
             * Gets the default type url for ControlRequest
             * @function getTypeUrl
             * @memberof simplisafe.media.controls.v1.ControlRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ControlRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
              if (typeUrlPrefix === undefined) {
                typeUrlPrefix = 'type.googleapis.com';
              }
              return (
                typeUrlPrefix + '/simplisafe.media.controls.v1.ControlRequest'
              );
            };

            return ControlRequest;
          })();

          v1.ControlResponse = (function () {
            /**
             * Properties of a ControlResponse.
             * @memberof simplisafe.media.controls.v1
             * @interface IControlResponse
             * @property {simplisafe.v1.IHeader|null} [header] ControlResponse header
             * @property {simplisafe.media.controls.v1.IRecordingResponse|null} [recordingResponse] ControlResponse recordingResponse
             */

            /**
             * Constructs a new ControlResponse.
             * @memberof simplisafe.media.controls.v1
             * @classdesc Represents a ControlResponse.
             * @implements IControlResponse
             * @constructor
             * @param {simplisafe.media.controls.v1.IControlResponse=} [properties] Properties to set
             */
            function ControlResponse(properties) {
              if (properties)
                for (
                  var keys = Object.keys(properties), i = 0;
                  i < keys.length;
                  ++i
                )
                  if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
            }

            /**
             * ControlResponse header.
             * @member {simplisafe.v1.IHeader|null|undefined} header
             * @memberof simplisafe.media.controls.v1.ControlResponse
             * @instance
             */
            ControlResponse.prototype.header = null;

            /**
             * ControlResponse recordingResponse.
             * @member {simplisafe.media.controls.v1.IRecordingResponse|null|undefined} recordingResponse
             * @memberof simplisafe.media.controls.v1.ControlResponse
             * @instance
             */
            ControlResponse.prototype.recordingResponse = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ControlResponse message.
             * @member {"recordingResponse"|undefined} message
             * @memberof simplisafe.media.controls.v1.ControlResponse
             * @instance
             */
            Object.defineProperty(ControlResponse.prototype, 'message', {
              get: $util.oneOfGetter(($oneOfFields = ['recordingResponse'])),
              set: $util.oneOfSetter($oneOfFields),
            });

            /**
             * Creates a new ControlResponse instance using the specified properties.
             * @function create
             * @memberof simplisafe.media.controls.v1.ControlResponse
             * @static
             * @param {simplisafe.media.controls.v1.IControlResponse=} [properties] Properties to set
             * @returns {simplisafe.media.controls.v1.ControlResponse} ControlResponse instance
             */
            ControlResponse.create = function create(properties) {
              return new ControlResponse(properties);
            };

            /**
             * Encodes the specified ControlResponse message. Does not implicitly {@link simplisafe.media.controls.v1.ControlResponse.verify|verify} messages.
             * @function encode
             * @memberof simplisafe.media.controls.v1.ControlResponse
             * @static
             * @param {simplisafe.media.controls.v1.IControlResponse} message ControlResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ControlResponse.encode = function encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.header != null &&
                Object.hasOwnProperty.call(message, 'header')
              )
                $root.simplisafe.v1.Header.encode(
                  message.header,
                  writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
                ).ldelim();
              if (
                message.recordingResponse != null &&
                Object.hasOwnProperty.call(message, 'recordingResponse')
              )
                $root.simplisafe.media.controls.v1.RecordingResponse.encode(
                  message.recordingResponse,
                  writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
                ).ldelim();
              return writer;
            };

            /**
             * Encodes the specified ControlResponse message, length delimited. Does not implicitly {@link simplisafe.media.controls.v1.ControlResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof simplisafe.media.controls.v1.ControlResponse
             * @static
             * @param {simplisafe.media.controls.v1.IControlResponse} message ControlResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ControlResponse.encodeDelimited = function encodeDelimited(
              message,
              writer
            ) {
              return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ControlResponse message from the specified reader or buffer.
             * @function decode
             * @memberof simplisafe.media.controls.v1.ControlResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {simplisafe.media.controls.v1.ControlResponse} ControlResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ControlResponse.decode = function decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              var end = length === undefined ? reader.len : reader.pos + length,
                message =
                  new $root.simplisafe.media.controls.v1.ControlResponse();
              while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.header = $root.simplisafe.v1.Header.decode(
                      reader,
                      reader.uint32()
                    );
                    break;
                  }
                  case 2: {
                    message.recordingResponse =
                      $root.simplisafe.media.controls.v1.RecordingResponse.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  default:
                    reader.skipType(tag & 7);
                    break;
                }
              }
              return message;
            };

            /**
             * Decodes a ControlResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof simplisafe.media.controls.v1.ControlResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {simplisafe.media.controls.v1.ControlResponse} ControlResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ControlResponse.decodeDelimited = function decodeDelimited(reader) {
              if (!(reader instanceof $Reader)) reader = new $Reader(reader);
              return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ControlResponse message.
             * @function verify
             * @memberof simplisafe.media.controls.v1.ControlResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ControlResponse.verify = function verify(message) {
              if (typeof message !== 'object' || message === null)
                return 'object expected';
              var properties = {};
              if (message.header != null && message.hasOwnProperty('header')) {
                var error = $root.simplisafe.v1.Header.verify(message.header);
                if (error) return 'header.' + error;
              }
              if (
                message.recordingResponse != null &&
                message.hasOwnProperty('recordingResponse')
              ) {
                properties.message = 1;
                {
                  var error =
                    $root.simplisafe.media.controls.v1.RecordingResponse.verify(
                      message.recordingResponse
                    );
                  if (error) return 'recordingResponse.' + error;
                }
              }
              return null;
            };

            /**
             * Creates a ControlResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof simplisafe.media.controls.v1.ControlResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {simplisafe.media.controls.v1.ControlResponse} ControlResponse
             */
            ControlResponse.fromObject = function fromObject(object) {
              if (
                object instanceof
                $root.simplisafe.media.controls.v1.ControlResponse
              )
                return object;
              var message =
                new $root.simplisafe.media.controls.v1.ControlResponse();
              if (object.header != null) {
                if (typeof object.header !== 'object')
                  throw TypeError(
                    '.simplisafe.media.controls.v1.ControlResponse.header: object expected'
                  );
                message.header = $root.simplisafe.v1.Header.fromObject(
                  object.header
                );
              }
              if (object.recordingResponse != null) {
                if (typeof object.recordingResponse !== 'object')
                  throw TypeError(
                    '.simplisafe.media.controls.v1.ControlResponse.recordingResponse: object expected'
                  );
                message.recordingResponse =
                  $root.simplisafe.media.controls.v1.RecordingResponse.fromObject(
                    object.recordingResponse
                  );
              }
              return message;
            };

            /**
             * Creates a plain object from a ControlResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof simplisafe.media.controls.v1.ControlResponse
             * @static
             * @param {simplisafe.media.controls.v1.ControlResponse} message ControlResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ControlResponse.toObject = function toObject(message, options) {
              if (!options) options = {};
              var object = {};
              if (options.defaults) object.header = null;
              if (message.header != null && message.hasOwnProperty('header'))
                object.header = $root.simplisafe.v1.Header.toObject(
                  message.header,
                  options
                );
              if (
                message.recordingResponse != null &&
                message.hasOwnProperty('recordingResponse')
              ) {
                object.recordingResponse =
                  $root.simplisafe.media.controls.v1.RecordingResponse.toObject(
                    message.recordingResponse,
                    options
                  );
                if (options.oneofs) object.message = 'recordingResponse';
              }
              return object;
            };

            /**
             * Converts this ControlResponse to JSON.
             * @function toJSON
             * @memberof simplisafe.media.controls.v1.ControlResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ControlResponse.prototype.toJSON = function toJSON() {
              return this.constructor.toObject(
                this,
                $protobuf.util.toJSONOptions
              );
            };

            /**
             * Gets the default type url for ControlResponse
             * @function getTypeUrl
             * @memberof simplisafe.media.controls.v1.ControlResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ControlResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
              if (typeUrlPrefix === undefined) {
                typeUrlPrefix = 'type.googleapis.com';
              }
              return (
                typeUrlPrefix + '/simplisafe.media.controls.v1.ControlResponse'
              );
            };

            return ControlResponse;
          })();

          v1.RecordingEvent = (function () {
            /**
             * Properties of a RecordingEvent.
             * @memberof simplisafe.media.controls.v1
             * @interface IRecordingEvent
             * @property {number|Long|null} [eventId] RecordingEvent eventId
             * @property {string|null} [eventUuid] RecordingEvent eventUuid
             * @property {number|null} [currentCameraTimestamp] RecordingEvent currentCameraTimestamp
             * @property {number|null} [recordingStartTimestamp] RecordingEvent recordingStartTimestamp
             * @property {number|null} [recordingStopTimestamp] RecordingEvent recordingStopTimestamp
             * @property {Array.<simplisafe.media.controls.v1.ITag>|null} [tags] RecordingEvent tags
             */

            /**
             * Constructs a new RecordingEvent.
             * @memberof simplisafe.media.controls.v1
             * @classdesc Represents a RecordingEvent.
             * @implements IRecordingEvent
             * @constructor
             * @param {simplisafe.media.controls.v1.IRecordingEvent=} [properties] Properties to set
             */
            function RecordingEvent(properties) {
              this.tags = [];
              if (properties)
                for (
                  var keys = Object.keys(properties), i = 0;
                  i < keys.length;
                  ++i
                )
                  if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
            }

            /**
             * RecordingEvent eventId.
             * @member {number|Long|null|undefined} eventId
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @instance
             */
            RecordingEvent.prototype.eventId = null;

            /**
             * RecordingEvent eventUuid.
             * @member {string|null|undefined} eventUuid
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @instance
             */
            RecordingEvent.prototype.eventUuid = null;

            /**
             * RecordingEvent currentCameraTimestamp.
             * @member {number|null|undefined} currentCameraTimestamp
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @instance
             */
            RecordingEvent.prototype.currentCameraTimestamp = null;

            /**
             * RecordingEvent recordingStartTimestamp.
             * @member {number|null|undefined} recordingStartTimestamp
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @instance
             */
            RecordingEvent.prototype.recordingStartTimestamp = null;

            /**
             * RecordingEvent recordingStopTimestamp.
             * @member {number|null|undefined} recordingStopTimestamp
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @instance
             */
            RecordingEvent.prototype.recordingStopTimestamp = null;

            /**
             * RecordingEvent tags.
             * @member {Array.<simplisafe.media.controls.v1.ITag>} tags
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @instance
             */
            RecordingEvent.prototype.tags = $util.emptyArray;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * RecordingEvent _eventId.
             * @member {"eventId"|undefined} _eventId
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @instance
             */
            Object.defineProperty(RecordingEvent.prototype, '_eventId', {
              get: $util.oneOfGetter(($oneOfFields = ['eventId'])),
              set: $util.oneOfSetter($oneOfFields),
            });

            /**
             * RecordingEvent _eventUuid.
             * @member {"eventUuid"|undefined} _eventUuid
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @instance
             */
            Object.defineProperty(RecordingEvent.prototype, '_eventUuid', {
              get: $util.oneOfGetter(($oneOfFields = ['eventUuid'])),
              set: $util.oneOfSetter($oneOfFields),
            });

            /**
             * RecordingEvent _currentCameraTimestamp.
             * @member {"currentCameraTimestamp"|undefined} _currentCameraTimestamp
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @instance
             */
            Object.defineProperty(
              RecordingEvent.prototype,
              '_currentCameraTimestamp',
              {
                get: $util.oneOfGetter(
                  ($oneOfFields = ['currentCameraTimestamp'])
                ),
                set: $util.oneOfSetter($oneOfFields),
              }
            );

            /**
             * RecordingEvent _recordingStartTimestamp.
             * @member {"recordingStartTimestamp"|undefined} _recordingStartTimestamp
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @instance
             */
            Object.defineProperty(
              RecordingEvent.prototype,
              '_recordingStartTimestamp',
              {
                get: $util.oneOfGetter(
                  ($oneOfFields = ['recordingStartTimestamp'])
                ),
                set: $util.oneOfSetter($oneOfFields),
              }
            );

            /**
             * RecordingEvent _recordingStopTimestamp.
             * @member {"recordingStopTimestamp"|undefined} _recordingStopTimestamp
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @instance
             */
            Object.defineProperty(
              RecordingEvent.prototype,
              '_recordingStopTimestamp',
              {
                get: $util.oneOfGetter(
                  ($oneOfFields = ['recordingStopTimestamp'])
                ),
                set: $util.oneOfSetter($oneOfFields),
              }
            );

            /**
             * Creates a new RecordingEvent instance using the specified properties.
             * @function create
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @static
             * @param {simplisafe.media.controls.v1.IRecordingEvent=} [properties] Properties to set
             * @returns {simplisafe.media.controls.v1.RecordingEvent} RecordingEvent instance
             */
            RecordingEvent.create = function create(properties) {
              return new RecordingEvent(properties);
            };

            /**
             * Encodes the specified RecordingEvent message. Does not implicitly {@link simplisafe.media.controls.v1.RecordingEvent.verify|verify} messages.
             * @function encode
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @static
             * @param {simplisafe.media.controls.v1.IRecordingEvent} message RecordingEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecordingEvent.encode = function encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.eventId != null &&
                Object.hasOwnProperty.call(message, 'eventId')
              )
                writer
                  .uint32(/* id 1, wireType 0 =*/ 8)
                  .uint64(message.eventId);
              if (
                message.eventUuid != null &&
                Object.hasOwnProperty.call(message, 'eventUuid')
              )
                writer
                  .uint32(/* id 2, wireType 2 =*/ 18)
                  .string(message.eventUuid);
              if (
                message.currentCameraTimestamp != null &&
                Object.hasOwnProperty.call(message, 'currentCameraTimestamp')
              )
                writer
                  .uint32(/* id 3, wireType 0 =*/ 24)
                  .uint32(message.currentCameraTimestamp);
              if (
                message.recordingStartTimestamp != null &&
                Object.hasOwnProperty.call(message, 'recordingStartTimestamp')
              )
                writer
                  .uint32(/* id 4, wireType 0 =*/ 32)
                  .uint32(message.recordingStartTimestamp);
              if (
                message.recordingStopTimestamp != null &&
                Object.hasOwnProperty.call(message, 'recordingStopTimestamp')
              )
                writer
                  .uint32(/* id 5, wireType 0 =*/ 40)
                  .uint32(message.recordingStopTimestamp);
              if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                  $root.simplisafe.media.controls.v1.Tag.encode(
                    message.tags[i],
                    writer.uint32(/* id 6, wireType 2 =*/ 50).fork()
                  ).ldelim();
              return writer;
            };

            /**
             * Encodes the specified RecordingEvent message, length delimited. Does not implicitly {@link simplisafe.media.controls.v1.RecordingEvent.verify|verify} messages.
             * @function encodeDelimited
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @static
             * @param {simplisafe.media.controls.v1.IRecordingEvent} message RecordingEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecordingEvent.encodeDelimited = function encodeDelimited(
              message,
              writer
            ) {
              return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RecordingEvent message from the specified reader or buffer.
             * @function decode
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {simplisafe.media.controls.v1.RecordingEvent} RecordingEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecordingEvent.decode = function decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              var end = length === undefined ? reader.len : reader.pos + length,
                message =
                  new $root.simplisafe.media.controls.v1.RecordingEvent();
              while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.eventId = reader.uint64();
                    break;
                  }
                  case 2: {
                    message.eventUuid = reader.string();
                    break;
                  }
                  case 3: {
                    message.currentCameraTimestamp = reader.uint32();
                    break;
                  }
                  case 4: {
                    message.recordingStartTimestamp = reader.uint32();
                    break;
                  }
                  case 5: {
                    message.recordingStopTimestamp = reader.uint32();
                    break;
                  }
                  case 6: {
                    if (!(message.tags && message.tags.length))
                      message.tags = [];
                    message.tags.push(
                      $root.simplisafe.media.controls.v1.Tag.decode(
                        reader,
                        reader.uint32()
                      )
                    );
                    break;
                  }
                  default:
                    reader.skipType(tag & 7);
                    break;
                }
              }
              return message;
            };

            /**
             * Decodes a RecordingEvent message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {simplisafe.media.controls.v1.RecordingEvent} RecordingEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecordingEvent.decodeDelimited = function decodeDelimited(reader) {
              if (!(reader instanceof $Reader)) reader = new $Reader(reader);
              return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RecordingEvent message.
             * @function verify
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RecordingEvent.verify = function verify(message) {
              if (typeof message !== 'object' || message === null)
                return 'object expected';
              var properties = {};
              if (
                message.eventId != null &&
                message.hasOwnProperty('eventId')
              ) {
                properties._eventId = 1;
                if (
                  !$util.isInteger(message.eventId) &&
                  !(
                    message.eventId &&
                    $util.isInteger(message.eventId.low) &&
                    $util.isInteger(message.eventId.high)
                  )
                )
                  return 'eventId: integer|Long expected';
              }
              if (
                message.eventUuid != null &&
                message.hasOwnProperty('eventUuid')
              ) {
                properties._eventUuid = 1;
                if (!$util.isString(message.eventUuid))
                  return 'eventUuid: string expected';
              }
              if (
                message.currentCameraTimestamp != null &&
                message.hasOwnProperty('currentCameraTimestamp')
              ) {
                properties._currentCameraTimestamp = 1;
                if (!$util.isInteger(message.currentCameraTimestamp))
                  return 'currentCameraTimestamp: integer expected';
              }
              if (
                message.recordingStartTimestamp != null &&
                message.hasOwnProperty('recordingStartTimestamp')
              ) {
                properties._recordingStartTimestamp = 1;
                if (!$util.isInteger(message.recordingStartTimestamp))
                  return 'recordingStartTimestamp: integer expected';
              }
              if (
                message.recordingStopTimestamp != null &&
                message.hasOwnProperty('recordingStopTimestamp')
              ) {
                properties._recordingStopTimestamp = 1;
                if (!$util.isInteger(message.recordingStopTimestamp))
                  return 'recordingStopTimestamp: integer expected';
              }
              if (message.tags != null && message.hasOwnProperty('tags')) {
                if (!Array.isArray(message.tags)) return 'tags: array expected';
                for (var i = 0; i < message.tags.length; ++i) {
                  var error = $root.simplisafe.media.controls.v1.Tag.verify(
                    message.tags[i]
                  );
                  if (error) return 'tags.' + error;
                }
              }
              return null;
            };

            /**
             * Creates a RecordingEvent message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {simplisafe.media.controls.v1.RecordingEvent} RecordingEvent
             */
            RecordingEvent.fromObject = function fromObject(object) {
              if (
                object instanceof
                $root.simplisafe.media.controls.v1.RecordingEvent
              )
                return object;
              var message =
                new $root.simplisafe.media.controls.v1.RecordingEvent();
              if (object.eventId != null)
                if ($util.Long)
                  (message.eventId = $util.Long.fromValue(
                    object.eventId
                  )).unsigned = true;
                else if (typeof object.eventId === 'string')
                  message.eventId = parseInt(object.eventId, 10);
                else if (typeof object.eventId === 'number')
                  message.eventId = object.eventId;
                else if (typeof object.eventId === 'object')
                  message.eventId = new $util.LongBits(
                    object.eventId.low >>> 0,
                    object.eventId.high >>> 0
                  ).toNumber(true);
              if (object.eventUuid != null)
                message.eventUuid = String(object.eventUuid);
              if (object.currentCameraTimestamp != null)
                message.currentCameraTimestamp =
                  object.currentCameraTimestamp >>> 0;
              if (object.recordingStartTimestamp != null)
                message.recordingStartTimestamp =
                  object.recordingStartTimestamp >>> 0;
              if (object.recordingStopTimestamp != null)
                message.recordingStopTimestamp =
                  object.recordingStopTimestamp >>> 0;
              if (object.tags) {
                if (!Array.isArray(object.tags))
                  throw TypeError(
                    '.simplisafe.media.controls.v1.RecordingEvent.tags: array expected'
                  );
                message.tags = [];
                for (var i = 0; i < object.tags.length; ++i) {
                  if (typeof object.tags[i] !== 'object')
                    throw TypeError(
                      '.simplisafe.media.controls.v1.RecordingEvent.tags: object expected'
                    );
                  message.tags[i] =
                    $root.simplisafe.media.controls.v1.Tag.fromObject(
                      object.tags[i]
                    );
                }
              }
              return message;
            };

            /**
             * Creates a plain object from a RecordingEvent message. Also converts values to other types if specified.
             * @function toObject
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @static
             * @param {simplisafe.media.controls.v1.RecordingEvent} message RecordingEvent
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RecordingEvent.toObject = function toObject(message, options) {
              if (!options) options = {};
              var object = {};
              if (options.arrays || options.defaults) object.tags = [];
              if (
                message.eventId != null &&
                message.hasOwnProperty('eventId')
              ) {
                if (typeof message.eventId === 'number')
                  object.eventId =
                    options.longs === String
                      ? String(message.eventId)
                      : message.eventId;
                else
                  object.eventId =
                    options.longs === String
                      ? $util.Long.prototype.toString.call(message.eventId)
                      : options.longs === Number
                      ? new $util.LongBits(
                          message.eventId.low >>> 0,
                          message.eventId.high >>> 0
                        ).toNumber(true)
                      : message.eventId;
                if (options.oneofs) object._eventId = 'eventId';
              }
              if (
                message.eventUuid != null &&
                message.hasOwnProperty('eventUuid')
              ) {
                object.eventUuid = message.eventUuid;
                if (options.oneofs) object._eventUuid = 'eventUuid';
              }
              if (
                message.currentCameraTimestamp != null &&
                message.hasOwnProperty('currentCameraTimestamp')
              ) {
                object.currentCameraTimestamp = message.currentCameraTimestamp;
                if (options.oneofs)
                  object._currentCameraTimestamp = 'currentCameraTimestamp';
              }
              if (
                message.recordingStartTimestamp != null &&
                message.hasOwnProperty('recordingStartTimestamp')
              ) {
                object.recordingStartTimestamp =
                  message.recordingStartTimestamp;
                if (options.oneofs)
                  object._recordingStartTimestamp = 'recordingStartTimestamp';
              }
              if (
                message.recordingStopTimestamp != null &&
                message.hasOwnProperty('recordingStopTimestamp')
              ) {
                object.recordingStopTimestamp = message.recordingStopTimestamp;
                if (options.oneofs)
                  object._recordingStopTimestamp = 'recordingStopTimestamp';
              }
              if (message.tags && message.tags.length) {
                object.tags = [];
                for (var j = 0; j < message.tags.length; ++j)
                  object.tags[j] =
                    $root.simplisafe.media.controls.v1.Tag.toObject(
                      message.tags[j],
                      options
                    );
              }
              return object;
            };

            /**
             * Converts this RecordingEvent to JSON.
             * @function toJSON
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RecordingEvent.prototype.toJSON = function toJSON() {
              return this.constructor.toObject(
                this,
                $protobuf.util.toJSONOptions
              );
            };

            /**
             * Gets the default type url for RecordingEvent
             * @function getTypeUrl
             * @memberof simplisafe.media.controls.v1.RecordingEvent
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RecordingEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
              if (typeUrlPrefix === undefined) {
                typeUrlPrefix = 'type.googleapis.com';
              }
              return (
                typeUrlPrefix + '/simplisafe.media.controls.v1.RecordingEvent'
              );
            };

            return RecordingEvent;
          })();

          v1.RecordingResponse = (function () {
            /**
             * Properties of a RecordingResponse.
             * @memberof simplisafe.media.controls.v1
             * @interface IRecordingResponse
             * @property {number|Long|null} [eventId] RecordingResponse eventId
             * @property {string|null} [eventUuid] RecordingResponse eventUuid
             * @property {number|null} [uploadStartTimestamp] RecordingResponse uploadStartTimestamp
             * @property {number|null} [uploadLastTimestamp] RecordingResponse uploadLastTimestamp
             * @property {Array.<number>|null} [timestampsNotReceived] RecordingResponse timestampsNotReceived
             */

            /**
             * Constructs a new RecordingResponse.
             * @memberof simplisafe.media.controls.v1
             * @classdesc Represents a RecordingResponse.
             * @implements IRecordingResponse
             * @constructor
             * @param {simplisafe.media.controls.v1.IRecordingResponse=} [properties] Properties to set
             */
            function RecordingResponse(properties) {
              this.timestampsNotReceived = [];
              if (properties)
                for (
                  var keys = Object.keys(properties), i = 0;
                  i < keys.length;
                  ++i
                )
                  if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
            }

            /**
             * RecordingResponse eventId.
             * @member {number|Long|null|undefined} eventId
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @instance
             */
            RecordingResponse.prototype.eventId = null;

            /**
             * RecordingResponse eventUuid.
             * @member {string|null|undefined} eventUuid
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @instance
             */
            RecordingResponse.prototype.eventUuid = null;

            /**
             * RecordingResponse uploadStartTimestamp.
             * @member {number|null|undefined} uploadStartTimestamp
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @instance
             */
            RecordingResponse.prototype.uploadStartTimestamp = null;

            /**
             * RecordingResponse uploadLastTimestamp.
             * @member {number|null|undefined} uploadLastTimestamp
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @instance
             */
            RecordingResponse.prototype.uploadLastTimestamp = null;

            /**
             * RecordingResponse timestampsNotReceived.
             * @member {Array.<number>} timestampsNotReceived
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @instance
             */
            RecordingResponse.prototype.timestampsNotReceived =
              $util.emptyArray;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * RecordingResponse _eventId.
             * @member {"eventId"|undefined} _eventId
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @instance
             */
            Object.defineProperty(RecordingResponse.prototype, '_eventId', {
              get: $util.oneOfGetter(($oneOfFields = ['eventId'])),
              set: $util.oneOfSetter($oneOfFields),
            });

            /**
             * RecordingResponse _eventUuid.
             * @member {"eventUuid"|undefined} _eventUuid
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @instance
             */
            Object.defineProperty(RecordingResponse.prototype, '_eventUuid', {
              get: $util.oneOfGetter(($oneOfFields = ['eventUuid'])),
              set: $util.oneOfSetter($oneOfFields),
            });

            /**
             * RecordingResponse _uploadStartTimestamp.
             * @member {"uploadStartTimestamp"|undefined} _uploadStartTimestamp
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @instance
             */
            Object.defineProperty(
              RecordingResponse.prototype,
              '_uploadStartTimestamp',
              {
                get: $util.oneOfGetter(
                  ($oneOfFields = ['uploadStartTimestamp'])
                ),
                set: $util.oneOfSetter($oneOfFields),
              }
            );

            /**
             * RecordingResponse _uploadLastTimestamp.
             * @member {"uploadLastTimestamp"|undefined} _uploadLastTimestamp
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @instance
             */
            Object.defineProperty(
              RecordingResponse.prototype,
              '_uploadLastTimestamp',
              {
                get: $util.oneOfGetter(
                  ($oneOfFields = ['uploadLastTimestamp'])
                ),
                set: $util.oneOfSetter($oneOfFields),
              }
            );

            /**
             * Creates a new RecordingResponse instance using the specified properties.
             * @function create
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @static
             * @param {simplisafe.media.controls.v1.IRecordingResponse=} [properties] Properties to set
             * @returns {simplisafe.media.controls.v1.RecordingResponse} RecordingResponse instance
             */
            RecordingResponse.create = function create(properties) {
              return new RecordingResponse(properties);
            };

            /**
             * Encodes the specified RecordingResponse message. Does not implicitly {@link simplisafe.media.controls.v1.RecordingResponse.verify|verify} messages.
             * @function encode
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @static
             * @param {simplisafe.media.controls.v1.IRecordingResponse} message RecordingResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecordingResponse.encode = function encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.eventId != null &&
                Object.hasOwnProperty.call(message, 'eventId')
              )
                writer
                  .uint32(/* id 1, wireType 0 =*/ 8)
                  .uint64(message.eventId);
              if (
                message.eventUuid != null &&
                Object.hasOwnProperty.call(message, 'eventUuid')
              )
                writer
                  .uint32(/* id 2, wireType 2 =*/ 18)
                  .string(message.eventUuid);
              if (
                message.uploadStartTimestamp != null &&
                Object.hasOwnProperty.call(message, 'uploadStartTimestamp')
              )
                writer
                  .uint32(/* id 3, wireType 0 =*/ 24)
                  .uint32(message.uploadStartTimestamp);
              if (
                message.uploadLastTimestamp != null &&
                Object.hasOwnProperty.call(message, 'uploadLastTimestamp')
              )
                writer
                  .uint32(/* id 4, wireType 0 =*/ 32)
                  .uint32(message.uploadLastTimestamp);
              if (
                message.timestampsNotReceived != null &&
                message.timestampsNotReceived.length
              ) {
                writer.uint32(/* id 5, wireType 2 =*/ 42).fork();
                for (var i = 0; i < message.timestampsNotReceived.length; ++i)
                  writer.uint32(message.timestampsNotReceived[i]);
                writer.ldelim();
              }
              return writer;
            };

            /**
             * Encodes the specified RecordingResponse message, length delimited. Does not implicitly {@link simplisafe.media.controls.v1.RecordingResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @static
             * @param {simplisafe.media.controls.v1.IRecordingResponse} message RecordingResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecordingResponse.encodeDelimited = function encodeDelimited(
              message,
              writer
            ) {
              return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RecordingResponse message from the specified reader or buffer.
             * @function decode
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {simplisafe.media.controls.v1.RecordingResponse} RecordingResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecordingResponse.decode = function decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              var end = length === undefined ? reader.len : reader.pos + length,
                message =
                  new $root.simplisafe.media.controls.v1.RecordingResponse();
              while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.eventId = reader.uint64();
                    break;
                  }
                  case 2: {
                    message.eventUuid = reader.string();
                    break;
                  }
                  case 3: {
                    message.uploadStartTimestamp = reader.uint32();
                    break;
                  }
                  case 4: {
                    message.uploadLastTimestamp = reader.uint32();
                    break;
                  }
                  case 5: {
                    if (
                      !(
                        message.timestampsNotReceived &&
                        message.timestampsNotReceived.length
                      )
                    )
                      message.timestampsNotReceived = [];
                    if ((tag & 7) === 2) {
                      var end2 = reader.uint32() + reader.pos;
                      while (reader.pos < end2)
                        message.timestampsNotReceived.push(reader.uint32());
                    } else message.timestampsNotReceived.push(reader.uint32());
                    break;
                  }
                  default:
                    reader.skipType(tag & 7);
                    break;
                }
              }
              return message;
            };

            /**
             * Decodes a RecordingResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {simplisafe.media.controls.v1.RecordingResponse} RecordingResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecordingResponse.decodeDelimited = function decodeDelimited(
              reader
            ) {
              if (!(reader instanceof $Reader)) reader = new $Reader(reader);
              return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RecordingResponse message.
             * @function verify
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RecordingResponse.verify = function verify(message) {
              if (typeof message !== 'object' || message === null)
                return 'object expected';
              var properties = {};
              if (
                message.eventId != null &&
                message.hasOwnProperty('eventId')
              ) {
                properties._eventId = 1;
                if (
                  !$util.isInteger(message.eventId) &&
                  !(
                    message.eventId &&
                    $util.isInteger(message.eventId.low) &&
                    $util.isInteger(message.eventId.high)
                  )
                )
                  return 'eventId: integer|Long expected';
              }
              if (
                message.eventUuid != null &&
                message.hasOwnProperty('eventUuid')
              ) {
                properties._eventUuid = 1;
                if (!$util.isString(message.eventUuid))
                  return 'eventUuid: string expected';
              }
              if (
                message.uploadStartTimestamp != null &&
                message.hasOwnProperty('uploadStartTimestamp')
              ) {
                properties._uploadStartTimestamp = 1;
                if (!$util.isInteger(message.uploadStartTimestamp))
                  return 'uploadStartTimestamp: integer expected';
              }
              if (
                message.uploadLastTimestamp != null &&
                message.hasOwnProperty('uploadLastTimestamp')
              ) {
                properties._uploadLastTimestamp = 1;
                if (!$util.isInteger(message.uploadLastTimestamp))
                  return 'uploadLastTimestamp: integer expected';
              }
              if (
                message.timestampsNotReceived != null &&
                message.hasOwnProperty('timestampsNotReceived')
              ) {
                if (!Array.isArray(message.timestampsNotReceived))
                  return 'timestampsNotReceived: array expected';
                for (var i = 0; i < message.timestampsNotReceived.length; ++i)
                  if (!$util.isInteger(message.timestampsNotReceived[i]))
                    return 'timestampsNotReceived: integer[] expected';
              }
              return null;
            };

            /**
             * Creates a RecordingResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {simplisafe.media.controls.v1.RecordingResponse} RecordingResponse
             */
            RecordingResponse.fromObject = function fromObject(object) {
              if (
                object instanceof
                $root.simplisafe.media.controls.v1.RecordingResponse
              )
                return object;
              var message =
                new $root.simplisafe.media.controls.v1.RecordingResponse();
              if (object.eventId != null)
                if ($util.Long)
                  (message.eventId = $util.Long.fromValue(
                    object.eventId
                  )).unsigned = true;
                else if (typeof object.eventId === 'string')
                  message.eventId = parseInt(object.eventId, 10);
                else if (typeof object.eventId === 'number')
                  message.eventId = object.eventId;
                else if (typeof object.eventId === 'object')
                  message.eventId = new $util.LongBits(
                    object.eventId.low >>> 0,
                    object.eventId.high >>> 0
                  ).toNumber(true);
              if (object.eventUuid != null)
                message.eventUuid = String(object.eventUuid);
              if (object.uploadStartTimestamp != null)
                message.uploadStartTimestamp =
                  object.uploadStartTimestamp >>> 0;
              if (object.uploadLastTimestamp != null)
                message.uploadLastTimestamp = object.uploadLastTimestamp >>> 0;
              if (object.timestampsNotReceived) {
                if (!Array.isArray(object.timestampsNotReceived))
                  throw TypeError(
                    '.simplisafe.media.controls.v1.RecordingResponse.timestampsNotReceived: array expected'
                  );
                message.timestampsNotReceived = [];
                for (var i = 0; i < object.timestampsNotReceived.length; ++i)
                  message.timestampsNotReceived[i] =
                    object.timestampsNotReceived[i] >>> 0;
              }
              return message;
            };

            /**
             * Creates a plain object from a RecordingResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @static
             * @param {simplisafe.media.controls.v1.RecordingResponse} message RecordingResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RecordingResponse.toObject = function toObject(message, options) {
              if (!options) options = {};
              var object = {};
              if (options.arrays || options.defaults)
                object.timestampsNotReceived = [];
              if (
                message.eventId != null &&
                message.hasOwnProperty('eventId')
              ) {
                if (typeof message.eventId === 'number')
                  object.eventId =
                    options.longs === String
                      ? String(message.eventId)
                      : message.eventId;
                else
                  object.eventId =
                    options.longs === String
                      ? $util.Long.prototype.toString.call(message.eventId)
                      : options.longs === Number
                      ? new $util.LongBits(
                          message.eventId.low >>> 0,
                          message.eventId.high >>> 0
                        ).toNumber(true)
                      : message.eventId;
                if (options.oneofs) object._eventId = 'eventId';
              }
              if (
                message.eventUuid != null &&
                message.hasOwnProperty('eventUuid')
              ) {
                object.eventUuid = message.eventUuid;
                if (options.oneofs) object._eventUuid = 'eventUuid';
              }
              if (
                message.uploadStartTimestamp != null &&
                message.hasOwnProperty('uploadStartTimestamp')
              ) {
                object.uploadStartTimestamp = message.uploadStartTimestamp;
                if (options.oneofs)
                  object._uploadStartTimestamp = 'uploadStartTimestamp';
              }
              if (
                message.uploadLastTimestamp != null &&
                message.hasOwnProperty('uploadLastTimestamp')
              ) {
                object.uploadLastTimestamp = message.uploadLastTimestamp;
                if (options.oneofs)
                  object._uploadLastTimestamp = 'uploadLastTimestamp';
              }
              if (
                message.timestampsNotReceived &&
                message.timestampsNotReceived.length
              ) {
                object.timestampsNotReceived = [];
                for (var j = 0; j < message.timestampsNotReceived.length; ++j)
                  object.timestampsNotReceived[j] =
                    message.timestampsNotReceived[j];
              }
              return object;
            };

            /**
             * Converts this RecordingResponse to JSON.
             * @function toJSON
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RecordingResponse.prototype.toJSON = function toJSON() {
              return this.constructor.toObject(
                this,
                $protobuf.util.toJSONOptions
              );
            };

            /**
             * Gets the default type url for RecordingResponse
             * @function getTypeUrl
             * @memberof simplisafe.media.controls.v1.RecordingResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RecordingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
              if (typeUrlPrefix === undefined) {
                typeUrlPrefix = 'type.googleapis.com';
              }
              return (
                typeUrlPrefix +
                '/simplisafe.media.controls.v1.RecordingResponse'
              );
            };

            return RecordingResponse;
          })();

          v1.Tag = (function () {
            /**
             * Properties of a Tag.
             * @memberof simplisafe.media.controls.v1
             * @interface ITag
             * @property {string|null} [name] Tag name
             * @property {number|null} [frameNumber] Tag frameNumber
             * @property {number|null} [timestamp] Tag timestamp
             */

            /**
             * Constructs a new Tag.
             * @memberof simplisafe.media.controls.v1
             * @classdesc Represents a Tag.
             * @implements ITag
             * @constructor
             * @param {simplisafe.media.controls.v1.ITag=} [properties] Properties to set
             */
            function Tag(properties) {
              if (properties)
                for (
                  var keys = Object.keys(properties), i = 0;
                  i < keys.length;
                  ++i
                )
                  if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
            }

            /**
             * Tag name.
             * @member {string|null|undefined} name
             * @memberof simplisafe.media.controls.v1.Tag
             * @instance
             */
            Tag.prototype.name = null;

            /**
             * Tag frameNumber.
             * @member {number|null|undefined} frameNumber
             * @memberof simplisafe.media.controls.v1.Tag
             * @instance
             */
            Tag.prototype.frameNumber = null;

            /**
             * Tag timestamp.
             * @member {number|null|undefined} timestamp
             * @memberof simplisafe.media.controls.v1.Tag
             * @instance
             */
            Tag.prototype.timestamp = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Tag _name.
             * @member {"name"|undefined} _name
             * @memberof simplisafe.media.controls.v1.Tag
             * @instance
             */
            Object.defineProperty(Tag.prototype, '_name', {
              get: $util.oneOfGetter(($oneOfFields = ['name'])),
              set: $util.oneOfSetter($oneOfFields),
            });

            /**
             * Tag _frameNumber.
             * @member {"frameNumber"|undefined} _frameNumber
             * @memberof simplisafe.media.controls.v1.Tag
             * @instance
             */
            Object.defineProperty(Tag.prototype, '_frameNumber', {
              get: $util.oneOfGetter(($oneOfFields = ['frameNumber'])),
              set: $util.oneOfSetter($oneOfFields),
            });

            /**
             * Tag _timestamp.
             * @member {"timestamp"|undefined} _timestamp
             * @memberof simplisafe.media.controls.v1.Tag
             * @instance
             */
            Object.defineProperty(Tag.prototype, '_timestamp', {
              get: $util.oneOfGetter(($oneOfFields = ['timestamp'])),
              set: $util.oneOfSetter($oneOfFields),
            });

            /**
             * Creates a new Tag instance using the specified properties.
             * @function create
             * @memberof simplisafe.media.controls.v1.Tag
             * @static
             * @param {simplisafe.media.controls.v1.ITag=} [properties] Properties to set
             * @returns {simplisafe.media.controls.v1.Tag} Tag instance
             */
            Tag.create = function create(properties) {
              return new Tag(properties);
            };

            /**
             * Encodes the specified Tag message. Does not implicitly {@link simplisafe.media.controls.v1.Tag.verify|verify} messages.
             * @function encode
             * @memberof simplisafe.media.controls.v1.Tag
             * @static
             * @param {simplisafe.media.controls.v1.ITag} message Tag message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Tag.encode = function encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.name != null &&
                Object.hasOwnProperty.call(message, 'name')
              )
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.name);
              if (
                message.frameNumber != null &&
                Object.hasOwnProperty.call(message, 'frameNumber')
              )
                writer
                  .uint32(/* id 2, wireType 0 =*/ 16)
                  .uint32(message.frameNumber);
              if (
                message.timestamp != null &&
                Object.hasOwnProperty.call(message, 'timestamp')
              )
                writer
                  .uint32(/* id 3, wireType 0 =*/ 24)
                  .uint32(message.timestamp);
              return writer;
            };

            /**
             * Encodes the specified Tag message, length delimited. Does not implicitly {@link simplisafe.media.controls.v1.Tag.verify|verify} messages.
             * @function encodeDelimited
             * @memberof simplisafe.media.controls.v1.Tag
             * @static
             * @param {simplisafe.media.controls.v1.ITag} message Tag message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Tag.encodeDelimited = function encodeDelimited(message, writer) {
              return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Tag message from the specified reader or buffer.
             * @function decode
             * @memberof simplisafe.media.controls.v1.Tag
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {simplisafe.media.controls.v1.Tag} Tag
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Tag.decode = function decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              var end = length === undefined ? reader.len : reader.pos + length,
                message = new $root.simplisafe.media.controls.v1.Tag();
              while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.name = reader.string();
                    break;
                  }
                  case 2: {
                    message.frameNumber = reader.uint32();
                    break;
                  }
                  case 3: {
                    message.timestamp = reader.uint32();
                    break;
                  }
                  default:
                    reader.skipType(tag & 7);
                    break;
                }
              }
              return message;
            };

            /**
             * Decodes a Tag message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof simplisafe.media.controls.v1.Tag
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {simplisafe.media.controls.v1.Tag} Tag
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Tag.decodeDelimited = function decodeDelimited(reader) {
              if (!(reader instanceof $Reader)) reader = new $Reader(reader);
              return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Tag message.
             * @function verify
             * @memberof simplisafe.media.controls.v1.Tag
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Tag.verify = function verify(message) {
              if (typeof message !== 'object' || message === null)
                return 'object expected';
              var properties = {};
              if (message.name != null && message.hasOwnProperty('name')) {
                properties._name = 1;
                if (!$util.isString(message.name))
                  return 'name: string expected';
              }
              if (
                message.frameNumber != null &&
                message.hasOwnProperty('frameNumber')
              ) {
                properties._frameNumber = 1;
                if (!$util.isInteger(message.frameNumber))
                  return 'frameNumber: integer expected';
              }
              if (
                message.timestamp != null &&
                message.hasOwnProperty('timestamp')
              ) {
                properties._timestamp = 1;
                if (!$util.isInteger(message.timestamp))
                  return 'timestamp: integer expected';
              }
              return null;
            };

            /**
             * Creates a Tag message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof simplisafe.media.controls.v1.Tag
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {simplisafe.media.controls.v1.Tag} Tag
             */
            Tag.fromObject = function fromObject(object) {
              if (object instanceof $root.simplisafe.media.controls.v1.Tag)
                return object;
              var message = new $root.simplisafe.media.controls.v1.Tag();
              if (object.name != null) message.name = String(object.name);
              if (object.frameNumber != null)
                message.frameNumber = object.frameNumber >>> 0;
              if (object.timestamp != null)
                message.timestamp = object.timestamp >>> 0;
              return message;
            };

            /**
             * Creates a plain object from a Tag message. Also converts values to other types if specified.
             * @function toObject
             * @memberof simplisafe.media.controls.v1.Tag
             * @static
             * @param {simplisafe.media.controls.v1.Tag} message Tag
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Tag.toObject = function toObject(message, options) {
              if (!options) options = {};
              var object = {};
              if (message.name != null && message.hasOwnProperty('name')) {
                object.name = message.name;
                if (options.oneofs) object._name = 'name';
              }
              if (
                message.frameNumber != null &&
                message.hasOwnProperty('frameNumber')
              ) {
                object.frameNumber = message.frameNumber;
                if (options.oneofs) object._frameNumber = 'frameNumber';
              }
              if (
                message.timestamp != null &&
                message.hasOwnProperty('timestamp')
              ) {
                object.timestamp = message.timestamp;
                if (options.oneofs) object._timestamp = 'timestamp';
              }
              return object;
            };

            /**
             * Converts this Tag to JSON.
             * @function toJSON
             * @memberof simplisafe.media.controls.v1.Tag
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Tag.prototype.toJSON = function toJSON() {
              return this.constructor.toObject(
                this,
                $protobuf.util.toJSONOptions
              );
            };

            /**
             * Gets the default type url for Tag
             * @function getTypeUrl
             * @memberof simplisafe.media.controls.v1.Tag
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Tag.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
              if (typeUrlPrefix === undefined) {
                typeUrlPrefix = 'type.googleapis.com';
              }
              return typeUrlPrefix + '/simplisafe.media.controls.v1.Tag';
            };

            return Tag;
          })();

          return v1;
        })();

        return controls;
      })();

      return media;
    })();

    simplisafe.signal = (function () {
      /**
       * Namespace signal.
       * @memberof simplisafe
       * @namespace
       */
      var signal = {};

      signal.v1 = (function () {
        /**
         * Namespace v1.
         * @memberof simplisafe.signal
         * @namespace
         */
        var v1 = {};

        v1.ConnectionCloseRequest = (function () {
          /**
           * Properties of a ConnectionCloseRequest.
           * @memberof simplisafe.signal.v1
           * @interface IConnectionCloseRequest
           * @property {string|null} [reason] ConnectionCloseRequest reason
           */

          /**
           * Constructs a new ConnectionCloseRequest.
           * @memberof simplisafe.signal.v1
           * @classdesc Represents a ConnectionCloseRequest.
           * @implements IConnectionCloseRequest
           * @constructor
           * @param {simplisafe.signal.v1.IConnectionCloseRequest=} [properties] Properties to set
           */
          function ConnectionCloseRequest(properties) {
            if (properties)
              for (
                var keys = Object.keys(properties), i = 0;
                i < keys.length;
                ++i
              )
                if (properties[keys[i]] != null)
                  this[keys[i]] = properties[keys[i]];
          }

          /**
           * ConnectionCloseRequest reason.
           * @member {string} reason
           * @memberof simplisafe.signal.v1.ConnectionCloseRequest
           * @instance
           */
          ConnectionCloseRequest.prototype.reason = '';

          /**
           * Creates a new ConnectionCloseRequest instance using the specified properties.
           * @function create
           * @memberof simplisafe.signal.v1.ConnectionCloseRequest
           * @static
           * @param {simplisafe.signal.v1.IConnectionCloseRequest=} [properties] Properties to set
           * @returns {simplisafe.signal.v1.ConnectionCloseRequest} ConnectionCloseRequest instance
           */
          ConnectionCloseRequest.create = function create(properties) {
            return new ConnectionCloseRequest(properties);
          };

          /**
           * Encodes the specified ConnectionCloseRequest message. Does not implicitly {@link simplisafe.signal.v1.ConnectionCloseRequest.verify|verify} messages.
           * @function encode
           * @memberof simplisafe.signal.v1.ConnectionCloseRequest
           * @static
           * @param {simplisafe.signal.v1.IConnectionCloseRequest} message ConnectionCloseRequest message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          ConnectionCloseRequest.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.reason != null &&
              Object.hasOwnProperty.call(message, 'reason')
            )
              writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.reason);
            return writer;
          };

          /**
           * Encodes the specified ConnectionCloseRequest message, length delimited. Does not implicitly {@link simplisafe.signal.v1.ConnectionCloseRequest.verify|verify} messages.
           * @function encodeDelimited
           * @memberof simplisafe.signal.v1.ConnectionCloseRequest
           * @static
           * @param {simplisafe.signal.v1.IConnectionCloseRequest} message ConnectionCloseRequest message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          ConnectionCloseRequest.encodeDelimited = function encodeDelimited(
            message,
            writer
          ) {
            return this.encode(message, writer).ldelim();
          };

          /**
           * Decodes a ConnectionCloseRequest message from the specified reader or buffer.
           * @function decode
           * @memberof simplisafe.signal.v1.ConnectionCloseRequest
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @param {number} [length] Message length if known beforehand
           * @returns {simplisafe.signal.v1.ConnectionCloseRequest} ConnectionCloseRequest
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          ConnectionCloseRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length,
              message = new $root.simplisafe.signal.v1.ConnectionCloseRequest();
            while (reader.pos < end) {
              var tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.reason = reader.string();
                  break;
                }
                default:
                  reader.skipType(tag & 7);
                  break;
              }
            }
            return message;
          };

          /**
           * Decodes a ConnectionCloseRequest message from the specified reader or buffer, length delimited.
           * @function decodeDelimited
           * @memberof simplisafe.signal.v1.ConnectionCloseRequest
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @returns {simplisafe.signal.v1.ConnectionCloseRequest} ConnectionCloseRequest
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          ConnectionCloseRequest.decodeDelimited = function decodeDelimited(
            reader
          ) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
          };

          /**
           * Verifies a ConnectionCloseRequest message.
           * @function verify
           * @memberof simplisafe.signal.v1.ConnectionCloseRequest
           * @static
           * @param {Object.<string,*>} message Plain object to verify
           * @returns {string|null} `null` if valid, otherwise the reason why it is not
           */
          ConnectionCloseRequest.verify = function verify(message) {
            if (typeof message !== 'object' || message === null)
              return 'object expected';
            if (message.reason != null && message.hasOwnProperty('reason'))
              if (!$util.isString(message.reason))
                return 'reason: string expected';
            return null;
          };

          /**
           * Creates a ConnectionCloseRequest message from a plain object. Also converts values to their respective internal types.
           * @function fromObject
           * @memberof simplisafe.signal.v1.ConnectionCloseRequest
           * @static
           * @param {Object.<string,*>} object Plain object
           * @returns {simplisafe.signal.v1.ConnectionCloseRequest} ConnectionCloseRequest
           */
          ConnectionCloseRequest.fromObject = function fromObject(object) {
            if (
              object instanceof
              $root.simplisafe.signal.v1.ConnectionCloseRequest
            )
              return object;
            var message =
              new $root.simplisafe.signal.v1.ConnectionCloseRequest();
            if (object.reason != null) message.reason = String(object.reason);
            return message;
          };

          /**
           * Creates a plain object from a ConnectionCloseRequest message. Also converts values to other types if specified.
           * @function toObject
           * @memberof simplisafe.signal.v1.ConnectionCloseRequest
           * @static
           * @param {simplisafe.signal.v1.ConnectionCloseRequest} message ConnectionCloseRequest
           * @param {$protobuf.IConversionOptions} [options] Conversion options
           * @returns {Object.<string,*>} Plain object
           */
          ConnectionCloseRequest.toObject = function toObject(
            message,
            options
          ) {
            if (!options) options = {};
            var object = {};
            if (options.defaults) object.reason = '';
            if (message.reason != null && message.hasOwnProperty('reason'))
              object.reason = message.reason;
            return object;
          };

          /**
           * Converts this ConnectionCloseRequest to JSON.
           * @function toJSON
           * @memberof simplisafe.signal.v1.ConnectionCloseRequest
           * @instance
           * @returns {Object.<string,*>} JSON object
           */
          ConnectionCloseRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(
              this,
              $protobuf.util.toJSONOptions
            );
          };

          /**
           * Gets the default type url for ConnectionCloseRequest
           * @function getTypeUrl
           * @memberof simplisafe.signal.v1.ConnectionCloseRequest
           * @static
           * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
           * @returns {string} The default type url
           */
          ConnectionCloseRequest.getTypeUrl = function getTypeUrl(
            typeUrlPrefix
          ) {
            if (typeUrlPrefix === undefined) {
              typeUrlPrefix = 'type.googleapis.com';
            }
            return (
              typeUrlPrefix + '/simplisafe.signal.v1.ConnectionCloseRequest'
            );
          };

          return ConnectionCloseRequest;
        })();

        v1.EmptyRoom = (function () {
          /**
           * Properties of an EmptyRoom.
           * @memberof simplisafe.signal.v1
           * @interface IEmptyRoom
           */

          /**
           * Constructs a new EmptyRoom.
           * @memberof simplisafe.signal.v1
           * @classdesc Represents an EmptyRoom.
           * @implements IEmptyRoom
           * @constructor
           * @param {simplisafe.signal.v1.IEmptyRoom=} [properties] Properties to set
           */
          function EmptyRoom(properties) {
            if (properties)
              for (
                var keys = Object.keys(properties), i = 0;
                i < keys.length;
                ++i
              )
                if (properties[keys[i]] != null)
                  this[keys[i]] = properties[keys[i]];
          }

          /**
           * Creates a new EmptyRoom instance using the specified properties.
           * @function create
           * @memberof simplisafe.signal.v1.EmptyRoom
           * @static
           * @param {simplisafe.signal.v1.IEmptyRoom=} [properties] Properties to set
           * @returns {simplisafe.signal.v1.EmptyRoom} EmptyRoom instance
           */
          EmptyRoom.create = function create(properties) {
            return new EmptyRoom(properties);
          };

          /**
           * Encodes the specified EmptyRoom message. Does not implicitly {@link simplisafe.signal.v1.EmptyRoom.verify|verify} messages.
           * @function encode
           * @memberof simplisafe.signal.v1.EmptyRoom
           * @static
           * @param {simplisafe.signal.v1.IEmptyRoom} message EmptyRoom message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          EmptyRoom.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create();
            return writer;
          };

          /**
           * Encodes the specified EmptyRoom message, length delimited. Does not implicitly {@link simplisafe.signal.v1.EmptyRoom.verify|verify} messages.
           * @function encodeDelimited
           * @memberof simplisafe.signal.v1.EmptyRoom
           * @static
           * @param {simplisafe.signal.v1.IEmptyRoom} message EmptyRoom message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          EmptyRoom.encodeDelimited = function encodeDelimited(
            message,
            writer
          ) {
            return this.encode(message, writer).ldelim();
          };

          /**
           * Decodes an EmptyRoom message from the specified reader or buffer.
           * @function decode
           * @memberof simplisafe.signal.v1.EmptyRoom
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @param {number} [length] Message length if known beforehand
           * @returns {simplisafe.signal.v1.EmptyRoom} EmptyRoom
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          EmptyRoom.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length,
              message = new $root.simplisafe.signal.v1.EmptyRoom();
            while (reader.pos < end) {
              var tag = reader.uint32();
              switch (tag >>> 3) {
                default:
                  reader.skipType(tag & 7);
                  break;
              }
            }
            return message;
          };

          /**
           * Decodes an EmptyRoom message from the specified reader or buffer, length delimited.
           * @function decodeDelimited
           * @memberof simplisafe.signal.v1.EmptyRoom
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @returns {simplisafe.signal.v1.EmptyRoom} EmptyRoom
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          EmptyRoom.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
          };

          /**
           * Verifies an EmptyRoom message.
           * @function verify
           * @memberof simplisafe.signal.v1.EmptyRoom
           * @static
           * @param {Object.<string,*>} message Plain object to verify
           * @returns {string|null} `null` if valid, otherwise the reason why it is not
           */
          EmptyRoom.verify = function verify(message) {
            if (typeof message !== 'object' || message === null)
              return 'object expected';
            return null;
          };

          /**
           * Creates an EmptyRoom message from a plain object. Also converts values to their respective internal types.
           * @function fromObject
           * @memberof simplisafe.signal.v1.EmptyRoom
           * @static
           * @param {Object.<string,*>} object Plain object
           * @returns {simplisafe.signal.v1.EmptyRoom} EmptyRoom
           */
          EmptyRoom.fromObject = function fromObject(object) {
            if (object instanceof $root.simplisafe.signal.v1.EmptyRoom)
              return object;
            return new $root.simplisafe.signal.v1.EmptyRoom();
          };

          /**
           * Creates a plain object from an EmptyRoom message. Also converts values to other types if specified.
           * @function toObject
           * @memberof simplisafe.signal.v1.EmptyRoom
           * @static
           * @param {simplisafe.signal.v1.EmptyRoom} message EmptyRoom
           * @param {$protobuf.IConversionOptions} [options] Conversion options
           * @returns {Object.<string,*>} Plain object
           */
          EmptyRoom.toObject = function toObject() {
            return {};
          };

          /**
           * Converts this EmptyRoom to JSON.
           * @function toJSON
           * @memberof simplisafe.signal.v1.EmptyRoom
           * @instance
           * @returns {Object.<string,*>} JSON object
           */
          EmptyRoom.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(
              this,
              $protobuf.util.toJSONOptions
            );
          };

          /**
           * Gets the default type url for EmptyRoom
           * @function getTypeUrl
           * @memberof simplisafe.signal.v1.EmptyRoom
           * @static
           * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
           * @returns {string} The default type url
           */
          EmptyRoom.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
              typeUrlPrefix = 'type.googleapis.com';
            }
            return typeUrlPrefix + '/simplisafe.signal.v1.EmptyRoom';
          };

          return EmptyRoom;
        })();

        v1.IceServer = (function () {
          /**
           * Properties of an IceServer.
           * @memberof simplisafe.signal.v1
           * @interface IIceServer
           * @property {Array.<string>|null} [urls] IceServer urls
           * @property {string|null} [username] IceServer username
           * @property {string|null} [credential] IceServer credential
           */

          /**
           * Constructs a new IceServer.
           * @memberof simplisafe.signal.v1
           * @classdesc Represents an IceServer.
           * @implements IIceServer
           * @constructor
           * @param {simplisafe.signal.v1.IIceServer=} [properties] Properties to set
           */
          function IceServer(properties) {
            this.urls = [];
            if (properties)
              for (
                var keys = Object.keys(properties), i = 0;
                i < keys.length;
                ++i
              )
                if (properties[keys[i]] != null)
                  this[keys[i]] = properties[keys[i]];
          }

          /**
           * IceServer urls.
           * @member {Array.<string>} urls
           * @memberof simplisafe.signal.v1.IceServer
           * @instance
           */
          IceServer.prototype.urls = $util.emptyArray;

          /**
           * IceServer username.
           * @member {string} username
           * @memberof simplisafe.signal.v1.IceServer
           * @instance
           */
          IceServer.prototype.username = '';

          /**
           * IceServer credential.
           * @member {string} credential
           * @memberof simplisafe.signal.v1.IceServer
           * @instance
           */
          IceServer.prototype.credential = '';

          /**
           * Creates a new IceServer instance using the specified properties.
           * @function create
           * @memberof simplisafe.signal.v1.IceServer
           * @static
           * @param {simplisafe.signal.v1.IIceServer=} [properties] Properties to set
           * @returns {simplisafe.signal.v1.IceServer} IceServer instance
           */
          IceServer.create = function create(properties) {
            return new IceServer(properties);
          };

          /**
           * Encodes the specified IceServer message. Does not implicitly {@link simplisafe.signal.v1.IceServer.verify|verify} messages.
           * @function encode
           * @memberof simplisafe.signal.v1.IceServer
           * @static
           * @param {simplisafe.signal.v1.IIceServer} message IceServer message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          IceServer.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.urls != null && message.urls.length)
              for (var i = 0; i < message.urls.length; ++i)
                writer
                  .uint32(/* id 1, wireType 2 =*/ 10)
                  .string(message.urls[i]);
            if (
              message.username != null &&
              Object.hasOwnProperty.call(message, 'username')
            )
              writer
                .uint32(/* id 2, wireType 2 =*/ 18)
                .string(message.username);
            if (
              message.credential != null &&
              Object.hasOwnProperty.call(message, 'credential')
            )
              writer
                .uint32(/* id 3, wireType 2 =*/ 26)
                .string(message.credential);
            return writer;
          };

          /**
           * Encodes the specified IceServer message, length delimited. Does not implicitly {@link simplisafe.signal.v1.IceServer.verify|verify} messages.
           * @function encodeDelimited
           * @memberof simplisafe.signal.v1.IceServer
           * @static
           * @param {simplisafe.signal.v1.IIceServer} message IceServer message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          IceServer.encodeDelimited = function encodeDelimited(
            message,
            writer
          ) {
            return this.encode(message, writer).ldelim();
          };

          /**
           * Decodes an IceServer message from the specified reader or buffer.
           * @function decode
           * @memberof simplisafe.signal.v1.IceServer
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @param {number} [length] Message length if known beforehand
           * @returns {simplisafe.signal.v1.IceServer} IceServer
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          IceServer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length,
              message = new $root.simplisafe.signal.v1.IceServer();
            while (reader.pos < end) {
              var tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!(message.urls && message.urls.length)) message.urls = [];
                  message.urls.push(reader.string());
                  break;
                }
                case 2: {
                  message.username = reader.string();
                  break;
                }
                case 3: {
                  message.credential = reader.string();
                  break;
                }
                default:
                  reader.skipType(tag & 7);
                  break;
              }
            }
            return message;
          };

          /**
           * Decodes an IceServer message from the specified reader or buffer, length delimited.
           * @function decodeDelimited
           * @memberof simplisafe.signal.v1.IceServer
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @returns {simplisafe.signal.v1.IceServer} IceServer
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          IceServer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
          };

          /**
           * Verifies an IceServer message.
           * @function verify
           * @memberof simplisafe.signal.v1.IceServer
           * @static
           * @param {Object.<string,*>} message Plain object to verify
           * @returns {string|null} `null` if valid, otherwise the reason why it is not
           */
          IceServer.verify = function verify(message) {
            if (typeof message !== 'object' || message === null)
              return 'object expected';
            if (message.urls != null && message.hasOwnProperty('urls')) {
              if (!Array.isArray(message.urls)) return 'urls: array expected';
              for (var i = 0; i < message.urls.length; ++i)
                if (!$util.isString(message.urls[i]))
                  return 'urls: string[] expected';
            }
            if (message.username != null && message.hasOwnProperty('username'))
              if (!$util.isString(message.username))
                return 'username: string expected';
            if (
              message.credential != null &&
              message.hasOwnProperty('credential')
            )
              if (!$util.isString(message.credential))
                return 'credential: string expected';
            return null;
          };

          /**
           * Creates an IceServer message from a plain object. Also converts values to their respective internal types.
           * @function fromObject
           * @memberof simplisafe.signal.v1.IceServer
           * @static
           * @param {Object.<string,*>} object Plain object
           * @returns {simplisafe.signal.v1.IceServer} IceServer
           */
          IceServer.fromObject = function fromObject(object) {
            if (object instanceof $root.simplisafe.signal.v1.IceServer)
              return object;
            var message = new $root.simplisafe.signal.v1.IceServer();
            if (object.urls) {
              if (!Array.isArray(object.urls))
                throw TypeError(
                  '.simplisafe.signal.v1.IceServer.urls: array expected'
                );
              message.urls = [];
              for (var i = 0; i < object.urls.length; ++i)
                message.urls[i] = String(object.urls[i]);
            }
            if (object.username != null)
              message.username = String(object.username);
            if (object.credential != null)
              message.credential = String(object.credential);
            return message;
          };

          /**
           * Creates a plain object from an IceServer message. Also converts values to other types if specified.
           * @function toObject
           * @memberof simplisafe.signal.v1.IceServer
           * @static
           * @param {simplisafe.signal.v1.IceServer} message IceServer
           * @param {$protobuf.IConversionOptions} [options] Conversion options
           * @returns {Object.<string,*>} Plain object
           */
          IceServer.toObject = function toObject(message, options) {
            if (!options) options = {};
            var object = {};
            if (options.arrays || options.defaults) object.urls = [];
            if (options.defaults) {
              object.username = '';
              object.credential = '';
            }
            if (message.urls && message.urls.length) {
              object.urls = [];
              for (var j = 0; j < message.urls.length; ++j)
                object.urls[j] = message.urls[j];
            }
            if (message.username != null && message.hasOwnProperty('username'))
              object.username = message.username;
            if (
              message.credential != null &&
              message.hasOwnProperty('credential')
            )
              object.credential = message.credential;
            return object;
          };

          /**
           * Converts this IceServer to JSON.
           * @function toJSON
           * @memberof simplisafe.signal.v1.IceServer
           * @instance
           * @returns {Object.<string,*>} JSON object
           */
          IceServer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(
              this,
              $protobuf.util.toJSONOptions
            );
          };

          /**
           * Gets the default type url for IceServer
           * @function getTypeUrl
           * @memberof simplisafe.signal.v1.IceServer
           * @static
           * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
           * @returns {string} The default type url
           */
          IceServer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
              typeUrlPrefix = 'type.googleapis.com';
            }
            return typeUrlPrefix + '/simplisafe.signal.v1.IceServer';
          };

          return IceServer;
        })();

        v1.JoinMessage = (function () {
          /**
           * Properties of a JoinMessage.
           * @memberof simplisafe.signal.v1
           * @interface IJoinMessage
           * @property {Array.<simplisafe.signal.v1.IIceServer>|null} [iceServers] JoinMessage iceServers
           */

          /**
           * Constructs a new JoinMessage.
           * @memberof simplisafe.signal.v1
           * @classdesc Represents a JoinMessage.
           * @implements IJoinMessage
           * @constructor
           * @param {simplisafe.signal.v1.IJoinMessage=} [properties] Properties to set
           */
          function JoinMessage(properties) {
            this.iceServers = [];
            if (properties)
              for (
                var keys = Object.keys(properties), i = 0;
                i < keys.length;
                ++i
              )
                if (properties[keys[i]] != null)
                  this[keys[i]] = properties[keys[i]];
          }

          /**
           * JoinMessage iceServers.
           * @member {Array.<simplisafe.signal.v1.IIceServer>} iceServers
           * @memberof simplisafe.signal.v1.JoinMessage
           * @instance
           */
          JoinMessage.prototype.iceServers = $util.emptyArray;

          /**
           * Creates a new JoinMessage instance using the specified properties.
           * @function create
           * @memberof simplisafe.signal.v1.JoinMessage
           * @static
           * @param {simplisafe.signal.v1.IJoinMessage=} [properties] Properties to set
           * @returns {simplisafe.signal.v1.JoinMessage} JoinMessage instance
           */
          JoinMessage.create = function create(properties) {
            return new JoinMessage(properties);
          };

          /**
           * Encodes the specified JoinMessage message. Does not implicitly {@link simplisafe.signal.v1.JoinMessage.verify|verify} messages.
           * @function encode
           * @memberof simplisafe.signal.v1.JoinMessage
           * @static
           * @param {simplisafe.signal.v1.IJoinMessage} message JoinMessage message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          JoinMessage.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.iceServers != null && message.iceServers.length)
              for (var i = 0; i < message.iceServers.length; ++i)
                $root.simplisafe.signal.v1.IceServer.encode(
                  message.iceServers[i],
                  writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
                ).ldelim();
            return writer;
          };

          /**
           * Encodes the specified JoinMessage message, length delimited. Does not implicitly {@link simplisafe.signal.v1.JoinMessage.verify|verify} messages.
           * @function encodeDelimited
           * @memberof simplisafe.signal.v1.JoinMessage
           * @static
           * @param {simplisafe.signal.v1.IJoinMessage} message JoinMessage message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          JoinMessage.encodeDelimited = function encodeDelimited(
            message,
            writer
          ) {
            return this.encode(message, writer).ldelim();
          };

          /**
           * Decodes a JoinMessage message from the specified reader or buffer.
           * @function decode
           * @memberof simplisafe.signal.v1.JoinMessage
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @param {number} [length] Message length if known beforehand
           * @returns {simplisafe.signal.v1.JoinMessage} JoinMessage
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          JoinMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length,
              message = new $root.simplisafe.signal.v1.JoinMessage();
            while (reader.pos < end) {
              var tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!(message.iceServers && message.iceServers.length))
                    message.iceServers = [];
                  message.iceServers.push(
                    $root.simplisafe.signal.v1.IceServer.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                default:
                  reader.skipType(tag & 7);
                  break;
              }
            }
            return message;
          };

          /**
           * Decodes a JoinMessage message from the specified reader or buffer, length delimited.
           * @function decodeDelimited
           * @memberof simplisafe.signal.v1.JoinMessage
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @returns {simplisafe.signal.v1.JoinMessage} JoinMessage
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          JoinMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
          };

          /**
           * Verifies a JoinMessage message.
           * @function verify
           * @memberof simplisafe.signal.v1.JoinMessage
           * @static
           * @param {Object.<string,*>} message Plain object to verify
           * @returns {string|null} `null` if valid, otherwise the reason why it is not
           */
          JoinMessage.verify = function verify(message) {
            if (typeof message !== 'object' || message === null)
              return 'object expected';
            if (
              message.iceServers != null &&
              message.hasOwnProperty('iceServers')
            ) {
              if (!Array.isArray(message.iceServers))
                return 'iceServers: array expected';
              for (var i = 0; i < message.iceServers.length; ++i) {
                var error = $root.simplisafe.signal.v1.IceServer.verify(
                  message.iceServers[i]
                );
                if (error) return 'iceServers.' + error;
              }
            }
            return null;
          };

          /**
           * Creates a JoinMessage message from a plain object. Also converts values to their respective internal types.
           * @function fromObject
           * @memberof simplisafe.signal.v1.JoinMessage
           * @static
           * @param {Object.<string,*>} object Plain object
           * @returns {simplisafe.signal.v1.JoinMessage} JoinMessage
           */
          JoinMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.simplisafe.signal.v1.JoinMessage)
              return object;
            var message = new $root.simplisafe.signal.v1.JoinMessage();
            if (object.iceServers) {
              if (!Array.isArray(object.iceServers))
                throw TypeError(
                  '.simplisafe.signal.v1.JoinMessage.iceServers: array expected'
                );
              message.iceServers = [];
              for (var i = 0; i < object.iceServers.length; ++i) {
                if (typeof object.iceServers[i] !== 'object')
                  throw TypeError(
                    '.simplisafe.signal.v1.JoinMessage.iceServers: object expected'
                  );
                message.iceServers[i] =
                  $root.simplisafe.signal.v1.IceServer.fromObject(
                    object.iceServers[i]
                  );
              }
            }
            return message;
          };

          /**
           * Creates a plain object from a JoinMessage message. Also converts values to other types if specified.
           * @function toObject
           * @memberof simplisafe.signal.v1.JoinMessage
           * @static
           * @param {simplisafe.signal.v1.JoinMessage} message JoinMessage
           * @param {$protobuf.IConversionOptions} [options] Conversion options
           * @returns {Object.<string,*>} Plain object
           */
          JoinMessage.toObject = function toObject(message, options) {
            if (!options) options = {};
            var object = {};
            if (options.arrays || options.defaults) object.iceServers = [];
            if (message.iceServers && message.iceServers.length) {
              object.iceServers = [];
              for (var j = 0; j < message.iceServers.length; ++j)
                object.iceServers[j] =
                  $root.simplisafe.signal.v1.IceServer.toObject(
                    message.iceServers[j],
                    options
                  );
            }
            return object;
          };

          /**
           * Converts this JoinMessage to JSON.
           * @function toJSON
           * @memberof simplisafe.signal.v1.JoinMessage
           * @instance
           * @returns {Object.<string,*>} JSON object
           */
          JoinMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(
              this,
              $protobuf.util.toJSONOptions
            );
          };

          /**
           * Gets the default type url for JoinMessage
           * @function getTypeUrl
           * @memberof simplisafe.signal.v1.JoinMessage
           * @static
           * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
           * @returns {string} The default type url
           */
          JoinMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
              typeUrlPrefix = 'type.googleapis.com';
            }
            return typeUrlPrefix + '/simplisafe.signal.v1.JoinMessage';
          };

          return JoinMessage;
        })();

        v1.SessionDescription = (function () {
          /**
           * Properties of a SessionDescription.
           * @memberof simplisafe.signal.v1
           * @interface ISessionDescription
           * @property {string|null} [type] SessionDescription type
           * @property {string|null} [sdp] SessionDescription sdp
           */

          /**
           * Constructs a new SessionDescription.
           * @memberof simplisafe.signal.v1
           * @classdesc Represents a SessionDescription.
           * @implements ISessionDescription
           * @constructor
           * @param {simplisafe.signal.v1.ISessionDescription=} [properties] Properties to set
           */
          function SessionDescription(properties) {
            if (properties)
              for (
                var keys = Object.keys(properties), i = 0;
                i < keys.length;
                ++i
              )
                if (properties[keys[i]] != null)
                  this[keys[i]] = properties[keys[i]];
          }

          /**
           * SessionDescription type.
           * @member {string} type
           * @memberof simplisafe.signal.v1.SessionDescription
           * @instance
           */
          SessionDescription.prototype.type = '';

          /**
           * SessionDescription sdp.
           * @member {string} sdp
           * @memberof simplisafe.signal.v1.SessionDescription
           * @instance
           */
          SessionDescription.prototype.sdp = '';

          /**
           * Creates a new SessionDescription instance using the specified properties.
           * @function create
           * @memberof simplisafe.signal.v1.SessionDescription
           * @static
           * @param {simplisafe.signal.v1.ISessionDescription=} [properties] Properties to set
           * @returns {simplisafe.signal.v1.SessionDescription} SessionDescription instance
           */
          SessionDescription.create = function create(properties) {
            return new SessionDescription(properties);
          };

          /**
           * Encodes the specified SessionDescription message. Does not implicitly {@link simplisafe.signal.v1.SessionDescription.verify|verify} messages.
           * @function encode
           * @memberof simplisafe.signal.v1.SessionDescription
           * @static
           * @param {simplisafe.signal.v1.ISessionDescription} message SessionDescription message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          SessionDescription.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.type != null &&
              Object.hasOwnProperty.call(message, 'type')
            )
              writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.type);
            if (
              message.sdp != null &&
              Object.hasOwnProperty.call(message, 'sdp')
            )
              writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.sdp);
            return writer;
          };

          /**
           * Encodes the specified SessionDescription message, length delimited. Does not implicitly {@link simplisafe.signal.v1.SessionDescription.verify|verify} messages.
           * @function encodeDelimited
           * @memberof simplisafe.signal.v1.SessionDescription
           * @static
           * @param {simplisafe.signal.v1.ISessionDescription} message SessionDescription message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          SessionDescription.encodeDelimited = function encodeDelimited(
            message,
            writer
          ) {
            return this.encode(message, writer).ldelim();
          };

          /**
           * Decodes a SessionDescription message from the specified reader or buffer.
           * @function decode
           * @memberof simplisafe.signal.v1.SessionDescription
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @param {number} [length] Message length if known beforehand
           * @returns {simplisafe.signal.v1.SessionDescription} SessionDescription
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          SessionDescription.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length,
              message = new $root.simplisafe.signal.v1.SessionDescription();
            while (reader.pos < end) {
              var tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.type = reader.string();
                  break;
                }
                case 2: {
                  message.sdp = reader.string();
                  break;
                }
                default:
                  reader.skipType(tag & 7);
                  break;
              }
            }
            return message;
          };

          /**
           * Decodes a SessionDescription message from the specified reader or buffer, length delimited.
           * @function decodeDelimited
           * @memberof simplisafe.signal.v1.SessionDescription
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @returns {simplisafe.signal.v1.SessionDescription} SessionDescription
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          SessionDescription.decodeDelimited = function decodeDelimited(
            reader
          ) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
          };

          /**
           * Verifies a SessionDescription message.
           * @function verify
           * @memberof simplisafe.signal.v1.SessionDescription
           * @static
           * @param {Object.<string,*>} message Plain object to verify
           * @returns {string|null} `null` if valid, otherwise the reason why it is not
           */
          SessionDescription.verify = function verify(message) {
            if (typeof message !== 'object' || message === null)
              return 'object expected';
            if (message.type != null && message.hasOwnProperty('type'))
              if (!$util.isString(message.type)) return 'type: string expected';
            if (message.sdp != null && message.hasOwnProperty('sdp'))
              if (!$util.isString(message.sdp)) return 'sdp: string expected';
            return null;
          };

          /**
           * Creates a SessionDescription message from a plain object. Also converts values to their respective internal types.
           * @function fromObject
           * @memberof simplisafe.signal.v1.SessionDescription
           * @static
           * @param {Object.<string,*>} object Plain object
           * @returns {simplisafe.signal.v1.SessionDescription} SessionDescription
           */
          SessionDescription.fromObject = function fromObject(object) {
            if (object instanceof $root.simplisafe.signal.v1.SessionDescription)
              return object;
            var message = new $root.simplisafe.signal.v1.SessionDescription();
            if (object.type != null) message.type = String(object.type);
            if (object.sdp != null) message.sdp = String(object.sdp);
            return message;
          };

          /**
           * Creates a plain object from a SessionDescription message. Also converts values to other types if specified.
           * @function toObject
           * @memberof simplisafe.signal.v1.SessionDescription
           * @static
           * @param {simplisafe.signal.v1.SessionDescription} message SessionDescription
           * @param {$protobuf.IConversionOptions} [options] Conversion options
           * @returns {Object.<string,*>} Plain object
           */
          SessionDescription.toObject = function toObject(message, options) {
            if (!options) options = {};
            var object = {};
            if (options.defaults) {
              object.type = '';
              object.sdp = '';
            }
            if (message.type != null && message.hasOwnProperty('type'))
              object.type = message.type;
            if (message.sdp != null && message.hasOwnProperty('sdp'))
              object.sdp = message.sdp;
            return object;
          };

          /**
           * Converts this SessionDescription to JSON.
           * @function toJSON
           * @memberof simplisafe.signal.v1.SessionDescription
           * @instance
           * @returns {Object.<string,*>} JSON object
           */
          SessionDescription.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(
              this,
              $protobuf.util.toJSONOptions
            );
          };

          /**
           * Gets the default type url for SessionDescription
           * @function getTypeUrl
           * @memberof simplisafe.signal.v1.SessionDescription
           * @static
           * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
           * @returns {string} The default type url
           */
          SessionDescription.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
              typeUrlPrefix = 'type.googleapis.com';
            }
            return typeUrlPrefix + '/simplisafe.signal.v1.SessionDescription';
          };

          return SessionDescription;
        })();

        v1.SignalRequest = (function () {
          /**
           * Properties of a SignalRequest.
           * @memberof simplisafe.signal.v1
           * @interface ISignalRequest
           * @property {simplisafe.v1.IHeader|null} [header] SignalRequest header
           * @property {simplisafe.signal.v1.ISessionDescription|null} [offer] SignalRequest offer
           * @property {simplisafe.signal.v1.ITrickleRequest|null} [trickle] SignalRequest trickle
           * @property {simplisafe.signal.v1.IConnectionCloseRequest|null} [connectionClose] SignalRequest connectionClose
           */

          /**
           * Constructs a new SignalRequest.
           * @memberof simplisafe.signal.v1
           * @classdesc Represents a SignalRequest.
           * @implements ISignalRequest
           * @constructor
           * @param {simplisafe.signal.v1.ISignalRequest=} [properties] Properties to set
           */
          function SignalRequest(properties) {
            if (properties)
              for (
                var keys = Object.keys(properties), i = 0;
                i < keys.length;
                ++i
              )
                if (properties[keys[i]] != null)
                  this[keys[i]] = properties[keys[i]];
          }

          /**
           * SignalRequest header.
           * @member {simplisafe.v1.IHeader|null|undefined} header
           * @memberof simplisafe.signal.v1.SignalRequest
           * @instance
           */
          SignalRequest.prototype.header = null;

          /**
           * SignalRequest offer.
           * @member {simplisafe.signal.v1.ISessionDescription|null|undefined} offer
           * @memberof simplisafe.signal.v1.SignalRequest
           * @instance
           */
          SignalRequest.prototype.offer = null;

          /**
           * SignalRequest trickle.
           * @member {simplisafe.signal.v1.ITrickleRequest|null|undefined} trickle
           * @memberof simplisafe.signal.v1.SignalRequest
           * @instance
           */
          SignalRequest.prototype.trickle = null;

          /**
           * SignalRequest connectionClose.
           * @member {simplisafe.signal.v1.IConnectionCloseRequest|null|undefined} connectionClose
           * @memberof simplisafe.signal.v1.SignalRequest
           * @instance
           */
          SignalRequest.prototype.connectionClose = null;

          // OneOf field names bound to virtual getters and setters
          var $oneOfFields;

          /**
           * SignalRequest message.
           * @member {"offer"|"trickle"|"connectionClose"|undefined} message
           * @memberof simplisafe.signal.v1.SignalRequest
           * @instance
           */
          Object.defineProperty(SignalRequest.prototype, 'message', {
            get: $util.oneOfGetter(
              ($oneOfFields = ['offer', 'trickle', 'connectionClose'])
            ),
            set: $util.oneOfSetter($oneOfFields),
          });

          /**
           * Creates a new SignalRequest instance using the specified properties.
           * @function create
           * @memberof simplisafe.signal.v1.SignalRequest
           * @static
           * @param {simplisafe.signal.v1.ISignalRequest=} [properties] Properties to set
           * @returns {simplisafe.signal.v1.SignalRequest} SignalRequest instance
           */
          SignalRequest.create = function create(properties) {
            return new SignalRequest(properties);
          };

          /**
           * Encodes the specified SignalRequest message. Does not implicitly {@link simplisafe.signal.v1.SignalRequest.verify|verify} messages.
           * @function encode
           * @memberof simplisafe.signal.v1.SignalRequest
           * @static
           * @param {simplisafe.signal.v1.ISignalRequest} message SignalRequest message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          SignalRequest.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.header != null &&
              Object.hasOwnProperty.call(message, 'header')
            )
              $root.simplisafe.v1.Header.encode(
                message.header,
                writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
              ).ldelim();
            if (
              message.offer != null &&
              Object.hasOwnProperty.call(message, 'offer')
            )
              $root.simplisafe.signal.v1.SessionDescription.encode(
                message.offer,
                writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
              ).ldelim();
            if (
              message.trickle != null &&
              Object.hasOwnProperty.call(message, 'trickle')
            )
              $root.simplisafe.signal.v1.TrickleRequest.encode(
                message.trickle,
                writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
              ).ldelim();
            if (
              message.connectionClose != null &&
              Object.hasOwnProperty.call(message, 'connectionClose')
            )
              $root.simplisafe.signal.v1.ConnectionCloseRequest.encode(
                message.connectionClose,
                writer.uint32(/* id 4, wireType 2 =*/ 34).fork()
              ).ldelim();
            return writer;
          };

          /**
           * Encodes the specified SignalRequest message, length delimited. Does not implicitly {@link simplisafe.signal.v1.SignalRequest.verify|verify} messages.
           * @function encodeDelimited
           * @memberof simplisafe.signal.v1.SignalRequest
           * @static
           * @param {simplisafe.signal.v1.ISignalRequest} message SignalRequest message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          SignalRequest.encodeDelimited = function encodeDelimited(
            message,
            writer
          ) {
            return this.encode(message, writer).ldelim();
          };

          /**
           * Decodes a SignalRequest message from the specified reader or buffer.
           * @function decode
           * @memberof simplisafe.signal.v1.SignalRequest
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @param {number} [length] Message length if known beforehand
           * @returns {simplisafe.signal.v1.SignalRequest} SignalRequest
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          SignalRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length,
              message = new $root.simplisafe.signal.v1.SignalRequest();
            while (reader.pos < end) {
              var tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.header = $root.simplisafe.v1.Header.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 2: {
                  message.offer =
                    $root.simplisafe.signal.v1.SessionDescription.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 3: {
                  message.trickle =
                    $root.simplisafe.signal.v1.TrickleRequest.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 4: {
                  message.connectionClose =
                    $root.simplisafe.signal.v1.ConnectionCloseRequest.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                default:
                  reader.skipType(tag & 7);
                  break;
              }
            }
            return message;
          };

          /**
           * Decodes a SignalRequest message from the specified reader or buffer, length delimited.
           * @function decodeDelimited
           * @memberof simplisafe.signal.v1.SignalRequest
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @returns {simplisafe.signal.v1.SignalRequest} SignalRequest
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          SignalRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
          };

          /**
           * Verifies a SignalRequest message.
           * @function verify
           * @memberof simplisafe.signal.v1.SignalRequest
           * @static
           * @param {Object.<string,*>} message Plain object to verify
           * @returns {string|null} `null` if valid, otherwise the reason why it is not
           */
          SignalRequest.verify = function verify(message) {
            if (typeof message !== 'object' || message === null)
              return 'object expected';
            var properties = {};
            if (message.header != null && message.hasOwnProperty('header')) {
              var error = $root.simplisafe.v1.Header.verify(message.header);
              if (error) return 'header.' + error;
            }
            if (message.offer != null && message.hasOwnProperty('offer')) {
              properties.message = 1;
              {
                var error =
                  $root.simplisafe.signal.v1.SessionDescription.verify(
                    message.offer
                  );
                if (error) return 'offer.' + error;
              }
            }
            if (message.trickle != null && message.hasOwnProperty('trickle')) {
              if (properties.message === 1) return 'message: multiple values';
              properties.message = 1;
              {
                var error = $root.simplisafe.signal.v1.TrickleRequest.verify(
                  message.trickle
                );
                if (error) return 'trickle.' + error;
              }
            }
            if (
              message.connectionClose != null &&
              message.hasOwnProperty('connectionClose')
            ) {
              if (properties.message === 1) return 'message: multiple values';
              properties.message = 1;
              {
                var error =
                  $root.simplisafe.signal.v1.ConnectionCloseRequest.verify(
                    message.connectionClose
                  );
                if (error) return 'connectionClose.' + error;
              }
            }
            return null;
          };

          /**
           * Creates a SignalRequest message from a plain object. Also converts values to their respective internal types.
           * @function fromObject
           * @memberof simplisafe.signal.v1.SignalRequest
           * @static
           * @param {Object.<string,*>} object Plain object
           * @returns {simplisafe.signal.v1.SignalRequest} SignalRequest
           */
          SignalRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.simplisafe.signal.v1.SignalRequest)
              return object;
            var message = new $root.simplisafe.signal.v1.SignalRequest();
            if (object.header != null) {
              if (typeof object.header !== 'object')
                throw TypeError(
                  '.simplisafe.signal.v1.SignalRequest.header: object expected'
                );
              message.header = $root.simplisafe.v1.Header.fromObject(
                object.header
              );
            }
            if (object.offer != null) {
              if (typeof object.offer !== 'object')
                throw TypeError(
                  '.simplisafe.signal.v1.SignalRequest.offer: object expected'
                );
              message.offer =
                $root.simplisafe.signal.v1.SessionDescription.fromObject(
                  object.offer
                );
            }
            if (object.trickle != null) {
              if (typeof object.trickle !== 'object')
                throw TypeError(
                  '.simplisafe.signal.v1.SignalRequest.trickle: object expected'
                );
              message.trickle =
                $root.simplisafe.signal.v1.TrickleRequest.fromObject(
                  object.trickle
                );
            }
            if (object.connectionClose != null) {
              if (typeof object.connectionClose !== 'object')
                throw TypeError(
                  '.simplisafe.signal.v1.SignalRequest.connectionClose: object expected'
                );
              message.connectionClose =
                $root.simplisafe.signal.v1.ConnectionCloseRequest.fromObject(
                  object.connectionClose
                );
            }
            return message;
          };

          /**
           * Creates a plain object from a SignalRequest message. Also converts values to other types if specified.
           * @function toObject
           * @memberof simplisafe.signal.v1.SignalRequest
           * @static
           * @param {simplisafe.signal.v1.SignalRequest} message SignalRequest
           * @param {$protobuf.IConversionOptions} [options] Conversion options
           * @returns {Object.<string,*>} Plain object
           */
          SignalRequest.toObject = function toObject(message, options) {
            if (!options) options = {};
            var object = {};
            if (options.defaults) object.header = null;
            if (message.header != null && message.hasOwnProperty('header'))
              object.header = $root.simplisafe.v1.Header.toObject(
                message.header,
                options
              );
            if (message.offer != null && message.hasOwnProperty('offer')) {
              object.offer =
                $root.simplisafe.signal.v1.SessionDescription.toObject(
                  message.offer,
                  options
                );
              if (options.oneofs) object.message = 'offer';
            }
            if (message.trickle != null && message.hasOwnProperty('trickle')) {
              object.trickle =
                $root.simplisafe.signal.v1.TrickleRequest.toObject(
                  message.trickle,
                  options
                );
              if (options.oneofs) object.message = 'trickle';
            }
            if (
              message.connectionClose != null &&
              message.hasOwnProperty('connectionClose')
            ) {
              object.connectionClose =
                $root.simplisafe.signal.v1.ConnectionCloseRequest.toObject(
                  message.connectionClose,
                  options
                );
              if (options.oneofs) object.message = 'connectionClose';
            }
            return object;
          };

          /**
           * Converts this SignalRequest to JSON.
           * @function toJSON
           * @memberof simplisafe.signal.v1.SignalRequest
           * @instance
           * @returns {Object.<string,*>} JSON object
           */
          SignalRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(
              this,
              $protobuf.util.toJSONOptions
            );
          };

          /**
           * Gets the default type url for SignalRequest
           * @function getTypeUrl
           * @memberof simplisafe.signal.v1.SignalRequest
           * @static
           * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
           * @returns {string} The default type url
           */
          SignalRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
              typeUrlPrefix = 'type.googleapis.com';
            }
            return typeUrlPrefix + '/simplisafe.signal.v1.SignalRequest';
          };

          return SignalRequest;
        })();

        v1.SignalResponse = (function () {
          /**
           * Properties of a SignalResponse.
           * @memberof simplisafe.signal.v1
           * @interface ISignalResponse
           * @property {simplisafe.v1.IHeader|null} [header] SignalResponse header
           * @property {simplisafe.signal.v1.ISessionDescription|null} [answer] SignalResponse answer
           * @property {simplisafe.signal.v1.ITrickleRequest|null} [trickle] SignalResponse trickle
           * @property {simplisafe.signal.v1.IConnectionCloseRequest|null} [connectionClose] SignalResponse connectionClose
           * @property {simplisafe.signal.v1.IJoinMessage|null} [joinDetails] SignalResponse joinDetails
           * @property {simplisafe.signal.v1.IStartStreaming|null} [startStreaming] SignalResponse startStreaming
           * @property {simplisafe.signal.v1.IEmptyRoom|null} [emptyRoom] SignalResponse emptyRoom
           */

          /**
           * Constructs a new SignalResponse.
           * @memberof simplisafe.signal.v1
           * @classdesc Represents a SignalResponse.
           * @implements ISignalResponse
           * @constructor
           * @param {simplisafe.signal.v1.ISignalResponse=} [properties] Properties to set
           */
          function SignalResponse(properties) {
            if (properties)
              for (
                var keys = Object.keys(properties), i = 0;
                i < keys.length;
                ++i
              )
                if (properties[keys[i]] != null)
                  this[keys[i]] = properties[keys[i]];
          }

          /**
           * SignalResponse header.
           * @member {simplisafe.v1.IHeader|null|undefined} header
           * @memberof simplisafe.signal.v1.SignalResponse
           * @instance
           */
          SignalResponse.prototype.header = null;

          /**
           * SignalResponse answer.
           * @member {simplisafe.signal.v1.ISessionDescription|null|undefined} answer
           * @memberof simplisafe.signal.v1.SignalResponse
           * @instance
           */
          SignalResponse.prototype.answer = null;

          /**
           * SignalResponse trickle.
           * @member {simplisafe.signal.v1.ITrickleRequest|null|undefined} trickle
           * @memberof simplisafe.signal.v1.SignalResponse
           * @instance
           */
          SignalResponse.prototype.trickle = null;

          /**
           * SignalResponse connectionClose.
           * @member {simplisafe.signal.v1.IConnectionCloseRequest|null|undefined} connectionClose
           * @memberof simplisafe.signal.v1.SignalResponse
           * @instance
           */
          SignalResponse.prototype.connectionClose = null;

          /**
           * SignalResponse joinDetails.
           * @member {simplisafe.signal.v1.IJoinMessage|null|undefined} joinDetails
           * @memberof simplisafe.signal.v1.SignalResponse
           * @instance
           */
          SignalResponse.prototype.joinDetails = null;

          /**
           * SignalResponse startStreaming.
           * @member {simplisafe.signal.v1.IStartStreaming|null|undefined} startStreaming
           * @memberof simplisafe.signal.v1.SignalResponse
           * @instance
           */
          SignalResponse.prototype.startStreaming = null;

          /**
           * SignalResponse emptyRoom.
           * @member {simplisafe.signal.v1.IEmptyRoom|null|undefined} emptyRoom
           * @memberof simplisafe.signal.v1.SignalResponse
           * @instance
           */
          SignalResponse.prototype.emptyRoom = null;

          // OneOf field names bound to virtual getters and setters
          var $oneOfFields;

          /**
           * SignalResponse message.
           * @member {"answer"|"trickle"|"connectionClose"|"joinDetails"|"startStreaming"|"emptyRoom"|undefined} message
           * @memberof simplisafe.signal.v1.SignalResponse
           * @instance
           */
          Object.defineProperty(SignalResponse.prototype, 'message', {
            get: $util.oneOfGetter(
              ($oneOfFields = [
                'answer',
                'trickle',
                'connectionClose',
                'joinDetails',
                'startStreaming',
                'emptyRoom',
              ])
            ),
            set: $util.oneOfSetter($oneOfFields),
          });

          /**
           * Creates a new SignalResponse instance using the specified properties.
           * @function create
           * @memberof simplisafe.signal.v1.SignalResponse
           * @static
           * @param {simplisafe.signal.v1.ISignalResponse=} [properties] Properties to set
           * @returns {simplisafe.signal.v1.SignalResponse} SignalResponse instance
           */
          SignalResponse.create = function create(properties) {
            return new SignalResponse(properties);
          };

          /**
           * Encodes the specified SignalResponse message. Does not implicitly {@link simplisafe.signal.v1.SignalResponse.verify|verify} messages.
           * @function encode
           * @memberof simplisafe.signal.v1.SignalResponse
           * @static
           * @param {simplisafe.signal.v1.ISignalResponse} message SignalResponse message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          SignalResponse.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.header != null &&
              Object.hasOwnProperty.call(message, 'header')
            )
              $root.simplisafe.v1.Header.encode(
                message.header,
                writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
              ).ldelim();
            if (
              message.answer != null &&
              Object.hasOwnProperty.call(message, 'answer')
            )
              $root.simplisafe.signal.v1.SessionDescription.encode(
                message.answer,
                writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
              ).ldelim();
            if (
              message.trickle != null &&
              Object.hasOwnProperty.call(message, 'trickle')
            )
              $root.simplisafe.signal.v1.TrickleRequest.encode(
                message.trickle,
                writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
              ).ldelim();
            if (
              message.connectionClose != null &&
              Object.hasOwnProperty.call(message, 'connectionClose')
            )
              $root.simplisafe.signal.v1.ConnectionCloseRequest.encode(
                message.connectionClose,
                writer.uint32(/* id 4, wireType 2 =*/ 34).fork()
              ).ldelim();
            if (
              message.joinDetails != null &&
              Object.hasOwnProperty.call(message, 'joinDetails')
            )
              $root.simplisafe.signal.v1.JoinMessage.encode(
                message.joinDetails,
                writer.uint32(/* id 5, wireType 2 =*/ 42).fork()
              ).ldelim();
            if (
              message.startStreaming != null &&
              Object.hasOwnProperty.call(message, 'startStreaming')
            )
              $root.simplisafe.signal.v1.StartStreaming.encode(
                message.startStreaming,
                writer.uint32(/* id 6, wireType 2 =*/ 50).fork()
              ).ldelim();
            if (
              message.emptyRoom != null &&
              Object.hasOwnProperty.call(message, 'emptyRoom')
            )
              $root.simplisafe.signal.v1.EmptyRoom.encode(
                message.emptyRoom,
                writer.uint32(/* id 7, wireType 2 =*/ 58).fork()
              ).ldelim();
            return writer;
          };

          /**
           * Encodes the specified SignalResponse message, length delimited. Does not implicitly {@link simplisafe.signal.v1.SignalResponse.verify|verify} messages.
           * @function encodeDelimited
           * @memberof simplisafe.signal.v1.SignalResponse
           * @static
           * @param {simplisafe.signal.v1.ISignalResponse} message SignalResponse message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          SignalResponse.encodeDelimited = function encodeDelimited(
            message,
            writer
          ) {
            return this.encode(message, writer).ldelim();
          };

          /**
           * Decodes a SignalResponse message from the specified reader or buffer.
           * @function decode
           * @memberof simplisafe.signal.v1.SignalResponse
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @param {number} [length] Message length if known beforehand
           * @returns {simplisafe.signal.v1.SignalResponse} SignalResponse
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          SignalResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length,
              message = new $root.simplisafe.signal.v1.SignalResponse();
            while (reader.pos < end) {
              var tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.header = $root.simplisafe.v1.Header.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 2: {
                  message.answer =
                    $root.simplisafe.signal.v1.SessionDescription.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 3: {
                  message.trickle =
                    $root.simplisafe.signal.v1.TrickleRequest.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 4: {
                  message.connectionClose =
                    $root.simplisafe.signal.v1.ConnectionCloseRequest.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 5: {
                  message.joinDetails =
                    $root.simplisafe.signal.v1.JoinMessage.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 6: {
                  message.startStreaming =
                    $root.simplisafe.signal.v1.StartStreaming.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 7: {
                  message.emptyRoom =
                    $root.simplisafe.signal.v1.EmptyRoom.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                default:
                  reader.skipType(tag & 7);
                  break;
              }
            }
            return message;
          };

          /**
           * Decodes a SignalResponse message from the specified reader or buffer, length delimited.
           * @function decodeDelimited
           * @memberof simplisafe.signal.v1.SignalResponse
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @returns {simplisafe.signal.v1.SignalResponse} SignalResponse
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          SignalResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
          };

          /**
           * Verifies a SignalResponse message.
           * @function verify
           * @memberof simplisafe.signal.v1.SignalResponse
           * @static
           * @param {Object.<string,*>} message Plain object to verify
           * @returns {string|null} `null` if valid, otherwise the reason why it is not
           */
          SignalResponse.verify = function verify(message) {
            if (typeof message !== 'object' || message === null)
              return 'object expected';
            var properties = {};
            if (message.header != null && message.hasOwnProperty('header')) {
              var error = $root.simplisafe.v1.Header.verify(message.header);
              if (error) return 'header.' + error;
            }
            if (message.answer != null && message.hasOwnProperty('answer')) {
              properties.message = 1;
              {
                var error =
                  $root.simplisafe.signal.v1.SessionDescription.verify(
                    message.answer
                  );
                if (error) return 'answer.' + error;
              }
            }
            if (message.trickle != null && message.hasOwnProperty('trickle')) {
              if (properties.message === 1) return 'message: multiple values';
              properties.message = 1;
              {
                var error = $root.simplisafe.signal.v1.TrickleRequest.verify(
                  message.trickle
                );
                if (error) return 'trickle.' + error;
              }
            }
            if (
              message.connectionClose != null &&
              message.hasOwnProperty('connectionClose')
            ) {
              if (properties.message === 1) return 'message: multiple values';
              properties.message = 1;
              {
                var error =
                  $root.simplisafe.signal.v1.ConnectionCloseRequest.verify(
                    message.connectionClose
                  );
                if (error) return 'connectionClose.' + error;
              }
            }
            if (
              message.joinDetails != null &&
              message.hasOwnProperty('joinDetails')
            ) {
              if (properties.message === 1) return 'message: multiple values';
              properties.message = 1;
              {
                var error = $root.simplisafe.signal.v1.JoinMessage.verify(
                  message.joinDetails
                );
                if (error) return 'joinDetails.' + error;
              }
            }
            if (
              message.startStreaming != null &&
              message.hasOwnProperty('startStreaming')
            ) {
              if (properties.message === 1) return 'message: multiple values';
              properties.message = 1;
              {
                var error = $root.simplisafe.signal.v1.StartStreaming.verify(
                  message.startStreaming
                );
                if (error) return 'startStreaming.' + error;
              }
            }
            if (
              message.emptyRoom != null &&
              message.hasOwnProperty('emptyRoom')
            ) {
              if (properties.message === 1) return 'message: multiple values';
              properties.message = 1;
              {
                var error = $root.simplisafe.signal.v1.EmptyRoom.verify(
                  message.emptyRoom
                );
                if (error) return 'emptyRoom.' + error;
              }
            }
            return null;
          };

          /**
           * Creates a SignalResponse message from a plain object. Also converts values to their respective internal types.
           * @function fromObject
           * @memberof simplisafe.signal.v1.SignalResponse
           * @static
           * @param {Object.<string,*>} object Plain object
           * @returns {simplisafe.signal.v1.SignalResponse} SignalResponse
           */
          SignalResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.simplisafe.signal.v1.SignalResponse)
              return object;
            var message = new $root.simplisafe.signal.v1.SignalResponse();
            if (object.header != null) {
              if (typeof object.header !== 'object')
                throw TypeError(
                  '.simplisafe.signal.v1.SignalResponse.header: object expected'
                );
              message.header = $root.simplisafe.v1.Header.fromObject(
                object.header
              );
            }
            if (object.answer != null) {
              if (typeof object.answer !== 'object')
                throw TypeError(
                  '.simplisafe.signal.v1.SignalResponse.answer: object expected'
                );
              message.answer =
                $root.simplisafe.signal.v1.SessionDescription.fromObject(
                  object.answer
                );
            }
            if (object.trickle != null) {
              if (typeof object.trickle !== 'object')
                throw TypeError(
                  '.simplisafe.signal.v1.SignalResponse.trickle: object expected'
                );
              message.trickle =
                $root.simplisafe.signal.v1.TrickleRequest.fromObject(
                  object.trickle
                );
            }
            if (object.connectionClose != null) {
              if (typeof object.connectionClose !== 'object')
                throw TypeError(
                  '.simplisafe.signal.v1.SignalResponse.connectionClose: object expected'
                );
              message.connectionClose =
                $root.simplisafe.signal.v1.ConnectionCloseRequest.fromObject(
                  object.connectionClose
                );
            }
            if (object.joinDetails != null) {
              if (typeof object.joinDetails !== 'object')
                throw TypeError(
                  '.simplisafe.signal.v1.SignalResponse.joinDetails: object expected'
                );
              message.joinDetails =
                $root.simplisafe.signal.v1.JoinMessage.fromObject(
                  object.joinDetails
                );
            }
            if (object.startStreaming != null) {
              if (typeof object.startStreaming !== 'object')
                throw TypeError(
                  '.simplisafe.signal.v1.SignalResponse.startStreaming: object expected'
                );
              message.startStreaming =
                $root.simplisafe.signal.v1.StartStreaming.fromObject(
                  object.startStreaming
                );
            }
            if (object.emptyRoom != null) {
              if (typeof object.emptyRoom !== 'object')
                throw TypeError(
                  '.simplisafe.signal.v1.SignalResponse.emptyRoom: object expected'
                );
              message.emptyRoom =
                $root.simplisafe.signal.v1.EmptyRoom.fromObject(
                  object.emptyRoom
                );
            }
            return message;
          };

          /**
           * Creates a plain object from a SignalResponse message. Also converts values to other types if specified.
           * @function toObject
           * @memberof simplisafe.signal.v1.SignalResponse
           * @static
           * @param {simplisafe.signal.v1.SignalResponse} message SignalResponse
           * @param {$protobuf.IConversionOptions} [options] Conversion options
           * @returns {Object.<string,*>} Plain object
           */
          SignalResponse.toObject = function toObject(message, options) {
            if (!options) options = {};
            var object = {};
            if (options.defaults) object.header = null;
            if (message.header != null && message.hasOwnProperty('header'))
              object.header = $root.simplisafe.v1.Header.toObject(
                message.header,
                options
              );
            if (message.answer != null && message.hasOwnProperty('answer')) {
              object.answer =
                $root.simplisafe.signal.v1.SessionDescription.toObject(
                  message.answer,
                  options
                );
              if (options.oneofs) object.message = 'answer';
            }
            if (message.trickle != null && message.hasOwnProperty('trickle')) {
              object.trickle =
                $root.simplisafe.signal.v1.TrickleRequest.toObject(
                  message.trickle,
                  options
                );
              if (options.oneofs) object.message = 'trickle';
            }
            if (
              message.connectionClose != null &&
              message.hasOwnProperty('connectionClose')
            ) {
              object.connectionClose =
                $root.simplisafe.signal.v1.ConnectionCloseRequest.toObject(
                  message.connectionClose,
                  options
                );
              if (options.oneofs) object.message = 'connectionClose';
            }
            if (
              message.joinDetails != null &&
              message.hasOwnProperty('joinDetails')
            ) {
              object.joinDetails =
                $root.simplisafe.signal.v1.JoinMessage.toObject(
                  message.joinDetails,
                  options
                );
              if (options.oneofs) object.message = 'joinDetails';
            }
            if (
              message.startStreaming != null &&
              message.hasOwnProperty('startStreaming')
            ) {
              object.startStreaming =
                $root.simplisafe.signal.v1.StartStreaming.toObject(
                  message.startStreaming,
                  options
                );
              if (options.oneofs) object.message = 'startStreaming';
            }
            if (
              message.emptyRoom != null &&
              message.hasOwnProperty('emptyRoom')
            ) {
              object.emptyRoom = $root.simplisafe.signal.v1.EmptyRoom.toObject(
                message.emptyRoom,
                options
              );
              if (options.oneofs) object.message = 'emptyRoom';
            }
            return object;
          };

          /**
           * Converts this SignalResponse to JSON.
           * @function toJSON
           * @memberof simplisafe.signal.v1.SignalResponse
           * @instance
           * @returns {Object.<string,*>} JSON object
           */
          SignalResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(
              this,
              $protobuf.util.toJSONOptions
            );
          };

          /**
           * Gets the default type url for SignalResponse
           * @function getTypeUrl
           * @memberof simplisafe.signal.v1.SignalResponse
           * @static
           * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
           * @returns {string} The default type url
           */
          SignalResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
              typeUrlPrefix = 'type.googleapis.com';
            }
            return typeUrlPrefix + '/simplisafe.signal.v1.SignalResponse';
          };

          return SignalResponse;
        })();

        v1.StartStreaming = (function () {
          /**
           * Properties of a StartStreaming.
           * @memberof simplisafe.signal.v1
           * @interface IStartStreaming
           */

          /**
           * Constructs a new StartStreaming.
           * @memberof simplisafe.signal.v1
           * @classdesc Represents a StartStreaming.
           * @implements IStartStreaming
           * @constructor
           * @param {simplisafe.signal.v1.IStartStreaming=} [properties] Properties to set
           */
          function StartStreaming(properties) {
            if (properties)
              for (
                var keys = Object.keys(properties), i = 0;
                i < keys.length;
                ++i
              )
                if (properties[keys[i]] != null)
                  this[keys[i]] = properties[keys[i]];
          }

          /**
           * Creates a new StartStreaming instance using the specified properties.
           * @function create
           * @memberof simplisafe.signal.v1.StartStreaming
           * @static
           * @param {simplisafe.signal.v1.IStartStreaming=} [properties] Properties to set
           * @returns {simplisafe.signal.v1.StartStreaming} StartStreaming instance
           */
          StartStreaming.create = function create(properties) {
            return new StartStreaming(properties);
          };

          /**
           * Encodes the specified StartStreaming message. Does not implicitly {@link simplisafe.signal.v1.StartStreaming.verify|verify} messages.
           * @function encode
           * @memberof simplisafe.signal.v1.StartStreaming
           * @static
           * @param {simplisafe.signal.v1.IStartStreaming} message StartStreaming message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          StartStreaming.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create();
            return writer;
          };

          /**
           * Encodes the specified StartStreaming message, length delimited. Does not implicitly {@link simplisafe.signal.v1.StartStreaming.verify|verify} messages.
           * @function encodeDelimited
           * @memberof simplisafe.signal.v1.StartStreaming
           * @static
           * @param {simplisafe.signal.v1.IStartStreaming} message StartStreaming message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          StartStreaming.encodeDelimited = function encodeDelimited(
            message,
            writer
          ) {
            return this.encode(message, writer).ldelim();
          };

          /**
           * Decodes a StartStreaming message from the specified reader or buffer.
           * @function decode
           * @memberof simplisafe.signal.v1.StartStreaming
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @param {number} [length] Message length if known beforehand
           * @returns {simplisafe.signal.v1.StartStreaming} StartStreaming
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          StartStreaming.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length,
              message = new $root.simplisafe.signal.v1.StartStreaming();
            while (reader.pos < end) {
              var tag = reader.uint32();
              switch (tag >>> 3) {
                default:
                  reader.skipType(tag & 7);
                  break;
              }
            }
            return message;
          };

          /**
           * Decodes a StartStreaming message from the specified reader or buffer, length delimited.
           * @function decodeDelimited
           * @memberof simplisafe.signal.v1.StartStreaming
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @returns {simplisafe.signal.v1.StartStreaming} StartStreaming
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          StartStreaming.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
          };

          /**
           * Verifies a StartStreaming message.
           * @function verify
           * @memberof simplisafe.signal.v1.StartStreaming
           * @static
           * @param {Object.<string,*>} message Plain object to verify
           * @returns {string|null} `null` if valid, otherwise the reason why it is not
           */
          StartStreaming.verify = function verify(message) {
            if (typeof message !== 'object' || message === null)
              return 'object expected';
            return null;
          };

          /**
           * Creates a StartStreaming message from a plain object. Also converts values to their respective internal types.
           * @function fromObject
           * @memberof simplisafe.signal.v1.StartStreaming
           * @static
           * @param {Object.<string,*>} object Plain object
           * @returns {simplisafe.signal.v1.StartStreaming} StartStreaming
           */
          StartStreaming.fromObject = function fromObject(object) {
            if (object instanceof $root.simplisafe.signal.v1.StartStreaming)
              return object;
            return new $root.simplisafe.signal.v1.StartStreaming();
          };

          /**
           * Creates a plain object from a StartStreaming message. Also converts values to other types if specified.
           * @function toObject
           * @memberof simplisafe.signal.v1.StartStreaming
           * @static
           * @param {simplisafe.signal.v1.StartStreaming} message StartStreaming
           * @param {$protobuf.IConversionOptions} [options] Conversion options
           * @returns {Object.<string,*>} Plain object
           */
          StartStreaming.toObject = function toObject() {
            return {};
          };

          /**
           * Converts this StartStreaming to JSON.
           * @function toJSON
           * @memberof simplisafe.signal.v1.StartStreaming
           * @instance
           * @returns {Object.<string,*>} JSON object
           */
          StartStreaming.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(
              this,
              $protobuf.util.toJSONOptions
            );
          };

          /**
           * Gets the default type url for StartStreaming
           * @function getTypeUrl
           * @memberof simplisafe.signal.v1.StartStreaming
           * @static
           * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
           * @returns {string} The default type url
           */
          StartStreaming.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
              typeUrlPrefix = 'type.googleapis.com';
            }
            return typeUrlPrefix + '/simplisafe.signal.v1.StartStreaming';
          };

          return StartStreaming;
        })();

        v1.TrickleRequest = (function () {
          /**
           * Properties of a TrickleRequest.
           * @memberof simplisafe.signal.v1
           * @interface ITrickleRequest
           * @property {string|null} [candidateInit] TrickleRequest candidateInit
           */

          /**
           * Constructs a new TrickleRequest.
           * @memberof simplisafe.signal.v1
           * @classdesc Represents a TrickleRequest.
           * @implements ITrickleRequest
           * @constructor
           * @param {simplisafe.signal.v1.ITrickleRequest=} [properties] Properties to set
           */
          function TrickleRequest(properties) {
            if (properties)
              for (
                var keys = Object.keys(properties), i = 0;
                i < keys.length;
                ++i
              )
                if (properties[keys[i]] != null)
                  this[keys[i]] = properties[keys[i]];
          }

          /**
           * TrickleRequest candidateInit.
           * @member {string} candidateInit
           * @memberof simplisafe.signal.v1.TrickleRequest
           * @instance
           */
          TrickleRequest.prototype.candidateInit = '';

          /**
           * Creates a new TrickleRequest instance using the specified properties.
           * @function create
           * @memberof simplisafe.signal.v1.TrickleRequest
           * @static
           * @param {simplisafe.signal.v1.ITrickleRequest=} [properties] Properties to set
           * @returns {simplisafe.signal.v1.TrickleRequest} TrickleRequest instance
           */
          TrickleRequest.create = function create(properties) {
            return new TrickleRequest(properties);
          };

          /**
           * Encodes the specified TrickleRequest message. Does not implicitly {@link simplisafe.signal.v1.TrickleRequest.verify|verify} messages.
           * @function encode
           * @memberof simplisafe.signal.v1.TrickleRequest
           * @static
           * @param {simplisafe.signal.v1.ITrickleRequest} message TrickleRequest message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          TrickleRequest.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.candidateInit != null &&
              Object.hasOwnProperty.call(message, 'candidateInit')
            )
              writer
                .uint32(/* id 1, wireType 2 =*/ 10)
                .string(message.candidateInit);
            return writer;
          };

          /**
           * Encodes the specified TrickleRequest message, length delimited. Does not implicitly {@link simplisafe.signal.v1.TrickleRequest.verify|verify} messages.
           * @function encodeDelimited
           * @memberof simplisafe.signal.v1.TrickleRequest
           * @static
           * @param {simplisafe.signal.v1.ITrickleRequest} message TrickleRequest message or plain object to encode
           * @param {$protobuf.Writer} [writer] Writer to encode to
           * @returns {$protobuf.Writer} Writer
           */
          TrickleRequest.encodeDelimited = function encodeDelimited(
            message,
            writer
          ) {
            return this.encode(message, writer).ldelim();
          };

          /**
           * Decodes a TrickleRequest message from the specified reader or buffer.
           * @function decode
           * @memberof simplisafe.signal.v1.TrickleRequest
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @param {number} [length] Message length if known beforehand
           * @returns {simplisafe.signal.v1.TrickleRequest} TrickleRequest
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          TrickleRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length,
              message = new $root.simplisafe.signal.v1.TrickleRequest();
            while (reader.pos < end) {
              var tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.candidateInit = reader.string();
                  break;
                }
                default:
                  reader.skipType(tag & 7);
                  break;
              }
            }
            return message;
          };

          /**
           * Decodes a TrickleRequest message from the specified reader or buffer, length delimited.
           * @function decodeDelimited
           * @memberof simplisafe.signal.v1.TrickleRequest
           * @static
           * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
           * @returns {simplisafe.signal.v1.TrickleRequest} TrickleRequest
           * @throws {Error} If the payload is not a reader or valid buffer
           * @throws {$protobuf.util.ProtocolError} If required fields are missing
           */
          TrickleRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
          };

          /**
           * Verifies a TrickleRequest message.
           * @function verify
           * @memberof simplisafe.signal.v1.TrickleRequest
           * @static
           * @param {Object.<string,*>} message Plain object to verify
           * @returns {string|null} `null` if valid, otherwise the reason why it is not
           */
          TrickleRequest.verify = function verify(message) {
            if (typeof message !== 'object' || message === null)
              return 'object expected';
            if (
              message.candidateInit != null &&
              message.hasOwnProperty('candidateInit')
            )
              if (!$util.isString(message.candidateInit))
                return 'candidateInit: string expected';
            return null;
          };

          /**
           * Creates a TrickleRequest message from a plain object. Also converts values to their respective internal types.
           * @function fromObject
           * @memberof simplisafe.signal.v1.TrickleRequest
           * @static
           * @param {Object.<string,*>} object Plain object
           * @returns {simplisafe.signal.v1.TrickleRequest} TrickleRequest
           */
          TrickleRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.simplisafe.signal.v1.TrickleRequest)
              return object;
            var message = new $root.simplisafe.signal.v1.TrickleRequest();
            if (object.candidateInit != null)
              message.candidateInit = String(object.candidateInit);
            return message;
          };

          /**
           * Creates a plain object from a TrickleRequest message. Also converts values to other types if specified.
           * @function toObject
           * @memberof simplisafe.signal.v1.TrickleRequest
           * @static
           * @param {simplisafe.signal.v1.TrickleRequest} message TrickleRequest
           * @param {$protobuf.IConversionOptions} [options] Conversion options
           * @returns {Object.<string,*>} Plain object
           */
          TrickleRequest.toObject = function toObject(message, options) {
            if (!options) options = {};
            var object = {};
            if (options.defaults) object.candidateInit = '';
            if (
              message.candidateInit != null &&
              message.hasOwnProperty('candidateInit')
            )
              object.candidateInit = message.candidateInit;
            return object;
          };

          /**
           * Converts this TrickleRequest to JSON.
           * @function toJSON
           * @memberof simplisafe.signal.v1.TrickleRequest
           * @instance
           * @returns {Object.<string,*>} JSON object
           */
          TrickleRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(
              this,
              $protobuf.util.toJSONOptions
            );
          };

          /**
           * Gets the default type url for TrickleRequest
           * @function getTypeUrl
           * @memberof simplisafe.signal.v1.TrickleRequest
           * @static
           * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
           * @returns {string} The default type url
           */
          TrickleRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
              typeUrlPrefix = 'type.googleapis.com';
            }
            return typeUrlPrefix + '/simplisafe.signal.v1.TrickleRequest';
          };

          return TrickleRequest;
        })();

        return v1;
      })();

      return signal;
    })();

    simplisafe.v1 = (function () {
      /**
       * Namespace v1.
       * @memberof simplisafe
       * @namespace
       */
      var v1 = {};

      /**
       * ErrorCode enum.
       * @name simplisafe.v1.ErrorCode
       * @enum {number}
       * @property {number} ERROR_CODE_UNSPECIFIED=0 ERROR_CODE_UNSPECIFIED value
       * @property {number} ERROR_CODE_UNKNOWN_MESSAGE=1 ERROR_CODE_UNKNOWN_MESSAGE value
       * @property {number} ERROR_CODE_INVALID_MESSAGE=2 ERROR_CODE_INVALID_MESSAGE value
       */
      v1.ErrorCode = (function () {
        var valuesById = {},
          values = Object.create(valuesById);
        values[(valuesById[0] = 'ERROR_CODE_UNSPECIFIED')] = 0;
        values[(valuesById[1] = 'ERROR_CODE_UNKNOWN_MESSAGE')] = 1;
        values[(valuesById[2] = 'ERROR_CODE_INVALID_MESSAGE')] = 2;
        return values;
      })();

      v1.Error = (function () {
        /**
         * Properties of an Error.
         * @memberof simplisafe.v1
         * @interface IError
         * @property {simplisafe.v1.ErrorCode|null} [errorCode] Error errorCode
         * @property {string|null} [message] Error message
         */

        /**
         * Constructs a new Error.
         * @memberof simplisafe.v1
         * @classdesc Represents an Error.
         * @implements IError
         * @constructor
         * @param {simplisafe.v1.IError=} [properties] Properties to set
         */
        function Error(properties) {
          if (properties)
            for (
              var keys = Object.keys(properties), i = 0;
              i < keys.length;
              ++i
            )
              if (properties[keys[i]] != null)
                this[keys[i]] = properties[keys[i]];
        }

        /**
         * Error errorCode.
         * @member {simplisafe.v1.ErrorCode} errorCode
         * @memberof simplisafe.v1.Error
         * @instance
         */
        Error.prototype.errorCode = 0;

        /**
         * Error message.
         * @member {string} message
         * @memberof simplisafe.v1.Error
         * @instance
         */
        Error.prototype.message = '';

        /**
         * Creates a new Error instance using the specified properties.
         * @function create
         * @memberof simplisafe.v1.Error
         * @static
         * @param {simplisafe.v1.IError=} [properties] Properties to set
         * @returns {simplisafe.v1.Error} Error instance
         */
        Error.create = function create(properties) {
          return new Error(properties);
        };

        /**
         * Encodes the specified Error message. Does not implicitly {@link simplisafe.v1.Error.verify|verify} messages.
         * @function encode
         * @memberof simplisafe.v1.Error
         * @static
         * @param {simplisafe.v1.IError} message Error message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.errorCode != null &&
            Object.hasOwnProperty.call(message, 'errorCode')
          )
            writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.errorCode);
          if (
            message.message != null &&
            Object.hasOwnProperty.call(message, 'message')
          )
            writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.message);
          return writer;
        };

        /**
         * Encodes the specified Error message, length delimited. Does not implicitly {@link simplisafe.v1.Error.verify|verify} messages.
         * @function encodeDelimited
         * @memberof simplisafe.v1.Error
         * @static
         * @param {simplisafe.v1.IError} message Error message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Error message from the specified reader or buffer.
         * @function decode
         * @memberof simplisafe.v1.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {simplisafe.v1.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          var end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.simplisafe.v1.Error();
          while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.errorCode = reader.int32();
                break;
              }
              case 2: {
                message.message = reader.string();
                break;
              }
              default:
                reader.skipType(tag & 7);
                break;
            }
          }
          return message;
        };

        /**
         * Decodes an Error message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof simplisafe.v1.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {simplisafe.v1.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Error message.
         * @function verify
         * @memberof simplisafe.v1.Error
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Error.verify = function verify(message) {
          if (typeof message !== 'object' || message === null)
            return 'object expected';
          if (message.errorCode != null && message.hasOwnProperty('errorCode'))
            switch (message.errorCode) {
              default:
                return 'errorCode: enum value expected';
              case 0:
              case 1:
              case 2:
                break;
            }
          if (message.message != null && message.hasOwnProperty('message'))
            if (!$util.isString(message.message))
              return 'message: string expected';
          return null;
        };

        /**
         * Creates an Error message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof simplisafe.v1.Error
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {simplisafe.v1.Error} Error
         */
        Error.fromObject = function fromObject(object) {
          if (object instanceof $root.simplisafe.v1.Error) return object;
          var message = new $root.simplisafe.v1.Error();
          switch (object.errorCode) {
            default:
              if (typeof object.errorCode === 'number') {
                message.errorCode = object.errorCode;
                break;
              }
              break;
            case 'ERROR_CODE_UNSPECIFIED':
            case 0:
              message.errorCode = 0;
              break;
            case 'ERROR_CODE_UNKNOWN_MESSAGE':
            case 1:
              message.errorCode = 1;
              break;
            case 'ERROR_CODE_INVALID_MESSAGE':
            case 2:
              message.errorCode = 2;
              break;
          }
          if (object.message != null) message.message = String(object.message);
          return message;
        };

        /**
         * Creates a plain object from an Error message. Also converts values to other types if specified.
         * @function toObject
         * @memberof simplisafe.v1.Error
         * @static
         * @param {simplisafe.v1.Error} message Error
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Error.toObject = function toObject(message, options) {
          if (!options) options = {};
          var object = {};
          if (options.defaults) {
            object.errorCode =
              options.enums === String ? 'ERROR_CODE_UNSPECIFIED' : 0;
            object.message = '';
          }
          if (message.errorCode != null && message.hasOwnProperty('errorCode'))
            object.errorCode =
              options.enums === String
                ? $root.simplisafe.v1.ErrorCode[message.errorCode] === undefined
                  ? message.errorCode
                  : $root.simplisafe.v1.ErrorCode[message.errorCode]
                : message.errorCode;
          if (message.message != null && message.hasOwnProperty('message'))
            object.message = message.message;
          return object;
        };

        /**
         * Converts this Error to JSON.
         * @function toJSON
         * @memberof simplisafe.v1.Error
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Error.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Error
         * @function getTypeUrl
         * @memberof simplisafe.v1.Error
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Error.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return typeUrlPrefix + '/simplisafe.v1.Error';
        };

        return Error;
      })();

      v1.Header = (function () {
        /**
         * Properties of a Header.
         * @memberof simplisafe.v1
         * @interface IHeader
         * @property {number|null} [timestamp] Header timestamp
         * @property {string|null} [xRequestId] Header xRequestId
         * @property {string|null} [sender] Header sender
         */

        /**
         * Constructs a new Header.
         * @memberof simplisafe.v1
         * @classdesc Represents a Header.
         * @implements IHeader
         * @constructor
         * @param {simplisafe.v1.IHeader=} [properties] Properties to set
         */
        function Header(properties) {
          if (properties)
            for (
              var keys = Object.keys(properties), i = 0;
              i < keys.length;
              ++i
            )
              if (properties[keys[i]] != null)
                this[keys[i]] = properties[keys[i]];
        }

        /**
         * Header timestamp.
         * @member {number} timestamp
         * @memberof simplisafe.v1.Header
         * @instance
         */
        Header.prototype.timestamp = 0;

        /**
         * Header xRequestId.
         * @member {string} xRequestId
         * @memberof simplisafe.v1.Header
         * @instance
         */
        Header.prototype.xRequestId = '';

        /**
         * Header sender.
         * @member {string} sender
         * @memberof simplisafe.v1.Header
         * @instance
         */
        Header.prototype.sender = '';

        /**
         * Creates a new Header instance using the specified properties.
         * @function create
         * @memberof simplisafe.v1.Header
         * @static
         * @param {simplisafe.v1.IHeader=} [properties] Properties to set
         * @returns {simplisafe.v1.Header} Header instance
         */
        Header.create = function create(properties) {
          return new Header(properties);
        };

        /**
         * Encodes the specified Header message. Does not implicitly {@link simplisafe.v1.Header.verify|verify} messages.
         * @function encode
         * @memberof simplisafe.v1.Header
         * @static
         * @param {simplisafe.v1.IHeader} message Header message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Header.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.timestamp != null &&
            Object.hasOwnProperty.call(message, 'timestamp')
          )
            writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.timestamp);
          if (
            message.xRequestId != null &&
            Object.hasOwnProperty.call(message, 'xRequestId')
          )
            writer
              .uint32(/* id 2, wireType 2 =*/ 18)
              .string(message.xRequestId);
          if (
            message.sender != null &&
            Object.hasOwnProperty.call(message, 'sender')
          )
            writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.sender);
          return writer;
        };

        /**
         * Encodes the specified Header message, length delimited. Does not implicitly {@link simplisafe.v1.Header.verify|verify} messages.
         * @function encodeDelimited
         * @memberof simplisafe.v1.Header
         * @static
         * @param {simplisafe.v1.IHeader} message Header message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Header.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Header message from the specified reader or buffer.
         * @function decode
         * @memberof simplisafe.v1.Header
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {simplisafe.v1.Header} Header
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Header.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          var end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.simplisafe.v1.Header();
          while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.timestamp = reader.uint32();
                break;
              }
              case 2: {
                message.xRequestId = reader.string();
                break;
              }
              case 3: {
                message.sender = reader.string();
                break;
              }
              default:
                reader.skipType(tag & 7);
                break;
            }
          }
          return message;
        };

        /**
         * Decodes a Header message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof simplisafe.v1.Header
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {simplisafe.v1.Header} Header
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Header.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader);
          return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Header message.
         * @function verify
         * @memberof simplisafe.v1.Header
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Header.verify = function verify(message) {
          if (typeof message !== 'object' || message === null)
            return 'object expected';
          if (message.timestamp != null && message.hasOwnProperty('timestamp'))
            if (!$util.isInteger(message.timestamp))
              return 'timestamp: integer expected';
          if (
            message.xRequestId != null &&
            message.hasOwnProperty('xRequestId')
          )
            if (!$util.isString(message.xRequestId))
              return 'xRequestId: string expected';
          if (message.sender != null && message.hasOwnProperty('sender'))
            if (!$util.isString(message.sender))
              return 'sender: string expected';
          return null;
        };

        /**
         * Creates a Header message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof simplisafe.v1.Header
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {simplisafe.v1.Header} Header
         */
        Header.fromObject = function fromObject(object) {
          if (object instanceof $root.simplisafe.v1.Header) return object;
          var message = new $root.simplisafe.v1.Header();
          if (object.timestamp != null)
            message.timestamp = object.timestamp >>> 0;
          if (object.xRequestId != null)
            message.xRequestId = String(object.xRequestId);
          if (object.sender != null) message.sender = String(object.sender);
          return message;
        };

        /**
         * Creates a plain object from a Header message. Also converts values to other types if specified.
         * @function toObject
         * @memberof simplisafe.v1.Header
         * @static
         * @param {simplisafe.v1.Header} message Header
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Header.toObject = function toObject(message, options) {
          if (!options) options = {};
          var object = {};
          if (options.defaults) {
            object.timestamp = 0;
            object.xRequestId = '';
            object.sender = '';
          }
          if (message.timestamp != null && message.hasOwnProperty('timestamp'))
            object.timestamp = message.timestamp;
          if (
            message.xRequestId != null &&
            message.hasOwnProperty('xRequestId')
          )
            object.xRequestId = message.xRequestId;
          if (message.sender != null && message.hasOwnProperty('sender'))
            object.sender = message.sender;
          return object;
        };

        /**
         * Converts this Header to JSON.
         * @function toJSON
         * @memberof simplisafe.v1.Header
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Header.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Header
         * @function getTypeUrl
         * @memberof simplisafe.v1.Header
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Header.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return typeUrlPrefix + '/simplisafe.v1.Header';
        };

        return Header;
      })();

      return v1;
    })();

    return simplisafe;
  })();

  return $root;
});
