import React, { useEffect, useState, useRef } from 'react';
import './overlay.css';

const API_URL = process.env.REACT_APP_PCS_ADMIN_URL;

const getColorFromType = (type: string): string => {
  switch (type) {
    case 'moves':
      return '#0000ff';
    case 'tracks':
      return '#aa1144';
    case 'faces':
      return '#f29705';
    default:
      return '#222222';
  }
};

const drawBox = (ctx, canvasWidth, canvasHeight, dimension, selected) => {
  ctx.clearRect(0, 0, canvasWidth, canvasHeight);

  ctx.strokeStyle = getColorFromType(selected.category);
  ctx.lineWidth = 2;

  const { x, y, w, h } = getNormalizedBox(
    selected.bbox,
    dimension.width,
    dimension.height,
    canvasWidth,
    canvasHeight
  );
  console.log(`Drawing Box for Category: ${selected.category}:
    color:   ${getColorFromType(selected.category)}
    x:       ${x}
    y:       ${y}
    w:       ${w}
    h:       ${h}
  `);
  ctx.strokeRect(x, y, w, h);
};

const getNormalizedBox = (
  bbox,
  imageWidth,
  imageHeight,
  canvasWidth,
  canvasHeight
) => {
  const ratioX = canvasWidth / imageWidth;
  const ratioY = canvasHeight / imageHeight;

  let x = bbox.x * ratioX;
  let y = bbox.y * ratioY;
  let w = bbox.w * ratioX;
  let h = bbox.h * ratioY;

  return { x, y, w, h };
};

interface OverlayProps {
  width: number;
  height: number;
  selected: boolean;
}

export default function Overlay({ width, height, selected }: OverlayProps) {
  height = height - 36;
  const canvasElement = useRef(null);
  const [dimension, setDimension] = useState(null);
  const [canvasWidth, setCanvasWidth] = useState(width);
  const [canvasHeight, setCanvasHeight] = useState(height);
  const [canvasTop, setCanvasTop] = useState(0);
  const [canvasLeft, setCanvasLeft] = useState(0);

  useEffect(() => {
    if (!dimension) {
      return;
    }

    const aspectRatio = dimension.width / dimension.height;

    let videoWidth = 0;
    let videoHeight = 0;
    let videoTop = 0;
    let videoLeft = 0;

    // Video is wider, so there is top padding
    if (height * aspectRatio > width) {
      videoLeft = 0;
      videoWidth = width;
      videoHeight = width / aspectRatio;
      videoTop = (height - videoHeight) / 2;
    } else {
      // Video is either perfectly even or taller
      videoTop = 0;
      videoHeight = height;
      videoWidth = height * aspectRatio;
      videoLeft = (width - videoWidth) / 2;
    }

    console.log(`Set Values based on outer: ${width}x${height} and aspect ratio ${aspectRatio} to be:
      width:  ${videoWidth}
      height: ${videoHeight}
      left:   ${videoLeft}
      top:    ${videoTop}
    `);

    setCanvasWidth(videoWidth);
    setCanvasHeight(videoHeight);
    setCanvasTop(videoTop);
    setCanvasLeft(videoLeft);
  }, [dimension, width, height]);

  useEffect(() => {
    if (!dimension) {
      return;
    }

    const canvas = canvasElement?.current;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');

    if (selected) {
      drawBox(ctx, canvasWidth, canvasHeight, dimension, selected);
    }
  }, [dimension, canvasWidth, canvasHeight]);

  useEffect(() => {
    const canvas = canvasElement?.current;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');

    if (selected) {
      // Load a new image when selected changes
      const image = new Image();

      image.src = `${API_URL}/${
        selected.category === 'faces' ? selected.frame_file : selected.img_file
      }`;

      image.onload = () => {
        setDimension({
          width: image.naturalWidth,
          height: image.naturalHeight,
        });
      };
    } else {
      setDimension(null);
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    }
  }, [selected]);

  return (
    <div
      className="parent fs-exclude"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <canvas
        ref={canvasElement}
        className="canvas"
        width={canvasWidth}
        height={canvasHeight}
        style={{
          width: `${canvasWidth}px`,
          height: `${canvasHeight}px`,
          left: canvasLeft,
          top: canvasTop,
        }}
      ></canvas>
    </div>
  );
}
