// Setup the state and callbacks for subscription data

// import * as React from 'react';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import { ReadyState } from 'react-use-websocket';
import { usePcsClient } from 'src/context/pcs-auth-context';
import { useSsUserSocket } from './socketHooks';

function useAllSubscriptions(userId) {
  const client = usePcsClient();
  const { readyState } = useSsUserSocket(userId);
  const query = useQuery({
    queryKey: ['subscriptions', userId],
    queryFn: () => client(`subscriptions/users/${userId}?activeOnly=true`),
    refetchInterval: readyState !== ReadyState.OPEN ? 5 * 1000 : null,
  });
  return query;
}

function useSubscription({ userId, sid }) {
  const client = usePcsClient();
  const { readyState } = useSsUserSocket(userId);
  const { data, isLoading, error } = useQuery({
    queryKey: ['subscriptions', userId, sid],
    queryFn: () => client(`subscriptions/${sid}`),
    staleTime: 1000 * 60,
    cacheTime: 1000 * 60 * 60, // Remove it from the cache after 60 minutes of inactivity
    refetchInterval: readyState !== ReadyState.OPEN ? 5 * 1000 : null,
  });
  return { subscription: data?.subscription, isLoading, error };
}

export { useSubscription, useAllSubscriptions };
