import { Typography } from '@material-ui/core';
import { useGetEventDocumentation } from 'src/hooks/eventHooks';

type DocumentationListProps = {
  eventId: number;
};

const DocumentationList = ({ eventId }: DocumentationListProps) => {
  const { data } = useGetEventDocumentation(eventId);

  const documentation = data?.documentation;

  return (
    <div>
      <Typography>{documentation}</Typography>
    </div>
  );
};

export default DocumentationList;
