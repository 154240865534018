import React from 'react';
import { useQuery } from 'react-query';

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { usePcsClient } from 'src/context/pcs-auth-context';

export default function VersionCheck() {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleReload = () => {
    window.location.reload();
  };

  // Refresh token hourly
  const client = usePcsClient();
  const versionCheckQuery = useQuery({
    queryKey: 'pcs_version',
    queryFn: () =>
      client(`version-check`, {
        params: {
          build: process.env.REACT_APP_GIT_SHA,
          version: process.env.REACT_APP_VERSION,
        },
      }),

    // Check hourly
    staleTime: 60 * 60 * 1000,
    refetchInterval: 60 * 60 * 1000,
    // refetchIntervalInBackground: true

    // Faster for testing
    // staleTime: 10 * 1000,
    // refetchInterval: 10 * 1000
  });

  if (versionCheckQuery.isSuccess) {
    if (versionCheckQuery.data['optional_refresh'] === true) {
      versionCheckQuery.data['optional_refresh'] = false;
      setOpen(true);
    }
    if (versionCheckQuery.data['force_refresh'] === true) {
      versionCheckQuery.data['force_refresh'] = false;
      const now = Math.round(new Date().getTime() / 1000);
      const lastReload = parseInt(
        localStorage.getItem('pcs_last_reload') || '0',
        10
      );

      // Throttle forced reloading to once every 60 minutes
      if (now - lastReload > 60 * 60) {
        console.log('Forcing reload to update app!');
        localStorage.setItem('pcs_last_reload', now.toString());
        setTimeout(() => {
          window.location.reload();
        }, 10000);

        return (
          <Dialog open>
            <DialogTitle id="alert-dialog-title">
              Forcing Page Reload
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                The PCS App has been updated to a new minimum version (
                {versionCheckQuery.data?.min_version}). You are currently using
                an outdated version ({process.env.REACT_APP_VERSION}). This page
                will force reload in 10 seconds. If the update is not
                successful, this will happen again in 60 minutes.
              </DialogContentText>
            </DialogContent>
          </Dialog>
        );
      }
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">App Version Updated</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          The PCS App has been updated to a new minimum version (
          {versionCheckQuery.data?.min_version}). You are currently using an
          outdated version ({process.env.REACT_APP_VERSION}). Please reload to
          get the new version. If you are in the middle of an activity, you may
          finish your work then refresh your browser when convenient.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Continue without Reloading
        </Button>
        <Button
          onClick={handleReload}
          color="primary"
          variant="contained"
          autoFocus
        >
          Reload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
