import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  CardHeader,
  Divider,
  styled,
} from '@material-ui/core';
import { useGetAgentsStatus } from 'src/hooks/reportHooks';
import { LoadingSpinner } from 'src/components/LoadingSpinner';

const ScrollableList = styled(List)(() => ({
  maxHeight: 'calc(35vh - 60px)',
  overflow: 'auto',
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  avatar: {
    fontSize: 14,
    color: theme.palette.common.white,
  },
  listItemAvatar: {
    maxWidth: 30,
  },
  active: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    background: 'green',
  },
  inactive: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    background: 'red',
  },
}));

const UserList = ({ className, ...rest }) => {
  const classes = useStyles();

  const agentsStatus = useGetAgentsStatus();

  return (agentsStatus?.isSuccess && agentsStatus?.data) ? (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Active in Queue" />
      <Divider />
      <ScrollableList disablePadding>
        {
          agentsStatus?.data.map((rep) => (
            <ListItem key={rep.name}>
              <ListItemAvatar className={classes.listItemAvatar}>
                <div
                  className={classes.active}
                ></div>
              </ListItemAvatar>
              <ListItemText
                primary={rep.name}
                primaryTypographyProps={{ variant: 'h6' }}
              />
            </ListItem>
          ))
        }
      </ScrollableList>
    </Card>
  ) : (<LoadingSpinner message="Loading agent statuses..." />)

};

UserList.propTypes = {
  className: PropTypes.string,
};

export default UserList;
