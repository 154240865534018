import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Card, Typography } from '@material-ui/core';
import DenseCardHeader from 'src/components/DenseCardHeader';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import moment from 'moment';

const CameraStatus = ({ camera }) => {
  const camStatus = camera?.cameraStatus;
  const camState = camera?.currentState;
  const camSet = camera?.cameraSettings;

  if (!camera) return <LoadingSpinner message="Loading camera" />;
  return (
    <>
      <Box mt={3} mb={3}>
        <Typography color="textPrimary" gutterBottom variant="h5">
          Camera: {`${camera?.cameraSettings?.cameraName}`}
        </Typography>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Card>
            <DenseCardHeader title="Camera Status" />
            <Box p={1}>
              <Typography>Battery: {camStatus?.batteryPercentage}</Typography>
              <Typography>Agent: {camStatus?.camAgentVersion}</Typography>
              <Typography>FW: {camStatus?.firmwareVersion}</Typography>
              <Typography>FW DL: {camStatus?.fwDownloadPercentage}%</Typography>
              <Typography>FW DL #: {camStatus?.fwDownloadVersion}</Typography>
              <Typography>Init Err: {camStatus?.initErrors}</Typography>
              <Typography>
                Login: {moment.unix(camStatus?.lastLogin).fromNow()}
              </Typography>
              <Typography>
                Logout: {moment.unix(camStatus?.lastLogout).fromNow()}
              </Typography>
              <Typography>MAC: {camStatus?.wlanMac}</Typography>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card>
            <DenseCardHeader title="Camera State" />
            <Box p={1}>
              <Typography>
                Charging: {camState?.batteryCharging.toString()}
              </Typography>
              <Typography>Idle: {camState?.idle.toString()}</Typography>
              <Typography>
                Live Stream: {camState?.liveStreaming.toString()}
              </Typography>
              <Typography>
                OTA DL: {camState?.otaDownloading.toString()}
              </Typography>
              <Typography>
                OTA Flash: {camState?.otaFlashing.toString()}
              </Typography>
              <Typography>
                Recording: {camState?.recording.toString()}
              </Typography>
              <Typography>TS: {camState?.ts}</Typography>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card>
            <DenseCardHeader title="Camera Settings" />
            <Box p={1}>
              <Typography>
                Power control:{' '}
                {camSet?.admin?.powerControl?.disabled.toString()}
              </Typography>
              <Typography>Uptime: {camSet?.admin?.uptime}</Typography>
              <Typography>
                Wifi Disconnects: {camSet?.admin?.wifiDisconnects}
              </Typography>
              <Typography>
                Wifi Reloads: {camSet?.admin?.wifiDriverReloads}
              </Typography>
              <Typography>Mic: {camSet?.micSensitivity}</Typography>
              <Typography>Motion: {camSet?.motionSensitivity}</Typography>
              <Typography>Motion: {camSet?.motionSensitivity}</Typography>
              <Typography>NightVision: {camSet?.nightVision}</Typography>
              <Typography>
                Notifications: {camSet?.notificationsEnable.toString()}
              </Typography>
              <Typography>Picture: {camSet?.pictureQuality}</Typography>
              <Typography>PIR: {camSet?.pirEnable.toString()}</Typography>
              <Typography>PIR level: {camSet?.pirLevel}</Typography>
              <Typography>Speaker: {camSet?.speakerVolume}</Typography>
              <Typography>Shutter Off: {camSet?.shutterOff}</Typography>
              <Typography>Shutter Home: {camSet?.shutterHome}</Typography>
              <Typography>Shutter Away: {camSet?.shutterAway}</Typography>
            </Box>
          </Card>
        </Grid>

        {/* End */}
      </Grid>
    </>
  );
};

CameraStatus.propTypes = {
  camera: PropTypes.object,
};

export default CameraStatus;
