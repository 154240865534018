import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    background: 'grey',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  message: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ConfirmDialog({
  message,
  continueButtonText = 'Continue',
  cancelButtonText = 'Cancel',
  onContinue,
  onCancel = null,
  style = {},
  titleStyle = {},
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={classes.root} style={style}>
      {message && (
        <Box m={2} p={2} borderRadius={10} className={classes.message}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={titleStyle}
          >
            {message}
          </Typography>
          <Typography display="inline">
            {!continueButtonText ? null : (
              <Button onClick={() => onContinue()}>{continueButtonText}</Button>
            )}
            <Button color="primary" variant="contained" onClick={handleCancel}>
              {cancelButtonText}
            </Button>
          </Typography>
        </Box>
      )}
    </div>
  );
}

ConfirmDialog.propTypes = {
  message: PropTypes.string,
  continueButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onContinue: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};
