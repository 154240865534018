import { useEffect, useRef, useContext, useCallback } from 'react';
import {
  MetricsContext,
  createOpenMetricDispatch,
} from 'src/context/Metrics-context';

interface UseEventQueueConcurrencyProps {
  events: any[];
}

export const useEventQueueConcurrencyMetric = ({
  events,
}: UseEventQueueConcurrencyProps) => {
  const [, dispatch] = useContext(MetricsContext);
  const eventConcurrencyMap = useRef<Map<number, number>>(new Map());
  const prevEventIdsRef = useRef<Set<number>>(new Set());

  useEffect(() => {
    if (!events) {
      return;
    }

    // grab all current events in the queue and check for newly arriving events
    const currentEventIds = new Set<number>(
      events.map((event) => event.eventId).filter((id) => id != null)
    );
    const prevEventIds = prevEventIdsRef.current;

    const newEventIds = new Set<number>(
      [...currentEventIds].filter((eventId) => !prevEventIds.has(eventId))
    );

    // For each new event, log the concurrency count of the queue when it first entered the queue
    const concurrencyCount = currentEventIds.size;

    for (const eventId of newEventIds) {
      eventConcurrencyMap.current.set(eventId, concurrencyCount);
    }

    prevEventIdsRef.current = currentEventIds;
  }, [events]);

  const submitConcurrencyMetric = useCallback(
    (eventId: number) => {
      const concurrencyCount = eventConcurrencyMap.current.get(eventId);
      if (concurrencyCount !== undefined) {
        const openMetricDispatch = createOpenMetricDispatch(dispatch);
        openMetricDispatch({
          metricName: 'event-queue-concurrency',
          payload: {
            eventId,
            concurrencyCount,
          },
        });
        eventConcurrencyMap.current.delete(eventId);
      }
    },
    [dispatch]
  );

  return {
    submitConcurrencyMetric,
  };
};
