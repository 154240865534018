import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, makeStyles, CardHeader, Divider } from '@material-ui/core';
import { useListPcsEventsQuery } from 'src/hooks/eventHooks';
import { LoadingSpinner } from 'src/components/LoadingSpinner';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import { useNavigate } from 'react-router-dom';
import { useGetRepMe } from 'src/hooks/repHooks';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  gridRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    // width: 500,
    height: '100%',
  },
  imageListItemBar: {
    height: '20px',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

const SampleImages = ({ className, ...rest }) => {
  const classes = useStyles();
  const { data: repData } = useGetRepMe();
  const eventsQuery = useListPcsEventsQuery({
    limit: 50,
    lookback: 3600 * 24 * 10,
    pcs_status_gte: -40,
  });
  const events = eventsQuery.data?.filter((event) => event.tracks?.[0]);

  const navigate = useNavigate();

  if (!eventsQuery.isSuccess) return <LoadingSpinner />;
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Recent Event Images" />
      <Divider />
      <div className={classes.gridRoot}>
        {(repData?.role === 'admin' ||
          repData?.role === 'agent_supervisor') && (
          <ImageList cols={3} rowHeight={180} className={classes.imageList}>
            {/* <ImageListItem key="Subheader" cols={2} style={{ height: 'auto' }}>
            <ListSubheader component="div">December</ListSubheader>
          </ImageListItem> */}
            {events &&
              events.map((event) => (
                <ImageListItem
                  style={{ cursor: 'pointer' }}
                  key={event.eventId}
                  onClick={() =>
                    repData?.role === 'admin'
                      ? navigate(
                          `/app/verify-respond?userId=${event.userId}&sid=${event.sid}&eventId=${event.eventId}&uuid=${event.uuid}`
                        )
                      : null
                  }
                >
                  <img
                    src={`${process.env.REACT_APP_PCS_ADMIN_URL}/${event.tracks[0].img_file}`}
                    alt={event.info}
                    className="fs-exclude"
                  />
                  <ImageListItemBar
                    className={classes.imageListItemBar}
                    // title={event.info}
                    subtitle={<span>{event.info}</span>}
                  />
                </ImageListItem>
              ))}
          </ImageList>
        )}
      </div>
    </Card>
  );
};

SampleImages.propTypes = {
  className: PropTypes.string,
};

export default SampleImages;
