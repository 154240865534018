import { CSVLink } from 'react-csv';
import { makeStyles } from '@material-ui/core';
import { useRef, useState } from 'react';
import { usePcsAuthRep, usePcsClient } from 'src/context/pcs-auth-context';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '2rem',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    border: 'none',
    padding: '0.7rem 1rem',
    borderRadius: '0.3rem',
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  csvLink: {
    display: 'none',
  },
}));

const CxReport = () => {
  const classes = useStyles();
  const client = usePcsClient();
  const [isDownloadingActivations, setIsDownloadingActivations] =
    useState(false);
  const [isDownloadingOrders, setIsDownloadingOrders] = useState(false);
  const [data, setData] = useState([]);
  const currentDate = new Date().toISOString().slice(0, 10);
  const ordersCSVLinkRef = useRef();
  const activationsCSVLinkRef = useRef();

  const { role } = usePcsAuthRep();

  const ALLOWED_ROLES = ['admin', 'cx_order_report'];

  if (role && !ALLOWED_ROLES.includes(role)) {
    return (
      <div className={classes.root}>
        <h1>Not Authorized</h1>
        <p>
          You are not authorized to view this page. Please contact your
          administrator.
        </p>
      </div>
    );
  }

  const handleDownloadOrdersReport = () => {
    setIsDownloadingOrders(true);
    client('reports/cx-orders-report')
      .then((res) => {
        // Set the data
        setData(res);
        // Trigger the CSV download
        if (ordersCSVLinkRef.current) {
          // @ts-ignore
          ordersCSVLinkRef.current.link.click();
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsDownloadingOrders(false);
      });
  };

  const handleDownloadActivationsReport = () => {
    setIsDownloadingActivations(true);
    client('reports/cx-activations-report')
      .then((res) => {
        // Set the data
        setData(res);
        // Trigger the CSV download
        if (activationsCSVLinkRef.current) {
          // @ts-ignore
          activationsCSVLinkRef.current.link.click();
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsDownloadingActivations(false);
      });
  };

  return (
    <div className={classes.root}>
      <div>
        <h1>Monitoring Plan Orders</h1>
        <p>Click on the button to download the CSV file</p>
      </div>
      <button onClick={handleDownloadOrdersReport} className={classes.button}>
        {isDownloadingOrders ? 'Downloading...' : 'Download Orders Report'}
      </button>
      <button
        onClick={handleDownloadActivationsReport}
        className={classes.button}
      >
        {isDownloadingActivations
          ? 'Downloading...'
          : 'Download Activations Report'}
      </button>

      <CSVLink
        className={classes.csvLink}
        data={data}
        filename={`${currentDate}-orders-report.csv`}
        headers={[
          { label: 'UID', key: 'uid' },
          { label: 'Link to CRM', key: 'link_to_crm' },
          { label: 'Email', key: 'email' },
          { label: 'Street 1', key: 'street1' },
          { label: 'Street 2', key: 'street2' },
          { label: 'City', key: 'city' },
          { label: 'State', key: 'state' },
          { label: 'Zip', key: 'zip' },
          { label: 'Qty', key: 'qty' },
          { label: 'Plan SKU', key: 'plan_sku' },
          { label: 'Created Date', key: 'created_date' },
        ]}
        ref={ordersCSVLinkRef}
      />

      <CSVLink
        className={classes.csvLink}
        data={data}
        filename={`${currentDate}-activations-report.csv`}
        headers={[
          { label: 'UID', key: 'uid' },
          { label: 'SID', key: 'sid' },
          { label: 'Link to CRM', key: 'link_to_crm' },
          { label: 'Plan SKU', key: 'plan_sku' },
          { label: 'Service Created Date', key: 'service_created_date' },
        ]}
        ref={activationsCSVLinkRef}
      />
    </div>
  );
};

export default CxReport;
