import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const Auth0CallbackView = () => {
  console.log('/callback hit');
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_AUTH0_USER_API_CALLBACK}?code=${code}`;
  }, []);

  return <></>;
};

export { Auth0CallbackView };
