import { Box, Card, Container, Grid, makeStyles } from '@material-ui/core';
import {
  FC,
  useEffect,
  useState,
  useContext,
  useRef,
  useLayoutEffect,
  useCallback,
} from 'react';
import { SsSubscription } from 'src/types';
import ReactGA from 'react-ga4';
import { Navigate, useNavigate } from 'react-router';

import ConfirmDialog from '../../components/ConfirmDialog';
import DataStream from '../../components/DataStream';
import LocationNotes from '../../components/LocationNotes';
import DenseCardHeader from '../../components/DenseCardHeader';
import Page from '../../components/Page';
import { useVideoPlayer } from '../../components/VideoPlayer/useVideoPlayer';
import {
  useGetPcsEventQuery,
  useHoldEvents,
  useUnholdEvents,
} from '../../hooks/eventHooks';
import { useSubscription } from '../../hooks/useSubscription';
import { useMostRecent } from '../../utils/useMostRecent';
import AlarmEvents from './AlarmEvents';
import { AlarmStatus } from './AlarmStatus';
import PcsCamera from './PcsCamera';
import CamerasGrid from './components/CamerasGrid';
import { MetricsContext } from 'src/context/Metrics-context';
import MetricsModule from 'src/utils/MetricsModule';
import dayjs from 'dayjs';
import VerifySteps from './VerifiySteps';
import PerfectScrollbar from 'react-perfect-scrollbar';
import FaceGrid from './components/FaceGrid';
import Stopwatch from './components/Stopwatch';
import {
  useAddRecordingSession,
  useGeneratePresignedURL,
} from 'src/hooks/recordingHook';
import { ScreenContext } from 'src/context/ScreenRecordingContext';
import { useGetRepMe } from 'src/hooks/repHooks';
import { assignCategoryOfDetectedEvents } from 'src/components/DataStream/helpers';
import { Role } from 'src/models';
import { useSnackbar } from 'notistack';
import { useDisarmHook } from 'src/hooks/useDisarmHooks';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  mainVideoWrapper: {
    position: 'relative',
  },
}));

const initialPlayerState = { isPlaying: true, volume: 1 };
export interface statusButtonType {
  getIsHandled: () => boolean;
  getIsCanceled: () => boolean;
}

const OLDEST_ALLOWED_TIMESTAMP = 30 * 24 * 3600;

const VerifyRespondPage: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const goBackRef = useRef(false);
  const [_, dispatch] = useContext(MetricsContext);
  const { userId, sid, eventId, uuid } = useMostRecent();
  const metrics = new MetricsModule('verify-respond');
  const payload = useRef(metrics.payload({ eventID: eventId }));
  const [clickedAlarmEvent, setClickedAlarmEvent] = useState(eventId);
  const playerState = useVideoPlayer(userId, initialPlayerState);
  const isAfterFirstLoad = useRef(false);
  const { subscription: realSubscription }: { subscription: SsSubscription } =
    useSubscription({
      userId,
      sid,
    });

  const cameras = realSubscription?.location?.system?.cameras;
  // Get recording permission from context
  const { stream, remoteStream } = useContext(ScreenContext);

  const startTime = useRef<Date>();
  const mediaRecorder = useRef<MediaRecorder>();
  const chunks = useRef<Blob[]>();

  const { data: repData } = useGetRepMe();
  const audioContext = useRef<AudioContext>(null);
  const audioDestination = useRef<MediaStreamAudioDestinationNode>(null);
  const outgoingAudio = useRef<MediaStreamAudioSourceNode>(null);

  const addRecording = useAddRecordingSession();
  const generateURL = useGeneratePresignedURL();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!repData?.user_id || repData?.role !== Role.AGENT_OPS) return;
    generateURL.mutate(`${repData?.user_id}/${Date.now()}.mp4`);
  }, [repData?.user_id]);

  // Start recording this page
  useEffect(() => {
    if (!(stream && remoteStream && generateURL.data)) return;

    if (!audioContext.current) {
      audioContext.current = new AudioContext();
    }

    if (!outgoingAudio.current) {
      const outgoingStream = new MediaStream(stream);
      outgoingAudio.current =
        audioContext.current.createMediaStreamSource(outgoingStream);
    }

    if (!audioDestination.current) {
      audioDestination.current =
        audioContext.current.createMediaStreamDestination();
      outgoingAudio.current.connect(audioDestination.current);
    }

    Object.values(remoteStream).forEach((streamItem: MediaStream) => {
      const incomingStream = new MediaStream(streamItem.getAudioTracks());
      const incomingAudio =
        audioContext.current.createMediaStreamSource(incomingStream);
      incomingAudio.connect(audioDestination.current);
    });

    mediaRecorder.current = new MediaRecorder(audioDestination.current.stream);
    chunks.current = [];

    mediaRecorder.current.addEventListener('dataavailable', (event) => {
      chunks.current.push(event.data);
    });

    mediaRecorder.current.addEventListener('stop', () => {
      const blob = new Blob(chunks.current, {
        type: mediaRecorder.current.mimeType,
      });

      fetch(generateURL.data.url, {
        method: 'PUT',
        body: blob,
      }).then((response) => {
        if (response.status === 403) {
          enqueueSnackbar(
            `Session could not be recorded: ${response.status} ${response.statusText}`,
            {
              variant: 'error',
            }
          );
          return;
        }
        addRecording.mutate({
          recording_url: generateURL.data.url.split('?')[0],
          start_time: startTime.current,
          end_time: new Date(),
          agent_uid: repData?.user_id,
          eventId,
        });
      });
    });

    mediaRecorder.current.start();
  }, [stream, remoteStream, generateURL.data]);

  useEffect(() => {
    startTime.current = new Date();
    return () => {
      console.log('*** Unloading');
      if (mediaRecorder.current) {
        mediaRecorder.current.stop();
      }
    };
  }, []);

  // subscription
  //   ? localStorage.setItem('Subscription', JSON.stringify(subscription))
  //   : null;
  const eventQuery = useGetPcsEventQuery(clickedAlarmEvent);
  const isFake = false; // I don't feel like disassembling all the logic that might touch this
  const subscription = realSubscription;
  useHoldEvents(eventId, sid);
  const fakeSid = isFake ? eventQuery?.data?.fakeData?.fakeSid || 0 : 0;

  const newStatus = useUnholdEvents(eventId, sid);
  const dataCreator = () => {
    return navigator.sendBeacon(
      `${process.env.REACT_APP_PCS_ADMIN_URL}/events/unhold-events?sid=${sid}&eventId=${eventId}&clean_handler=true`,
      JSON.stringify({ eventId, sid })
    );
  };

  useEffect(() => {
    window.addEventListener('unload', dataCreator);

    return () => {
      window.removeEventListener('unload', dataCreator);
    };
  }, []);

  const buttonsStatusRef = useRef<any>(null);
  useLayoutEffect(() => {
    // useLayoutEffect makes sure that cleanup function is called before child
    // component is unmounted. Due to this, we can get updated values from
    // buttonsStatusRef.
    return () => {
      const current = buttonsStatusRef.current;
      // I think optional chaining is not really needed, but it can help with
      // TypeScript type checking.
      if (!current?.isHandled && !current?.isCanceled && !goBackRef.current) {
        newStatus.mutate();
      }
    };
  }, []);

  const [videoTimestamp, setVideoTimestamp] = useState('');
  const [videoTz, setVideoTz] = useState(0);
  const [activeVideo, setActiveVideo] = useState();
  const [mainCameraUUID, setMainCameraUUID] = useState('');
  const [overRide, setOverRide] = useState(false);
  const [isDisarmed, setIsDisarmed] = useState(false);

  const sendMetrics = useRef(true);

  const detections = useCallback(assignCategoryOfDetectedEvents(eventQuery), [
    eventQuery,
  ]);

  useDisarmHook({
    eventId,
    isDisarmed,
    setIsDisarmed,
    dispatch,
    userId,
    subscription: realSubscription,
  });

  const changeActiveVideo = (value) => {
    setMainCameraUUID('');
    setActiveVideo(value);
    ReactGA.event({
      category: 'Verify & Respond page',
      action: 'Changing stream',
      value: eventId,
    });
  };

  const changeMainCameraUUID = (value) => {
    setMainCameraUUID(value);
    setActiveVideo(null);
    ReactGA.event({
      category: 'Verify & Respond page',
      action: 'Changing stream',
      value: eventId,
    });
  };

  const payloadMetricsData = () => {
    const watchData = JSON.parse(localStorage.getItem('pcs_watch_settings'));
    watchData
      ? metrics.fetchNewData(
          watchData.acceptNew,
          watchData.pcsStatusThreshold,
          watchData.threatLevel,
          watchData.armstate,
          eventId
        )
      : metrics.fetchNewData();
  };

  const onAlarmClickHandler = (clickEventId, clickEventTimestamp) => {
    payloadMetricsData();
    payload.current = metrics.payload();
    dispatch({
      type: 'SEND',
      payload: {
        ...payload.current,
        metricName: 'video-playback',
        eventID: clickEventId,
      },
    });

    if (clickEventId) {
      setClickedAlarmEvent(clickEventId);
      setMainCameraUUID('');
      if (activeVideo) {
        setActiveVideo(null);
        setTimeout(() => {
          setActiveVideo(clickEventId);
        }, 200);
      } else {
        setActiveVideo(clickEventId);
      }
    } else {
      setClickedAlarmEvent(eventId);
      setActiveVideo(null);
      setMainCameraUUID(uuid);
    }
  };

  useEffect(() => {
    if (mainCameraUUID) {
      setClickedAlarmEvent(eventId);
    }
  }, [mainCameraUUID]);

  const onStreamClickHandler = (item) => {
    if (item !== 0) {
      payloadMetricsData();
      payload.current = metrics.payload();
      dispatch({
        type: 'SEND',
        payload: {
          ...payload.current,
          metricName: 'video-playback',
          eventID: eventId,
        },
      });
    }
    changeActiveVideo({ ...item, rand: Math.random() });
    ReactGA.event({
      category: 'Verify & Respond page',
      action: 'Changing stream',
      value: eventId,
    });
  };

  useEffect(() => {
    if (eventQuery.data?.isFake && mainCameraUUID && !activeVideo) {
      onStreamClickHandler(detections[0]);
    }
  }, [activeVideo, mainCameraUUID, eventQuery, detections]);

  useEffect(() => {
    payloadMetricsData();
    sendMetrics.current = true;

    if (uuid) {
      changeMainCameraUUID(uuid);
    } else {
      changeMainCameraUUID(cameras?.[0]?.uuid);
    }

    return () => {
      if (sendMetrics.current) {
        metrics.stopTimer();
        payload.current = metrics.payload({ eventID: eventId });
        dispatch({
          type: 'SEND',
          payload: { ...payload.current, PCSstatus: 'verifying' },
        });
      }
    };
  }, [cameras, uuid]);

  useEffect(() => {
    setVideoTz(0);
  }, [eventId]);

  useEffect(() => {
    if (eventQuery.data == null) {
      return;
    }
    // if the agent is already on page when event disposition has changed then redirect them
    if (
      isAfterFirstLoad.current &&
      eventQuery?.data?.disposition?.action != null
    ) {
      dispatch({
        type: 'SEND',
        payload: {
          metricName: 'event-already-handled',
          payload: { eventId, agentID: repData?.user_id },
        },
      });
      enqueueSnackbar(
        `The event has been already handled [${eventQuery?.data?.disposition?.action}]`,
        {
          variant: 'info',
        }
      );
      navigate('/app/queue');
    }

    isAfterFirstLoad.current = true;
  }, [eventQuery.data]);

  const elapsed = Date.now() / 1000 - eventQuery.data?.eventTimestamp;
  if (elapsed > OLDEST_ALLOWED_TIMESTAMP) {
    return <Navigate to="/app/dashboard" />;
  }

  if (isDisarmed) {
    return (
      <ConfirmDialog
        message="Customer disarmed system, event has ended"
        cancelButtonText="RETURN TO QUEUE"
        continueButtonText={null}
        onCancel={() => {
          navigate('/app/queue');
        }}
      />
    );
  }

  if (repData?.role === Role.ENGINEER || repData?.role === Role.QA) {
    return <Navigate to="/app/dashboard" />;
  }

  const cameraMapMic =
    cameras?.reduce((acc, camera) => {
      return { ...acc, [camera.uuid]: !!camera?.cameraSettings?.micEnable };
    }, {}) || {};

  return (
    <Page title="Verify & Respond">
      <Container className={classes.root} maxWidth={false} disableGutters>
        <Grid container spacing={1}>
          {/*LEFT - MAIN */}
          <Grid item xs={6}>
            <Grid container spacing={1} className={classes.mainVideoWrapper}>
              <Grid item xs={12}>
                <Card>
                  <Box height="67vh">
                    <DenseCardHeader
                      title={`All Cameras (rate: ${playerState.playbackRate})`}
                      second={<Stopwatch />}
                    />
                    <Card>
                      <Box height="67vh">
                        {(isFake || activeVideo) && (
                          <PcsCamera
                            eventId={eventId}
                            liveIndicator={false}
                            playerState={playerState}
                            activeItem={activeVideo}
                            disableVolumeAndMicrophone={
                              !cameraMapMic?.[mainCameraUUID] &&
                              mainCameraUUID !== ''
                            }
                            eventQuery={eventQuery}
                            onItemClick={onStreamClickHandler}
                          />
                        )}
                      </Box>
                    </Card>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <Box height="calc(31vh - 8px)">
                    {isFake && 'Fake Cameras'}
                    {!isFake && (
                      <CamerasGrid
                        cameras={cameras}
                        videoTimestamp={videoTimestamp}
                        playerState={playerState}
                        activeVideo={mainCameraUUID}
                        setActiveVideo={changeMainCameraUUID}
                        liveIndicator={!videoTz}
                        sid={sid}
                        uuid={uuid}
                        setVideoTimestamp={setVideoTimestamp}
                        setLiveIndicator={setVideoTz}
                        onItemClick={onStreamClickHandler}
                        eventId={eventId}
                      />
                    )}
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          {/* CENTER STREAM */}
          <Grid item xs={3}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card>
                  <Box height="calc(67vh)">
                    <DenseCardHeader
                      title="Datastream"
                      second={
                        eventQuery.data?.eventTimestamp
                          ? dayjs(eventQuery.data.eventTimestamp * 1000).format(
                              'MMM D, H:mm a'
                            )
                          : undefined
                      }
                    />
                    <DataStream
                      eventId={eventId}
                      isSelectingPeople={false}
                      onItemClick={onStreamClickHandler}
                      eventQuery={eventQuery}
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <Box height="calc(28vh)">
                    <LocationNotes sid={sid} eventId={eventId} />
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          {/* RIGHT RAIL */}
          <Grid item xs={3}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card>
                  <Box height="7vh" className={'fs-exclude'}>
                    <DenseCardHeader
                      title="Alarm Status"
                      second={`Account: ${subscription?.location?.account}`}
                    />
                    <AlarmStatus
                      fakeAlarm={isFake ? eventQuery.data?.alarmState : null}
                      subscription={subscription}
                      dispatch={dispatch}
                      eventId={eventId}
                      payload={payload}
                      sendMetrics={sendMetrics}
                      event={eventQuery.data}
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <Box height="21vh" display="flex" flexDirection="column">
                    <DenseCardHeader title="Event History" />
                    <AlarmEvents
                      subscription={subscription}
                      onEventClick={onAlarmClickHandler}
                      mainCameraUUID={mainCameraUUID}
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <Box height="34vh">
                    <DenseCardHeader title="Faces" />
                    <PerfectScrollbar style={{ padding: 5 }}>
                      <FaceGrid
                        userId={userId}
                        isFake={isFake}
                        fakeSid={fakeSid}
                      />
                    </PerfectScrollbar>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <Box
                    height="calc(34vh - 30px)"
                    display="flex"
                    flexDirection="column"
                  >
                    <DenseCardHeader title="Customer Info" />
                    <VerifySteps
                      isFake={isFake}
                      subscription={subscription}
                      dispatch={dispatch}
                      payload={payload.current}
                      buttonRef={buttonsStatusRef}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default VerifyRespondPage;
