import React, { useEffect, useMemo } from 'react';
import NoVideoCard from './NoVideoCard';
import { AddLiveIndicator } from './AddLiveIndicator';
import FlvPlayer from './FlvPlayer';
import HlsPlayer from './HlsPlayer';
import { KvsPlayer } from './KvsPlayer';
import { PropTypes } from 'prop-types';

const MemoizedHlsPlayer = React.memo(HlsPlayer);

export const VideoElement = ({
  type,
  url,
  uuid,
  sid,
  eventId,
  authHeader,
  retry,
  playerElRef,
  loop,
  isLive,
  isSiren,
  isTalking,
  onError,
  hasVideo,
  onScrollZoom,
  onNoVideoClick = () => {},
  style,
  video = {},
  fullDuplexAudio,
}) => {
  // Memoize config so that it doesn't appear to change (should this really be necessary?)
  const config = useMemo(() => {
    return {
      headers: { Authorization: authHeader },
      enableStashBuffer: false,
      // enableStashBuffer: true,
      // stashInitialSize: '384KB'
    };
  }, [authHeader]);

  // Force retry by changing key
  const [retryVideo, setRetryVideo] = React.useState(0);
  const handleTimeOver = () => {
    console.log('Retry video by forcing new render');
    setRetryVideo((x) => x + 1);
  };

  useEffect(() => {
    setRetryVideo((x) => x + 1);
  }, [retry]);

  const connectionStateStart =
    type === 'kvs-experiment' ? 'Downloading...' : 'Starting...';
  const [connectionState, setConnectionState] =
    React.useState(connectionStateStart);

  const connectionMsg = connectionState ? (
    <NoVideoCard
      retryTime={60}
      message={`${connectionState}`}
      onTimeOver={handleTimeOver}
      onRetry={handleTimeOver}
      onNoVideoClick={onNoVideoClick}
    />
  ) : (
    ''
  );

  //  Return Player Element
  if (type === 'flv') {
    return (
      <AddLiveIndicator onScrollZoom={onScrollZoom} hasVideo={hasVideo}>
        <FlvPlayer
          ref={playerElRef}
          url={url}
          type="flv"
          isLive={isLive}
          cors
          hasAudio
          hasVideo
          controls={false}
          config={config}
          onError={onError}
          className="aspectRatio"
          style={style}
          uuid={uuid}
          sid={sid}
        />
      </AddLiveIndicator>
    );
  } else if (type === 'kvs-experiment') {
    return (
      <>
        {connectionState && <div>{connectionMsg}</div>}
        <AddLiveIndicator hasVideo={hasVideo && !connectionState}>
          <MemoizedHlsPlayer
            playerRef={playerElRef}
            retryVideo={retryVideo}
            authHeader={authHeader}
            uuid={uuid}
            sid={sid}
            eventId={eventId}
            isTalking={isTalking}
            config={config}
            onError={onError}
            onConnectionState={setConnectionState}
            className="aspectRatio"
            style={style}
            url={url}
            isLive={isLive}
          />
        </AddLiveIndicator>
      </>
    );
  } else if (type === 'kvs') {
    return (
      <>
        {connectionState && <div>{connectionMsg}</div>}
        <AddLiveIndicator
          onScrollZoom={onScrollZoom}
          hasVideo={hasVideo && !connectionState}
        >
          <KvsPlayer
            ref={playerElRef}
            retryVideo={retryVideo}
            authHeader={authHeader}
            uuid={uuid}
            sid={sid}
            isLive={isLive}
            isTalking={isTalking}
            isSiren={isSiren}
            config={config}
            onError={onError}
            onConnectionState={setConnectionState}
            className="aspectRatio"
            style={style}
            fullDuplexAudio={fullDuplexAudio}
          />
        </AddLiveIndicator>
      </>
    );
  }
  // Default to MP4
  return (
    <AddLiveIndicator hasVideo={hasVideo}>
      <video
        style={{ ...style }}
        ref={playerElRef}
        className="react-video-player"
        loop={loop}
      >
        <source src={url} type="video/mp4" />
      </video>
    </AddLiveIndicator>
  );
};

VideoElement.propTypes = {
  type: PropTypes.string,
  authHeader: PropTypes.string,
  uuid: PropTypes.string,
  sid: PropTypes.number,
  retry: PropTypes.number,
  url: PropTypes.string,
  loop: PropTypes.bool,
  isLive: PropTypes.bool,
  hasVideo: PropTypes.bool,
  isSiren: PropTypes.bool,
  isTalking: PropTypes.bool,
  playerElRef: PropTypes.object,
  onError: PropTypes.func,
  fullDuplexAudio: PropTypes.bool,
};
