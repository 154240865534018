import moment from 'moment';
import { PcsEvent } from 'src/models';

export const getDefaultMessage = ({ camera, agent, date, time }) =>
  `<p>Hello,<br/><br/>We reviewed a motion event detected by ${camera}" camera on ${date} at ${time}. We concluded there wasn’t any reason to alarm you. If we can be of any further help, or make things better let us know <a href="https://98475.co1.qualtrics.com/jfe/form/SV_3WNsFF7m0PhHG0C">here</a><p><br/></p><p>Best,<br/>${agent}<br/>SimpliSafe Monitoring Specialist</p>`;

export const createEmailTemplate = ({
  context,
  customMessage,
  removeDefaultDateTime = false,
  removeLastCompliments = false,
  disarmSystem = '',
  prefix = '',
}) => ({
  getSubject: ({ time, date }) => `${context} on ${date} at ${time}`,
  getMessage: ({ camera, date, time, agent }) => `<p>Hello,<br/><br/>${prefix}${
    removeDefaultDateTime
      ? ''
      : `We reviewed a motion event detected by the "${camera}" camera on ${date} at ${time}. `
  }${customMessage
    .replace('[date]', date)
    .replace('[time]', time)
    .replace('[camera]', camera)}\n
      ${
        disarmSystem === undefined
          ? 'We’ll keep an eye on it until you get home.'
          : disarmSystem
      }<br/><br/>${
    removeLastCompliments
      ? ''
      : `If we can be of any further help, or make things better
      let us know <a href="https://98475.co1.qualtrics.com/jfe/form/SV_3WNsFF7m0PhHG0C">here</a><p><br/></p>`
  }
      <p>Best,<br/>${agent}<br/>SimpliSafe Monitoring Specialist</p>`,
});

export const FAMILIAR_PERSON_ON_PROPERTY_TEMPLATE = createEmailTemplate({
  context: 'Familiar Face on Property',
  customMessage:
    'A person was on your property. They appeared to match a familliar face. This motion event was detected by your [camera] camera on [date] at [time].',
  removeDefaultDateTime: true,
});

export const UNFAMILIAR_PERSON_ON_PROPERTY_DELIVERY_TEMPLATE =
  createEmailTemplate({
    context: 'Delivery',
    customMessage:
      'A mail, package or food delivery was dropped off on your property. This motion event was detected by your [camera] camera on [date] at [time].',
    disarmSystem: undefined,
    removeDefaultDateTime: true,
  });

export const UNFAMILIAR_PERSON_ON_PROPERTY_ENTRY_TEMPLATE = createEmailTemplate(
  {
    context: 'Spoke with Person on Property',
    customMessage:
      'We spoke with a person on your property who identified themselves as "Name". They said "insert quote". This motion event was captured by your [camera] camera on [date] at [time].',
    removeDefaultDateTime: true,
  }
);

export const UNFAMILIAR_PERSON_ON_PROPERTY_OUTDOOR_SERVICE_TEMPLATE =
  createEmailTemplate({
    context: 'Outdoor Service on Property',
    customMessage:
      'A service is being performed on your property. We were unable to identify the person because they were either out of audible range or background noise. This motion event was detected by your [camera] camera at [time].',
    removeDefaultDateTime: true,
  });

export const IDENTIFIED_PERSON_ON_PROPERTY_OUTDOOR_SERVICE_TEMPLATE =
  createEmailTemplate({
    context: 'Outdoor Service on Property',
    customMessage:
      'A service is being performed on your property. The person identified themselves as "Insert Name" with "{Company Name}". This motion event was detected by your [camera] camera on [date] at [time].',
    removeDefaultDateTime: true,
  });

export const UNIFORMED_AUTHORITIES_ON_PROPERTY_OUTDOOR_SERVICE_TEMPLATE =
  createEmailTemplate({
    context: 'Uniformed Authorities on Property',
    customMessage:
      'Uniformed authorities were on your property. This motion event was detected by your [camera] camera on [date] at [time].',
    removeDefaultDateTime: true,
  });

export const UNATTENDED_MINOR_ON_PROPERTY_OUTDOOR_SERVICE_TEMPLATE =
  createEmailTemplate({
    context: 'Unattended Minor on Property',
    customMessage:
      'An unattended minor was on your property. This motion event was detected by your [camera] camera on [date] at [time].',
    removeDefaultDateTime: true,
  });

export const NON_THREATENING_DETERRENCE_OUTDOOR_SERVICE_TEMPLATE =
  createEmailTemplate({
    context: 'Asked Unfamiliar Person to Leave',
    customMessage:
      'An unfamiliar person was on your property. We instructed them to leave and they walked out of view. This motion event was detected by your [camera] camera on [date] at [time].',
    removeDefaultDateTime: true,
  });

export const UNFAMILIAR_PERSON_ON_PROPERTY_OUT_OF_RANGE_TEMPLATE =
  createEmailTemplate({
    context: 'Unfamiliar Person on Property',
    customMessage:
      'An unfamiliar person was on your property on [date] at [time]. This motion event was detected by your [camera] camera.',
    removeDefaultDateTime: true,
  });

export const UNFAMILIAR_PERSON_ON_PROPERTY_NO_RESPONSE_TEMPLATE =
  createEmailTemplate({
    context: 'Unfamiliar Person on Property',
    customMessage:
      'An unfamiliar person was on your property on [date] at [time]. This motion event was detected by your [camera] camera.',
    removeDefaultDateTime: true,
  });

export const UNFAMILIAR_PERSON_ON_PROPERTY_CAMERA_FAILURE_TEMPLATE =
  createEmailTemplate({
    context: 'Unfamiliar Person on Property',
    customMessage:
      'An unfamiliar person was on your property in a recorded video. This motion event was detected by your [camera] camera on [date] at [time].',
    removeDefaultDateTime: true,
  });
export const UNFAMILIAR_PERSON_ON_PROPERTY_OTHER_TEMPLATE = createEmailTemplate(
  {
    context: 'Unfamiliar Person on Property',
    customMessage:
      'An unfamiliar person was on your property on [date] at [time]. This motion event was detected by your [camera] camera.',
    removeDefaultDateTime: true,
  }
);

export const CAR_ARRIVES_ON_PROPERTY_OUTDOOR_SERVICE_TEMPLATE =
  createEmailTemplate({
    context: 'Car Stopped on Property',
    customMessage:
      'A car stopped on your property at [time]. This motion event was detected by your [camera] camera.',
    removeDefaultDateTime: true,
  });

export const COMMON_EVENT_TEMPLATE = createEmailTemplate({
  context: 'Common Activity',
  customMessage:
    'Common activity was verified at [time]. No issues were observed. This event was detected by your [camera] camera.',
  removeDefaultDateTime: true,
});

export const UNFAMILIAR_PERSON_ON_PROPERTY_ESCALATION_TEMPLATE =
  createEmailTemplate({
    context: 'Escalation',
    customMessage: 'We are engaging with an unverified person at your home.',
    removeLastCompliments: true,
  });

export const URGENT_EVENTS_TEMPLATE = createEmailTemplate({
  context: 'Urgent Events',
  customMessage:
    'There is an Urgent Event occuring on your property. We have {describe agent actions, i.e. called emergency contacts}.',
  prefix: 'URGENT: ',
  removeLastCompliments: true,
  disarmSystem: '',
});

export const EMERGENCY_EVENTS_TEMPLATE = createEmailTemplate({
  context: 'Emergency Events',
  customMessage:
    'There is an Emergency event occuring on your property. We have {describe agent actions, i.e. dispatched P/F/M}.',
  prefix: 'EMERGENCY: ',
  removeLastCompliments: true,
  disarmSystem: '',
});

export const SYSTEM_DISARM_TEMPLATE = createEmailTemplate({
  context: 'System Disarm',
  customMessage: 'Your system was disarmed on [date] at [time].',
  disarmSystem: '',
  removeDefaultDateTime: true,
});

export const getMailTemplate = ({
  camera,
  agent,
  date,
  time,
  dispositionSubtype,
}) => {
  if (dispositionSubtype === 'default') {
    return {
      getSubject: () => 'Monitoring Summary',
      getMessage: () => getDefaultMessage({ camera, agent, date, time }),
    };
  }
  if (dispositionSubtype === 'common_event')
    return {
      getSubject: () => COMMON_EVENT_TEMPLATE.getSubject({ time, date }),
      getMessage: () =>
        COMMON_EVENT_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
  if (dispositionSubtype === 'familiar_person_on_property')
    return {
      getSubject: () =>
        FAMILIAR_PERSON_ON_PROPERTY_TEMPLATE.getSubject({ time, date }),
      getMessage: () =>
        FAMILIAR_PERSON_ON_PROPERTY_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
  if (dispositionSubtype === 'unfamiliar_person_on_property_delivery')
    return {
      getSubject: () =>
        UNFAMILIAR_PERSON_ON_PROPERTY_DELIVERY_TEMPLATE.getSubject({
          time,
          date,
        }),
      getMessage: () =>
        UNFAMILIAR_PERSON_ON_PROPERTY_DELIVERY_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
  if (dispositionSubtype === 'unfamiliar_person_on_property_entry')
    return {
      getSubject: () =>
        UNFAMILIAR_PERSON_ON_PROPERTY_ENTRY_TEMPLATE.getSubject({ time, date }),
      getMessage: () =>
        UNFAMILIAR_PERSON_ON_PROPERTY_ENTRY_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
  if (dispositionSubtype === 'unfamiliar_person_on_property_outdoor_service')
    return {
      getSubject: () =>
        UNFAMILIAR_PERSON_ON_PROPERTY_OUTDOOR_SERVICE_TEMPLATE.getSubject({
          time,
          date,
        }),
      getMessage: () =>
        UNFAMILIAR_PERSON_ON_PROPERTY_OUTDOOR_SERVICE_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
  if (dispositionSubtype === 'outdoor_service_identified_person')
    return {
      getSubject: () =>
        IDENTIFIED_PERSON_ON_PROPERTY_OUTDOOR_SERVICE_TEMPLATE.getSubject({
          time,
          date,
        }),
      getMessage: () =>
        IDENTIFIED_PERSON_ON_PROPERTY_OUTDOOR_SERVICE_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
  if (dispositionSubtype === 'uniformed_authorities')
    return {
      getSubject: () =>
        UNIFORMED_AUTHORITIES_ON_PROPERTY_OUTDOOR_SERVICE_TEMPLATE.getSubject({
          time,
          date,
        }),
      getMessage: () =>
        UNIFORMED_AUTHORITIES_ON_PROPERTY_OUTDOOR_SERVICE_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
  if (dispositionSubtype === 'unattended_minor')
    return {
      getSubject: () =>
        UNATTENDED_MINOR_ON_PROPERTY_OUTDOOR_SERVICE_TEMPLATE.getSubject({
          time,
          date,
        }),
      getMessage: () =>
        UNATTENDED_MINOR_ON_PROPERTY_OUTDOOR_SERVICE_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
  if (dispositionSubtype === 'unknown_person_out_of_range')
    return {
      getSubject: () =>
        UNFAMILIAR_PERSON_ON_PROPERTY_OUT_OF_RANGE_TEMPLATE.getSubject({
          time,
          date,
        }),
      getMessage: () =>
        UNFAMILIAR_PERSON_ON_PROPERTY_OUT_OF_RANGE_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
  if (dispositionSubtype === 'non_threatening_deterrence')
    return {
      getSubject: () =>
        NON_THREATENING_DETERRENCE_OUTDOOR_SERVICE_TEMPLATE.getSubject({
          time,
          date,
        }),
      getMessage: () =>
        NON_THREATENING_DETERRENCE_OUTDOOR_SERVICE_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
  if (dispositionSubtype === 'unknown_person_no_response')
    return {
      getSubject: () =>
        UNFAMILIAR_PERSON_ON_PROPERTY_NO_RESPONSE_TEMPLATE.getSubject({
          time,
          date,
        }),
      getMessage: () =>
        UNFAMILIAR_PERSON_ON_PROPERTY_NO_RESPONSE_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
  if (dispositionSubtype === 'unknown_person_camera_failure')
    return {
      getSubject: () =>
        UNFAMILIAR_PERSON_ON_PROPERTY_CAMERA_FAILURE_TEMPLATE.getSubject({
          time,
          date,
        }),
      getMessage: () =>
        UNFAMILIAR_PERSON_ON_PROPERTY_CAMERA_FAILURE_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
  if (dispositionSubtype === 'car_on_property')
    return {
      getSubject: () =>
        CAR_ARRIVES_ON_PROPERTY_OUTDOOR_SERVICE_TEMPLATE.getSubject({
          time,
          date,
        }),
      getMessage: () =>
        CAR_ARRIVES_ON_PROPERTY_OUTDOOR_SERVICE_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
  if (dispositionSubtype === 'unknown_person_other')
    return {
      getSubject: () =>
        UNFAMILIAR_PERSON_ON_PROPERTY_OTHER_TEMPLATE.getSubject({
          time,
          date,
        }),
      getMessage: () =>
        UNFAMILIAR_PERSON_ON_PROPERTY_OTHER_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
  if (dispositionSubtype === 'unfamiliar_person_on_property_escalation')
    return {
      getSubject: () =>
        UNFAMILIAR_PERSON_ON_PROPERTY_ESCALATION_TEMPLATE.getSubject({
          time,
          date,
        }),
      getMessage: () =>
        UNFAMILIAR_PERSON_ON_PROPERTY_ESCALATION_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
  if (dispositionSubtype === 'urgent_events')
    return {
      getSubject: () => URGENT_EVENTS_TEMPLATE.getSubject({ time, date }),
      getMessage: () =>
        URGENT_EVENTS_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
  if (dispositionSubtype === 'emergency_events')
    return {
      getSubject: () => EMERGENCY_EVENTS_TEMPLATE.getSubject({ time, date }),
      getMessage: () =>
        EMERGENCY_EVENTS_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
  if (dispositionSubtype === 'system_disarm')
    return {
      getSubject: () => SYSTEM_DISARM_TEMPLATE.getSubject({ time, date }),
      getMessage: () =>
        SYSTEM_DISARM_TEMPLATE.getMessage({
          camera,
          date,
          time,
          agent,
        }),
    };
};

export const getEmail = (
  dispositionType: string,
  rep: { firstName: string },
  event: PcsEvent
) => {
  const mailFormatter = getMailTemplate({
    camera: event.info,
    agent: rep.firstName,
    date: moment.unix(event.eventTimestamp).format('MM/DD/YYYY'),
    time: moment.unix(event.eventTimestamp).format('h:mm:ss a'),
    dispositionSubtype: dispositionType,
  });
  const subject = mailFormatter.getSubject();
  const message = mailFormatter.getMessage();
  return [subject, message];
};
