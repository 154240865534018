import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { usePcsClient } from 'src/context/pcs-auth-context';

interface Rep {
  id: string;
  email: string;
  is_active: boolean;
  is_verified: boolean;
  firstName: string;
  lastName: string;
  user_id: number;
}

interface UpdateRep {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
}

interface QueueSettings {
  id: string;
  limit: number;
  acceptNew: boolean;
  autoExpire: number;
  pcsStatusThreshold: number;
  threatLevel: number;
  lookback: number;
  armstate: string;
}

export function useGetRepMe() {
  const client = usePcsClient();
  const query = useQuery(['reps', 'me'], () => client(`reps/me`), {});
  return query;
}

export function useUpdateRepMe() {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (data: {
      firstName: string;
      lastName: string;
      email: string;
      user_id: number;
    }) =>
      client(`reps/me`, {
        method: 'PATCH',
        data: { ...data },
      }),
    {
      onSuccess: (data, variables) => {
        cache.invalidateQueries(['reps', 'me']);
        enqueueSnackbar(`Successfully saved ${variables.firstName}`, {
          variant: 'success',
        });
      },
      onError: (error, variables) => {
        enqueueSnackbar(`Error saving ${variables.firstName}: ${error}`, {
          variant: 'error',
        });
      },
    }
  );
  return mutation;
}

export function useGetRepsList(
  skip: number = 0,
  limit: number = 10,
  searchValue: string = ''
) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, error } = useQuery({
    queryKey: ['reps', { skip, limit, searchValue }],
    queryFn: () =>
      client(
        `manage-reps/reps-list/?skip=${skip}&limit=${limit}&searchBy=${searchValue}`
      ),
    onError: (err) => {
      enqueueSnackbar(`Endpoint: manage-reps/reps-list/. ${err}`, {
        variant: 'error',
      });
    },
    refetchInterval: 20000,
  });
  return {
    data,
    isLoading,
    error,
  };
}

export function useGetRepsCount(
  skip: number = 0,
  limit: number = 10,
  searchValue: string = ''
) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, error } = useQuery({
    queryKey: ['reps', { skip, limit, searchValue }],
    queryFn: () => client(`manage-reps/count?searchBy=${searchValue}`),
    onError: (err) => {
      enqueueSnackbar(`Endpoint: manage-reps/reps-count/. ${err}`, {
        variant: 'error',
      });
    },
    refetchInterval: 20000,
  });
  return {
    data,
    isLoading,
    error,
  };
}

export function useGetRepLog(params: any) {
  const client = usePcsClient();
  const query = useQuery(['rep_log', params], () =>
    client(`reps/${params.repId}`, { params: params })
  );
  return query;
}

export function useForceLogOut() {
  const client = usePcsClient();
  const mutation = useMutation((user_id: number) =>
    client(`manage-reps/force-rep-logout`, {
      method: 'POST',
      params: {
        user_id: user_id,
      },
    })
  );

  return mutation;
}

export function useGetRepById(repsId) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, error } = useQuery(
    ['reps', 'id'],
    () => client(`reps/${repsId}`),
    {
      onError: (errormes) => {
        enqueueSnackbar(`Endpoint: reps/repId. ${errormes}`, {
          variant: 'error',
        });
      },
    }
  );
  return {
    data,
    isLoading,
    error,
  };
}

export function useChangeActiveStatus() {
  const client = usePcsClient();
  const mutation = useMutation((data: { id: string; rep_status: boolean }) =>
    client(`manage-reps/update-status/`, {
      method: 'POST',
      params: {
        id: data.id,
        rep_status: data.rep_status,
      },
    })
  );
  return mutation;
}

export function useChangeRole() {
  const client = usePcsClient();
  const mutation = useMutation((data: { id: string; role: string }) =>
    client(`manage-reps/change-role/`, {
      method: 'POST',
      params: {
        id: data.id,
        role: data.role,
      },
    })
  );
  return mutation;
}

export function useChangeUserInfo() {
  const client = usePcsClient();
  const mutation = useMutation((UpdateRep: UpdateRep) =>
    client(`manage-reps/update-user-info/`, {
      method: 'POST',
      data: UpdateRep,
    })
  );
  return mutation;
}

export function useSaveQueueSittings() {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (settings: QueueSettings) =>
      client(`manage-reps/change-queue-settings/`, {
        method: 'POST',
        data: settings,
      }),
    {
      onSuccess: () => {
        enqueueSnackbar(`Successfully saved `, {
          variant: 'success',
        });
      },
      onError: (error) => {
        enqueueSnackbar(`Error saving: ${error}`, {
          variant: 'error',
        });
      },
    }
  );
  return mutation;
}

export function useGetQueueSettings(user_id: string) {
  const client = usePcsClient();
  const isQueryEnabled = !!user_id;
  const query = useQuery(
    ['queue_sittings', user_id],
    () => client(`manage-reps/get-queue-settings/${user_id}`),
    { enabled: isQueryEnabled }
  );
  return query;
}
