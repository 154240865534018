import Button, { ButtonProps } from '@material-ui/core/Button/Button';
import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';

export function PromiseButton({
  onClick,
  children,
  ...props
}: ButtonProps & {
  onClick: (
    ev?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | Promise<void>;
}) {
  const [loading, setLoading] = useState(false);
  const handleClick = async (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setLoading(true);
    try {
      await onClick(ev);
    } catch (e) {
      // ignore this.
    }
    setLoading(false);
  };
  return (
    <Button {...props} onClick={handleClick}>
      {loading && (
        <CircularProgress
          size={16}
          style={{ marginRight: 10 }}
          color="inherit"
        />
      )}
      {children}
    </Button>
  );
}
