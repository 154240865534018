import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { Outlet } from 'react-router-dom';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    padding: '2rem',
  },
}));

const ExternalLayout = () => {
  const classes = useStyles();
  return (
    <Page className={classes.root} title="Cx report">
      <TopBar title="External access" />
      <Outlet />
    </Page>
  );
};

export default ExternalLayout;
