import React from 'react';
import flvjs from 'flv.js';

// DEBUG FLAGS
const debugFLV = false;
const rendering = false;

if (rendering && process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

// DEBUG setup

if (!debugFLV) {
  flvjs.LoggingControl.enableAll = false;
  flvjs.LoggingControl.enableDebug = false;
  flvjs.LoggingControl.enableVerbose = false;
  flvjs.LoggingControl.enableInfo = false;
  flvjs.LoggingControl.enableWarn = false;
  flvjs.LoggingControl.enableError = false;
}
