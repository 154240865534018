// src/hooks/useQueueTimeMetrics.ts
import {
  useEffect,
  useRef,
  useContext,
  useMemo,
  useCallback,
  MutableRefObject,
} from 'react';
import MetricsModule from 'src/utils/MetricsModule';
import { MetricsContext } from 'src/context/Metrics-context';

const INTERVAL_TIMER = 15 * 60 * 1000; // 15 mins

interface QueueTimeMetricsProps {
  acceptNew: boolean;
  pcsStatusThreshold: number;
  threatLevel: number;
  armstate: string;
  eventClickedRef: MutableRefObject<number | null>;
  postInterval?: number;
}

export const useQueueTimeMetrics = ({
  acceptNew,
  pcsStatusThreshold,
  threatLevel,
  armstate,
  eventClickedRef,
  postInterval = INTERVAL_TIMER,
}: QueueTimeMetricsProps) => {
  const metrics = useMemo(() => new MetricsModule('queue time'), []);
  const [, dispatch] = useContext(MetricsContext);
  const payload = useRef(metrics.payload());

  const startTimeRef = useRef<number>(Date.now());
  const pausedTimeRef = useRef<number>(0);
  const pauseStartTimeRef = useRef<number | null>(null);

  const pauseTimer = useCallback(() => {
    if (pauseStartTimeRef.current === null) {
      pauseStartTimeRef.current = Date.now();
    }
  }, []);

  const resumeTimer = useCallback(() => {
    if (pauseStartTimeRef.current !== null) {
      pausedTimeRef.current += Date.now() - pauseStartTimeRef.current;
      pauseStartTimeRef.current = null;
    }
  }, []);

  const resetTimer = useCallback(() => {
    startTimeRef.current = Date.now();
    pausedTimeRef.current = 0;
    pauseStartTimeRef.current = null;
  }, []);

  const getElapsedTime = useCallback(() => {
    let elapsedTime = Date.now() - startTimeRef.current - pausedTimeRef.current;
    if (pauseStartTimeRef.current !== null) {
      elapsedTime -= Date.now() - pauseStartTimeRef.current;
    }
    return Math.floor(elapsedTime / 1000);
  }, []);

  const postMetric = useCallback(() => {
    payload.current.elapsedTime = getElapsedTime();
    dispatch({
      type: 'SEND',
      payload: { ...payload.current, eventID: eventClickedRef.current },
    });
    resetTimer();
  }, [dispatch, eventClickedRef, getElapsedTime, resetTimer]);

  // Update metrics data when dependencies change
  useEffect(() => {
    metrics.fetchNewData(acceptNew, pcsStatusThreshold, threatLevel, armstate);
    payload.current = metrics.payload();
  }, [acceptNew, pcsStatusThreshold, threatLevel, armstate, metrics]);

  useEffect(() => {
    if (!acceptNew) {
      postMetric();
      pauseTimer();
    } else {
      resumeTimer();
    }

    const timer = setInterval(() => {
      postMetric();
    }, postInterval);

    window.addEventListener('beforeunload', postMetric);

    return () => {
      clearInterval(timer);
      window.removeEventListener('beforeunload', postMetric);
    };
  }, [postInterval, postMetric, acceptNew, resumeTimer, pauseTimer]);

  // if leaving page then post the metric
  useEffect(() => {
    return () => {
      postMetric();
    };
  }, [postMetric]);

  return {
    metrics,
    payload,
  };
};
