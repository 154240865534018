import React from 'react';
import { Typography } from '@material-ui/core';
import { Action, PcsEvent, PcsStatus } from 'src/types';

/** Format HTML email with basic styling -- NOTE: probably better done on the backend (moved there) */
export function formatEmail(body: string) {
  return `
  <html>
    <head>
      <style type="text/css">
        .ExternalClass, .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td, .ExternalClass div {
          line-height: 100%;
        }
        p {margin:0; padding:0; margin-bottom:0;line-height: 100%;}
      </style>
    </head>
    <body>${body}</body>
  </html>`;
}

/** Convert HTML to plain text */
export function htmlToText(html: string) {
  // Preserve line breaks
  html = html.replace(/<p>/g, '');
  html = html.replace(/<\/p>/g, '\n');

  // Remove all tags
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

export const capitalize = (word: string) => {
  return word ? word.charAt(0).toUpperCase() + word.slice(1) : '';
};

export const formatHandlerFromEmail = (email: string | null, short = false) => {
  if (short) {
    return capitalize(
      email?.substring(0, email.indexOf('@')).substring(0, email.indexOf('.'))
    );
  } else {
    return email?.substring(0, email.indexOf('@'));
  }
};

export const formatSStatus = (sStatus: number) => {
  if (sStatus === 0) return <Typography color="textSecondary">New</Typography>;
  if (sStatus === 10)
    return <Typography color="textSecondary">Practice Mode</Typography>;
  if (sStatus === 20)
    return <Typography color="textSecondary">Active</Typography>;
  if (sStatus === 5)
    return <Typography color="textSecondary">Suspended</Typography>;
  if (sStatus === -10)
    return <Typography color="textSecondary">Canceled</Typography>;

  // Unknown Status
  return <Typography color="textSecondary">Unknown ({sStatus})</Typography>;
};

export const mapPcsEventStatus = (event: PcsEvent) => {
  if (event.pcs_status === PcsStatus.not_monitored) return 'Not monitored';
  if (event.pcs_status === PcsStatus.canceled) return 'Canceled';
  if (event.pcs_status === PcsStatus.operator_handled)
    return 'Operator Handled';
  if (event.pcs_status === PcsStatus.pcs_handled) return 'PCS Handled';
  if (event.pcs_status === PcsStatus.expired) return 'Expired';
  if (event.pcs_status === PcsStatus.new) return 'New';
  if (event.pcs_status === PcsStatus.watch) return 'Watching';
  if (event.pcs_status === PcsStatus.verify) return 'Verifying';
  if (event.pcs_status === PcsStatus.dispatch) return 'Dispatching';
  if (event.pcs_status === PcsStatus.follow_up) return 'Following up';
  if (event.pcs_status === PcsStatus.hold) return 'Hold';
  return 'Unknown';
};

// This is largely taken from User App, not used yet?
export const formatPcsEventStatus = (event: PcsEvent) => {
  if (event.pcs_status === PcsStatus.operator_handled) {
    if (event.disposition?.action === Action.canceled_user_app) {
      return (
        <Typography color="textSecondary">{` User dismissed event`}</Typography>
      );
    } else {
      return (
        <Typography color="textSecondary">
          {` ${formatHandlerFromEmail(
            event.handler.email as string
          )} handled (${event.disposition?.action || ''})`}
        </Typography>
      );
    }
  }

  if (event.pcs_status === PcsStatus.pcs_handled) {
    if (event.disposition?.action === Action.canceled_alarm_state) {
      return (
        <Typography color="textSecondary">
          {` Alarm state (${event.alarmState}) not monitored`}
        </Typography>
      );
    } else {
      return (
        <Typography color="textSecondary">
          {` Event disregarded (${event.disposition?.action})`}
        </Typography>
      );
    }
  }
  if (event.pcs_status === PcsStatus.expired)
    return <Typography color="textSecondary">{` Event expired`}</Typography>;
  if (event.pcs_status === PcsStatus.new)
    return <Typography color="textSecondary">{` New event queued`}</Typography>;
  if (event.pcs_status === PcsStatus.watch)
    return (
      <Typography color="textSecondary">
        {` Guard ${formatHandlerFromEmail(
          event.handler?.email as string
        )} reviewing video`}
      </Typography>
    );
  if (event.pcs_status === PcsStatus.verify)
    return (
      <Typography color="textSecondary">
        {` Guard ${formatHandlerFromEmail(
          event.handler?.email as string
        )} verifying access`}
      </Typography>
    );
  if (event.pcs_status === PcsStatus.dispatch)
    return (
      <Typography color="textSecondary">
        {` Guard ${formatHandlerFromEmail(
          event.handler?.email as string
        )} dispatching police`}
      </Typography>
    );
  if (event.pcs_status === PcsStatus.follow_up)
    return (
      <Typography color="textSecondary">
        {` Guard ${formatHandlerFromEmail(
          event.handler?.email as string
        )} finalizing event`}
      </Typography>
    );
  if (event.pcs_status === PcsStatus.canceled)
    return <Typography color="textSecondary">{` Canceled`}</Typography>;
  if (event.pcs_status === PcsStatus.not_monitored)
    return <Typography color="textSecondary">{` Not monitored`}</Typography>;
  // Unknown Status
  return (
    <Typography color="textSecondary">
      {` Unknown: (${event.pcs_status})`}
    </Typography>
  );
};
