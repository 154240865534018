import { Box, Card, Container, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
// import Overlay from 'src/components/Overlay/Overlay';
import DenseCardHeader from 'src/components/DenseCardHeader';
import Page from 'src/components/Page';
import PlaceHolder from './PlaceHolder';
import { WebCamera } from './WebCamera';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(0),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  // const [modelName, setModelName] = React.useState('posenet');

  useEffect(() => {
    setTimeout(async () => {
      // setModelName('bodypix');
      console.log('Timmout!');
    }, 7000);
  }, []);

  return (
    <Page className={classes.root} title="Verify & Respond">
      <Container maxWidth={false}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Card>
              <DenseCardHeader title="Player 1" />
              <WebCamera id="player1" />
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <DenseCardHeader title="Overlay controls" />
              {/*<Overlay videoId="player1" modelName="cocossd" />*/}
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <DenseCardHeader title="Player 2" />
              <WebCamera id="player2" />
              {/* <Overlay videoId="player2" defaultModelName={modelName} /> */}
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Box height="10vh">
              <PlaceHolder height="35vh" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
