import { useLocation } from 'react-router';

export function useQueryParams() {
  const location = useLocation();
  const pathname = location.pathname;

  // Parse all params
  const allParams = new URLSearchParams(location.search);

  // Get frequently used (+ converts to int)
  const userId = +allParams.get('userId');
  const sid = +allParams.get('sid');
  const eventId = +allParams.get('eventId');
  const uuid = allParams.get('uuid');
  return { pathname, allParams, userId, sid, eventId, uuid };
}
