import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  spinnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  spinner: {},
  message: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export const LoadingSpinner = ({ message }) => {
  const classes = useStyles();
  return (
    <Box className={classes.spinnerContainer}>
      <CircularProgress className={classes.spinner} />
      {message && (
        <Box m={2} p={2} borderRadius={10} className={classes.message}>
          <Typography variant="subtitle1" color="textSecondary">
            {message}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

LoadingSpinner.propTypes = {
  message: PropTypes.string,
};
