import React from 'react';
import AppLayout from 'src/layouts/AppLayout/AppLayout';
import AccountView from 'src/views/account/AccountView';
import SettingsView from 'src/views/settings/SettingsView';
import { SubscriberCreateView } from './views/subscribers/SubscriberCreateView';
import { SubscriberListView } from 'src/views/subscribers/SubscriberViews';
import VerifyRespondPage from 'src/views/verify-respond/VerifyRespondPage';
import { SubscriberDetailsView } from './views/subscribers/SubscriberDetailsView';
import MonitorView from './views/queue/MonitorView';
import EventListView from './views/events/EventListView';
import LogsListView from './views/logs/LogsListView';
import { RepsListView, RepsDetailsView } from './views/people/PeopleListView';

import WebcamView from './views/dev/WebcamView/index';
import VideoView from './views/dev/VideoView/index';
import CameraView from './views/subscribers/CameraView';
import NotFoundView from './views/errors/NotFoundView';
import { Navigate } from 'react-router';
import FollowUpView from './views/follow-up/FollowUpView';
import DashboardAlternativeView from './views/reports/DashboardAlternativeView';
import { Auth0CallbackView } from './views/subscribers/Auth0CallbackView';
import MetricsContextProvider from './context/Metrics-context';

import InviteListView from 'src/views/invites/InviteListView';
import CxReport from './views/external/CxReport';
import ExternalLayout from './layouts/ExternalLayout';

const routes = [
  {
    // Auth0 callback url
    path: 'callback',
    element: <Auth0CallbackView />,
  },
  {
    path: 'app',
    element: <AppLayout title="PCS" />,
    children: [
      // Old Samples
      { path: 'account', element: <AccountView /> },
      { path: 'settings', element: <SettingsView /> },

      // Redirects, 404
      { path: 'login', element: <Navigate to="/app/dashboard" replace /> },
      { path: '*', element: <NotFoundView /> },

      // Admin
      {
        path: 'dashboard',
        element: <DashboardAlternativeView />,
      },
      // { path: 'reps', element: <RepListView /> },

      // Subs and Setup
      { path: 'subs', element: <SubscriberListView /> },
      {
        path: 'subs/:userId',
        element: (
          <MetricsContextProvider>
            <SubscriberDetailsView />
          </MetricsContextProvider>
        ),
      },
      { path: 'subs/create', element: <SubscriberCreateView /> },
      {
        path: 'camera',
        element: (
          <MetricsContextProvider>
            <CameraView />
          </MetricsContextProvider>
        ),
      },

      // Queues
      {
        path: 'queue',
        element: (
          <MetricsContextProvider>
            <MonitorView />
          </MetricsContextProvider>
        ),
      },
      { path: 'event-list', element: <EventListView /> },

      // Handle Alarms
      // { path: 'verify-respond', element: <NoEvent /> },
      {
        path: 'verify-respond',
        element: (
          <MetricsContextProvider>
            <VerifyRespondPage />
          </MetricsContextProvider>
        ),
      },
      {
        path: 'follow-up',
        element: (
          <MetricsContextProvider>
            <FollowUpView />
          </MetricsContextProvider>
        ),
      },

      // Dev Pages
      { path: 'webcam', element: <WebcamView /> },
      { path: 'video', element: <VideoView /> },
      { path: 'cameras', element: <CameraView /> },

      //Audit
      {
        path: 'audit-list',
        element: (
          <MetricsContextProvider>
            <LogsListView />
          </MetricsContextProvider>
        ),
      },

      //Manage people
      { path: 'reps-list', element: <RepsListView /> },
      { path: 'rep/:userId', element: <RepsDetailsView /> },

      // Manage invites
      { path: 'invites', element: <InviteListView /> },
    ],
  },
  {
    path: '/external',
    element: <ExternalLayout />,
    children: [
      { path: 'cx-report', element: <CxReport /> },
      { path: '*', element: <NotFoundView /> },
    ],
  },
  {
    path: '',
    element: <AppLayout title="Not Found" />,
    children: [
      // Redirects, 404
      { path: '', element: <Navigate to="/app/dashboard" replace /> },
      { path: '*', element: <NotFoundView /> },
    ],
  },
];

export default routes;
