import { useEffect, useRef } from 'react';

export function useOnValueChange<T>(
  value: T,
  onChange: (value: T, previous: T) => Promise<void>,
  frequency = 100
) {
  const valueRef = useRef(value);
  const changesRef = useRef<Array<{ value: any; previous: any }>>([]);
  useEffect(() => {
    if (value !== valueRef.current) {
      changesRef.current.push({ value, previous: valueRef.current });
      valueRef.current = value;
    }
  }, [value]);

  useEffect(() => {
    let running = false;
    const interval = setInterval(async () => {
      if (!running) {
        running = true;
        const list: Array<{ value: any; previous: any }> =
          changesRef.current.splice(0);

        if (list.length > 0) {
          const previousValue = list[0].previous;
          const currentValue = list[list.length - 1].value;
          if (currentValue !== previousValue) {
            await onChange(currentValue, previousValue);
          }
        }
        running = false;
      }
    }, frequency);
    return () => clearInterval(interval);
  }, []);
}
