import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, CardHeader, Divider, makeStyles } from '@material-ui/core';
import Chart from './Chart';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  chart: {
    height: 300,
  },
}));

const EventsHandled = ({ className, ...rest }) => {
  const classes = useStyles();
  const stats = {
    thisYear: [18, 16, 5, 8, 3, 14, 14, 16, 17, 19, 18, 20],
    lastYear: [12, 11, 4, 6, 2, 9, 9, 10, 11, 12, 13, 13],
  };
  const labels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Events Handled" />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={700} pt={4} pr={2} pl={2}>
          <Chart className={classes.chart} data={stats} labels={labels} />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

EventsHandled.propTypes = {
  className: PropTypes.string,
};

export default EventsHandled;
