import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles,
  Typography,
  IconButton,
  styled,
} from '@material-ui/core';

// Maybe lift this up to calling component?
// import { events } from './data';
import {
  useAlarmEvents,
  useEventDispositionInfo,
} from 'src/hooks/useAlarmEvents';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { useQueryParams } from 'src/utils/useQueryParams';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import MessageOutlined from '@material-ui/icons/MessageOutlined';
import PostAdd from '@material-ui/icons/PostAdd';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => {
  return {
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: '50vw',
      maxHeight: '50vh',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  };
});

const getDispositionIcon = (dispositionInfo) => {
  if (dispositionInfo === undefined) {
    return '';
  }

  // Common Events now send push/email notifications, and these happen very frequently
  // Do not show notifications from these events for 'canceled_rep' dispositions to make finding other dispositions easier
  if (
    dispositionInfo.email &&
    dispositionInfo.notification &&
    dispositionInfo?.disposition?.action !== 'canceled_rep'
  ) {
    return (
      <HtmlTooltip
        title={
          <div
            dangerouslySetInnerHTML={{ __html: dispositionInfo.email.body }}
          />
        }
        className={'fs-exclude'}
      >
        <IconButton>
          <PostAdd />
        </IconButton>
      </HtmlTooltip>
    );
  }

  if (dispositionInfo.email) {
    return (
      <HtmlTooltip
        title={
          <div
            dangerouslySetInnerHTML={{ __html: dispositionInfo.email.body }}
          />
        }
        className={'fs-exclude'}
      >
        <IconButton>
          <EmailOutlined />
        </IconButton>
      </HtmlTooltip>
    );
  }

  if (dispositionInfo.notification) {
    return (
      <HtmlTooltip title={dispositionInfo.notification.title}>
        <IconButton>
          <MessageOutlined />
        </IconButton>
      </HtmlTooltip>
    );
  }

  return '';
};

const useStyles = makeStyles(() => ({
  root: {},
  showPointer: {
    cursor: 'pointer',
  },
  disabledRowColor: {
    color: 'grey',
  },
  selectedEventColor: {
    backgroundColor: 'grey',
  },
}));

const AlarmEvents = ({ subscription, onEventClick, mainCameraUUID }) => {
  const classes = useStyles();
  const queryParams = useQueryParams();
  const sid = subscription ? subscription.sid : queryParams.sid;
  const uid = subscription ? subscription.uid : queryParams.userId;
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { events, isLoading, error } = useAlarmEvents({
    sid,
    userId: uid,
  });

  const { data: dispositionInfo } = useEventDispositionInfo({
    eventIds: (events ?? []).map((x) => x.eventId).sort(),
    isEnabled: !!events,
  });

  const dispositionMap = {};
  (dispositionInfo ?? []).forEach((x) => (dispositionMap[x.eventId] = x));

  useEffect(() => {
    if (mainCameraUUID) {
      setSelectedEvent(null);
    }
  }, [mainCameraUUID]);

  if (isLoading) return <LoadingSpinner />;
  if (error) return <Typography>{error}</Typography>;
  if (!events) return <Typography>No events found (yet)...</Typography>;

  // Check if Alarm Event is from past 60 minutes
  const isWithinPastMinutes = (timeStamp) => {
    const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
    const differenceInMinutes = Math.floor((currentTime - timeStamp) / 60);
    if (differenceInMinutes <= 60) {
      return true;
    } else {
      return false;
    }
  };

  const handleClick = (e, eventId, eventTimestamp) => {
    if (!isWithinPastMinutes(eventTimestamp)) {
      setSelectedEvent(null);
    } else {
      setSelectedEvent(eventId);
    }

    if (isWithinPastMinutes(eventTimestamp) || !eventTimestamp) {
      onEventClick(eventId, eventTimestamp.toString());
    }
  };

  if (!subscription)
    return <LoadingSpinner message="Loading subscription from Yoda" />;

  return (
    <PerfectScrollbar>
      <Box minWidth={100}>
        <Table size="small" padding="checkbox">
          <colgroup>
            <col width="30%" />
            <col width="70%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell sortDirection="desc">
                <Tooltip enterDelay={300} title="Sort">
                  <TableSortLabel active direction="desc">
                    Time
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell>Event</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              className={[
                classes.showPointer,
                !selectedEvent && classes.selectedEventColor,
              ]}
              hover={!selectedEvent ? false : true}
              key={0}
              onClick={(e) => handleClick(e, '', '')}
            >
              <TableCell>(live)</TableCell>
              <TableCell>(go live)</TableCell>
            </TableRow>
            {events.map((event) => (
              <TableRow
                className={
                  isWithinPastMinutes(event.eventTimestamp) && [
                    classes.showPointer,
                    selectedEvent === event.eventId &&
                      classes.selectedEventColor,
                  ]
                }
                hover={isWithinPastMinutes(event.eventTimestamp) ? true : false}
                key={event.eventId}
                onClick={(e) =>
                  handleClick(e, event.eventId, event.eventTimestamp)
                }
              >
                <TableCell
                  className={
                    !isWithinPastMinutes(event.eventTimestamp) &&
                    classes.disabledRowColor
                  }
                >
                  {moment.unix(event.eventTimestamp).format('h:mm:ss a')}
                </TableCell>
                <TableCell
                  className={
                    !isWithinPastMinutes(event.eventTimestamp) &&
                    classes.disabledRowColor
                  }
                >
                  {event.info}, {event.sensorName}
                </TableCell>
                <TableCell>
                  {getDispositionIcon(dispositionMap[event.eventId])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
  );
};

AlarmEvents.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  subscription: PropTypes.object,
  onEventClick: PropTypes.func,
};

export default AlarmEvents;
