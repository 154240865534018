import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { useEvent } from 'react-use';
import Controls from './Controls';
import { VideoElement } from './VideoElement';
import './styles.css';
import NoVideoCard from './NoVideoCard';
import TalkSocket from './TalkSocket';
import MetricsModule from 'src/utils/MetricsModule';
import { isIterable } from 'src/utils/utils';
import { MetricsContext } from 'src/context/Metrics-context';
import { LiveIndicatorProvider } from './AddLiveIndicator';
import { playVideoComponent } from './kvs-camera/AudioUtils';
import { useGesture } from 'react-use-gesture';
import { AudioSocketState } from './kvs-camera/useStreamRecorder';
import { useSubscription } from 'src/hooks/useSubscription';
import { useActivateSiren } from 'src/hooks/cameraHooks';

type Props = {
  authHeader?: string;
  checkZoom?: boolean;
  duration?: number;
  eventId?: number;
  forceIsPlaying?: boolean;
  forceIsTalking?: boolean;
  forceVolume?: number;
  handleZoom?: boolean;
  height?: string;
  isLive?: boolean;
  isPlaying?: boolean;
  isTalking?: boolean;
  liveIndicator?: boolean;
  loop?: boolean;
  markers?: any;
  mesuredView?: string;
  onDurationLoaded?: (duration: number) => void;
  onItemClick?: (item: any) => void;
  onMarkerClick?: (marker: any) => void;
  onPause?: () => void;
  onPlay?: () => void;
  onPlayerClick?: () => void;
  onProgress?: (progress: number) => void;
  onReady?: (playerRef: any) => void;
  onVolume?: (volume: number) => void;
  pauseTrigger?: boolean;
  playbackRate?: number;
  playerElRef?: any;
  seekCommand?: any;
  setCheckZoom?: (checkZoom: boolean) => void;
  setLiveIndicator?: (liveIndicator: boolean) => void;
  setOverlay?: (overlay: any) => void;
  setVideoTimestamp?: (timestamp: string) => void;
  showImgMask?: number;
  showLiveIndicator?: boolean;
  sid?: number;
  timeStart?: number;
  type?: string;
  unmuteOnHoverEnabled?: boolean;
  url?: string;
  uuid?: string;
  video?: any;
  volume?: number;
  width?: string;
  disableVolumeAndMicrophone?: boolean;
  controls?: any;
  active?: boolean;
  fullDuplexAudio?: boolean;
};

const DEFAULT_VOLUME = 1;

const VideoPlayer = (props: Props) => {
  const {
    authHeader = '',
    checkZoom,
    duration = 0,
    eventId,
    forceIsPlaying = undefined,
    forceIsTalking = undefined,
    forceVolume = undefined,
    handleZoom = true,
    height = '100%',
    isLive = true,
    isPlaying = false,
    isTalking = false,
    liveIndicator = false,
    loop = false,
    markers,
    mesuredView = '',
    onDurationLoaded = () => { },
    onItemClick,
    onMarkerClick = () => { },
    onPause = () => { },
    onPlay = () => { },
    onPlayerClick = () => { },
    onProgress = () => { },
    onReady = (playerRef) => { },
    onVolume = () => { },
    pauseTrigger = false,
    playbackRate = 1.0,
    // playerElRef,
    seekCommand = '',
    setCheckZoom = () => Boolean,
    setLiveIndicator = () => { },
    setOverlay,
    setVideoTimestamp = () => { },
    showImgMask = 0,
    showLiveIndicator = true,
    sid,
    timeStart,
    type = 'flv', // options: flv, video/mp4
    unmuteOnHoverEnabled = false,
    url,
    uuid,
    disableVolumeAndMicrophone = false,
    video = {},
    volume = DEFAULT_VOLUME,
    width = '100%',
    controls = [
      'play',
      'time',
      'progress',
      'volume',
      'talk',
      'full-screen',
      ...(type === 'kvs' ? ['reload'] : []),
    ],
    active = undefined,
    fullDuplexAudio = false,
  } = props;

  const [unmuteOnHover, setUnmuteOnHover] = useState(unmuteOnHoverEnabled);

  const progressEl = useRef(null);
  const volumeEl = useRef(null);

  const micMetric = useRef(null);
  const sirenMetric = useRef(null);
  const muteMetric = useRef(null);
  const [_, dispatch] = useContext(MetricsContext);
  const [currentTime, setCurrentTime] = useState(0);
  const currentTimeRef = useRef<number>();

  const [videoDuration, setVideoDuration] = useState(duration);
  const videoDurationRef = useRef<number>();
  const [muted, setMuted] = useState<boolean>(true);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(isPlaying);
  const [talkingState, setTalkingState] = useState(isTalking);
  const [sirenState, setSirenState] = useState(false);
  const [volumeState, setVolumeState] = useState(volume);
  const [videoKey, setVideoKey] = useState(1);
  const [retryTime, setRetryTime] = useState(null);
  const [retryRand, setRetryRand] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0, scale: 1 });
  const [pan, setPan] = useState({ x: 0, y: 0 });
  const pcs_watch_settings = useRef(null);
  const playerElRef = useRef(null);
  const playerSavedVolumeRef = useRef(0);
  const [videoPlayTimestamp, setVideoPlayTimestamp] = useState(0);
  const { subscription } = useSubscription({ userId: uuid, sid: sid });
  const setActivateSiren = useActivateSiren();

  const useFirmwareSiren =
    subscription?.location?.system?.cameras.find(
      (camera) => camera.uuid === uuid
    )?.supportedFeatures?.sirenManualControl || false;

  // TODO: remove this when we're sure we have passed the eventID to the player in all cases
  if (!eventId) {
    // eslint-disable-next-line no-debugger
    // debugger;
    console.warn('VideoPlayer: no eventId provided');
  }

  const playVideo = useCallback(() => {
    // Start playing (.play() is async and returns a promise)
    (async () => {
      try {
        playerElRef.current && (await playVideoComponent(playerElRef.current));
      } catch (error) {
        console.error('Error playing the video', error);
      }
    })();

    // Update state
    setIsVideoPlaying(true);
    onPlay();
  }, []);

  const pauseVideo = useCallback(() => {
    if (
      playerElRef.current.currentTime > 0 &&
      !playerElRef.current.paused &&
      !playerElRef.current.ended &&
      playerElRef.current.readyState > 2
    ) {
      playerElRef.current.pause();
    }
    setIsVideoPlaying(false);
    onPause();
  }, []);

  const metricUpdate = (metric) => {
    pcs_watch_settings.current = JSON.parse(
      localStorage.getItem('pcs_watch_settings')
    );
    if (isIterable(pcs_watch_settings.current)) {
      metric.fetchNewData(...pcs_watch_settings.current, eventId);
    } else {
      metric.fetchNewData();
    }
    metric.stopTimer();
  };

  // For No Video - if it's SS Video allow rep to click to V&R while downloading
  const onNoVideoClick = () => {
    return type === 'kvs-experiment' && eventId ? onPlayerClick() : null;
  };

  const retry = () => {
    setRetryRand(Date.now());
  };

  const setVolume = (value) => {
    playerElRef.current.volume = value;
    setVolumeState(value);
    setMuted(!value);
  };

  const handleStartTalkingClick = useCallback(() => {
    micMetric.current = new MetricsModule(
      mesuredView.length !== 0
        ? `player mic clicked on ${mesuredView}`
        : 'player mic clicked'
    );
    metricUpdate(micMetric.current);
    dispatch({
      type: 'SEND',
      payload: {
        ...micMetric.current.payload(),
        cameraUUID: uuid,
        eventID: eventId,
      },
    });
    setTalkingState(true);
  }, []);

  const handleStopTalkingClick = useCallback(() => {
    setTalkingState(false);
  }, []);

  const talkSocketRef = React.useRef<any>(null);

  const handleStartSirenClick = useCallback(() => {
    sirenMetric.current = new MetricsModule(
      mesuredView.length !== 0
        ? `player siren clicked on ${mesuredView}`
        : 'player siren clicked'
    );
    metricUpdate(sirenMetric.current);
    dispatch({
      type: 'SEND',
      payload: {
        ...sirenMetric.current.payload(),
        eventID: eventId,
      },
    });
    talkSocketRef.current?.startSiren();
    if (type === 'flv') {
      setTalkingState((currentTalking) => {
        if (!currentTalking) {
          setTimeout(() => {
            setTalkingState(false);
          }, 4000);
        }
        return true;
      });
    }

    if (useFirmwareSiren && !sirenState) {
      setActivateSiren.mutate({ uuid, sid });
    }
    setSirenState(true);
    setTimeout(() => {
      setSirenState(false);
    }, 6000);
  }, [useFirmwareSiren, sirenState]);

  const handlePlayClick = useCallback(() => {
    playVideo();
  }, []);

  const handlePauseClick = useCallback(() => {
    pauseVideo();
  }, []);

  const handleDurationLoaded = useCallback(
    (e) => {
      // If this is the first duration update, fire onReady()
      if (!videoDurationRef.current) {
        onReady(playerElRef);
      }

      let { duration: browserCalculatedVideoDuration } = e.currentTarget;
      if (browserCalculatedVideoDuration === Infinity) {
        browserCalculatedVideoDuration = duration;
      }

      // Set duration variables
      setVideoDuration(browserCalculatedVideoDuration);
      videoDurationRef.current = browserCalculatedVideoDuration;
      onDurationLoaded(videoDurationRef.current);
    },
    [onReady, videoDuration]
  );

  const handleProgress = useCallback(
    (e) => {
      const { currentTime: newTime } = e.currentTarget;
      let durationForProgress = playerElRef.current.duration;

      if ((isLive || !durationForProgress) && e.currentTarget.buffered.length) {
        durationForProgress = playerElRef.current.buffered.end(0);
        videoDurationRef.current = durationForProgress;
        setVideoDuration(durationForProgress);
      } else {
        durationForProgress = videoDuration || playerElRef.current.duration;
      }

      if (durationForProgress) {
        currentTimeRef.current = newTime;
        setCurrentTime(newTime);
      }

      onProgress(newTime);
    },
    [onPause, onProgress, isLive, duration, playerElRef.current]
  );

  const handleLoadingProgress = useCallback(
    (e) => {
      if (isLive && e.target.buffered.length > 0) {
        const bufferedEnd = e.target.buffered.end(0);

        if (bufferedEnd > videoDurationRef.current) {
          videoDurationRef.current = bufferedEnd;
          setVideoDuration(bufferedEnd);

          handleProgress(e);
        }
      }
    },
    [handleProgress, isLive]
  );

  const handleProgressClick = (
    event: React.ChangeEvent,
    value: number | number[]
  ) => {
    const videoPlayerDuration = playerElRef.current.duration;
    const seekTo = Array.isArray(value) ? value[0] : value;

    if (Number.isNaN(seekTo)) {
      return;
    }

    if (seekTo <= 0) {
      playerElRef.current.currentTime = 0;
      return;
    }

    // check if is possible to seek to the time, based on the buffered
    if (
      playerElRef.current.buffered != null &&
      playerElRef.current.buffered.length > 0
    ) {
      let canSeek = false;
      for (let i = 0; i < playerElRef.current.buffered.length; i++) {
        if (
          seekTo >= playerElRef.current.buffered.start(i) &&
          seekTo <= playerElRef.current.buffered.end(i)
        ) {
          canSeek = true;
          break;
        }
      }
      if (!canSeek) {
        return;
      }
    }

    if (seekTo > videoPlayerDuration) {
      playerElRef.current.currentTime = videoPlayerDuration;
      return;
    }

    playerElRef.current.currentTime = seekTo;
  };

  const handleVolumeClick = (e) => {
    if (disableVolumeAndMicrophone) {
      return;
    }

    const y =
      volumeEl.current.offsetWidth -
      (e['clientY'] -
        volumeEl.current.getBoundingClientRect().top +
        document.body.scrollTop);
    const percentage =
      (y * volumeEl.current.max) / volumeEl.current.offsetWidth;
    playerElRef.current.muted = false;
    onVolume(percentage / 100);
  };

  const handleMuteMetrics = ({ isUnmuteOnHover = false } = {}) => {
    muteMetric.current = new MetricsModule(
      isUnmuteOnHover
        ? 'player un-mute on hover'
        : mesuredView.length !== 0
          ? `player un-mute clicked on ${mesuredView}`
          : 'player un-mute clicked'
    );
    metricUpdate(muteMetric.current);
    dispatch({
      type: 'SEND',
      payload: {
        ...muteMetric.current.payload(),
        eventID: eventId,
        cameraUUID: uuid,
      },
    });
  };

  const handleMuteClick = () => {
    if (disableVolumeAndMicrophone) {
      return;
    }

    if (muted) {
      handleMuteMetrics();
      playerElRef.current.muted = false;
      setVolume(DEFAULT_VOLUME);
      setMuted(false);
    } else {
      playerElRef.current.muted = true;
      setVolume(0);
      setMuted(true);
    }
  };

  const handleFullScreenClick = () => {
    // const videoWrap = document.getElementsByClassName('react-video-wrap')[0];
    const videoWrap = playerElRef.current.parentNode;
    // if (isFullScreen) {
    if (document.fullscreenElement) {
      // document.body.classList.remove('react-video-full-screen');
      if (document['exitFullscreen']) {
        document['exitFullscreen']();
      } else if (document['mozCancelFullScreen']) {
        document['mozCancelFullScreen']();
      } else if (document['webkitExitFullscreen']) {
        document['webkitExitFullscreen']();
      } else if (document['msExitFullscreen']) {
        document['msExitFullscreen']();
      }
    } else {
      // document.body.classList.add('react-video-full-screen');
      // eslint-disable-next-line no-lonely-if
      if (videoWrap['requestFullscreen']) {
        videoWrap['requestFullscreen']();
      } else if (videoWrap['mozRequestFullScreen']) {
        videoWrap['mozRequestFullScreen']();
      } else if (videoWrap['webkitRequestFullscreen']) {
        videoWrap['webkitRequestFullscreen']();
      } else if (videoWrap['msRequestFullscreen']) {
        videoWrap['msRequestFullscreen']();
      }
    }
    setIsFullScreen(!isFullScreen);
  };

  const handleMarkerClick = (marker) => {
    playerElRef.current.currentTime = marker['time'];
    onMarkerClick(marker);
  };

  const handleEnded = useCallback((e) => {
    setIsVideoPlaying(false);
  }, []);

  const handleLoadedData = (e) => {
    setRetryTime(null);

    // Diagnosing times when video never loads... send a metric when data for a stream is actually loaded
    // Theory: video-play-start - video-loaded = videos that never played
    const now = Math.floor(Date.now() / 1000);
    const videoLoadDelay = now - videoPlayTimestamp;
    const videoLoadedEvent = new MetricsModule('video-loaded');
    dispatch({
      type: 'SEND',
      payload: {
        ...videoLoadedEvent,
        eventID: eventId,
        elapsedTime: videoLoadDelay, // front_metrics_router.py doesn't actually allow any payload, so we're overloading this one that already exists
      },
    });
  };

  /* Array of possible browser specific settings for transformation */
  let properties = [
    'transform',
    'WebkitTransform',
    'MozTransform',
    'msTransform',
    'OTransform',
  ],
    prop = properties[0];

  /* Iterators and stuff */
  let i, j, t;

  /* Find out which CSS transform the browser supports */
  for (i = 0, j = properties.length; i < j; i++) {
    if (typeof playerElRef.current?.style[properties[i]] !== 'undefined') {
      prop = properties[i];
      break;
    }
  }

  const onScrollZoom = (e) => {
    if (!handleZoom) {
      return;
    }
    const delta = e.deltaY * -0.01;
    let newScale = position.scale + delta;
    let ratio = 1 - newScale / position.scale;
    let x = position.x + (e.clientX - position.x) * ratio;
    let y = position.y + (e.clientY - position.y) * ratio;
    playerElRef.current.style[prop] = `translate(${newScale < 1 ? (x = 0) : x
      }px, ${newScale < 1 ? (y = 0) : y}px) scale(${newScale < 1 ? (newScale = 1) : newScale
      })`;
    setPosition({
      scale: newScale,
      x,
      y,
    });
  };

  useEffect(() => {
    if (position.x !== 0 || position.y !== 0 || position.scale !== 1) {
      setCheckZoom(true);
    } else {
      setCheckZoom(false);
    }
  }, [position]);

  useEffect(() => {
    if (setOverlay !== undefined) {
      if (position.x !== 0 || position.y !== 0 || position.scale !== 1) {
        setOverlay(false);
      } else {
        setOverlay(true);
      }
    }
  }, [position]);

  // ERROR HANDLING
  const timeoutId = useRef<number>();
  const handleError = useCallback(
    (code, msg) => {
      console.log('Error', code, msg);
      if (code === 404) {
        setRetryTime((s) => s + 5);
        timeoutId.current = window.setTimeout(
          () => setVideoKey((s) => s + 1),
          1000 * (retryTime + 5) // retryTime won't be updated until next render
        );
      }
      if (code === 401) {
        console.log(
          'Unauthorized to view video. Token should be kept up-to-date. This should not happen generally'
        );
      }
    },
    [retryTime]
  );

  // ERROR HANDLING - Clear timeout from above
  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current);
    };
  }, []);

  // Setup video events
  useEvent('timeupdate', handleProgress, playerElRef.current);
  useEvent('durationchange', handleDurationLoaded, playerElRef.current);
  useEvent('progress', handleLoadingProgress, playerElRef.current);
  useEvent('end', handleEnded, playerElRef.current);
  useEvent('loadeddata', handleLoadedData, playerElRef.current);
  useEvent('play', onPlay, playerElRef.current);
  useEvent('pause', onPause, playerElRef.current);
  useEvent('click', onPlayerClick, playerElRef.current);

  // Set START TIME -- first effect handles when video is loading (uses videoDuration dependancy to re-run until passed timeStart)
  // Second effect handles changes to timeStart after video is loaded
  const startTimeSeekDone = useRef(false);

  useEffect(() => {
    if (
      timeStart &&
      playerElRef &&
      videoDurationRef.current > timeStart &&
      !startTimeSeekDone.current
    ) {
      console.log('Seeking to', timeStart);
      playerElRef.current.currentTime = timeStart;
      startTimeSeekDone.current = true;
    }
  }, [timeStart, videoDuration]);

  useEffect(() => {
    if (pauseTrigger === true) {
      if (!playerElRef.current.paused) {
        playerElRef.current.pause();
        setIsVideoPlaying(false);
      }
    }
  }, [pauseTrigger]);

  useEffect(() => {
    if (timeStart && playerElRef && videoDurationRef.current > timeStart) {
      console.log('Seeking to', timeStart);
      playerElRef.current.currentTime = timeStart;
      startTimeSeekDone.current = true;
    }
  }, [timeStart]);

  // SEEKING - go to end, forward, back, next marker
  // NOTE: if you seek past the end of duration (or even exatly to the end) then playerElRef.current.currentTime will no longer update, so leave room
  // NOTE: when seeking to the end of a video while connection to server is still open -- seem to have to leave at least 1 second of room, otherwise the seek will stall until the connection to server is closed
  // NOTE: put markers in a ref -- we only want the seekCommand to run when it changes, not when markers change (which can happen repeatedly)
  const markersRef = useRef(markers);
  markersRef.current = markers || [];
  useEffect(() => {
    if (playerElRef && videoDurationRef.current && seekCommand.detail) {
      let seekTime = 0;
      if (typeof seekCommand.detail === 'number') {
        if (seekCommand.detail > 0) {
          seekTime = Math.min(
            videoDurationRef.current - 1,
            currentTimeRef.current + seekCommand.detail
          );
        } else {
          seekTime = Math.max(0.1, currentTimeRef.current + seekCommand.detail);
        }
      } else {
        switch (seekCommand.detail) {
          case 'END':
            setVideoTimestamp('');
            setLiveIndicator(true);
            break;

          // NOTE: marker advance-rewind relies on markes being in time sorted order; if that's ever not the case, add sorting here or elsewhere...
          case 'NEXT_MARKER':
            seekTime = markersRef.current
              .filter((marker) => marker['time'] > currentTimeRef.current)
              .sort((a, b) => a.time - b.time)[0]?.time;
            break;
          case 'PREV_MARKER':
            seekTime = markersRef.current
              .filter((marker) => marker['time'] < currentTimeRef.current - 0.5)
              .sort((a, b) => b.time - a.time)[0]?.time;
            // .reverse()[0]?.time;
            break;
          default:
            console.log('Invalid Seek Command', seekCommand.detail);
        }
      }
      if (seekTime) {
        console.log('seekCommand, Seeking to', seekTime);
        playerElRef.current.currentTime = seekTime;
      }
    }
  }, [seekCommand]);

  useEffect(() => {
    setTalkingState((value) => forceIsTalking ?? value);
  }, [forceIsTalking]);

  useEffect(() => {
    setTalkingState((value) => isTalking ?? value);
  }, [isTalking]);

  useEffect(() => {
    setIsVideoPlaying((value) => forceIsPlaying ?? value);
  }, [forceIsPlaying]);

  useEffect(() => {
    setIsVideoPlaying((value) => isPlaying ?? value);

    // Diagnosing times when video never loads... send a metric when data for when we believe the video playback to start
    // Theory: video-play-start - video-loaded = videos that never played
    const videoPlayStartEvent = new MetricsModule('video-play-start');
    dispatch({
      type: 'SEND',
      payload: {
        ...videoPlayStartEvent,
        eventID: eventId,
        agentID: localStorage.getItem('id') || null,
      },
    });

    // Start a timer on first "play" event so that we can see how long it takes for the video to then load
    setVideoPlayTimestamp(Math.floor(Date.now() / 1000));
  }, [isPlaying]);

  useEffect(() => {
    setVolume(active ? 1 : 0);
    setMuted(active ? false : true);

    if (active) {
      handleMuteMetrics();
      playerElRef.current.muted = false;
    } else {
      playerElRef.current.muted = true;
    }
  }, [active]);

  // 2WAY Audio - talking - go "live" (to end of video)
  useEffect(() => {
    if (playerElRef && videoDurationRef.current && talkingState) {
      console.log(
        'Talking, seeking to end of video to be "live"',
        videoDurationRef.current - 1
      );
      playerElRef.current.currentTime = videoDurationRef.current - 1;
    }
  }, [talkingState]);

  useEffect(() => {
    if (isVideoPlaying) {
      playVideo();
    } else {
      pauseVideo();
    }
  }, [isVideoPlaying]);

  useEffect(() => {
    setVolume(volume);
  }, [volume]);

  useEffect(() => {
    if (playerElRef.current) {
      playerElRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  // IMAGE MASK -- for motion zone
  const img_mask_key = useRef(0);
  useEffect(() => {
    img_mask_key.current = Math.random();
  }, [showImgMask]);
  const imgURL = `${process.env.REACT_APP_PCS_ADMIN_URL}/camera/motion-zone-img?uuid=${uuid}&rand=${img_mask_key.current}`;

  // May want to memoize video component so that progress updates don't cause continuous re-render
  // But will also need to make functions useCallback
  // https://aheadcreative.co.uk/articles/avoiding-react-component-re-renders-with-react-memo/
  // const MemoVideoElement = React.memo(VideoElement);

  const style = { height, width } as React.CSSProperties;

  useGesture(
    {
      onDrag: ({ offset: [dx, dy] }) => {
        setPan({ x: dx, y: dy });
      },
    },
    {
      domTarget: playerElRef,
    }
  );

  useEffect(() => {
    if (position.x !== 0 || position.y !== 0) {
      setPan({ ...pan });
    }
  }, [position]);

  const videoPlayerStyle = {
    transformOrigin: '0 0',
    position: position.x !== 0 || position.y !== 0 ? 'absolute' : '',
    left: pan.x,
    top: pan.y,
  } as React.CSSProperties;

  useEffect(() => {
    setPosition({ scale: 1, x: 0, y: 0 });
    playerElRef.current.style[prop] = `translate(${0}px, ${0}px) scale(${1})`;
  }, [onItemClick]);

  const [wsState, setWsState] = useState(AudioSocketState.UNINSTANTIATED);

  const changeUnmuteOnHover = (newValue) => {
    setUnmuteOnHover(newValue);
  };

  const showControls = () => {
    const hasControls = controls.length;
    const shouldRender = onItemClick || hasControls;

    if (!shouldRender) return null;
    return (
      <>
        <Controls
          progressEl={progressEl}
          volumeEl={volumeEl}
          controls={controls}
          isPlaying={isVideoPlaying}
          disableVolumeAndMicrophone={disableVolumeAndMicrophone}
          volume={volumeState}
          currentTime={currentTime}
          duration={videoDuration}
          muted={muted}
          markers={markers}
          onRetry={retry}
          onPlayClick={handlePlayClick}
          onPauseClick={handlePauseClick}
          onProgressClick={handleProgressClick}
          onVolumeClick={handleVolumeClick}
          onMuteClick={handleMuteClick}
          onFullScreenClick={handleFullScreenClick}
          onMarkerClick={handleMarkerClick}
          isTalking={talkingState}
          onStartTalkingClick={handleStartTalkingClick}
          onStopTalkingClick={handleStopTalkingClick}
          onStartSirenClick={handleStartSirenClick}
          isSiren={sirenState}
          wsState={wsState}
          isTalkOnHold={type === 'flv' && !fullDuplexAudio}
          changeUnmuteOnHover={changeUnmuteOnHover}
          showUnmuteOnHoverIcon={unmuteOnHoverEnabled && !unmuteOnHover}
        />
        {type === 'flv' && !disableVolumeAndMicrophone && (
          <TalkSocket
            setWsState={setWsState}
            authHeader={authHeader}
            sid={sid}
            isTalking={talkingState}
            isSiren={sirenState}
            uuid={uuid}
          />
        )}
      </>
    );
  };

  const onMouseEnter = () => {
    if (disableVolumeAndMicrophone) {
      return;
    }

    if (unmuteOnHoverEnabled && unmuteOnHover) {
      playerElRef.current.muted = false;
      setVolume(DEFAULT_VOLUME);
      setMuted(false);
      handleMuteMetrics({ isUnmuteOnHover: true });
    }
  };
  const onMouseLeave = () => {
    if (unmuteOnHoverEnabled && unmuteOnHover) {
      handleMuteClick();
    }
  };

  return (
    <>
      <div style={{ position: 'relative', height: '100%' }}>
        <div className="react-video-wrap" style={style}>
          {retryTime !== null && (
            <NoVideoCard
              retryTime={retryTime}
              message={`Retrying in ${retryTime} seconds`}
              onNoVideoClick={onNoVideoClick}
            />
          )}
          <LiveIndicatorProvider
            liveIndicator={liveIndicator}
            showLiveIndicator={showLiveIndicator}
          >
            <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              <VideoElement
                onScrollZoom={onScrollZoom}
                style={videoPlayerStyle}
                key={videoKey} // Used to force re-render
                hasVideo={!retryTime}
                playerElRef={playerElRef}
                retry={retryRand}
                type={type}
                url={url}
                uuid={uuid}
                sid={sid}
                eventId={eventId}
                authHeader={authHeader}
                loop={loop}
                isLive={isLive}
                isSiren={sirenState}
                isTalking={talkingState}
                onError={handleError}
                video={video}
                onNoVideoClick={onNoVideoClick}
                fullDuplexAudio={fullDuplexAudio}
              />
              {isFullScreen ? (
                <button
                  type="button"
                  className="react-video-close"
                  onClick={handleFullScreenClick}
                >
                  Close video
                </button>
              ) : null}

              {showControls && (
                <>
                  <Controls
                    progressEl={progressEl}
                    volumeEl={volumeEl}
                    controls={controls}
                    isPlaying={isVideoPlaying}
                    disableVolumeAndMicrophone={disableVolumeAndMicrophone}
                    volume={volumeState}
                    currentTime={currentTime}
                    duration={videoDuration}
                    muted={muted}
                    markers={markers}
                    onRetry={retry}
                    onPlayClick={handlePlayClick}
                    onPauseClick={handlePauseClick}
                    onProgressClick={handleProgressClick}
                    onVolumeClick={handleVolumeClick}
                    onMuteClick={handleMuteClick}
                    onFullScreenClick={handleFullScreenClick}
                    onMarkerClick={handleMarkerClick}
                    isTalking={talkingState}
                    onStartTalkingClick={handleStartTalkingClick}
                    onStopTalkingClick={handleStopTalkingClick}
                    onStartSirenClick={handleStartSirenClick}
                    isSiren={sirenState}
                    wsState={wsState}
                    isTalkOnHold={type === 'flv'}
                    changeUnmuteOnHover={changeUnmuteOnHover}
                    showUnmuteOnHoverIcon={
                      unmuteOnHoverEnabled && !unmuteOnHover
                    }
                    eventId={eventId}
                    uuid={uuid}
                    sid={sid}
                  />
                  {type === 'flv' && !disableVolumeAndMicrophone && (
                    <TalkSocket
                      setWsState={setWsState}
                      authHeader={authHeader}
                      sid={sid}
                      isTalking={talkingState}
                      isSiren={sirenState}
                      uuid={uuid}
                    />
                  )}
                </>
              )}
            </div>
          </LiveIndicatorProvider>
        </div>

        {showImgMask > 0 && (
          <img
            src={imgURL}
            alt=""
            style={{
              position: 'absolute',
              top: 0,
              opacity: 0.8,
              width: '100%',
            }}
          />
        )}
      </div>
    </>
  );
};

/**
 * Select video player (FLV, KVS, MP4) and passes in relavant props to that player
 * */

export default VideoPlayer;

// VideoPlayer.whyDidYouRender = true;
// VideoElement.whyDidYouRender = true;
