import React, { createContext, useState } from 'react';

export const ScreenContext = createContext();

const ScreenContextProvider = ({ children }) => {
  const [stream, setStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);

  return (
    <ScreenContext.Provider
      value={{ stream, setStream, remoteStream, setRemoteStream }}
    >
      {children}
    </ScreenContext.Provider>
  );
};

export default ScreenContextProvider;
