import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useQueryParams } from 'src/utils/useQueryParams';
import Annotator from 'react-image-annotate';
import {
  useSetCameraMotionZone,
  useGetCameraMotionZoneMask,
} from 'src/hooks/subscriberHooks';
import { useSnackbar } from 'notistack';

const MotionZone = ({ mzRef = React.createRef(), playerElRef, onDone }) => {
  const { sid, uuid } = useQueryParams();

  // IMAGE MASK -- saved motion zone, provided by server GET
  // const [imageMask, setImageMask] = React.useState(null);
  const [regions, setRegions] = React.useState(null);
  const motionZoneMaskQuery = useGetCameraMotionZoneMask(sid, uuid);
  React.useEffect(() => {
    if (
      motionZoneMaskQuery.isSuccess &&
      motionZoneMaskQuery.data?.motionZone?.regions
    ) {
      // Image mask - OLD - use raw png image (can't do this if we're returning json with region as well)
      // Note: If not base64 encoded, could use data blob directly returned, like this:
      // const blob = new Blob([motionZoneMaskQuery.data.imageMask], { type: 'image/png' });

      // Image Mask - decode from base64 - No longer using, because we're relying on video player to show mask or not
      // const decoded = atob(motionZoneMaskQuery.data.imageMask);
      // const uInt8Array = new Uint8Array(decoded.length);
      // for (let i = 0; i < decoded.length; ++i) {
      //   uInt8Array[i] = decoded.charCodeAt(i);
      // }
      // const blob = new Blob([uInt8Array], { type: 'image/png' });
      // let img = new Image();
      // img = URL.createObjectURL(blob);
      // setImageMask(img);

      // Regions to put into annotator
      setRegions(motionZoneMaskQuery.data.motionZone.regions);
    }
    return () => {};
  }, [motionZoneMaskQuery.isSuccess, motionZoneMaskQuery.data]);

  // IMAGE -- captured from live video
  const [image, setImage] = React.useState(null);
  const capture = () => {
    if (!playerElRef.current) return;
    const canvas = document.createElement('CANVAS');
    const video = playerElRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas
      .getContext('2d')
      .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    canvas.toBlob((blob) => {
      let img = new Image();
      if (blob) {
        img = URL.createObjectURL(blob);
        setImage(img);
      }
    });
  };
  React.useImperativeHandle(mzRef, () => ({
    capture: () => {
      capture();
    },
  }));

  // POST motion zone to PCS Admin server
  const setCameraMotionZoneMutation = useSetCameraMotionZone();
  const handleSave = (data) => {
    if (data) {
      setCameraMotionZoneMutation.mutate({
        sid,
        uuid,
        motionZone: data.images[0],
      });
    } else {
      onDoneRef.current();
    }
    setImage(null);
  };

  // Success / Error on Save, and call onDone()
  const { enqueueSnackbar } = useSnackbar();
  const onDoneRef = React.useRef(onDone);
  onDoneRef.current = onDone;
  React.useEffect(() => {
    if (setCameraMotionZoneMutation.isError) {
      enqueueSnackbar(`Error saving: ${setCameraMotionZoneMutation.error}`);
      onDoneRef.current();
    } else if (setCameraMotionZoneMutation.isSuccess) {
      enqueueSnackbar(`Successfully saved`);
      onDoneRef.current();
    }
    return () => {};
  }, [
    setCameraMotionZoneMutation.isError,
    setCameraMotionZoneMutation.isSuccess,
    setCameraMotionZoneMutation.error,
    enqueueSnackbar,
  ]);

  return (
    <>
      {image && (
        <Box
          m={1}
          height="75vh"
          id="pcs-annotation-container"
          key="pcs-annotation-container"
        >
          <Annotator
            hideHeader
            regionClsList={['Exclude']}
            enabledTools={[
              'select',
              // 'create-point',
              // 'create-box',
              'create-polygon',
              // 'create-line',
              // 'create-expanding-line',
              // 'show-mask'
            ]}
            selectedTool="create-polygon"
            onExit={handleSave}
            images={[
              {
                src: image,
                name: '',
                regions: regions || [],
              },
            ]}
          />
        </Box>
      )}
    </>
  );
};

MotionZone.propTypes = {
  mzRef: PropTypes.object,
  playerElRef: PropTypes.object,
  onDone: PropTypes.func,
};

export default MotionZone;
