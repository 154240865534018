import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SsCamera } from 'src/types/camera';
import { useSnackbar } from 'notistack';
import { usePcsClient } from 'src/context/pcs-auth-context';

export type CameraUpgradeFWType = 'upgrade_fw' | 'revert_fw';

export function useCameraUpgradeFW(
  uuid: string,
  type: CameraUpgradeFWType = 'upgrade_fw'
) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (data: { uuid: string }) =>
      client(`camera/${type}/${uuid}`, {
        method: 'POST',
        data: data.uuid,
      }),
    {
      onSuccess: (data, variables) => {
        enqueueSnackbar(
          `${type === 'upgrade_fw' ? 'Upgrade' : 'Revert'} request ${
            data.success ? 'succeded' : 'failed'
          }`,
          {
            variant: data.success ? 'success' : 'error',
          }
        );
      },
      onError: (error) => {
        enqueueSnackbar(`Endpoint: camera/${type}/${uuid}. ${error}`, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
}

export function useCameraSettings(uuid: string, sid: number) {
  const client = usePcsClient();
  const { data, isLoading, error } = useQuery<SsCamera['cameraSettings']>({
    queryKey: ['cameras', uuid, sid],
    queryFn: () => client(`subscriptions/${sid}/cameras/${uuid}/settings`),
  });

  return { data, isLoading, error };
}

export function useGetSpeedTestData(sid: number, uuid: string) {
  const client = usePcsClient();
  const { data, isLoading, error, refetch, isFetching } = useQuery({
    queryKey: ['cameras', sid, uuid],
    queryFn: () =>
      client(`subscriptions/${sid}/cameras/${uuid}/wifiSpeedTest/results`),
  });
  return { data, isLoading, error, refetch, isFetching };
}

export function useRequestSpeedTest(
  sid: number,
  uuid: string,
  operation: string
) {
  const { enqueueSnackbar } = useSnackbar();
  const client = usePcsClient();
  const mutation = useMutation(
    () =>
      client(
        `subscriptions/${sid}/cameras/${uuid}/wifiSpeedTest/${operation}?testType=ALL`,
        {
          method: 'POST',
        }
      ),
    {
      onSuccess: () => {
        enqueueSnackbar('Request sent successfully', { variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar(
          `Endpoint: subscriptions/${sid}/cameras/${uuid}/wifiSpeedTest/${operation}?testType=ALL. ${error}`,
          {
            variant: 'error',
          }
        );
      },
    }
  );
  return mutation;
}

export function useSetCameraSettings(uuid: string, sid: number) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const cache = useQueryClient();
  return useMutation<
    SsCamera['cameraSettings'],
    unknown,
    SsCamera['cameraSettings']
  >(
    (data) =>
      client(`subscriptions/${sid}/cameras/${uuid}/settings`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: async (data) => {
        await cache.invalidateQueries(['cameras', uuid]);
        cache.setQueryData(['cameras', uuid], () => data);
        enqueueSnackbar('Saved successfully.', { variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar(`Error saving settings. ${error}`, {
          variant: 'error',
        });
      },
    }
  );
}

export const useActivateSiren = () => {
  const client = usePcsClient();
  const mutation = useMutation((data: { sid: number; uuid: string }) =>
    client(`camera/${data.sid}/siren/${data.uuid}/start`, {
      method: 'POST',
    })
  );
  return mutation;
};

export const SPOTLIGHT_DURATION_SECONDS = 15;
export const SPOTLIGHT_COOLDOWN_SECONDS = 40;
const SPOTLIGHT_BRIGHTNESS = 0.8;

// From the Agent Portal, activating spotlight is a binary thing -
//  Activation turns spotlight on for 15 seconds at 80% brigtness
//  Deactivation turns spotlight off immediately
export const useActivateSpotlight = () => {
  const client = usePcsClient();
  const mutation = useMutation(
    (spotlightRequest: { sid: number; uuid: string }) =>
      client(
        `camera/${spotlightRequest.sid}/spotlight/${spotlightRequest.uuid}/start`,
        {
          method: 'POST',
          data: {
            duration: SPOTLIGHT_DURATION_SECONDS,
            brightnessPercent: SPOTLIGHT_BRIGHTNESS * 100,
          },
        }
      )
  );
  return mutation;
};
