import React from 'react';
import { Box, Card, Grid } from '@material-ui/core';
import CameraConnectivityDetails from './CameraConnectivityDetails';
import CameraSettingsDetails from './CameraSettingsDetails';
import CameraSpeedTestDetails from './CameraSpeedTestDetails';
import CameraSignalHistory from './CameraSignalHistory';
import CameraBatteryHistory from './CameraBatteryHistory';
import CameraSpeedTestHistory from './CameraSpeedTestHistory';

const CameraDetails = ({ camera }) => {
  return (
    <Card>
      <Box
        mt={3}
        padding={3}
        sx={{
          backgroundColor: '#303030',
          width: '100%',
        }}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Grid container justifyContent="space-between" spacing={8}>
          <Grid
            item
            style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
          >
            <CameraConnectivityDetails camera={camera} />
          </Grid>
          <Grid item>
            <CameraSettingsDetails camera={camera} />
          </Grid>
          <Grid item>
            <CameraSpeedTestDetails camera={camera} />
          </Grid>
          <Grid container item style={{ maxWidth: 600 }}>
            <CameraSpeedTestHistory camera={camera} />
          </Grid>
          <Grid container item style={{ maxWidth: 600 }}>
            <CameraSignalHistory camera={camera} />
          </Grid>
          <Grid container item style={{ maxWidth: 700 }}>
            {camera.supportedFeatures.battery && (
              <CameraBatteryHistory camera={camera} />
            )}
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default CameraDetails;
