import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { FC, useMemo } from 'react';

import { Person } from '../../../../hooks/peopleHooks';
import { AddPersonForm } from '../../../../views/verify-respond/AddPersonForm';
import { SelectPerson } from '../../../../views/verify-respond/SelectPerson';
import { apiURL } from '../../constants';
import { ICommonProps, ITrack } from '../../types';
import ZoomedOutAvatar from '../ZoomedOutAvatar';

interface IProps extends ICommonProps {
  track: ITrack;
  people: Person[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    padding: '8px',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  avatarWrapper: {
    padding: '2px',
  },
  avatarMatchedWrapper: {
    padding: '2px',
    height: '100%',
  },
  avatar: {
    width: '80px',
    height: 'auto',
  },
}));

const Face: FC<IProps> = ({
  track,
  people,
  isSelectingPeople,
  onItemClick,
}) => {
  const classes = useStyles();

  const matchedPerson = useMemo(() => {
    return people?.find((person) => person.person_id === track.match_person_id);
  }, [people]);

  return (
    <Card className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={isSelectingPeople ? 3 : 6}>
          <Card className={classes.avatarWrapper}>
            <CardActionArea onClick={() => onItemClick(track)}>
              <ZoomedOutAvatar
                track={track}
                avatarComponent={
                  <Avatar
                    src={`${apiURL}/${track.img_file}`}
                    variant="rounded"
                    className={classes.avatar}
                  />
                }
              />
              <Typography variant="subtitle1">Detected Face</Typography>
              <Typography variant="body2">
                {`Group ${
                  track.group_id
                    ? track.group_id.substring(track.group_id.length - 5)
                    : track.id.substring(track.id.length - 5)
                }`}
              </Typography>
              {track.face_confidence && (
                <Typography variant="body2" color="textSecondary">
                  Conf:
                  {` ${(track.face_confidence * 100.0).toFixed(0)}%`}
                </Typography>
              )}
              {track.dominant_emotion && (
                <Typography variant="body2" color="textSecondary">
                  Emotion:{' '}
                  <span className={classes.capitalize}>
                    {track.dominant_emotion}
                  </span>
                  {` (${track.emotion_score.toFixed(0)}%)`}
                </Typography>
              )}
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={isSelectingPeople ? 3 : 6}>
          <Card className={classes.avatarMatchedWrapper}>
            {matchedPerson && matchedPerson.faces?.[0]?.img_file && (
              <Box
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Avatar
                  src={
                    matchedPerson?.faces?.[0].img_file
                      ? `${apiURL}/${matchedPerson.faces[0].img_file}`
                      : ''
                  }
                  variant="rounded"
                  className={classes.avatar}
                />
                <Box>
                  <Typography variant="subtitle1">Matched Face</Typography>
                  <Typography variant="body2">
                    {`${matchedPerson?.name} `}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Match:{' '}
                    {track.match_distance >= 0 &&
                      `${(100 - track.match_distance * 100).toFixed(1)}%`}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Category:{' '}
                    <span className={classes.capitalize}>
                      {matchedPerson?.category}
                    </span>
                  </Typography>
                </Box>
              </Box>
            )}
            {!matchedPerson && (
              <Box
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Avatar variant="rounded" />
                <Typography variant="subtitle1">No Match</Typography>
              </Box>
            )}
          </Card>
        </Grid>
        {isSelectingPeople && (
          <Grid item xs={6}>
            <Typography variant="subtitle1">Select Match</Typography>
            <SelectPerson face={track} />
            <Box mt={1}>
              <Typography variant="subtitle2" color="textSecondary">
                Create New Person and Select
              </Typography>
              <AddPersonForm />
            </Box>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Face;
