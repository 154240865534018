import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { useCameraSettings, useSetCameraSettings } from 'src/hooks/cameraHooks';
import { SsCamera } from 'src/types/camera';
import { isOutdoorModel } from 'src/utils/utils';

export type CameraSettings = {
  pictureQuality: string;
  nightVision: 'auto' | 'on' | 'off';
  statusLight: 'on' | 'off';
  spotlightBrightness: 'low' | 'medium' | 'high';

  // true: person detection
  // false: all motion
  motionType: boolean;

  motionEvents: boolean;
  sensitivity: 'low' | 'medium' | 'high';
  personDetectionThreshold: string;
  outdoorMonitoringEnabled: boolean;
};

function toCameraSettings(data?: SsCamera['cameraSettings']): CameraSettings {
  return data
    ? {
        pictureQuality: data.pictureQuality,
        nightVision: data.nightVision,
        statusLight: data.statusLight,
        spotlightBrightness: data.spotlight?.level,
        motionEvents: data.pirEnable,
        motionType: data.pirEnable && data.vaEnable,
        sensitivity: data.pirLevel,
        personDetectionThreshold: data.admin.pirHysteresisHigh.toString(),
        outdoorMonitoringEnabled: data.admin.outdoorMonitoringEnabled,
      }
    : null;
}

const CameraSettingsDetails = ({ camera }) => {
  const [pictureQuality, setPictureQuality] = useState(null);
  const [nightVision, setNightVision] =
    useState<CameraSettings['nightVision']>('auto');
  const [statusLight, setStatusLight] =
    useState<CameraSettings['statusLight']>('off');
  const [spotlightBrightess, setSpotlightBrightness] =
    useState<CameraSettings['spotlightBrightness']>('medium');
  const [motionType, setMotionType] = useState(null);
  const [motionEvents, setMotionEvents] = useState(null);
  const [sensitivity, setSensitivity] = useState(null);
  const [personDetectionThreshold, setPersonDetectionThreshold] =
    useState(null);
  const [outdoorMonitoringEnabled, setOutdoorMonitoringEnabled] =
    useState(null);

  const { data } = useCameraSettings(camera.uuid, camera.sid);
  const cameraSettingsMutation = useSetCameraSettings(camera.uuid, camera.sid);

  const supportedResolutions = data?.supportedResolutions ?? [];
  const motionDetection =
    motionEvents && motionType
      ? 'personOnly'
      : motionEvents
      ? 'allMotion'
      : 'off';
  const setMotionDetection = (value: string) => {
    if (value == 'off') {
      setMotionEvents(false);
      setMotionType(false);
    } else if (value == 'allMotion') {
      setMotionEvents(true);
      setMotionType(false);
    } else {
      setMotionEvents(true);
      setMotionType(true);
    }
  };

  useEffect(() => {
    if (data) {
      const cameraSettings = toCameraSettings(data);
      setPictureQuality(cameraSettings.pictureQuality);
      setNightVision(cameraSettings.nightVision);
      setStatusLight(cameraSettings.statusLight);
      setSpotlightBrightness(cameraSettings.spotlightBrightness);
      setMotionType(cameraSettings.motionType);
      setMotionEvents(cameraSettings.motionEvents);
      setSensitivity(cameraSettings.sensitivity);
      setPersonDetectionThreshold(cameraSettings.personDetectionThreshold);
      setOutdoorMonitoringEnabled(
        cameraSettings.outdoorMonitoringEnabled.toString()
      );
    }
  }, [data]);

  const onSubmit = useCallback(async () => {
    cameraSettingsMutation.mutate({
      ...data,
      pictureQuality,
      nightVision,
      statusLight,
      spotlight: {
        enableColorNightMode: false,
        level: spotlightBrightess,
      },
      pirEnable: motionEvents,
      vaEnable: motionType && motionEvents,
      pirLevel: sensitivity,
      admin: {
        ...data.admin,
        pirHysteresisHigh: Number(personDetectionThreshold),
        outdoorMonitoringEnabled,
      },
    });
  }, [
    data,
    motionType,
    motionEvents,
    pictureQuality,
    statusLight,
    nightVision,
    spotlightBrightess,
    sensitivity,
    personDetectionThreshold,
    outdoorMonitoringEnabled,
  ]);

  return (
    <>
      <Typography
        variant="h2"
        style={{ color: 'rgba(255, 255, 255, 0.7)', marginBottom: 10 }}
      >
        Settings
      </Typography>
      <TableContainer>
        <Table aria-label="simple table" padding="none">
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                Picture quality
              </TableCell>
              <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={pictureQuality}
                    onChange={(e) => setPictureQuality(e.target.value)}
                  >
                    {supportedResolutions.map((value) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio />}
                        label={value}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                Night Vision
              </TableCell>
              <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={nightVision}
                    onChange={(e) =>
                      setNightVision(
                        e.target.value as CameraSettings['nightVision']
                      )
                    }
                  >
                    <FormControlLabel
                      value={'auto'}
                      control={<Radio />}
                      label="Auto"
                    />
                    <FormControlLabel
                      value={'on'}
                      control={<Radio />}
                      label="On"
                    />
                    <FormControlLabel
                      value={'off'}
                      control={<Radio />}
                      label="Off"
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                Status light
              </TableCell>
              <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={statusLight}
                    onChange={(e) =>
                      setStatusLight(
                        e.target.value as CameraSettings['statusLight']
                      )
                    }
                  >
                    <FormControlLabel
                      value={'on'}
                      control={<Radio />}
                      label="On"
                    />
                    <FormControlLabel
                      value={'off'}
                      control={<Radio />}
                      label="Off"
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>
            </TableRow>
            {camera.supportedFeatures.spotlight && (
              <TableRow>
                <TableCell
                  style={{
                    borderBottom: 'none',
                    fontSize: 14,
                    paddingRight: 20,
                  }}
                >
                  Spotlight Brightness
                </TableCell>
                <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={spotlightBrightess}
                      onChange={(e) =>
                        setSpotlightBrightness(
                          e.target
                            .value as CameraSettings['spotlightBrightness']
                        )
                      }
                    >
                      <FormControlLabel
                        value="low"
                        control={<Radio />}
                        label="Low"
                      />
                      <FormControlLabel
                        value="medium"
                        control={<Radio />}
                        label="Medium"
                      />
                      <FormControlLabel
                        value="high"
                        control={<Radio />}
                        label="High"
                      />
                    </RadioGroup>
                  </FormControl>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                Motion Detection
              </TableCell>
              <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={motionDetection}
                    onChange={(e) => setMotionDetection(e.target.value)}
                  >
                    <FormControlLabel
                      value="personOnly"
                      control={<Radio />}
                      label="Person Only"
                    />
                    <FormControlLabel
                      value="allMotion"
                      control={<Radio />}
                      label="All Motion"
                    />
                    <FormControlLabel
                      value="off"
                      control={<Radio />}
                      label="Off"
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ borderBottom: 'none', fontSize: 14, paddingRight: 20 }}
              >
                Sensitivity
              </TableCell>
              <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={sensitivity}
                    onChange={(e) =>
                      setSensitivity(
                        e.target.value as CameraSettings['sensitivity']
                      )
                    }
                  >
                    <FormControlLabel
                      value="low"
                      control={<Radio />}
                      label="Low"
                    />
                    <FormControlLabel
                      value="medium"
                      control={<Radio />}
                      label="Medium"
                    />
                    <FormControlLabel
                      value="high"
                      control={<Radio />}
                      label="High"
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ borderBottom: 'none', fontSize: 14, paddingRight: 20 }}
              >
                Person Detection Threshold
              </TableCell>
              <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={personDetectionThreshold}
                    onChange={(e) =>
                      setPersonDetectionThreshold(e.target.value)
                    }
                  >
                    <FormControlLabel
                      value="3"
                      control={<Radio />}
                      label="Low"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Medium"
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="High"
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ borderBottom: 'none', fontSize: 14, paddingRight: 20 }}
              >
                ODMON Mode
              </TableCell>
              <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                <FormControl disabled={!isOutdoorModel(camera.model)}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={outdoorMonitoringEnabled}
                    onChange={(e) =>
                      setOutdoorMonitoringEnabled(e.target.value)
                    }
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="On"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="Off"
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        color="primary"
        variant="contained"
        style={{ marginTop: 20, textTransform: 'none', fontSize: 15 }}
        onClick={onSubmit}
      >
        Send Settings
      </Button>
    </>
  );
};

export default CameraSettingsDetails;
