import { FC, Fragment, useMemo, useState } from 'react';
import {
  Avatar,
  Grid,
  Card,
  Box,
  Typography,
  makeStyles,
  Divider,
} from '@material-ui/core';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import {
  Face as FaceInterface,
  Person,
  useGetPeople,
} from 'src/hooks/peopleHooks';
import { apiURL } from 'src/components/DataStream/constants';
import DenseCardHeader from 'src/components/DenseCardHeader';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '80px',
    height: 'auto',
  },
}));

interface IProps {
  userId: number;
  isFake: boolean;
  fakeSid: number;
}

const FaceGrid: FC<IProps> = ({ userId, isFake, fakeSid }) => {
  const { data: persons = [], isLoading } = useGetPeople(
    userId,
  );
  const [selectedPerson, setSelectedPerson] = useState(null);
  const classes = useStyles();

  const getSids = (person: Person) => {
    const defaultSid = !isNaN(person?.sid) && person?.sid ? [person?.sid] : [];
    return (person?.locations || []).length === 0
      ? defaultSid
      : person?.locations || [];
  };

  const personsWithFaces = persons.filter((person: Person) => {
    return (
      (person?.faces?.length || person?.main_photo) && getSids(person).length
    );
  });

  const personsWithPhotos = persons.filter(
    (person: Person) => person.photos?.length
  );

  const selectedPersonFaces = useMemo(() => {
    const person = personsWithFaces.filter(
      (x: Person) => x.person_id === selectedPerson
    );
    return person.length ? person[0].faces : [];
  }, [personsWithFaces, selectedPerson]);

  if (isLoading) {
    return <LoadingSpinner message="Getting faces data" />;
  }

  if (!personsWithFaces.length) {
    return <Typography>No faces in DB against this customer</Typography>;
  }

  return (
    <Fragment>
      <Grid container spacing={1}>
        {personsWithFaces.map((person: Person) => (
          <Grid item>
            <Card>
              <Box
                height="100%"
                border={person.person_id === selectedPerson ? 2 : 0}
                onClick={() => setSelectedPerson(person.person_id)}
                className={'fs-exclude'}
              >
                <DenseCardHeader title={person.name || 'Empty'} />
                <Avatar
                  src={`${apiURL}/${person?.main_photo || person.faces?.[0]?.img_file
                    } `}
                  variant="rounded"
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      {selectedPersonFaces.length ? (
        <Card style={{ paddingBottom: 25 }}>
          <DenseCardHeader title="Known examples" />
          <Grid container spacing={1}>
            {selectedPersonFaces.map((face: FaceInterface) => (
              <Grid item>
                <Card className={'fs-exclude'}>
                  <Avatar
                    src={`${apiURL}/${face.img_file}`}
                    variant="rounded"
                    className={classes.avatar}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Card>
      ) : (
        ''
      )}
      <Divider style={{ marginTop: 20, marginBottom: 10 }} />
      <Grid container spacing={1}>
        <DenseCardHeader title="Uploaded photos" />
        {personsWithPhotos.map((person: Person) => (
          <Grid item>
            <Card style={{ paddingBottom: 25 }}>
              <Box height="100%" className={'fs-exclude'}>
                <DenseCardHeader title={person.name || 'Empty'} />
                {person.photos.map((photo) => (
                  <Box marginBottom={1}>
                    <Avatar
                      src={`${apiURL}/${photo.img_file}`}
                      variant="rounded"
                    />
                  </Box>
                ))}
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};

export default FaceGrid;
