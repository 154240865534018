import React from 'react';
import { Navigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Theme } from 'src/theme/index';
import Page from 'src/components/Page';
import { useAuth0 } from '@auth0/auth0-react';
import FullPageSpinner from 'src/components/FullPageSpinner';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const LoginView = () => {
  const classes = useStyles();
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const authError = localStorage.getItem('auth:error');

  React.useEffect(() => {
    // Clear auth error on unmount
    return () => {
      localStorage.removeItem('auth:error');
    };
  }, []);

  if (isLoading) {
    return <FullPageSpinner message="Loading App..." />;
  }

  return isAuthenticated ? (
    <Navigate to="/app/dashboard" />
  ) : (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="80%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box mb={3}>
            <Typography color="textPrimary" variant="h2">
              {process.env.REACT_APP_LOGIN_TITLE || 'PCS Agent Portal'}
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="body2">
              Sign in on the internal platform with your SimpliSafe credentials
            </Typography>
          </Box>

          <Box my={2}>
            <Button
              color="primary"
              disabled={isLoading}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => loginWithRedirect()}
            >
              Log in with Auth0
            </Button>
          </Box>

          {authError && (
            <Box my={2}>
              <Typography color="error" variant="body1">
                {authError}
              </Typography>
            </Box>
          )}
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
