import './wdyr';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import 'react-quill/dist/quill.snow.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { AppProviders } from './context/AppProviders';
import SocketContextProvider from './context/socket-context';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { init as FullStoryInit } from '@fullstory/browser';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_KEY,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.02,
});

// Set up FullStory tracking
// https://www.npmjs.com/package/@fullstory/browser
const FULLSTORY_ORG_ID = 'o-1KYHXC-na1';
FullStoryInit({
  orgId: FULLSTORY_ORG_ID, // The SimpliSafe org in FullStory is shared between VV, ODMON, and potentially others
  // debug: false,
  // devMode: process.env.REACT_APP_BUILD_ENV !== 'production', // To save on costs, normally deactivate in Dev + QA.  Enable for testing.
});

ReactDOM.render(
  <BrowserRouter>
    <SocketContextProvider>
      <AppProviders>
        <App />
      </AppProviders>
    </SocketContextProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
