import moment from 'moment';

const BUFFER_LIMIT = 500;
const BUFFER = [] as Array<Record<string, any>>;

export function addCameraInfo(data: Record<string, any>) {
  BUFFER.push({
    platform: 'web',
    ts: moment().toISOString(),
    ...data,
  });
  if (BUFFER.length > BUFFER_LIMIT) {
    BUFFER.splice(0, BUFFER.length - BUFFER_LIMIT);
  }
}

export type Request = {
  list: typeof BUFFER;
};

async function uploadCameraInfo(token: string, list: Request) {
  const endpoint = '/front-metrics/camera-info';
  const url = process.env.REACT_APP_PCS_ADMIN_URL + endpoint;
  const config = {
    method: 'POST',
    body: JSON.stringify(list),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  return window.fetch(url, config).then((response) => {
    if (response.status === 401) {
      console.log(`401 Not Authorized on PCS Client`);
      return Promise.reject(
        new Error(`Error caught: Unauthorized PCS call to ${endpoint}`)
      );
    }
    if (!response.ok) {
      return response.text().then((text) => {
        throw new Error(text);
      });
    }
    return response.json();
  });
}

async function uploadCameraInfoTick() {
  if (BUFFER.length > 0) {
    const token = localStorage.getItem('token');
    if (token) {
      const list = BUFFER.splice(0, BUFFER.length);
      try {
        await uploadCameraInfo(token, {
          list,
        });
      } catch (e) {
        console.error(`Error sending camera info: ${JSON.stringify(list)}`, e);
      }
    }
  }
}

setInterval(uploadCameraInfoTick, 1000);
