export interface KvsCameraServiceProps {
  uuid: string;
  sid: number;
  authHeader: string;
}

export enum KvsCameraConnectionStatus {
  Unknown = 'unknown',
  Offline = 'offline',
  Online = 'online',
}

export interface KvsCamera {
  status: KvsCameraConnectionStatus;
  channelARN: string;
  channelEndpoint: string;
  signedChannelEndpoint: string;
  clientId: string;
  iceServers: any[];
}

export function getKvsCameraInfo(
  { uuid, sid, authHeader }: KvsCameraServiceProps,
  abortSignal?: AbortSignal
): Promise<KvsCamera> {
  return fetch(
    `${process.env.REACT_APP_PCS_ADMIN_URL}/camera/${uuid}/${sid}/live-view`,
    {
      signal: abortSignal,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => {
      if (error.name !== 'AbortError') {
        throw error;
      }
    });
}
