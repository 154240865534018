import React from 'react';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
import Notifications from './Notifications';
import { FaqEdit } from './contents';
import { useContentQuery, useSaveContent } from '../../../hooks/contentHooks';
import { useGetRepMe } from 'src/hooks/repHooks';
import { Role } from 'src/models';
import FacialRecognition from './FacialRecognition';
import ExportParticipantEmailLog from './ExportParticipantEmailLog';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const SettingsView = () => {
  const classes = useStyles();
  const contentQuery = useContentQuery('faq');
  const contentSave = useSaveContent('faq');
  const repQuery = useGetRepMe();

  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth="lg">
        <Notifications />
        {repQuery.data?.role === Role.ADMIN && (
          <Box mb={3}>
            <FacialRecognition />
          </Box>
        )}
        {repQuery.data?.role === Role.ADMIN && (
          <Box mb={3}>
            <ExportParticipantEmailLog />
          </Box>
        )}
        {contentQuery.data !== undefined && contentQuery.data !== null && (
          <Box mt={3}>
            <FaqEdit
              defaultValue={contentQuery.data}
              onChange={(v) => contentSave.mutate(v)}
            />
          </Box>
        )}
        <Typography variant="caption" color="textPrimary">
          {`Version: ${process.env.REACT_APP_VERSION}, Build: ${process.env.REACT_APP_GIT_SHA}, Env: ${process.env.REACT_APP_BUILD_ENV}`}
        </Typography>
      </Container>
    </Page>
  );
};

export default SettingsView;
