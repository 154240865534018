import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Card, makeStyles } from '@material-ui/core';
import Webcam from 'react-webcam';

const useStyles = makeStyles({
  root: {
    height: '100%',
    '& div': {
      '& video': {
        width: '100%',
      },
    },
  },
});

const handleUserMedia = (mediastream) => {};
const handleUserMediaError = (error) => {};

export const WebCamera = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box maxHeight="700" position="relative">
        <Webcam
          audio={false}
          onUserMedia={handleUserMedia}
          onUserMediaError={handleUserMediaError}
        />
      </Box>
    </Card>
  );
};

export const WebCameraDevices = ({ className, ...rest }) => {
  const classes = useStyles();

  // const [deviceId, setDeviceId] = useState({});
  const [devices, setDevices] = useState([]);

  const handleDevices = useCallback(
    (mediaDevices) => {
      setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput'));
    },
    [setDevices]
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box maxHeight="700">
        {devices.map((device, key) => (
          <div>
            <Webcam
              audio={false}
              onUserMedia={handleUserMedia}
              onUserMediaError={handleUserMediaError}
              videoConstraints={{
                deviceId: device.deviceId,
                // facingMode: 'user',
                // facingMode: { exact: "environment" }
              }}
            />
            {device.label || `Device ${key + 1}`}
          </div>
        ))}
      </Box>
    </Card>
  );
};

WebCamera.propTypes = {
  className: PropTypes.string,
};
WebCameraDevices.propTypes = {
  className: PropTypes.string,
};

export default WebCamera;
