import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';
import { usePcsClient } from 'src/context/pcs-auth-context';

export function useListAuditQuery(params: any) {
  const client = usePcsClient();
  const query = useQuery(
    ['history', params],
    () => client(`audit/changes`, { params: params }),
    {
      enabled: !!params?.event_id,
      refetchInterval: 20000,
    }
  );
  return query;
}

export function useListAuditEmailQuery(params: any) {
  const client = usePcsClient();
  const query = useQuery(
    ['history', params],
    () => client(`audit/emails`, { params: params }),
    {
      refetchInterval: 20000,
    }
  );
  return query;
}

export function useListAuditSessionQuery(eventId: number) {
  const client = usePcsClient();
  const query = useQuery(
    ['audit-session', eventId],
    () => client(`manage-session/list`, { params: { eventId } }),
    {
      enabled: !!eventId,
      refetchInterval: 20000,
    }
  );
  return query;
}

export function useGenerateRecordingURL() {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (file_name: string) =>
      client('manage-session/generate_recording_url', {
        method: 'POST',
        data: { file_name },
      }),
    {
      onError: (error) => {
        enqueueSnackbar(
          `Endpoint: manage-session/generate_recording_url. ${error}`,
          {
            variant: 'error',
          }
        );
      },
    }
  );

  return mutation;
}
