import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { FC, useCallback, useState } from 'react';

import { apiURL, css_height, css_width } from '../../constants';
import { get_zoom_crop } from '../../helpers';
import { ICommonProps, ITrack } from '../../types';
import Controls from '../Controls';

interface IProps extends ICommonProps {
  eventId: number;
  track: ITrack;
  onImportant: (img_file) => void;
  title?: string;
  showCluster?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  cover: {
    width: css_width,
    minWidth: css_width,
    height: css_height,
    margin: '5px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    padding: '5px',
  },
  avatarWrapper: {
    padding: '2px',
  },
}));

const Details: FC<IProps> = ({
  eventId,
  track,
  isSelectingPeople,
  onItemClick,
  onImportant,
  title,
  showCluster,
}) => {
  const classes = useStyles();

  const [dimension, setDimension] = useState(null);

  if (!dimension) {
    const image = new Image();

    image.src = `${apiURL}/${track.img_file}`;

    image.onload = () => {
      setDimension({
        width: image.naturalWidth,
        height: image.naturalHeight,
      });
    };
  }

  const {
    zoom_factor_w_percent,
    zoom_factor_h_percent,
    position_x,
    position_y,
  } = useCallback(get_zoom_crop(track.bbox, dimension), [
    track.bbox,
    dimension,
  ]);

  return (
    <>
      <Grid item xs={isSelectingPeople ? 3 : 6}>
        <Card className={classes.avatarWrapper}>
          <CardActionArea onClick={() => onItemClick(track)}>
            <CardMedia
              image={`${apiURL}/${track.img_file}`}
              className={`${classes.cover} fs-exclude`}
              style={{
                backgroundPositionX: position_x,
                backgroundPositionY: position_y,
                backgroundSize: `${zoom_factor_w_percent} ${zoom_factor_h_percent}`,
              }}
            />
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={isSelectingPeople ? 3 : 6}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5">
              {title ? (
                title
              ) : (
                <>
                  <span className={classes.capitalize}>{track.class_name}</span>{' '}
                  {track.object_id}
                </>
              )}
            </Typography>
            {track.current_confidence && track.max_confidence && (
              <Typography variant="subtitle1" color="textSecondary">
                Conf:
                {` ${(track.current_confidence * 100.0).toFixed(0)}% / 
                  ${(track.max_confidence * 100.0).toFixed(0)}%`}
              </Typography>
            )}
            {track.bbox && (
              <Typography variant="subtitle1" color="textSecondary">
                Size:
                {` ${(track.bbox.w * track.bbox.h).toFixed(0)}`}
              </Typography>
            )}
            <Controls
              eventId={eventId}
              track={track}
              onImportant={onImportant}
              showCluster={showCluster}
            />
          </CardContent>
        </div>
      </Grid>
    </>
  );
};

export default Details;
