import { useRef, useState, useEffect, useMemo } from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { CircularProgress, IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useParticipantExport } from 'src/hooks/participantEmailLogHooks';

const ExportLink = () => {
  const userRole = localStorage.getItem('role');
  const csvRef = useRef();
  const [fetchData, setFetchData] = useState(false);

  let { data = [], done } = useParticipantExport(fetchData);

  useEffect(() => {
    if (fetchData && done) {
      csvRef.current.link.click();
      setFetchData(false);
    }
  }, [csvRef, setFetchData, done, fetchData]);

  const isDisabled = userRole !== 'admin' || fetchData;

  const csvData = useMemo(
    () =>
      data.map((item) => ({
        'User (user id)': item.userId,
        Email: item.email,
        Platform: item.platform,
        createdAt: item.createdAt,
      })),
    [data]
  );

  return (
    <>
      <IconButton
        onClick={() => setFetchData(true)}
        color="primary"
        variant="contained"
        disabled={isDisabled}
      >
        <GetAppIcon />
        Export Email Logs
      </IconButton>

      <CSVLink data={csvData} filename={'logs.csv'} ref={csvRef} />
    </>
  );
};

export default ExportLink;
