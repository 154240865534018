import { useQuery } from 'react-query';
import { usePcsClient } from 'src/context/pcs-auth-context';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

// TYPES
interface  ParticipantEmailLog {
  userId: number;
  email: string;
  platform: string;
  createdAt: string;
}

export function useParticipantExportList(
  skip: number = 0,
  limit: number = 10,
  enabled: boolean = true
) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, error } = useQuery({
    queryKey: ['export-participants', { skip, limit }],
    queryFn: () =>
      client(`settings/participate/list/?skip=${skip}&limit=${limit}`),
    onError: (err) => {
      enqueueSnackbar(`Endpoint: settings/participate/list. ${err}`, {
        variant: 'error',
      });
    },
    enabled: enabled,
  });
  return {
    data,
    isLoading,
    error,
  };
}

export function useParticipantExport(
  enabled: boolean = true
) {
  const [page, setPage] = useState(0);
  const [participants, setParticipants] = useState([]);

  const limit = 100;
  const skip = page * limit;
  // We are not giving default value to data because we use undefined as a
  // check for the first time execution.
  let { data } = useParticipantExportList(skip, limit, enabled);

  useEffect(() => {
    setParticipants([]);
    setPage(0);
  }, []);

  useEffect(() => {
    // data will be undefined only first time.  data.length === 0 will only be
    // true when have traversed all pages. If we are here, we are sure that
    // there are records in the DB.
    if (data !== undefined && data.length && enabled) {
      setParticipants([...participants, ...data]);
      setPage(page + 1);
    }
  }, [data, page, participants, setPage, setParticipants, enabled]);

  return {
    data: participants,
    done: data !== undefined && !data.length,
  };
}
