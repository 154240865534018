import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { usePcsClient } from 'src/context/pcs-auth-context';

export function useContentQuery(name: string) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery(['content', name], () => client(`content/${name}`), {
    onError: (error) => {
      enqueueSnackbar(`Endpoint content/${name}. ${error}`, {
        variant: 'error',
      });
    },
  });
  return query;
}

export function useSaveContent(name: string) {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (data: string) =>
      client(`content/${name}`, {
        method: 'PUT',
        data: {
          name: name,
          body: data,
        },
      }),
    {
      onSuccess: async () => {
        await cache.invalidateQueries(['content', name]);
      },
      onError: (error, variables) => {
        enqueueSnackbar(`Error saving content ${name}: ${error}`, {
          variant: 'error',
        });
      },
    }
  );
  return mutation;
}
