import React, { createContext, useReducer } from 'react';
import { playTone } from 'src/utils/utils';

export const SocketContext = createContext();

export const setEventsDataAC = (eventArray, toggleState) => {
  return {
    type: 'app/setEventsData',
    eventArray,
    toggleState,
  };
};
export const updateEventDataAC = (event) => {
  return {
    type: 'app/updateEventData',
    event,
  };
};

export const initState = {
  eventArray: [],
};

export const appReducer = (state, action) => {
  switch (action.type) {
    case 'app/setEventsData': {
      let copyState = { ...state };
      if (state.eventArray !== undefined && action.toggleState) {
        const currentIDs = state.eventArray?.map((x) => x.eventId);
        const newEvent = action.eventArray
          ?.map((x) => x.eventId)
          .filter((x) => !currentIDs.includes(x));
        if (newEvent?.length > 0) playTone('/static/dilin.mp3');
      }
      copyState.eventArray = action.eventArray;
      return copyState;
    }
    case 'app/updateEventData': {
      let copyState = { ...state };
      copyState.eventArray = Array.isArray(copyState.eventArray)
        ? copyState.eventArray.map((event) => {
            if (event.eventId === action.event.eventId) {
              return { ...event, ...action.event };
            } else {
              return event;
            }
          })
        : [];
      return copyState;
    }
    default: {
      return state;
    }
  }
};

const SocketContextProvider = ({ children }) => {
  const state = useReducer(appReducer, initState);

  return (
    <SocketContext.Provider value={state}>{children}</SocketContext.Provider>
  );
};

export default SocketContextProvider;
