import React, { useReducer } from 'react';
import useMousetrap from 'react-hook-mousetrap';

const KeysState = React.createContext();
KeysState.displayName = 'KeysState';
const KeysDispatch = React.createContext();
KeysDispatch.displayName = 'KeysDispatch';

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD':
      return {
        ...state,
        registeredKeys: [...state.registeredKeys, action.payload],
      };
    case 'REMOVE':
      return {
        ...state,
        registeredKeys: state.registeredKeys.filter(
          ({ key: existingKey }) => action.payload.key !== existingKey
        ),
      };
    default:
      throw new Error();
  }
};

// eslint-disable-next-line react/prop-types
function HotkeyProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {
    registeredKeys: [],
  });

  return (
    <KeysState.Provider value={state.registeredKeys}>
      <KeysDispatch.Provider value={dispatch}>{children}</KeysDispatch.Provider>
    </KeysState.Provider>
  );
}

function useGetKeys() {
  const registeredKeys = React.useContext(KeysState);
  return registeredKeys;
}
function useKey(key, callback, desc) {
  const dispatch = React.useContext(KeysDispatch);

  React.useEffect(() => {
    dispatch({ type: 'ADD', payload: { key, desc } });
    return () => {
      dispatch({ type: 'REMOVE', payload: { key } });
    };
  }, [key, desc, dispatch]);

  // useMousetrap(<Keys>, <Callback>)
  useMousetrap(key, (e, combo) => {
    e.preventDefault(); // Prevent default key behavior (space bar action, etc)
    callback(e, combo);
  });
}

export { HotkeyProvider, useGetKeys, useKey };
