import Page from '../../components/Page';
import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export const SubscriberCreateView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Create Subscriber">
      'https://qa.auth.simplisafe.com/authorize?response_type=code&client_id=yP3R0Us9fnV9z3NnAZ9kRCsWUAqTIxxI&redirect_uri=http://localhost:3000/callback&connection=ss-users&';
    </Page>
  );
};
