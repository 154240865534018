import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import {
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  Select,
  MenuItem,
  makeStyles,
  InputLabel,
  IconButton,
  FormControl,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { PcsStatus } from 'src/models';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { useGetRepMe } from 'src/hooks/repHooks';
import {
  SearchEventContext,
  EVENT_LIST_FILTER_INITIAL_DATA,
} from './SearchEventContext';
import { useDebounce } from '@uidotdev/usehooks';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 216,
  },
  clearFilterStyle: {
    marginTop: '1rem',
    marginLeft: '0.5rem',
  },
}));

const Toolbar = ({
  className,
  handleChangeFilterInfo,
  filtersError,
  setFiltersErrors,
  handleChangeDate,
  clearFilterEnabled = false,
  ...rest
}) => {
  const classes = useStyles();
  const { searchQueriesForEventList, setSearchQueriesForEventList } =
    useContext(SearchEventContext);

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { data: repData } = useGetRepMe();

  const clearFilter = () => {
    setSearchQueriesForEventList({ ...EVENT_LIST_FILTER_INITIAL_DATA });
  };
  const handleEndViewedDate = (date) => {
    handleChangeDate(date, 'endDatetime');
    setSearchQueriesForEventList({
      ...searchQueriesForEventList,
      endDatetime: date,
    });
  };
  const handleStartViewedDate = (date) => {
    handleChangeDate(date, 'startDatetime');
    setSearchQueriesForEventList({
      ...searchQueriesForEventList,
      startDatetime: date,
    });
  };

  // Network requests for search are triggered just by updating the search query
  // We only want that to happen once the user has stopped typing with a minimum input length
  useEffect(() => {
    if (debouncedSearchTerm.length > 3) {
      setSearchQueriesForEventList({
        ...searchQueriesForEventList,
        search: debouncedSearchTerm,
      });
    }
  }, [debouncedSearchTerm]);

  if (repData?.role === 'engineer' || repData?.role === 'agent_ops')
    return <></>;

  return (
    <div {...rest}>
      <Box mt={0} display="inline">
        <Box display="inline-flex" width={350}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder="uid, sid, uuid, account"
            variant="outlined"
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setSearchQueriesForEventList({
                  ...searchQueriesForEventList,
                  search: debouncedSearchTerm,
                });
              }
            }}
          />
        </Box>
        <Box>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <DatePicker
              name="startDatetime"
              clearable
              inputVariant="outlined"
              label="Date start"
              value={searchQueriesForEventList.startDatetime}
              onChange={handleStartViewedDate}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <DatePicker
              name="endDatetime"
              clearable
              inputVariant="outlined"
              label="Date end"
              value={searchQueriesForEventList.endDatetime}
              onChange={handleEndViewedDate}
            />
          </MuiPickersUtilsProvider>
          <TextField
            error={filtersError.sid}
            label="Sid"
            name="sid"
            onChange={handleChangeFilterInfo}
            value={searchQueriesForEventList.sid}
            variant="outlined"
            onBlur={handleChangeFilterInfo}
          />
          <TextField
            error={filtersError.user_id}
            label="UID"
            name="user_id"
            onChange={handleChangeFilterInfo}
            value={searchQueriesForEventList.user_id}
            variant="outlined"
            onBlur={handleChangeFilterInfo}
          />
          <TextField
            error={filtersError.eventId}
            label="Event ID"
            name="eventId"
            onChange={handleChangeFilterInfo}
            value={searchQueriesForEventList.eventId}
            variant="outlined"
            onBlur={handleChangeFilterInfo}
          />
        </Box>
        <Box>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Detection filter</InputLabel>
            <Select
              value={searchQueriesForEventList.detection_filter}
              label="Detection filter"
              name="detection_filter"
              onChange={handleChangeFilterInfo}
            >
              <MenuItem value={''}>Empty</MenuItem>
              <MenuItem value={'faces'}>Faces</MenuItem>
              <MenuItem value={'moves'}>Motion</MenuItem>
              <MenuItem value={'tracks'}>People</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>PCS status</InputLabel>
            <Select
              value={searchQueriesForEventList.pcs_status}
              label="PCS status"
              name="pcs_status"
              onChange={handleChangeFilterInfo}
            >
              <MenuItem value={''}>Empty</MenuItem>
              <MenuItem value={PcsStatus.not_monitored}>Not monitored</MenuItem>
              <MenuItem value={PcsStatus.canceled}>Canceled</MenuItem>
              <MenuItem value={PcsStatus.operator_handled}>
                Operator handled
              </MenuItem>
              <MenuItem value={PcsStatus.pcs_handled}>PCS handled</MenuItem>
              <MenuItem value={PcsStatus.expired}>Expired</MenuItem>
              <MenuItem value={PcsStatus.new}>New</MenuItem>
              <MenuItem value={PcsStatus.watch}>Watch</MenuItem>
              <MenuItem value={PcsStatus.verify}>Verify</MenuItem>
              <MenuItem value={PcsStatus.dispatch}>Dispatch</MenuItem>
              <MenuItem value={PcsStatus.follow_up}>Follow up</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Handling</InputLabel>
            <Select
              value={searchQueriesForEventList.handling}
              label="Handling"
              name="handling"
              onChange={handleChangeFilterInfo}
            >
              <MenuItem value={''}>Empty</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
          {clearFilterEnabled && (
            <IconButton
              onClick={clearFilter}
              className={classes.clearFilterStyle}
              aria-label="Clear"
              size="small"
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
        </Box>
        <Box display="inline-flex" flexGrow={1} />
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  filterValues: PropTypes.string,
  handleChangeFilterInfo: PropTypes.func,
  filtersError: PropTypes.object,
  setFiltersErrors: PropTypes.func,
  handleChangeDate: PropTypes.func,
  clearFilterEnabled: PropTypes.bool,
};

export default Toolbar;
