import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { useUpdateRepMe } from 'src/hooks/repHooks';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  root: {},
}));

const ProfileDetails = ({ rep, className, ...rest }) => {
  const classes = useStyles();

  const [error, setError] = useState('');

  const [values, setValues] = useState({
    firstName: rep.firstName,
    lastName: rep.lastName,
    email: rep.email,
    user_id: rep.user_id,
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const updateRepMutation = useUpdateRepMe();
  const handleSubmit = () => {
    const { ...update } = values;
    updateRepMutation.mutate({ ...update });
    setError('');
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="Edit below to update" title="Account Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="First name"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="User ID"
                helperText="SimpliSafe User ID"
                name="user_id"
                onChange={handleChange}
                required
                // type="number"
                value={values.user_id}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Save details
          </Button>
        </Box>
        <Box my={2}>{error && <Alert severity="error">{error}</Alert>}</Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  rep: PropTypes.object,
  className: PropTypes.string,
};

export default ProfileDetails;
