import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

const CameraBatteryHistory = ({ camera }) => {
  const { cameraSettings } = camera;

  const batteryX = cameraSettings.admin.battery.map((item: any) => moment.unix(item[0]).format("MM/DD"));
  const batteryY = cameraSettings.admin.battery.map((item: any) => item[1]);

  const options = {
    legend: {
      display: false,
    },
    responsive: true,
    title: {
      display: true,
      text: 'Batt %',
      position: 'left',
      fontSize: '16',
    },
    scales: {
      yAxes: [{
        ticks: {
          max: 0,
          min: 100
        }
      }]
    }
  };

  const data = {
    labels: batteryX,
    datasets: [
      {
        data: batteryY,
        borderColor: 'rgb(0, 141, 141)',
      },
    ],
  };
  return (
    <>
      <Typography
        variant="h2"
        style={{
          color: 'rgba(255, 255, 255, 0.7)',
          marginBottom: 30,
        }}
      >
        Battery History
      </Typography>
      <Line options={options} data={data} />
    </>
  );
};

export default CameraBatteryHistory;
