import React from 'react';
import { PropTypes } from 'prop-types';

function Marker(props) {
  const { marker, duration, onMarkerClick } = props;
  const { time, color, title, category } = marker;
  const id = String(marker.id);

  const getPosition = () => {
    if (duration) {
      const percent = time <= duration ? time / duration : 1;
      return `calc(${percent * 100}% - 2px)`;
    }
    return '-9999px';
  };

  const getTop = () => {
    if (category === 'tracks') return '16px';
    if (category === 'moves') return '19px';
    if (category === 'faces') return '22px';
    return '25px';
  };

  return (
    <i
      id={id}
      className="react-video-marker"
      title={title}
      style={{
        background: color,
        left: getPosition(),
        top: getTop(),
      }}
      onClick={() => {
        onMarkerClick(marker);
      }}
    />
  );
}

Marker.propTypes = {
  marker: PropTypes.object,
  duration: PropTypes.number,
  onMarkerClick: PropTypes.func,
};

export default Marker;
