import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
} from '@material-ui/core';
import { PromiseButton } from '../../../components/PromiseButton';
import MarkdownEditor from '@uiw/react-markdown-editor';

export function FaqEdit({
  defaultValue = '',
  onChange = () => undefined,
}: {
  defaultValue?: string;
  onChange?: (value: string) => void | Promise<void>;
}) {
  const theme = useTheme();
  const [value, setValue] = React.useState(defaultValue);
  return (
    <Card>
      <CardHeader subheader="FAQ mobile app content" title="FAQ" />
      <Divider />
      <CardContent>
        <Box border={1} display="flex" flexDirection="column">
          <MarkdownEditor value={value} onChange={setValue} />
        </Box>
      </CardContent>
      <Divider />
      <Box display="flex" justifyContent="flex-end" p={2}>
        <PromiseButton
          color="primary"
          variant="contained"
          onClick={() => onChange(value)}
        >
          Save
        </PromiseButton>
      </Box>
    </Card>
  );
}
