import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { usePcsAuthRep, usePcsClient } from 'src/context/pcs-auth-context';
import { jwtDecode, JwtPayload } from 'jwt-decode';

const AUDIT_RECORDING_ACCESS_SCOPE =
  'https://admin.pcs.simplisafe.com/recordings:read';

type JwtBody = JwtPayload & { scope: string };

interface RecordingSession {
  recording_url: string;
  eventId?: number;
  agent_uid: number;
  start_time: Date;
  end_time: Date;
}

export function useAddRecordingSession() {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (recording: RecordingSession) =>
      client('manage-session/add', {
        method: 'POST',
        data: recording,
      }),
    {
      onError: (error) => {
        enqueueSnackbar(`Endpoint: manage-session/add. ${error}`, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
}

export function useGeneratePresignedURL() {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (file_name: string) =>
      client('manage-session/generate_url', {
        method: 'POST',
        data: { file_name },
      }),
    {
      onError: (error) => {
        enqueueSnackbar(`Endpoint: manage-session/add. ${error}`, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
}

export function useUserCanAccessAuditRecordings() {
  const [canAccess, setCanAccess] = React.useState(false);
  const { token } = usePcsAuthRep();

  useEffect(() => {
    const decodedToken = jwtDecode<JwtBody>(token);
    if (decodedToken.scope?.includes(AUDIT_RECORDING_ACCESS_SCOPE)) {
      setCanAccess(true);
    }
  }, [token]);
  return canAccess;
}
