import { Tooltip, styled } from '@material-ui/core';
import { common } from '@material-ui/core/colors';

export default styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => {
  return {
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#d9d9d9',
      color: common.black,
      fontSize: theme.typography.pxToRem(12),
    },
  };
});
