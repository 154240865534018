/** returns 'kvs' for webrtc camera, 'flv' otherwise */
export function cameraStreamType(camera) {
  return camera?.supportedFeatures?.providers?.webrtc === 'kvs' ? 'kvs' : 'flv';
}

export function cameraIsWired(camera) {
  return !!camera?.supportedFeatures?.wired;
}

export function cameraIsPrivate(camera, alarmState = 'ANY') {
  // Camera doesn't have a privacy shutter, so not private
  if (!camera?.supportedFeatures?.privacyShutter) return false;

  // Shutter set to closed for current alarmState?  Then private.
  if (
    (camera?.cameraSettings?.shutterOff === 'closedAlarmOnly' &&
      (alarmState === 'OFF' || alarmState === 'ANY')) ||
    (camera?.cameraSettings?.shutterHome === 'closedAlarmOnly' &&
      (alarmState === 'HOME' || alarmState === 'ANY')) ||
    (camera?.cameraSettings?.shutterAway === 'closedAlarmOnly' &&
      (alarmState === 'AWAY' || alarmState === 'ANY'))
  )
    return true;

  // Otherwise, not private
  return false;
}

export function toMbpsSpeed(speedInBytes) {
  return ((speedInBytes * 8) / (1000 * 1000)).toFixed(2);
}
