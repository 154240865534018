import React, { useContext, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Modal,
  Button,
  FormControl,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useGetRepMe } from 'src/hooks/repHooks';
import {
  useEventDispatchIncident,
  useSetPcsEventStatus,
} from 'src/hooks/eventHooks';
import { MetricsContext } from 'src/context/Metrics-context';
import MetricsModule from 'src/utils/MetricsModule';
import {
  useSendFollowUpEmail,
  useSendPushNotification,
} from 'src/hooks/subscriberHooks';
import { htmlToText } from 'src/utils/format';
import { NotificationCategory, PcsEvent } from 'src/types';
import { getLocalDateFormatted, getLocalTimeFormatted } from 'src/utils/time';
import { getCameraName } from 'src/utils/utils';
import { Action, PcsStatus } from 'src/models';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    marginTop: '100px',
  },
  buttons: {
    '&:disabled': {
      background: '#8d8884',
      color: 'white',
    },
  },
  dispatch: {
    background: '#df6763',
    padding: 0,
    marginLeft: 10,
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    padding: 32,
    background: '#d9d9d9',
  },
  description: {
    background: 'white',
    height: 200,
    fontFamily: theme.typography.fontFamily,
    padding: 5,
    border: 'none',
    outline: 'none',
    color: 'black',
  },
  fields: {
    display: 'flex',
    width: '80%',
    flexDirection: 'column',
  },
  formControl: {
    width: '80%',
  },
  title: {
    marginTop: 10,
  },
  select: {
    padding: 8,
    border: 'none',
    outline: 'none',
    borderRight: '8px solid transparent',
    color: 'black',
    background: 'white',
  },
  cancelButton: {
    background: '#8d8884',
  },
}));

const replacePlaceholder = (template: string, values: Record<string, any>) =>
  template.replace(/{(\w+)}/g, (match, key) => {
    return values[key] !== undefined ? values[key] : match;
  });

const OPTIONS = [
  {
    text: 'Immediate Police Dispatch - Violence/Home Entry',
    eventCid: 1173,
    zoneCid: 0,

    disposition: Action.dispatched_police,
    email: {
      subject: 'Violence or Burglary Alarm - {date} at {time}',
      body: "Violence or a burglary was reported on your property at {time}. We requested the police dispatch at {time}. This event was detected by your '{camera_name}' camera.",
    },
    push: {
      title: 'Violence or Burglary Alarm - {camera_name}',
      message:
        'Violence or a burglary was reported on your property at {trigger_timestamp}.',
    },
  },
  {
    text: 'Immediate Fire Dispatch - Flames',
    eventCid: 1173,
    zoneCid: 2,

    disposition: Action.dispatched_fire,
    email: {
      subject: 'Fire Alarm - {date} at {time}',
      body: "A fire was reported on your property at {time}. This event was detected by your '{camera_name}' camera. We're requesting dispatch and will call you after.",
    },
    push: {
      title: 'Fire Alarm - {camera_name}',
      message: 'A fire was reported on your property at {trigger_timestamp}.',
    },
  },
  {
    text: 'Immediate Medical Dispatch',
    eventCid: 1173,
    zoneCid: 1,

    disposition: Action.dispatched_medical,
    email: {
      subject: 'Medical Emergency Alarm - {date} at {time}',
      body: "A medical emergency was reported on your property at {time}. This event was detected by your '{camera_name}' camera. We're requesting dispatch and will call you after.",
    },
    push: {
      title: 'Medical Emergency Alarm - {camera_name}',
      message:
        'A medical emergency was reported on your property at {trigger_timestamp}.',
    },
  },
  {
    text: 'Possible Police Dispatch - Suspicious Behavior/Property Damage',
    eventCid: 1173,
    zoneCid: 4,

    disposition: Action.dispatched_suspicious_activity,
    email: {
      subject: 'Suspicious Activity Alarm - {date} at {time}',
      body: "Suspicious activity was reported on your property at {time}. This event was detected by your '{camera_name}' camera.",
    },
    push: {
      title: 'Suspicious Activity Alarm - {camera_name}',
      message:
        'Suspicious activity was reported on your property at {trigger_timestamp}.',
    },
  },
  {
    text: 'Possible Fire Dispatch - Heavy Smoke',
    eventCid: 1173,
    zoneCid: 3,

    disposition: Action.dispatched_potential_fire,
    email: {
      subject: 'Possible Fire Alarm - {date} at {time}',
      body: "Smoke was reported on your property at {time}. This event was detected by your '{camera_name}' camera.",
    },
    push: {
      title: 'Possible Fire Alarm - {camera_name}',
      message: 'Smoke was reported on your property at {trigger_timestamp}.',
    },
  },
];

const DispatchModal = ({
  eventId,
  userId,
  event,
}: {
  eventId: number;
  userId: number;
  event: PcsEvent;
}) => {
  const [isOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatchIncident = useEventDispatchIncident(eventId);
  const [form, setForm] = useState({ option: null, description: null });
  const classes = useStyles();
  const repQuery = useGetRepMe();
  const metrics = new MetricsModule('dispatch-alarm');
  const metricPayload = useRef(metrics.payload({ eventID: eventId }));
  const { enqueueSnackbar } = useSnackbar();
  const [_, dispatchMetrics] = useContext(MetricsContext);
  const email = repQuery?.data?.email;

  const navigate = useNavigate();

  const sendFollowUpEmail = useSendFollowUpEmail();
  const sendPushNotification = useSendPushNotification();
  const setEventStatus = useSetPcsEventStatus();

  const sendNotificationsAndSetDisposition = (option: typeof OPTIONS[0]) => {
    const date = getLocalDateFormatted({
      timestampUTC: event?.eventTimestamp,
      locationOffsetMinutes: event?.locationOffset,
    });
    const time = getLocalTimeFormatted({
      timestampUTC: event?.eventTimestamp,
      locationOffsetMinutes: event?.locationOffset,
    });

    const trigger_timestamp = `${date} - ${time}`;
    const camera_name = getCameraName(event.info);

    const body = replacePlaceholder(option.email.body, {
      time,
      date,
      camera_name,
    });

    sendFollowUpEmail.mutate(
      {
        uid: userId.toString(),
        subject: replacePlaceholder(option.email.subject, {
          time,
          date,
          camera_name,
        }),
        html: body,
        text: htmlToText(body),
        eventId: eventId,
        category: NotificationCategory.emergency_events,
        critical: true,
      },
      {
        onSuccess: () => {
          enqueueSnackbar('Email dispatched!', {
            variant: 'success',
          });
        },
      }
    );

    sendPushNotification.mutate(
      {
        user_id: userId,
        title: replacePlaceholder(option.push.title, { camera_name }),
        message: replacePlaceholder(option.push.message, { trigger_timestamp }),
        event_id: eventId,
        category: NotificationCategory.emergency_events,
        critical: true,
      },
      {
        onSuccess: () => {
          enqueueSnackbar('Push notification dispatched!', {
            variant: 'success',
          });
        },
      }
    );

    setEventStatus.mutate({
      eventId,
      disposition_action: option.disposition,
      pcsStatus: PcsStatus.operator_handled,
    });

    navigate('/app/queue');
  };

  return (
    <>
      <Button
        className={classes.dispatch}
        variant="contained"
        onClick={() => setModalOpen(true)}
      >
        Dispatch
      </Button>
      <Modal
        open={isOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalBox}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            DISPATCH
          </Typography>
          <div className={classes.fields}>
            <FormControl className={classes.formControl}>
              <Typography
                className={classes.title}
                id="modal-modal-title"
                variant="h6"
                component="h6"
              >
                Reason
              </Typography>
              <select
                onChange={(item) => {
                  setForm({ ...form, option: item.target.value });
                }}
                className={classes.select}
              >
                {!form?.option && (
                  <option value={'0'}> Select a dispatch option</option>
                )}
                {OPTIONS.map((option) => {
                  return <option key={option.text} value={option.text}>{option.text}</option>;
                })}
              </select>
            </FormControl>
            <Typography
              className={classes.title}
              id="modal-modal-title"
              variant="h6"
              component="h6"
            >
              Agent Notes
            </Typography>
            <textarea
              className={classes.description}
              maxLength={450}
              placeholder="Document what you heard, saw, actions you took, and any 2WA interaction to be shared with contacts and/or dispatch."
              onChange={(evt) =>
                setForm({ ...form, description: evt?.target?.value })
              }
            />
            <Typography align="right" variant="overline">
              {form.description?.length ?? 0}/450
            </Typography>
          </div>
          <div className={classes.footer}>
            <Button
              disabled={isLoading}
              className={classes.cancelButton}
              onClick={() => {
                setModalOpen(false);
                setLoading(false);
                setForm({ description: null, option: null });
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                marginLeft: 15,
                paddingLeft: 15,
                paddingRight: 15,
              }}
              onClick={async () => {
                setLoading(true);
                const option = OPTIONS.find(
                  (_option) => _option.text === form.option
                );
                const payload = {
                  description: form.description.concat(' - ' + email),
                  eventCid: option.eventCid.toString(),
                  zoneCid: option.zoneCid.toString(),
                };
                try {
                  await dispatchIncident.mutateAsync(payload);
                  enqueueSnackbar('Successfully dispatched!', {
                    variant: 'success',
                  });
                  sendNotificationsAndSetDisposition(option);
                } catch (e) {
                  enqueueSnackbar(`Error while dispatching. ${e}`, {
                    variant: 'error',
                  });
                } finally {
                  setLoading(false);
                  setModalOpen(false);
                  dispatchMetrics({
                    type: 'SEND',
                    payload: {
                      ...metricPayload.current,
                      metricName: 'dispatch-alarm',
                      ...payload,
                    },
                  });
                }
              }}
              variant="contained"
              color="primary"
              className={classes.buttons}
              disabled={!form?.option || !form?.description}
            >
              {isLoading ? (
                <CircularProgress color="inherit" size={21} />
              ) : (
                'Dispatch'
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DispatchModal;
