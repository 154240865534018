import {
  Box,
  Button,
  Card,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { FC, Fragment, useCallback, useContext, useEffect } from 'react';

import { LoadingSpinner } from 'src/components/LoadingSpinner';
import Face from './components/Face';
import Moves from './components/Moves';
import Tracks from './components/Tracks';
import { assignCategoryOfDetectedEvents } from './helpers';
import { ICommonProps } from './types';

interface IProps extends ICommonProps {
  eventId: number;
  onImportant?: (img_file) => void;
  showCluster?: boolean;
  eventQuery: any;
  expanded?: boolean;
  hasMinDetectionsToExpand?: boolean;
}

const useStyles = makeStyles({
  scroll: {
    height: '100vh',
    overflow: 'scroll',
  },
});

const DataStream: FC<IProps> = ({
  eventId,
  isSelectingPeople,
  onItemClick,
  onImportant,
  showCluster,
  eventQuery,
  expanded,
  hasMinDetectionsToExpand,
}) => {
  const classes = useStyles();

  const detections = useCallback(assignCategoryOfDetectedEvents(eventQuery), [
    eventQuery,
  ]);

  if (eventQuery.isLoading) {
    return <LoadingSpinner />;
  }

  if (
    (!eventQuery.data?.tracks &&
      !eventQuery.data?.moves &&
      !eventQuery.data?.faces) ||
    !eventQuery.data
  ) {
    return (
      <Box p={1}>
        <Typography>No video analytics for this event (yet)</Typography>
        <Button onClick={() => onItemClick(0)}>View recording</Button>
      </Box>
    );
  }

  return (
    <Card
      className={classes.scroll}
      key="datastream"
      style={{
        maxHeight: expanded || expanded === undefined ? '100%' : '50vh',
        paddingBottom: hasMinDetectionsToExpand ? 42 : 0,
      }}
    >
      {detections?.map((detection) => (
        <Fragment key={`dataStream-${detection.id}-${detection.pts_seconds}`}>
          {detection.category === 'faces' && (
            <Box>
              <Face
                track={detection}
                people={eventQuery.data?.people}
                onItemClick={onItemClick}
                isSelectingPeople={isSelectingPeople}
              />
              <Divider />
            </Box>
          )}
          {detection.category === 'tracks' && (
            <Box>
              <Tracks
                eventId={eventId}
                isSelectingPeople={isSelectingPeople}
                onImportant={onImportant}
                onItemClick={onItemClick}
                track={detection}
                showCluster={showCluster}
              />
              <Divider />
            </Box>
          )}
          {detection.category === 'moves' && (
            <Box>
              <Moves
                eventId={eventId}
                isSelectingPeople={isSelectingPeople}
                onImportant={onImportant}
                onItemClick={onItemClick}
                track={detection}
              />
              <Divider />
            </Box>
          )}
          {detection.category !== 'faces' &&
            detection.category !== 'tracks' &&
            detection.category !== 'moves' && (
              <Typography>Unknown detection type</Typography>
            )}
        </Fragment>
      ))}
    </Card>
  );
};

export default DataStream;
