import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Box, FormControl, MenuItem, Select } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  PersonCreate,
  PersonCategory,
  useCreatePerson,
} from 'src/hooks/peopleHooks';
import { useQueryParams } from 'src/utils/useQueryParams';

export function AddPersonForm() {
  const { userId, sid } = useQueryParams();
  // const [value, setValue] = React.useState<PersonCreate | null>(null);

  const handleClose = () => {
    setDialogValue({
      name: '',
      category: PersonCategory.FRIENDLY,
    });
  };

  const [dialogValue, setDialogValue] = React.useState({
    name: '',
    category: PersonCategory.FRIENDLY,
  });

  const newPersonMutation = useCreatePerson();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const person: PersonCreate = {
      user_id: userId,
      sid: sid,
      name: dialogValue.name,
      category: dialogValue.category,
    };
    newPersonMutation.mutate(person);

    handleClose();
  };

  if (!userId || !sid) return <>'Missing userId or sid'</>;
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box display="flex" alignItems="flex-end" mt={-1}>
          <TextField
            // autoFocus
            margin="dense"
            value={dialogValue.name}
            onChange={(event) =>
              setDialogValue({ ...dialogValue, name: event.target.value })
            }
            label="Name"
            type="text"
          />
          &nbsp;
          <FormControl>
            {/* <InputLabel shrink>Category</InputLabel> */}
            <Select
              margin="dense"
              value={dialogValue.category || 'friendly'}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  category: event.target.value as PersonCategory,
                })
              }
              label="Type"
              type="string"
            >
              <MenuItem value={PersonCategory.FRIENDLY}>Friendly</MenuItem>
              <MenuItem value={PersonCategory.NEUTRAL}>Neutral</MenuItem>
              <MenuItem value={PersonCategory.UNKNOWN}>Unknown</MenuItem>
              <MenuItem value={PersonCategory.BANNED}>Banned</MenuItem>
            </Select>
          </FormControl>
          &nbsp;
          <Button type="submit" variant="outlined" startIcon={<AddIcon />}>
            Add
          </Button>
        </Box>
      </form>
    </>
  );
}
