// import { useReducer } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { usePcsClient } from 'src/context/pcs-auth-context';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useGetPcsEventQuery } from './eventHooks';
import { useGetRepMe } from './repHooks';
import { getEmail } from 'src/components/mail/MailTemplates';
import { getCopyFromDisposition } from 'src/components/pushNotification/PushCopy';
import { htmlToText } from 'src/utils/format';
import {
  MailNotification,
  NotificationCategory,
  PushNotification,
  NotificationSettings,
} from 'src/types';
import {
  getNotificationCategoryFromDispositionTypes,
  getNotificationCriticalityFromDispositionTypes,
} from 'src/utils/utils';

// TYPES
interface Subscriber {
  user_id: number;
  email: string;
  password?: string;
  client_id?: string;
  device_id?: string;
  access_token?: string;
  expires?: number;
  refresh_token?: string;
}

interface Camera {
  uuid: string;
  user_id: number;
  sid: number;
  monitored: boolean;
  model: string;
}

// SUBSCRIBER hooks
export function useSubscriberList(
  skip: number = 0,
  limit: number = 10,
  searchValue: string = ''
) {
  // const [state, dispatch] = useReducer(reducer, initialState);
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const encodedValue = encodeURIComponent(searchValue);
  const { data, isLoading, error } = useQuery({
    queryKey: ['subscribers', { skip, limit, searchValue }],
    queryFn: () =>
      client(`sub/list?skip=${skip}&limit=${limit}&searchBy=${encodedValue}`),
    onError: (err) => {
      enqueueSnackbar(`Endpoint: sub/list. ${err}`, {
        variant: 'error',
      });
    },
    // staleTime: (1000*100)
  });

  return {
    data,
    isLoading,
    error,
  };
}

export function useSubscriberCount(searchValue: string = '') {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, error } = useQuery({
    queryKey: ['subscribers_count', { searchValue }],
    queryFn: () => client(`sub/count?searchBy=${searchValue}`),
    onError: (err) => {
      enqueueSnackbar(`Endpoint: sub/sub-count. ${err}`, {
        variant: 'error',
      });
    },
  });
  return {
    data,
    isLoading,
    error,
  };
}

export function useSubscriberExportList(
  skip: number = 0,
  limit: number = 10,
  searchValue: string = '',
  enabled: boolean = true
) {
  // const [state, dispatch] = useReducer(reducer, initialState);
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, error } = useQuery({
    queryKey: ['export-subscribers', { skip, limit, searchValue }],
    queryFn: () =>
      client(`sub/list?skip=${skip}&limit=${limit}&searchBy=${searchValue}`),
    onError: (err) => {
      enqueueSnackbar(`Endpoint: sub/list. ${err}`, {
        variant: 'error',
      });
    },
    enabled: enabled,
  });
  return {
    data,
    isLoading,
    error,
  };
}

export function useSubscriberExport(
  searchValue: string = '',
  enabled: boolean = true
) {
  const [page, setPage] = useState(0);
  const [subscribers, setSubscribers] = useState([]);

  const limit = 100;
  const skip = page * limit;
  // We are not giving default value to data because we use undefined as a
  // check for the first time execution.
  let { data } = useSubscriberExportList(skip, limit, searchValue, enabled);

  useEffect(() => {
    setSubscribers([]);
    setPage(0);
  }, [searchValue]);

  useEffect(() => {
    // data will be undefined only first time.  data.length === 0 will only be
    // true when have traversed all pages. If we are here, we are sure that
    // there are records in the DB.
    if (data !== undefined && data.length && enabled) {
      setSubscribers([...subscribers, ...data]);
      setPage(page + 1);
    }
  }, [data, page, subscribers, setPage, setSubscribers, enabled]);

  return {
    data: subscribers,
    done: data !== undefined && !data.length,
  };
}

export function useCreateSubscriber() {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (subscriber: Subscriber) =>
      client(`sub/new`, {
        method: 'POST',
        data: subscriber,
      }),
    {
      onError: (error) => {
        enqueueSnackbar(`Endpoint: sub/new. ${error}`, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
}

export function useCreateSubscriberAuth0() {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (subscriber: Subscriber) =>
      client(`sub/new`, {
        method: 'POST',
        data: subscriber,
      }),
    {
      onError: (error) => {
        enqueueSnackbar(`Endpoint: sub/new. ${error}`, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
}

export function useUpdateSubscriber(subscriber: Subscriber) {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { mutate, status } = useMutation(
    () =>
      client(`sub/${subscriber.user_id}`, {
        method: 'POST',
        data: subscriber,
      }),
    {
      onSuccess: () => {
        cache.invalidateQueries('subscriber');
      },
    }
  );

  return { mutate, status };
}

// CAMERA hooks
export function useGetCamera(uuid: string) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery(['camera', uuid], () => client(`camera/${uuid}`), {
    onError: (error) => {
      enqueueSnackbar(`Endpoint: camera/{uuid}. ${error}`, {
        variant: 'error',
      });
    },
  });
  return query;
}

export function useGetCamerasMonitored(sid: number) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery(['cameras', sid], () => client(`camera/list/${sid}`), {
    onError: (error) => {
      enqueueSnackbar(`Endpoint: camera/list/{sid}. ${error}`, {
        variant: 'error',
      });
    },
  });
  return query;
}

export function useSetCamerasMonitored() {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (data: { sid: number; cameras: Camera[] }) =>
      client(`camera/update-monitoring/${data.sid}`, {
        method: 'POST',
        data: data.cameras,
      }),
    {
      onError: (error) => {
        enqueueSnackbar(`Endpoint: camera/update-monitoring/{sid}. ${error}`, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
}

// MOTION ZONE hooks
export function useSetCameraMotionZone() {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (data: { sid: number; uuid: string; motionZone: any }) =>
      client(`camera/update-motion-zone/${data.sid}?uuid=${data.uuid}`, {
        method: 'POST',
        data: data.motionZone,
      }),
    {
      onSuccess: (data, variables) => {
        cache.invalidateQueries('motion_zone');
        cache.setQueryData(
          ['motion_zone', variables.sid, variables.uuid],
          data
        );
      },
      onError: (error) => {
        enqueueSnackbar(`Endpoint: camera/update-motion-zone/{sid}. ${error}`, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
}

export function useGetCameraMotionZoneMask(sid: number, uuid: string) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery(
    ['motion_zone', sid, uuid],
    () => client(`camera/motion-zone/${sid}?uuid=${uuid}`),
    {
      onError: (error) => {
        enqueueSnackbar(`Endpoint: camera/motion-zone/{sid}. ${error}`, {
          variant: 'error',
        });
      },
    }
  );
  return query;
}

// SEVICE hooks
export function useGetPcsService(
  userId: number,
  sid: number,
  update_location_cache = false
) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery(
    ['service', { userId, sid }],
    () =>
      client(
        `service/get/${userId}/${sid}?update_location_cache=${update_location_cache}`
      ),
    {
      onError: (error) => {
        enqueueSnackbar(`Endpoint: service/get/{userId}/{sid}. ${error}`, {
          variant: 'error',
        });
      },
    }
  );
  return query;
}

export function useSetPcsService(userId: number, sid: number) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const cache = useQueryClient();
  const mutation = useMutation(
    (data: { sid: number; user_id: number; features: Object }) =>
      client(`service/update/${userId}/${sid}`, {
        method: 'POST',
        data: data,
      }),
    {
      onSuccess: (data, variables) => {
        cache.invalidateQueries('service');
        cache.setQueryData(['service', variables.user_id, variables.sid], data);
      },
      onError: (error) => {
        enqueueSnackbar(`Endpoint: service/update/{userId}/{sid}. ${error}`, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
}

export function useSendFollowUpEmail() {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (data: MailNotification) =>
      client(`sub/send/email`, {
        method: 'POST',
        data: {
          uid: data.uid,
          subject: data.subject,
          html: data.html,
          text: data.text,
          eventId: data.eventId,
          category: data.category,
          critical: data.critical || false,
        },
      }),
    {
      onError: (error: Error) => {
        enqueueSnackbar(`Endpoint: sub/send/email ${error}`, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
}

export function useGetStatusService(list_subs: any) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    [],
    () =>
      client(`service/get-service-status`, { method: 'POST', data: list_subs }),
    {
      onError: (error) => {
        enqueueSnackbar(`Endpoint: service/get-service-status ${error}`, {
          variant: 'error',
        });
      },
    }
  );
  return mutation;
}

export function useSendPushNotification() {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (data: PushNotification) =>
      client(`sub/send/push-notification`, {
        method: 'POST',
        data: {
          user_id: data.user_id,
          message: data.message,
          title: data.title,
          event_id: data.event_id,
          category: data.category,
          critical: data.critical || false,
        },
      }),
    {
      onSuccess: ({ event_id: eventId }) => {
        cache.invalidateQueries(['push_notification', eventId]);
      },
      onError: (error: Error) => {
        enqueueSnackbar(`Endpoint: sub/send/push-notification ${error}`, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
}

export function useGetPushNotifications(eventId: number) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery(
    ['push_notification', eventId],
    () => client(`sub/get/notifications/${eventId}`),
    {
      enabled: !!eventId,
      onError: (error) => {
        enqueueSnackbar(`Endpoint: sub/get/notification/{event_id}. ${error}`, {
          variant: 'error',
        });
      },
    }
  );
  return query;
}

export function useGetSubscribersInfo(isEnabled) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery({
    queryKey: ['subscribersInfo'],
    queryFn: () => client(`sub/sub-info`),
    onError: (err) => {
      enqueueSnackbar(`Endpoint: sub/sub-info. ${err}`, {
        variant: 'error',
      });
    },
    enabled: isEnabled,
  });
  return query;
}

export const useSendAutoNotify = (eventId: number, userId: number) => {
  const repQuery = useGetRepMe();
  const sendFollowUpEmail = useSendFollowUpEmail();
  const sendPushNotification = useSendPushNotification();
  const eventQuery = useGetPcsEventQuery(eventId);

  const send = (dispositionSubType: string) => {
    if (process.env.REACT_APP_ENABLE_AUTO_NOTIFICATION !== 'true') {
      return;
    }

    const category: NotificationCategory =
      getNotificationCategoryFromDispositionTypes(dispositionSubType);
    const critical: boolean =
      getNotificationCriticalityFromDispositionTypes(dispositionSubType);

    const [subject, message] = getEmail(
      dispositionSubType,
      repQuery.data,
      eventQuery.data
    );
    sendFollowUpEmail.mutate({
      uid: userId.toString(),
      subject: subject,
      html: message,
      text: htmlToText(message),
      eventId: eventId,
      category: category,
      critical: critical,
    });
    const { title, body } = getCopyFromDisposition(
      eventQuery.data,
      dispositionSubType
    );
    sendPushNotification.mutate({
      user_id: userId,
      message: body,
      title: title,
      event_id: eventId,
      category: category,
      critical: critical,
    });
  };
  return { send };
};

export function useNotificationSettings(sid: number) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, error } = useQuery<NotificationSettings>({
    queryKey: ['notifications_settings', sid],
    queryFn: () => client(`sub/notifications/settings/${sid}`),
    onError: (err) => {
      // @ts-ignore
      if (err.message.includes('Notification settings not found')) {
        return;
      }
      enqueueSnackbar(`Endpoint: notifications/settings. ${err}`, {
        variant: 'error',
      });
    },
  });
  return {
    data,
    isLoading,
    error,
  };
}
