// import { colors } from '@material-ui/core';

export const paletteDark = {
  type: 'dark',
  primary: {
    main: '#1e88e5',
    light: 'rgb(166, 212, 250)',
    dark: 'rgb(100, 141, 174)',
    // contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  secondary: {
    // main: '#00b0ff'
    main: '#f50057',
  },
  error: {
    main: '#f00',
  },
  appBar: '#111',
};

export const paletteLight = {
  type: 'light',
  primary: {
    light: '#7986cb',
    // main: '#3f51b5',
    main: '#0d47a1',
    dark: '#303f9f',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ff4081',
    main: '#f50057',
    dark: '#c51162',
    contrastText: '#fff',
  },
  error: {
    main: '#f00',
  },
  appBar: '#0d47a1', // Same as primary
};
