import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, DialogActions } from '@material-ui/core';

export type AudioAccessDeniedProps = {
  isOpen: boolean;
  onClose(): void;
};

export function useAudioAccessDenied() {
  const [isOpen, setOpen] = useState(false);

  return {
    props: {
      isOpen,
      onClose: () => setOpen(false),
    },
    open: () => setOpen(true),
  };
}

export function AudioAccessDenied({ isOpen, onClose }: AudioAccessDeniedProps) {
  return (
    <Dialog open={isOpen} keepMounted onClose={onClose}>
      <DialogTitle>Camera access denied</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enable camera access in your web browser's settings.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Continue
        </Button>
        <Button onClick={() => window.location.reload()} color="primary">
          Reload Page
        </Button>
      </DialogActions>
    </Dialog>
  );
}
