import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { usePcsClient } from 'src/context/pcs-auth-context';

export enum LocationFeatures {
  QA_OPERATIONAL_REVIEW = 'OperationalQualityAssuranceReviewEligible',
}

type History = {
  notes: string;
  eventId: number;
  updated_at: string;
};

export interface LocationNotes {
  sid: number;
  notes: string;
  eventId: number;
  updated_by: number;
  updated_at: string;
  history: History[];
}

type Props = {
  sid: string;
};

type LocationHistoryPayload = {
  notes: string;
  eventId: number;
};

export function useLocationHistory({ sid }: Props) {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (data: LocationHistoryPayload) =>
      client(`service/${sid}/notes`, {
        method: 'POST',
        data: {
          eventId: data.eventId,
          notes: data.notes,
        },
      }),
    {
      onSuccess: () => {
        enqueueSnackbar(`Saved Location Note`, {
          variant: 'success',
        });
        cache.invalidateQueries(['location_notes', sid]);
      },
      onError: (error) => {
        enqueueSnackbar(`Error setting location notes: ${error}`, {
          variant: 'error',
        });
      },
    }
  );
}

export function useLocationHistoryQuery({ sid }: { sid: string }) {
  const client = usePcsClient();
  const query: UseQueryResult<LocationNotes> = useQuery(
    ['location_notes', sid],
    () => client(`service/${sid}/notes`),
    {
      onError: (error) => {
        console.error(`Error getting location notes query: ${error}`);
      },
      retry: false,
    }
  );
  return query;
}

export function useLocationFeatures({ sid }: { sid: string }) {
  const client = usePcsClient();
  const query: UseQueryResult<LocationNotes> = useQuery(
    ['location_features', sid],
    () => client(`service/${sid}/features`),
    {
      onError: (error) => {
        console.error(`Error loading location features: ${error}`);
      },
      retry: false,
    }
  );
  return query;
}

export function useLocationFeatureEnabled({
  sid,
  feature,
}: {
  sid: string;
  feature: string;
}) {
  const [enabled, setEnabled] = React.useState(false);
  const { isLoading, data, error } = useLocationFeatures({ sid });

  useEffect(() => {
    if (!isLoading && data) {
      setEnabled(data[feature]);
    }
  }, [data, isLoading]);
  return enabled;
}
