import { FC, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
} from '@material-ui/core';
import {
  getAudioStream,
  getAudioRecorder,
  AudioStreamProps,
  AudioRecorderProps,
} from 'src/components/VideoPlayer/kvs-camera/useStreamRecorder';
import { useGetUserSettings, useSetUserSettings } from 'src/hooks/settingsHooks';

const DEFAULT_VOLUME = 3;
const RECORDING_DURATION = 5 * 1000; // 5 seconds

const useStyles = makeStyles({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const playSignal = async () => {
  const audio = new Audio('/static/dilin.mp3');
  await audio.play();
};

const checkVolume = (value: string) => {
  let volume = Number.parseInt(value);
  volume = Number.isNaN(volume) ? DEFAULT_VOLUME : volume;
  volume = Math.min(10, volume);
  return Math.max(1, volume);
};

type AudioSettingsProperties = {
  className: string;
}

type Settings = {
  volume: string;
}

const AudioSettings: FC<AudioSettingsProperties> = ({ className, ...rest }) => {
  const classes = useStyles();

  const { data: userSettings } = useGetUserSettings();
  const { mic_volume: volume } = userSettings ?? { mic_volume: '' };

  const updateUserSettings = useSetUserSettings();

  const audioStreamRef = useRef<AudioStreamProps>(null);
  const audioRecorderRef = useRef<AudioRecorderProps>(null);

  const [isRecording, setIsRecording] = useState(false);
  const [settings, setSettings] = useState<Settings>({ volume: volume.toString() });

  useEffect(() => {
    setSettings({ volume: volume.toString() });
  }, [volume]);

  const handleSave = useCallback(() => {
    const validVolume = checkVolume(settings.volume);
    updateUserSettings.mutate({ micVolume: validVolume });
    setSettings({ volume: validVolume.toString() });
  }, [settings.volume]);

  const startRecording = useCallback(async () => {
    audioStreamRef.current = await getAudioStream(checkVolume(settings.volume), true);
    audioRecorderRef.current = getAudioRecorder(
      audioStreamRef.current.audioStream
    );

    setIsRecording(true);
    playSignal();

    setTimeout(async () => {
      setIsRecording(false);
      playSignal();
      await audioStreamRef.current.stop();
      await audioRecorderRef.current.stop();
      const audioBlob = new Blob(audioRecorderRef.current.buffer);
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      audio.play();
    }, RECORDING_DURATION);

  }, [settings.volume]);

  return (
    <form className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          subheader="Manage audio settings. Valid values between 1-10. Change volume, press test, speak and listen to test the settings."
          title="Audio Settings"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Mic Volume"
            margin="normal"
            name="mic-gain"
            type="number"
            InputProps={{ inputProps: { min: 1, max: 10 } }}
            onChange={(e) =>
              setSettings({
                ...settings,
                ...{ volume: e.target.value },
              })
            }
            value={settings.volume}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            onClick={startRecording}
            color="primary"
            variant="contained"
            disabled={isRecording}
            style={{ marginRight: 5 }}
          >
            test
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            save
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AudioSettings;
