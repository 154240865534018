import { useQuery } from 'react-query';
import { usePcsClient } from 'src/context/pcs-auth-context';

/** EVENT STATS */
export function useGetEventStats(
  threat_level_gte: number,
  lookback: number,
  until: number,
  repId: string | null = null
) {
  const client = usePcsClient();
  const params = repId
    ? { threat_level_gte, lookback, until, repId }
    : { threat_level_gte, lookback, until };
  const query = useQuery(
    ['pcs_event_stats', threat_level_gte, lookback, until, repId],
    () =>
      client(`reports/stats`, {
        params,
      }),
    {
      refetchInterval: 10000,
    }
  );
  return query;
}

export function useGetAllRepEventStats(
  threat_level_gte: number,
  lookback: number,
  until: number
) {
  const client = usePcsClient();
  const params = { threat_level_gte, lookback, until };
  const query = useQuery(
    ['pcs_all_rep_event_stats', threat_level_gte, lookback, until],
    () =>
      client(`reports/all-rep-stats`, {
        params,
      }),
    {
      refetchInterval: 10000,
    }
  );
  return query;
}

export function useGetAgentsStatus() {
  const client = usePcsClient();
  const query = useQuery(
    ['agents-status'],
    () =>
      client(`reports/agents-status`, {
        params: {},
      }),
    {
      refetchInterval: 10000,
    }
  );
  return query;
}
