import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  register,
  saveAuthCookie,
  saveUserDataInLocalStorage,
} from 'src/utils/auth';
import FullPageSpinner from 'src/components/FullPageSpinner';
import { Role } from 'src/models';

const Auth0LoginCallbackView = () => {
  console.log('/callback login hit');
  const [searchParams] = useSearchParams();

  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth0();

  useEffect(() => {
    async function registerUser(auth0user) {
      try {
        const token = await getAccessTokenSilently();
        const apiUser = await register(
          {
            email: auth0user.email,
            firstName: auth0user.given_name,
            lastName: auth0user.family_name,
            user_id: parseInt(auth0user['http://simplisafe.com/uid']),
          },
          token
        );

        // When the user logs in for the first time, it doesn't have a role
        if (!(apiUser.role && apiUser.is_active)) {
          localStorage.setItem(
            'auth:error',
            'Your account is not active. Please contact an administrator.'
          );
          throw new Error('User registered without role or is inactive');
        }

        saveUserDataInLocalStorage({ user: apiUser, token });
        saveAuthCookie(token);

        if (apiUser.role === Role.CX_ORDER_REPORT) {
          window.location.href = '/external/cx-report';
          return;
        }

        window.location.href = '/app/dashboard';
      } catch (error) {
        // The PCS admin registration can fail, so we ne to log the user out from auth0
        logout();
      }
    }

    if (!isLoading && isAuthenticated) {
      registerUser(user);
    }
  }, [isLoading]);

  if (isLoading) {
    return <FullPageSpinner message="Loading App..." />;
  }

  return <></>;
};

export { Auth0LoginCallbackView };
