import { useParams } from 'react-router-dom';
import { useAllSubscriptions } from '../../hooks/useSubscription';
import { useVideoPlayer } from '../../components/VideoPlayer/useVideoPlayer';
import Page from '../../components/Page';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import Location from './Location';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export const SubscriberDetailsView = () => {
  const classes = useStyles();
  const { userId } = useParams();
  const subscriptionsQuery = useAllSubscriptions(userId);

  const limit = 10; // TODO: remove limit?

  const playerState = useVideoPlayer(userId, {
    volume: 0,
  });

  return (
    <Page className={classes.root} title="Subscriber Details">
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary">
          Service Locations
        </Typography>

        {subscriptionsQuery.isLoading && (
          <LoadingSpinner message="Getting subscriptions from Yoda" />
        )}
        {subscriptionsQuery.data?.subscriptions
          ?.slice(0, limit)
          .map((location) => (
            <Box mt={3} key={location.sid}>
              <Location location={location} playerState={playerState} />
            </Box>
          ))}
      </Container>
    </Page>
  );
};
