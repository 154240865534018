import { useEffect, useContext, useCallback } from 'react';
import {
  MetricsContext,
  createOpenMetricDispatch,
  getEventInQueueDuration,
} from 'src/context/Metrics-context';

const INTERVAL_TIMER = 15 * 60 * 1000; // 15 mins

interface EventHandleTimeMetricsProps {
  eventId: number | null;
  acceptNew: boolean;
  postInterval?: number; // Configurable interval in milliseconds
}

export const useEventHandleTimeMetrics = ({
  eventId,
  acceptNew,
  postInterval = INTERVAL_TIMER,
}: EventHandleTimeMetricsProps) => {
  const [, dispatch] = useContext(MetricsContext);

  const postEventHandleTimeMetric = useCallback(
    (evntId: number) => {
      if (evntId) {
        const openMetricDispatch = createOpenMetricDispatch(dispatch);
        openMetricDispatch({
          metricName: 'event-handle-time',
          payload: {
            eventId: evntId,
            duration: getEventInQueueDuration(evntId),
            PCSstatus: 'queue',
          },
        });
      }
    },
    [dispatch]
  );

  // Post metric at a configurable interval
  useEffect(() => {
    if (!acceptNew) {
      postEventHandleTimeMetric(eventId);
    }

    const timer = setInterval(() => {
      postEventHandleTimeMetric(eventId);
    }, postInterval);

    const handleBeforeUnload = () => {
      postEventHandleTimeMetric(eventId);
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      clearInterval(timer);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [postInterval, acceptNew, eventId]);

  useEffect(() => {
    return () => {
      postEventHandleTimeMetric(eventId);
    };
  }, [eventId]);

  return {
    submitEventHandleTimeMetric: postEventHandleTimeMetric,
  };
};
