import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'grey',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  message: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function FullPageSpinner({ message }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
      {message && (
        <Box m={2} p={2} borderRadius={10} className={classes.message}>
          <Typography variant="subtitle1" color="textSecondary">
            {message}
          </Typography>
        </Box>
      )}
    </div>
  );
}

FullPageSpinner.propTypes = {
  message: PropTypes.string,
};
