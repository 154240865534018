// import * as speex from 'src/lib/speex/dist/speex';

import { FormControlLabel, Switch } from '@material-ui/core';
import useMediaRecorder from '@wmik/use-media-recorder';
import React, { useState, useMemo, useRef } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useSubscriber } from 'src/context/sub-auth-context';

export const WebSocketTest = () => {
  const subscriberQuery = useSubscriber(154);
  const url = process.env.REACT_APP_2WAY_AUDIO_PROXY; // ws://127.0.0.1:8080/ws

  const [socketUrl, setSocketUrl] = useState(null);
  const readyStateRef = React.useRef();

  const messageHistory = useRef([]);

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    queryParams: {
      access_token: encodeURIComponent(subscriberQuery.data?.access_token),
    },
  });
  readyStateRef.current = readyState;

  messageHistory.current = useMemo(
    () => messageHistory.current.concat(`${lastMessage}<br/>`),
    [lastMessage]
  );

  const handleTalk = () => {
    if (socketUrl === null) {
      setSocketUrl(url);
      clearMediaStream();
      startRecording(100);
    } else {
      setSocketUrl(null);
      stopRecording();
      clearMediaStream();
    }
  };

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const handleData = async (data) => {
    // Don't send (queue up) data if socket is not open yet
    if (readyStateRef.current === ReadyState.OPEN) {
      sendMessage(data);
    }
  };

  const {
    // error,
    // status,
    // mediaBlob,
    stopRecording,
    // getMediaStream,
    startRecording,
    clearMediaStream,
  } = useMediaRecorder({
    recordScreen: false,
    // blobOptions: { type: 'video/webm' },
    mediaStreamConstraints: {
      audio: {
        sampleSize: 16,
        sampleRate: 16000,
        channelCount: 1,
      },
      video: false,
    },
    onDataAvailable: handleData,
    mediaRecorderOptions: { mimeType: 'audio/webm;codecs=opus' },
  });

  // const { stream, error } = useUserMedia({
  //   audio: {
  //     sampleSize: 16,
  //     sampleRate: 16000,
  //     channelCount: 1
  //   },
  //   video: false
  // });
  // console.log(stream);

  // if (stream && socketUrl) {
  //   // const track = stream.getAudioTracks()[0];
  //   // track.applyConstraints({"sampleRate":});
  //   const mediaRecorder = new MediaRecorder(stream, {
  //     // mimeType: 'audio/webm;codecs=pcm'
  //     mimeType: 'audio/webm;codecs=opus'
  //   });
  //   console.log(mediaRecorder);
  //   mediaRecorder.addEventListener('dataavailable', event => {
  //     if (event.data.size > 0) {
  //       sendMessage(event.data);
  //       console.log('Raw', event.data);
  //       // toSpeex(event.data);
  //     }
  //   });
  //   mediaRecorder.start(1000);
  // }

  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            checked={socketUrl === null ? false : true}
            onChange={(e) => {
              handleTalk(e.target.checked);
            }}
            // color="primary"
            // name="acceptNew"
          />
        }
        label="Live"
      />
      <span>The WebSocket is currently {connectionStatus}</span>
      {lastMessage ? <span>Last message: {lastMessage.data}</span> : null}
      <ul>
        {messageHistory.current.map((message, idx) => (
          <span key={idx}>{message?.data}</span>
        ))}
      </ul>

      {/* {error && <UserMediaError error={error} />}
      {!error && (
        <video
          autoPlay
          ref={video => {
            if (video) video.srcObject = stream;
          }}
        />
      )} */}
    </div>
  );
};
