import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Box,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
} from '@material-ui/core';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useGenerateRecordingURL,
  useListAuditSessionQuery,
} from 'src/hooks/auditHooks';
import { useEffect } from 'react';

const SessionList = ({ eventId }) => {
  const { data, isLoading } = useListAuditSessionQuery(eventId);
  const generateUrl = useGenerateRecordingURL();

  const viewRecording = (file_name) => {
    generateUrl.mutate(file_name);
  };

  useEffect(() => {
    if (generateUrl.data) {
      window.open(generateUrl.data.url, '_blank');
    }
  }, [generateUrl.data]);

  if (isLoading) return <LoadingSpinner message="Getting Sessions" />;
  if (!data?.length) return <Typography>No sessions(s) yet.</Typography>;
  return (
    <Card>
      <PerfectScrollbar>
        <Box>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Event ID</TableCell>
                <TableCell>Agent ID</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((session) => (
                <TableRow hover key={session.eventId}>
                  <TableCell>{session.eventId}</TableCell>
                  <TableCell>{session.agent_uid}</TableCell>
                  <TableCell>
                    {moment.utc(session.start_time).local().format('lll')}
                  </TableCell>
                  <TableCell>
                    {moment.utc(session.end_time).local().format('lll')}
                  </TableCell>
                  <TableCell>
                    <Button
                      type="button"
                      onClick={() =>
                        viewRecording(new URL(session.recording_url).pathname)
                      }
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

SessionList.propTypes = {
  eventId: PropTypes.number,
};
export default SessionList;
