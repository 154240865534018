type MetricsOverrides = {
  eventID?: number;
  agentID?: string;
  cameraUUID?: string;
};

/**
 * This class aims to create agent even metrics.
 *
 * @class MetricsModule
 */
class MetricsModule {
  private metricName: string;
  private agentID?: string;
  private PCSstatus?: string;
  private disposition?: string;
  private eventID?: number;
  private eventType?: string;
  private systemState?: string;
  private start?: number;
  private send?: boolean;
  private elapsedTime?: number;
  private rest?: any;
  constructor(
    metricName: string,
    agentID: string = '',
    PCSstatus: string = null,
    disposition: string = null,
    eventID: number = null,
    eventType: string = null,
    systemState: string = null,
    rest: any = null
  ) {
    this.metricName = metricName;
    (this.PCSstatus = PCSstatus),
      (this.disposition = disposition),
      (this.eventID = eventID),
      (this.eventType = eventType),
      (this.systemState = systemState);
    this.start = this.startTimer();
    this.send = true;
    this.elapsedTime = 0;
    this.rest = rest;

    this.agentID = agentID || localStorage.getItem('id');
  }
  /**
   * This method generates the starting point at what the accion took place.
   *
   * @private
   * @memberof MetricsModule
   */
  private startTimer = () => {
    let startTime = Date.now();
    return startTime;
  };

  /**
   *This method generates the elapsed time which would be the how long and accion took.
   * @private
   * @memberof MetricsModule
   */
  stopTimer = () => {
    let elapsedSecs = Date.now() - this.start;
    let elapsedTime = Math.floor(elapsedSecs / 1000);
    this.elapsedTime = elapsedTime;
  };

  /**
   *This method fetch data from the local storage and from the auth context
   *
   * @memberof MetricsModule
   */
  fetchNewData = (
    acceptNew = true,
    pcsStatusThreshold = 0,
    threatLevel = 0,
    armstate = 'All',
    eventID = null
  ) => {
    const pcsStatusThresholdObject = {
      '-30': 'Rep Handle',
      '-20': 'PCS AI Handle',
      '-10': 'Expired',
      '0': 'New',
      '10': 'Watching',
      '20': 'Verifying',
      '30': 'Dispatching',
      '40': 'Follow Up',
    };

    const threatLevelObject = {
      '0': 'None',
      '10': 'Motion',
      '20': 'Person',
      '30': 'TBD',
    };
    this.PCSstatus = pcsStatusThresholdObject[`${pcsStatusThreshold}`];
    this.systemState = armstate;
    this.eventType = threatLevelObject[`${threatLevel}`];
    this.send = acceptNew;
    this.eventID = eventID;
  };

  /**
   *This method creates a payload
   *
   * @memberof MetricsModule
   */
  payload = (overrides: MetricsOverrides = {}) => {
    return {
      metricName: this.metricName,
      agentID: overrides.agentID ? overrides.agentID : this.agentID,
      PCSstatus: this.PCSstatus,
      disposition: this.disposition,
      eventID: overrides.eventID ? overrides.eventID : this.eventID,
      eventType: this.eventType,
      systemState: this.systemState,
      elapsedTime: this.elapsedTime,
      send: this.send,
      cameraUUID: overrides.cameraUUID,
      ...this.rest,
    };
  };
}

export default MetricsModule;
