import * as React from 'react';
import { PcsAuthProvider } from 'src/context/pcs-auth-context';
import { CustomThemeProvider } from 'src/context/theme-context';
import { HotkeyProvider } from 'src/context/hotkey-context';

import GlobalStyles from 'src/components/GlobalStyles';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { PropTypes } from 'prop-types';
import { SnackbarProvider } from 'notistack';
import { Auth0Provider } from '@auth0/auth0-react';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (error.status === 404) {
          return false;
        } else if (failureCount < 2) {
          // Allow 2 tries by default -- this lets us invalidate token and get a new one on failure
          return true;
        }
        return false;
      },
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

function AppProviders({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_ISSUER}
        clientId={process.env.REACT_APP_AUTH0_AGENT_PORTAL_CLIENT_ID}
        authorizationParams={{
          scope:
            'openid profile email https://ticket-booth.simplisafe.com/create:authorization:outdoor-monitoring https://admin.pcs.simplisafe.com/recordings:read',
          audience: 'https://admin.pcs.simplisafe.com/',
          redirect_uri: `${window.location.origin}/auth0/callback`,
        }}
      >
        <PcsAuthProvider>
          <CustomThemeProvider>
            <HotkeyProvider>
              <SnackbarProvider maxSnack={3}>
                <GlobalStyles />
                {children}
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="bottom-right"
                />
              </SnackbarProvider>
            </HotkeyProvider>
          </CustomThemeProvider>
        </PcsAuthProvider>
      </Auth0Provider>
    </QueryClientProvider>
  );
}

AppProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppProviders };
