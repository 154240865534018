import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import { useGetSpeedTestData } from 'src/hooks/cameraHooks';
import moment from 'moment';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { toMbpsSpeed } from 'src/utils/camera';

const CameraSpeedTestHistory = ({ camera }) => {
  const { data, isLoading, error } = useGetSpeedTestData(
    camera?.sid,
    camera?.uuid
  );

  const [speedTestHistory, setSpeedTestHistory] = useState<any[]>([]);

  useEffect(() => {
    if (!isLoading && !error && data?.results) {
      const sortedData = [...data.results];
      sortedData.sort((a, b) => {
        const keyA = new Date(a.created);
        const keyB = new Date(b.created);
        if (keyA < keyB) {
          return -1;
        }

        if (keyA > keyB) {
          return 1;
        }

        return 0;
      });
      setSpeedTestHistory(sortedData);
    }
  }, [data, isLoading, error]);

  const uploadSpeedData = speedTestHistory.map((item: any) =>
    toMbpsSpeed(item.metrics.uploadSpeed)
  );
  const downloadSpeedData = speedTestHistory.map((item: any) =>
    toMbpsSpeed(item.metrics.downloadSpeed)
  );
  const createdData = speedTestHistory.map((item: any) =>
    moment(item.created).format('hh:mm MM/DD')
  );

  const options = {
    legend: {
      display: true,
    },

    responsive: true,
    title: {
      display: true,
      text: 'Speed in Mbps',
      position: 'left',
      fontSize: '16',
    },
  };

  const graphData = {
    labels: createdData,
    datasets: [
      {
        label: 'Upload speed',
        data: uploadSpeedData,
        fill: false,
        borderColor: 'rgb(205,205,0)',
      },
      {
        label: 'Download speed',
        data: downloadSpeedData,
        fill: false,
        borderColor: 'rgb(0, 171, 0)',
      },
    ],
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Box>
            <Typography
              variant="h2"
              style={{
                color: 'rgba(255, 255, 255, 0.7)',
                marginBottom: 30,
              }}
            >
              Speed Test History
            </Typography>
            {!speedTestHistory.length && <Typography>Unavailable</Typography>}
          </Box>
          {speedTestHistory.length ? (
            <Line options={options} data={graphData} />
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
};

export default CameraSpeedTestHistory;
