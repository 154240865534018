import { getKvsCameraInfo, KvsCameraServiceProps } from './KvsCameraApi';
import { Role, SignalingClient } from 'amazon-kinesis-video-streams-webrtc';
import { useCallback, useEffect, useRef, useState } from 'react';
import { log } from '../../../context/Logs';
import { PcsSession } from './PcsSession';
import * as protobufs from '../protobufs/protobufs';

export interface ConnectionState {
  connecting: boolean;
  connected: boolean;
  remoteStream: MediaStream | null;
}

export interface KvsCameraProps extends KvsCameraServiceProps { fullDuplexAudio: boolean }
export interface KvsCameraResponse extends ConnectionState {
  audioContext: AudioContext;
  audioDestination: MediaStreamAudioDestinationNode;
  open: (onConnectionTimeOut?: () => Promise<void> | void) => Promise<void>;
  openFullDuplex: (onConnectionTimeOut?: () => Promise<void> | void) => Promise<void>;
  close: () => void;
  sendDataChannelMessage?: (message: protobufs.simplisafe.cameras.messages.v1.ICameraMessage) => void;
  localStream: MediaStream | null;
}

function initialConnectionState(): ConnectionState {
  return {
    connected: false,
    connecting: false,
    remoteStream: null,
  };
}

// https://getstream.io/glossary/sdp-munging/
// remove unnecessary codecs from the SDP offer
// this fix the issue to get an SDP answer with video/audio and data channel
function modifySdpOffer(sdp: string): string {
  // Split the SDP into lines
  const lines = sdp.split('\r\n');

  // Filter out unnecessary codecs and lines
  const filteredLines = lines.filter((line) => {
    // Remove other unnecessary lines (example: remove telephone event codecs)
    if (
      line.startsWith('a=rtpmap') &&
      line.includes('telephone-event')
    ) {
      return false;
    }
    return true;
  });
  // Join the filtered lines back into a single SDP string
  return filteredLines.join('\r\n');
}

export function useKvsCamera(
  props: KvsCameraProps,
  pcsSession: PcsSession
): KvsCameraResponse {
  const [connectionState, setConnectionState] = useState(
    initialConnectionState()
  );
  const audioContextRef = useRef<AudioContext>(null);
  const audioDestinationRef = useRef<MediaStreamAudioDestinationNode>(null);
  const signalingClientRef = useRef<SignalingClient | null>();
  const peerConnectionRef = useRef<RTCPeerConnection | null>();
  const dataChannelRef = useRef<RTCDataChannel | null>();
  const timeoutRef = useRef<any>();
  const abortControllerRef = useRef<AbortController>(null);

  const localStreamRef = useRef<MediaStream | null>(null);

  const peerEventListenerIcecandidate = useRef<any>(null);
  const peerEventListenerTrack = useRef<any>(null);

  const signalingEventOpen = useRef<(...args: any[]) => void>(null);
  const signalingEventSdpAnswer = useRef<(...args: any[]) => void>(null);
  const signalingEventIceCandidate = useRef<(...args: any[]) => void>(null);
  const signalingEventClose = useRef<(...args: any[]) => void>(null);
  const signalingEventError = useRef<(...args: any[]) => void>(null);

  function close(onDone?: () => void) {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (abortControllerRef.current) abortControllerRef.current.abort();
    if (peerConnectionRef.current) {
      if (peerEventListenerIcecandidate.current) {
        peerConnectionRef.current.removeEventListener(
          'icecandidate',
          peerEventListenerIcecandidate.current
        );
      }
      if (peerEventListenerTrack.current) {
        peerConnectionRef.current.removeEventListener(
          'track',
          peerEventListenerTrack.current
        );
      }

      if (peerConnectionRef.current.connectionState !== 'closed') {
        pcsSession.livestreamStop();
      }
      peerConnectionRef.current.close();
      peerConnectionRef.current = null;
    }
    if (signalingClientRef.current) {
      if (signalingEventOpen.current) {
        signalingClientRef.current.off('open', signalingEventOpen.current);
      }
      if (signalingEventSdpAnswer.current) {
        signalingClientRef.current.off(
          'sdpAnswer',
          signalingEventSdpAnswer.current
        );
      }
      if (signalingEventIceCandidate.current) {
        signalingClientRef.current.off(
          'iceCandidate',
          signalingEventIceCandidate.current
        );
      }
      if (signalingEventClose.current) {
        signalingClientRef.current.off('close', signalingEventClose.current);
      }
      if (signalingEventError.current) {
        signalingClientRef.current.off('error', signalingEventError.current);
      }

      if (typeof onDone === 'function') {
        let closeDone = false;
        let closeDoneTimeout;
        const handleDone = () => {
          if (!closeDone) {
            closeDoneTimeout && clearTimeout(closeDoneTimeout);
            closeDone = true;
            onDone();
          }
        };
        signalingClientRef.current.on('close', handleDone);
        signalingClientRef.current.on('error', handleDone);
        closeDoneTimeout = setTimeout(handleDone, 2000);
      }

      signalingClientRef.current.close();
      signalingClientRef.current = null;
    } else {
      typeof onDone === 'function' && onDone();
    }
  }

  const clearUp = () =>
    new Promise<void>((res) => {
      close(res);
    });

  useEffect(() => {
    (async () => {
      await clearUp();
    })();
  }, [props.sid, props.uuid, props.authHeader]);


  const open = useCallback(
    async (onConnectionTimeOut = () => undefined) => {

      if (!connectionState.connecting) {
        let success = false;
        await clearUp();
        let timeoutCalled = false;
        const handleConnectionTimeOut = () => {
          if (!timeoutCalled) {
            timeoutCalled = true;
            onConnectionTimeOut();
          }
        };
        timeoutRef.current = setTimeout(async () => {
          // Connection timeout
          await clearUp();
          setConnectionState(initialConnectionState());
          handleConnectionTimeOut();
        }, 19000);
        setConnectionState({
          connecting: true,
          connected: false,
          remoteStream: null,
        });
        const audioContext = new AudioContext();
        const streamDestination = audioContext.createMediaStreamDestination();
        audioContextRef.current = audioContext;
        audioDestinationRef.current = streamDestination;
        abortControllerRef.current = new AbortController();
        const info = await getKvsCameraInfo(
          props,
          abortControllerRef.current.signal
        );
        if (info) {

          // Log the livestream connection attempt early in setup
          pcsSession.livestreamAttempt();

          signalingClientRef.current = new SignalingClient({
            channelARN: info.channelARN,
            channelEndpoint: info.channelEndpoint,
            role: Role.VIEWER,
            region: 'not-used-live',
            clientId: info.clientId,
            requestSigner: {
              getSignedURL: async () => info.signedChannelEndpoint,
            },
          });
          const signalingClient = signalingClientRef.current;
          peerConnectionRef.current = new RTCPeerConnection({
            iceTransportPolicy: 'relay',
            iceServers: info.iceServers,
          });
          const peerConnection = peerConnectionRef.current;
          streamDestination.stream?.getTracks().forEach((track) => {
            track.enabled = true;
            peerConnection?.addTrack(track, streamDestination.stream);
          });
          peerConnection.addEventListener(
            'icecandidate',
            (peerEventListenerIcecandidate.current = ({ candidate }) => {
              if (process.env.REACT_APP_DEBUG) {
                log({
                  message: `on icecandidate camera: ${props.uuid}, sid: ${props.sid}`,
                  data: {
                    uuid: props.uuid,
                    sid: props.sid,
                    candidate,
                  },
                });
              }
              if (candidate) {
                signalingClient.sendIceCandidate(candidate);
              } else {
                // No more ICE candidates will be generated
              }
            })
          );
          peerConnection?.addEventListener(
            'track',
            (peerEventListenerTrack.current = async (event) => {
              if (!success) {
                success = true;
                const stats = (await peerConnection.getStats()) as Map<
                  any,
                  any
                >;
                const filteredStats = Array.from(stats.values()).filter(
                  (report) => report.type === 'inbound-rtp'
                );
                const audioStats = filteredStats.find(
                  (report) => report.kind === 'audio'
                );
                const videoStats = filteredStats.find(
                  (report) => report.kind === 'video'
                );
                pcsSession.updateStats(audioStats, videoStats);
                pcsSession.livestreamSuccessfulAttempt();
              }
              if (process.env.REACT_APP_DEBUG) {
                log({
                  message: `on track camera: ${props.uuid}, sid: ${props.sid}`,
                  data: {
                    uuid: props.uuid,
                    sid: props.sid,
                    streamCount: event.streams?.length,
                    track: event.track,
                  },
                });
              }
              event.streams[0].getVideoTracks().forEach((videoTrack) => {
                videoTrack.onmute = () => {
                  if (process.env.REACT_APP_DEBUG) {
                    log({
                      message: `Frozen video stream detected on camera: ${props.uuid}, sid: ${props.sid}`,
                      data: {
                        uuid: props.uuid,
                        sid: props.sid,
                        streamCount: event.streams?.length,
                        track: event.track,
                      },
                    });
                  }
                  pcsSession.livestreamFreezeDetection();
                };
              });
              setConnectionState({
                connecting: false,
                connected: true,
                remoteStream: event.streams[0],
              });
              if (timeoutRef.current) clearTimeout(timeoutRef.current);
            })
          );

          signalingClient.on(
            'open',
            (signalingEventOpen.current = async () => {
              if (process.env.REACT_APP_DEBUG) {
                log({
                  message: `on signalingClient open camera: ${props.uuid}, sid: ${props.sid}`,
                  data: {
                    uuid: props.uuid,
                    sid: props.sid,
                  },
                });
              }
              const offer = await peerConnection.createOffer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: true,
              });
              await peerConnection.setLocalDescription(offer);
              signalingClient.sendSdpOffer(peerConnection.localDescription);
            })
          );

          signalingClient.on(
            'sdpAnswer',
            (signalingEventSdpAnswer.current = async (answer) => {
              if (process.env.REACT_APP_DEBUG) {
                log({
                  message: `on signalingClient sdpAnswer camera: ${props.uuid}, sid: ${props.sid}`,
                  data: {
                    uuid: props.uuid,
                    sid: props.sid,
                    answer,
                  },
                });
              }
              await peerConnection.setRemoteDescription(answer);
            })
          );

          signalingClient.on(
            'iceCandidate',
            (signalingEventIceCandidate.current = (candidate) => {
              if (process.env.REACT_APP_DEBUG) {
                log({
                  message: `on signalingClient iceCandidate camera: ${props.uuid}, sid: ${props.sid}`,
                  data: {
                    uuid: props.uuid,
                    sid: props.sid,
                    candidate,
                  },
                });
              }
              peerConnection.addIceCandidate(candidate);
            })
          );

          signalingClient.on(
            'close',
            (signalingEventClose.current = () => {
              pcsSession.livestreamStop();
              console.warn('KVS signaling client closed');
              if (process.env.REACT_APP_DEBUG) {
                log({
                  message: `on signalingClient close camera: ${props.uuid}, sid: ${props.sid}`,
                  data: {
                    uuid: props.uuid,
                    sid: props.sid,
                  },
                });
              }
              setConnectionState(initialConnectionState());
              if (timeoutRef.current) clearTimeout(timeoutRef.current);
              handleConnectionTimeOut();
            })
          );

          signalingClient.on(
            'error',
            (signalingEventError.current = (error) => {
              pcsSession.livestreamStop();
              console.error('KVS signaling client error', error);
              log({
                message: `on signalingClient error camera: ${props.uuid}, sid: ${props.sid}`,
                data: {
                  uuid: props.uuid,
                  sid: props.sid,
                  error: String(error),
                },
              });
              setConnectionState(initialConnectionState());
              if (timeoutRef.current) clearTimeout(timeoutRef.current);
              handleConnectionTimeOut();
            })
          );
          signalingClient.open();
        } else {
          // No info object returned
          handleConnectionTimeOut();
        }
      }
    },
    [connectionState, props.sid, props.uuid, props.authHeader, props.fullDuplexAudio]
  );


  // Open the camera in full duplex mode
  const openFullDuplex = useCallback(
    async (onConnectionTimeOut = () => undefined) => {

      if (!connectionState.connecting) {
        let success = false;
        await clearUp();
        let timeoutCalled = false;
        const handleConnectionTimeOut = () => {
          if (!timeoutCalled) {
            timeoutCalled = true;
            onConnectionTimeOut();
          }
        };
        timeoutRef.current = setTimeout(async () => {
          // Connection timeout
          await clearUp();
          setConnectionState(initialConnectionState());
          handleConnectionTimeOut();
        }, 19000);
        setConnectionState({
          connecting: true,
          connected: false,
          remoteStream: null,
        });

        const audioContext = new AudioContext();
        const streamDestination = audioContext.createMediaStreamDestination();
        audioContextRef.current = audioContext;
        audioDestinationRef.current = streamDestination;
        abortControllerRef.current = new AbortController();

        const info = await getKvsCameraInfo(
          props,
          abortControllerRef.current.signal
        );
        if (info) {

          // Log the livestream connection attempt early in setup
          pcsSession.livestreamAttempt();


          // High-Level workflow:
          // - Set up SignalingClient (uses AWS KVS lib)
          // - Attach event handlers to SignalingClient
          // - Set up PeerConnection
          // - Add Audio tracks to PeerConnection
          // - Add Datachannel to PeerConnection
          // - Attach event handlers to PeerConnection
          // - Open the Signaling Client
          //   - Create SDP Offer
          //   - Send SDP Offer to camera
          //   - Receive SDP Answer from camera <-- Problem! This is never received when BOTH audio tracks and datachannel are added to PeerConnection
          //     - In the SignalingClient sdpAnswer event handler, set the remoteDescrition of the PeerConnection to the SDP Answer
          // - Connection opens


          // Set up SignalingClient
          signalingClientRef.current = new SignalingClient({
            channelARN: info.channelARN,
            channelEndpoint: info.channelEndpoint,
            role: Role.VIEWER,
            region: 'not-used-live',
            clientId: info.clientId,
            requestSigner: {
              getSignedURL: async () => info.signedChannelEndpoint,
            },
          });
          const signalingClient = signalingClientRef.current;

          // Attach SignalingClient event handlers
          signalingClient.on(
            'open',
            (signalingEventOpen.current = async () => {

              if (process.env.REACT_APP_DEBUG) {
                log({
                  message: `on signalingClient open camera: ${props.uuid}, sid: ${props.sid}`,
                  data: {
                    uuid: props.uuid,
                    sid: props.sid,
                  },
                });
              }

              // Establish the RTCPeerConnection with a session offer
              const sessionDescription = await peerConnectionRef.current.createOffer();
              sessionDescription.sdp = modifySdpOffer(sessionDescription.sdp);

              await peerConnectionRef.current.setLocalDescription(sessionDescription);

              signalingClient.sendSdpOffer(peerConnectionRef.current.localDescription);
            })
          );

          signalingClient.on(
            'sdpAnswer',
            (signalingEventSdpAnswer.current = async (answer) => {

              if (process.env.REACT_APP_DEBUG) {
                log({
                  message: `on signalingClient sdpAnswer camera: ${props.uuid}, sid: ${props.sid}`,
                  data: {
                    uuid: props.uuid,
                    sid: props.sid,
                    answer,
                  },
                });
              }
              await peerConnectionRef.current.setRemoteDescription(answer);
            })
          );

          signalingClient.on(
            'iceCandidate',
            (signalingEventIceCandidate.current = (candidate) => {
              if (process.env.REACT_APP_DEBUG) {
                log({
                  message: `on signalingClient iceCandidate camera: ${props.uuid}, sid: ${props.sid}`,
                  data: {
                    uuid: props.uuid,
                    sid: props.sid,
                    candidate,
                  },
                });
              }
              peerConnectionRef.current.addIceCandidate(candidate);
            })
          );

          signalingClient.on(
            'close',
            (signalingEventClose.current = () => {
              pcsSession.livestreamStop();
              console.warn('KVS signaling client closed');
              if (process.env.REACT_APP_DEBUG) {
                log({
                  message: `on signalingClient close camera: ${props.uuid}, sid: ${props.sid}`,
                  data: {
                    uuid: props.uuid,
                    sid: props.sid,
                  },
                });
              }
              setConnectionState(initialConnectionState());
              if (timeoutRef.current) clearTimeout(timeoutRef.current);
              handleConnectionTimeOut();
            })
          );

          signalingClient.on(
            'error',
            (signalingEventError.current = (error) => {

              pcsSession.livestreamStop();
              log({
                message: `on signalingClient error camera: ${props.uuid}, sid: ${props.sid}`,
                data: {
                  uuid: props.uuid,
                  sid: props.sid,
                  error: String(error),
                },
              });
              setConnectionState(initialConnectionState());
              if (timeoutRef.current) clearTimeout(timeoutRef.current);
              handleConnectionTimeOut();
            })
          );


          // Set up PeerConnection
          peerConnectionRef.current = new RTCPeerConnection({
            iceTransportPolicy: 'relay',
            iceServers: info.iceServers,
            iceCandidatePoolSize: 8
          });

          peerConnectionRef.current.addTransceiver('video', {
            direction: 'recvonly',
          });

          // Add video and audio tracks
          localStreamRef.current = await navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true,
          });
          localStreamRef.current.getAudioTracks().forEach((track) => {
            track.enabled = false;
            peerConnectionRef.current?.addTrack(
              track,
              streamDestination.stream
            );
          });

          // Set up data channel
          const DATA_CHANNEL_LABEL = 'camera-data-channel-protobuf';
          dataChannelRef.current = peerConnectionRef.current.createDataChannel(DATA_CHANNEL_LABEL);

          // Attach data channel event handlers
          dataChannelRef.current.onmessage = (event) => {
            const cameraMessage = protobufs.simplisafe.cameras.messages.v1.CameraMessage;
            const instructions = cameraMessage.decode(new Uint8Array(event.data));
          };

          // Attach PeerConnection event handlers
          peerConnectionRef.current.addEventListener(
            'icecandidate',
            (peerEventListenerIcecandidate.current = ({ candidate }) => {
              if (process.env.REACT_APP_DEBUG) {
                log({
                  message: `on icecandidate camera: ${props.uuid}, sid: ${props.sid}`,
                  data: {
                    uuid: props.uuid,
                    sid: props.sid,
                    candidate,
                  },
                });
              }
              if (candidate) {
                signalingClient.sendIceCandidate(candidate);
              } else {
                // No more ICE candidates will be generated
              }
            })
          );

          peerConnectionRef.current?.addEventListener(
            'track',
            (peerEventListenerTrack.current = async (event) => {

              if (!success) {
                success = true;
                const stats = (await peerConnectionRef.current.getStats()) as Map<
                  any,
                  any
                >;
                const filteredStats = Array.from(stats.values()).filter(
                  (report) => report.type === 'inbound-rtp'
                );
                const audioStats = filteredStats.find(
                  (report) => report.kind === 'audio'
                );
                const videoStats = filteredStats.find(
                  (report) => report.kind === 'video'
                );
                pcsSession.updateStats(audioStats, videoStats);
                pcsSession.livestreamSuccessfulAttempt();
              }
              if (process.env.REACT_APP_DEBUG) {
                log({
                  message: `on track camera: ${props.uuid}, sid: ${props.sid}`,
                  data: {
                    uuid: props.uuid,
                    sid: props.sid,
                    streamCount: event.streams?.length,
                    track: event.track,
                  },
                });
              }
              event.streams[0].getVideoTracks().forEach((videoTrack) => {
                videoTrack.onmute = () => {
                  if (process.env.REACT_APP_DEBUG) {
                    log({
                      message: `Frozen video stream detected on camera: ${props.uuid}, sid: ${props.sid}`,
                      data: {
                        uuid: props.uuid,
                        sid: props.sid,
                        streamCount: event.streams?.length,
                        track: event.track,
                      },
                    });
                  }
                  pcsSession.livestreamFreezeDetection();
                };
              });
              setConnectionState({
                connecting: false,
                connected: true,
                remoteStream: event.streams[0],
              });
              if (timeoutRef.current) clearTimeout(timeoutRef.current);
            })
          );

          // Finally actually open the signaling client
          signalingClient.open();

        } else {
          // No info object returned
          handleConnectionTimeOut();
        }
      }
    },
    [connectionState, props.sid, props.uuid, props.authHeader]
  );

  const sendDataChannelMessage = (message: protobufs.simplisafe.cameras.messages.v1.ICameraMessage) => {
    // Data channel messages are sent in protobuf format - build and encode this first
    // Uses simplisafe-custom protobuf messages which are built on protobufjs lib

    const cameraMessage = protobufs.simplisafe.cameras.messages.v1.CameraMessage;
    const instructions = cameraMessage.create(message);
    const buffer = cameraMessage.encode(instructions).finish();

    try {
      dataChannelRef.current.send(buffer);
    }
    catch (e) {
      console.log("!!! ERROR !!!");
      console.log(e);
    }

  };

  return {
    audioContext: audioContextRef.current,
    audioDestination: audioDestinationRef.current,
    localStream: localStreamRef.current,
    ...connectionState,
    open,
    openFullDuplex,
    close,
    sendDataChannelMessage,
  };
}
