import type { Theme as MuiTheme } from '@material-ui/core/styles';
// import type { Shadows as MuiShadows } from '@material-ui/core/styles/shadows';
import type {
  Palette as MuiPalette,
  TypeBackground as MuiTypeBackground,
} from '@material-ui/core/styles/createPalette';

import { shadows } from './shadows';
import { typography } from './typography';

// Types needed for use of theme in TS components
interface TypeBackground extends MuiTypeBackground {
  dark: string;
}

interface Palette extends MuiPalette {
  background: TypeBackground;
}

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
}

const base = {
  // palette: paletteLight, -- this is merged in based on theme selection
  shadows,
  typography,
  mixins: {
    toolbar: {
      minHeight: 24,
      '@media (min-width:600px)': {
        minHeight: 24,
      },
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 24,
      },
    },
  },

  // Set default props for some components to make a dense layout
  props: {
    MuiButton: {
      size: 'small',
    },
    MuiFilledInput: {
      margin: 'dense',
    },
    MuiFormControl: {
      margin: 'dense',
    },
    MuiFormHelperText: {
      margin: 'dense',
    },
    MuiIconButton: {
      size: 'small',
    },
    MuiInputBase: {
      margin: 'dense',
    },
    MuiInputLabel: {
      margin: 'dense',
    },
    MuiListItem: {
      dense: true,
    },
    MuiOutlinedInput: {
      margin: 'dense',
    },
    MuiFab: {
      size: 'small',
    },
    MuiTable: {
      size: 'small',
    },
    MuiTextField: {
      margin: 'dense',
    },
    // MuiToolbar: {
    //   variant: 'dense',
    // },
  },
  overrides: {
    // MuiButton: {
    //   sizeSmall: {
    //     margin: 1,
    //     padding: 1
    //   }
    // },
    MuiToggleButton: {
      sizeSmall: {
        margin: 1,
        // marginLeft: 1,
        // marginRight: 1,
        padding: 1,
      },
    },
  },
};

export default base;
