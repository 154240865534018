import { css_height, css_width } from './constants';

export const assignCategoryOfDetectedEvents = (eventQuery): any => {
  return [
    ...(eventQuery.data?.tracks
      ? eventQuery.data?.tracks?.map((item) => ({
          ...item,
          category: 'tracks',
        }))
      : []),
    ...(eventQuery.data?.moves
      ? eventQuery.data?.moves?.map((item) => ({ ...item, category: 'moves' }))
      : []),
    ...(eventQuery.data?.faces
      ? eventQuery.data?.faces?.map((item) => ({ ...item, category: 'faces' }))
      : []),
  ].sort((a, b) => a.pts_seconds - b.pts_seconds);
};

export const get_zoom_crop = (bbox, dimension): any => {
  // TODO: hardcoded image size...
  const image_width = dimension?.width || 1280;
  const image_height = dimension?.height || 720;

  // Need a bbox with a positive width and height; bbox.x and bbox.y can be 0
  if (!bbox || !bbox.w || !bbox.h)
    return { zoom_factor: 1, position_x: 0, position_y: 0 };

  const scale_factor = 0.7;
  let zoom_factor_w = (image_width / bbox.w) * scale_factor;
  let zoom_factor_h = (image_height / bbox.h) * scale_factor;
  // const zoom_factor = Math.min(zoom_factor_w, zoom_factor_h);

  if (bbox.h < bbox.w) {
    zoom_factor_h = zoom_factor_w * (image_height / image_width);
  } else {
    zoom_factor_w = zoom_factor_h * (image_width / image_height);
  }

  const zoom_factor_w_percent = `${(zoom_factor_w * 100.0).toFixed(0)}%`;
  const zoom_factor_h_percent = `${(zoom_factor_h * 100.0).toFixed(0)}%`;

  const position_x =
    (-bbox.x + (bbox.w / scale_factor - bbox.w) / 2) *
    (css_width / image_width) *
    zoom_factor_w;
  const position_y =
    (-bbox.y + (bbox.h / scale_factor - bbox.h) / 2) *
    (css_height / image_height) *
    zoom_factor_h;

  return {
    zoom_factor_w_percent,
    zoom_factor_h_percent,
    position_x,
    position_y,
  };
};
