export const apiURL = process.env.REACT_APP_PCS_ADMIN_URL;

export const STOPWATCH_HIGH_ALERT_COLOR =
  process.env.REACT_APP_STOPWATCH_HIGH_ALERT_COLOR || 'red';
export const STOPWATCH_HIGH_ALERT_SECONDS_GREATER_THAN =
  parseFloat(process.env.REACT_APP_STOPWATCH_HIGH_ALERT_SECONDS_GREATER_THAN) ||
  59;
export const STOPWATCH_MEDIUM_ALERT_SECONDS_GREATER_THAN =
  parseFloat(
    process.env.REACT_APP_STOPWATCH_MEDIUM_ALERT_SECONDS_GREATER_THAN
  ) || 29;
export const STOPWATCH_MEDIUM_ALERT_COLOR =
  process.env.REACT_APP_STOPWATCH_MEDIUM_ALERT_COLOR || 'yellow';
export const STOPWATCH_LOW_ALERT_COLOR =
  process.env.REACT_APP_STOPWATCH_LOW_ALERT_COLOR || 'white';

export const css_width = 150;
export const css_height = 150;

export const SCALE_SMALL_FACE = process.env.REACT_APP_SCALE_SMALL_FACE || '0.4';

export const WIDTH_SMALL_FACE =
  parseFloat(process.env.REACT_APP_WIDTH_SMALL_FACE) || 200;

export const SCALE_MEDIUM_FACE =
  process.env.REACT_APP_SCALE_MEDIUM_FACE || '0.3';

export const WIDTH_MEDIUM_FACE =
  parseFloat(process.env.REACT_APP_WIDTH_MEDIUM_FACE) || 260;

export const SCALE_LARGE_FACE = process.env.REACT_APP_SCALE_LARGE_FACE || '0.2';

export const WIDTH_LARGE_FACE =
  parseFloat(process.env.REACT_APP_WIDTH_LARGE_FACE) || 400;

export const LARGE_SIZE_BREAKPOINT_GREATER_THAN =
  parseFloat(process.env.REACT_APP_LARGE_SIZE_BREAKPOINT_GREATER_THAN) || 100;

export const MEDIUM_SIZE_BREAKPOINT_GREATER_THAN =
  parseFloat(process.env.REACT_APP_MEDIUM_SIZE_BREAKPOINT_GREATER_THAN) || 36;
