import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  createOpenMetricDispatch,
  MetricsContext,
} from 'src/context/Metrics-context';
import { SocketContext } from 'src/context/socket-context';

const INTERVAL_TIMER = 15 * 60 * 1000; // 15 mins

interface TimelineManagerProps {
  agentId: string;
  agentEmail: string;
}

/**
 * Calculates aggregate the amount of time that the queue was empty for an agent over the course of the empty a given time.
 * Aggregation calculation is interleaved by incoming events but resume when queue becomes empty once more.
 * @param agentId
 * @param agentEmail
 */
const useEmptyEventQueueTimeMetric = ({
  agentId,
  agentEmail,
}: TimelineManagerProps) => {
  const [eventContainer] = useContext(SocketContext);
  const [_, dispatch] = useContext(MetricsContext);
  const openMetricDispatch = useRef(createOpenMetricDispatch(dispatch)).current;
  const previousQueueLength = useRef<number>();
  const emptyQueueStartMarkRef = useRef<number>();
  const emptyQueueTimeRef = useRef(0);

  const queueLength = useMemo(
    () => eventContainer?.eventArray?.filter((e) => !!e.eventId).length ?? 0,
    [eventContainer?.eventArray]
  );
  const isQueueEmpty = queueLength === 0;

  // Aggregate time that queue has been empty
  const calculateEmptyQueueTime = useCallback(() => {
    const emptyQueueEndMark = Date.now();
    const duration = emptyQueueEndMark - emptyQueueStartMarkRef.current;
    emptyQueueTimeRef.current += duration;
    emptyQueueStartMarkRef.current = null;
  }, []);

  const markQueueEmpty = useCallback(() => {
    emptyQueueStartMarkRef.current = Date.now();
  }, []);

  const retrigger = useCallback(() => {
    if (isQueueEmpty) {
      markQueueEmpty();
    }
  }, [isQueueEmpty, markQueueEmpty]);

  const submitEmptyQueueTime = useCallback(() => {
    if (emptyQueueStartMarkRef.current) {
      calculateEmptyQueueTime();
    }

    openMetricDispatch({
      metricName: 'agent-empty-queue-time',
      payload: {
        agentId,
        agentEmail,
        elapsedTime: Math.round(emptyQueueTimeRef.current / 1000),
      },
    });

    emptyQueueTimeRef.current = 0; // restart the aggregate
    retrigger();
  }, [
    agentId,
    agentEmail,
    calculateEmptyQueueTime,
    openMetricDispatch,
    retrigger,
  ]);

  useEffect(() => {
    if (isQueueEmpty) {
      markQueueEmpty();
    }

    const hasNewEvent = queueLength > previousQueueLength.current;
    if (hasNewEvent && emptyQueueStartMarkRef.current) {
      calculateEmptyQueueTime();
    }
    return () => {
      previousQueueLength.current = queueLength;
    };
  }, [queueLength, calculateEmptyQueueTime, isQueueEmpty, markQueueEmpty]);

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      submitEmptyQueueTime();
    }, INTERVAL_TIMER);

    return () => {
      clearInterval(intervalTimer);
    };
  }, [submitEmptyQueueTime]);

  useEffect(() => {
    window.addEventListener('beforeunload', submitEmptyQueueTime);
    return () => {
      window.removeEventListener('beforeunload', submitEmptyQueueTime);
    };
  }, [submitEmptyQueueTime]);

  useEffect(() => {
    return () => {
      submitEmptyQueueTime();
    };
  }, []);

  return {
    submitEmptyQueueTimeMetric: submitEmptyQueueTime,
    retrigger,
  };
};

export default useEmptyEventQueueTimeMetric;
