import { Box, Card, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import DenseCardHeader from 'src/components/DenseCardHeader';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import VideoPlayer from 'src/components/VideoPlayer';
import { useGetCamerasMonitored } from 'src/hooks/subscriberHooks';
import { cameraIsPrivate, cameraStreamType } from 'src/utils/camera';
import { useQueryParams } from 'src/utils/useQueryParams';

import PrivateCameraCard from './PrivateCameraCard';
import { cameraIsWired } from '../../utils/camera';

const useStyles = makeStyles({
  root: {
    height: '100%',
    paddingBottom: '12px',
    // overflow: 'scroll'
  },
  grid: {
    height: '100%',
    overflow: 'scroll',
  },
  camerablock: {
    cursor: 'pointer',
  },
  outerCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

const CameraGrid = ({
  sid,
  cameras,
  timestamp,
  playerState,
  onCameraClick,
}) => {
  const classes = useStyles();
  const { uuid, eventId } = useQueryParams();
  const { playbackRate, authHeader } = playerState;
  const [showAll, setShowAll] = React.useState(false);
  const [wakeBatteryCams, setWakeBatteryCams] = React.useState(false);

  const pcsCamerasQuery = useGetCamerasMonitored(sid);

  const handlePrivateClick = () => {
    console.log('Open shutter click');
    setShowAll(true);
  };

  const handleBatteryClick = () => {
    console.log('Wake battery camera click');
    setWakeBatteryCams(true);
  };

  if (!cameras) return <LoadingSpinner message="Loading cameras from Yoda" />;
  if (!pcsCamerasQuery.isSuccess)
    return <LoadingSpinner message="Loading cameras from PCS" />;

  function renderCameras() {
    return cameras?.map((camera) => {
      let element = null;

      const pcsCamera = pcsCamerasQuery.data.find(
        (item) => item.uuid === camera.uuid
      );

      // Need TEP auth to stream
      if (!authHeader) {
        element = <LoadingSpinner />;
      }

      // Monitored?
      else if (
        !pcsCamera ||
        pcsCamera.monitored === false
        // !showAll &&       // Allow "click to view" on private cameras?
        // timestamp === ''  // Allow viewing recorded events for a private camera?  Can probably open this up, but starting more restrictive
      ) {
        element = null;
      }

      // Privacy Mode?  (To make this less restrictive, pass in current alarmState to cameraIsPrivate)
      else if (
        cameraIsPrivate(camera)
        // !showAll &&       // Allow "click to view" on private cameras?
        // timestamp === ''  // Allow viewing recorded events for a private camera?  Can probably open this up, but starting more restrictive
      ) {
        element = <PrivateCameraCard onClick={handlePrivateClick} />;
      }

      // Battery cameras - don't waste battery by waking up any time account is viewed
      else if (
        !cameraIsWired(camera) &&
        camera.uuid !== uuid &&
        // !showAll &&
        !wakeBatteryCams &&
        timestamp === ''
      ) {
        element = (
          <PrivateCameraCard
            message="Camera Asleep"
            actionText="Click to wake battery camera"
            onClick={handleBatteryClick}
          />
        );
      }

      // Camera should be viewable (but double check...)
      else if (!cameraIsPrivate(camera) || showAll || timestamp !== '') {
        element = (
          <div id={`player-${camera.uuid}`}>
            <VideoPlayer
              {...playerState}
              controls={[
                'play',
                'time',
                'progress',
                'volume',
                'siren',
                'talk',
                ...(cameraStreamType(camera) === 'kvs' ? ['reload'] : []),
              ]}
              eventId={eventId}
              sid={camera.sid}
              type={cameraStreamType(camera)}
              url={`${process.env.REACT_APP_SS_MEDIA_URL}/v1/${camera.uuid}/flv?audioEncoding=AAC`}
              uuid={camera.uuid}
              fullDuplexAudio={camera?.supportedFeatures?.fullDuplexAudio}
            />
          </div>
        );
      } else {
        element = <div>unknown state</div>;
      }

      if (!element) {
        return null;
      }

      // Returns an individual camera
      return (
        <Grid
          item
          xs={4}
          md={4}
          key={`${camera.uuid}-${timestamp}`} // Keying on both uuid and timestamp forces re-render when event is clicked, so we can play recorded video
          value={camera.uuid}
          onClick={(value) => onCameraClick(value)}
          className={classes.camerablock}
        >
          <Card className={classes.outerCard}>
            <DenseCardHeader title={camera.cameraSettings.cameraName} />
            <Box height="100%">{element}</Box>
          </Card>
        </Grid>
      );
    });
  }

  return (
    <Card className={classes.root}>
      <DenseCardHeader title={`All Cameras (rate: ${playbackRate})`} />
      <Grid className={classes.grid} container spacing={1}>
        {renderCameras()}
      </Grid>
    </Card>
  );
};

CameraGrid.propTypes = {
  sid: PropTypes.number,
  className: PropTypes.string,
  cameras: PropTypes.array,
  playerState: PropTypes.object,
  onCameraClick: PropTypes.func,
  timestamp: PropTypes.string,
};

export default CameraGrid;
