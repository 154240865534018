import React from 'react';
import { NavLink } from 'react-router-dom';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import {
  Box,
  Button,
  Divider,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { TimelineOppositeContent } from '@material-ui/lab';
import { useQueryParams } from 'src/utils/useQueryParams';
import { useKey } from 'src/context/hotkey-context';
import { useSetPcsEventStatus } from 'src/hooks/eventHooks';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CancelEventMenu, {
  CANCELED_REP,
  DELIVERY,
} from 'src/components/CancelEventMenu';
import { PcsStatus } from 'src/types';
import { useNavigate } from 'react-router';
import { usePcsAuthRep } from 'src/context/pcs-auth-context';
import { useSendAutoNotify } from 'src/hooks/subscriberHooks';
import MetricsModule from 'src/utils/MetricsModule';
import {
  createOpenMetricDispatch,
  getEventInQueueDuration,
} from 'src/context/Metrics-context';

const useStyles = makeStyles(() => ({
  root: {
    padding: '4px 0px',
  },
  timelineItem: {
    minHeight: '40px',
  },
  opposite: {
    padding: '3px 10px',
    flex: '0.05',
  },
  content: {
    padding: '0px 4px 4px 4px',
  },
}));

export default function VerifySteps({
  subscription,
  dispatch,
  payload,
  buttonRef,
  isFake,
}: Props) {
  const classes = useStyles();
  const { userId, sid, eventId, uuid } = useQueryParams();
  const [stepNumber, setStepNumber] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [handledButton, setHandledButton] = React.useState(false);
  const [canceledButton, setCanceledButton] = React.useState(false);
  const [closeReason, setCloseReason] = React.useState('');
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { id } = usePcsAuthRep();

  const autoNotification = useSendAutoNotify(eventId, userId);

  React.useImperativeHandle(
    buttonRef,
    () => {
      return {
        isHandled: handledButton,
        isCanceled: canceledButton,
      };
    },
    [handledButton, canceledButton]
  );

  React.useEffect(() => {
    if (handledButton) {
      navigate(
        `/app/follow-up?userId=${userId}&sid=${sid}&uuid=${uuid}&eventId=${eventId}`
      );
      followUp(PcsStatus.follow_up);
    }
  }, [handledButton, canceledButton]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = ({ action, reason }) => {
    setCloseReason(reason);
    pcsStatusMutation.mutate(
      {
        eventId,
        pcsStatus: PcsStatus.operator_handled,

        disposition_reason: reason,
        disposition_action: action,
      },
      {
        onSettled: () => {
          if (action === CANCELED_REP && reason !== 'Duplicate Event') {
            autoNotification.send('common_event');
          }
          dispatch({
            type: 'SEND',
            payload: {
              ...payload,
              metricName: 'event-canceled',
              eventID: eventId,
              reasonCancelEvent: reason,
            },
          });

          // Send metric for how long the event was in the queue
          if (eventId) {
            const openMetricDispatch = createOpenMetricDispatch(dispatch);
            openMetricDispatch({
              metricName: 'event-handle-time',
              payload: {
                eventId,
                duration: getEventInQueueDuration(eventId),
                PCSstatus: 'verifying',
              },
            });
          }

          navigate('/app/queue');
        },
      }
    );
  };

  useKey(
    ']',
    () => setStepNumber(stepNumber < 4 ? stepNumber + 1 : stepNumber),
    'Next Verify Step'
  );
  useKey(
    '[',
    () => setStepNumber(stepNumber > 1 ? stepNumber - 1 : stepNumber),
    'Previous Verify Step'
  );

  const pcsStatusMutation = useSetPcsEventStatus();
  const followUp = (pcsStatus?: number | null) => {
    pcsStatusMutation.mutate({
      eventId,
      pcsStatus: pcsStatus ? pcsStatus : null,
    });

    // Send metric for how long the event was in the queue
    if (eventId) {
      const openMetricDispatch = createOpenMetricDispatch(dispatch);
      openMetricDispatch({
        metricName: 'event-handle-time',
        payload: {
          eventId,
          duration: getEventInQueueDuration(eventId),
          PCSstatus: 'verifying',
        },
      });
    }
  };
  if (!subscription)
    return <LoadingSpinner message="Loading subscription from Yoda" />;
  return (
    <PerfectScrollbar>
      <Timeline align="left" className={classes.root}>
        {/*  */}
        <Paper square elevation={5} className={`${classes.content} fs-exclude`}>
          <Typography variant="body2" color="textSecondary">
            {subscription.location.notes ? (
              <>
                <b>Customer Note: </b>
                {subscription.location.notes}
              </>
            ) : null}
          </Typography>
        </Paper>

        <TimelineItem className={classes.timelineItem}>
          <TimelineOppositeContent className={classes.opposite}>
            <Typography>1</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color={stepNumber === 1 ? 'primary' : 'grey'} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper square elevation={5} className={classes.content}>
              <Typography>Review Video and Respond</Typography>
              {stepNumber === 1 && (
                <>
                  <Typography variant="body2" color="textSecondary">
                    Prompt: "This is SimpliSafe security, can I help you?"
                  </Typography>
                  <Box display="flex" justifyContent="space-evenly">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setStepNumber(stepNumber + 1)}
                    >
                      Continue
                    </Button>
                    <Button
                      variant="outlined"
                      color="default"
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        setCanceledButton(true);
                      }}
                    >
                      Cancel/Reason
                    </Button>
                  </Box>
                </>
              )}
            </Paper>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem className={classes.timelineItem}>
          <TimelineOppositeContent className={classes.opposite}>
            <Typography>2</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color={stepNumber === 2 ? 'primary' : 'grey'} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper square elevation={5} className={classes.content}>
              <Typography>Customer Info</Typography>
              {stepNumber === 2 && (
                <>
                  <Typography variant="body2" className={'fs-exclude'}>
                    {subscription.location.primaryContacts.map(
                      (contact: any, index: number) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <span key={index}>
                          {contact.name ? contact.name : ''}
                          {contact.name && contact.phone ? ': ' : ''}
                          {contact.phone ? contact.phone : ''}
                        </span>
                      )
                    )}
                  </Typography>

                  <Typography variant="body2" className={'fs-exclude'}>
                    {subscription.location.secondaryContacts.map(
                      (contact: any, index: any) =>
                        (contact.name || contact.name) && (
                          // eslint-disable-next-line react/no-array-index-key
                          <span key={index}>
                            {contact.name ? contact.name : ' - '}:{' '}
                            {contact.phone}
                            <br />
                          </span>
                        )
                    )}
                  </Typography>
                  <Typography variant="body2" className={'fs-exclude'}>
                    <i>{subscription.location.locationName}</i>
                    <br />
                    {subscription.location.street1}
                    {subscription.location.street2 ? (
                      <>
                        <br />
                        {subscription.location.street2}
                      </>
                    ) : null}
                    <br />
                    {subscription.location.city}, {subscription.location.state}{' '}
                    {subscription.location.zip}
                    <br />
                    {subscription.location.county} County
                    {subscription.location.crossStreet ? (
                      <>
                        <br />
                        Cross Street: {subscription.location.crossStreet}
                      </>
                    ) : null}
                  </Typography>
                  <Box display="flex" justifyContent="space-evenly">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setStepNumber(stepNumber + 1)}
                    >
                      Continue
                    </Button>
                    <Button
                      variant="outlined"
                      color="default"
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        setCanceledButton(true);
                      }}
                    >
                      Cancel/Reason
                    </Button>
                  </Box>
                </>
              )}
            </Paper>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem className={classes.timelineItem}>
          <TimelineOppositeContent className={classes.opposite}>
            <Typography>3</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color={stepNumber === 3 ? 'primary' : 'grey'} />
          </TimelineSeparator>
          <TimelineContent>
            <Paper square elevation={5} className={classes.content}>
              <Typography>Finalize and Follow up</Typography>
              {stepNumber === 3 && (
                <>
                  <Typography variant="body2">
                    If alarm state was disarmed, re-arm now
                  </Typography>
                  <Box display="flex" justifyContent="space-evenly">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setHandledButton(true);
                        dispatch({
                          type: 'SEND',
                          payload: {
                            ...payload,
                            metricName: 'event-handled',
                            eventID: eventId,
                            agentID: id || null,
                            cameraUUID: uuid,
                          },
                        });
                      }}
                    >
                      Handled
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        setCanceledButton(true);
                      }}
                    >
                      Cancel/Reason
                    </Button>
                  </Box>
                </>
              )}
            </Paper>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
      <CancelEventMenu
        anchorEl={anchorEl}
        open={open}
        close={handleClose}
        select={handleSelection}
        excludeItems={['Delivery', 'Outdoor Service']}
      />
    </PerfectScrollbar>
  );
}

// TODO: create subscription type
interface Props {
  subscription: any;
  dispatch: any;
  payload: any;
  buttonRef: any;
  isFake: boolean;
}
