import { useAlarmState } from 'src/hooks/useAlarmState';
import { Action, PcsStatus } from 'src/models';
import { useSetPcsEventStatus } from './eventHooks';
import { useSendAutoNotify } from './subscriberHooks';
import { useEffect, useState } from 'react';
import MetricsModule from 'src/utils/MetricsModule';
import {
  getEventInQueueDuration,
  createOpenMetricDispatch,
} from 'src/context/Metrics-context';

export function useDisarmHook({
  subscription,
  setIsDisarmed,
  isDisarmed,
  dispatch,
  userId,
  eventId,
}) {
  const [prevState, setPrevState] = useState(null);
  const autoNotification = useSendAutoNotify(eventId, userId);
  const pcsStatusMutation = useSetPcsEventStatus();
  const alarmStateQuery = useAlarmState({
    sid: subscription?.sid,
    account: subscription?.location.account,
    userId: subscription?.uid,
  });

  useEffect(() => {
    if (alarmStateQuery?.data && alarmStateQuery?.data?.state !== prevState) {
      if (alarmStateQuery?.data?.state === 'OFF' && prevState !== null) {
        setIsDisarmed(true);
      }
      setPrevState(alarmStateQuery?.data?.state);
    }
  }, [prevState, alarmStateQuery?.data]);

  useEffect(() => {
    if (!isDisarmed || !eventId) {
      return;
    }

    pcsStatusMutation.mutate(
      {
        eventId,
        pcsStatus: PcsStatus.canceled,

        disposition_reason: 'System was disarmed',
        disposition_action: Action.canceled_by_customer,
      },
      {
        onSettled: () => {
          autoNotification.send('system_disarm');

          // Send metric for how long the event was in the queue
          if (eventId) {
            const openMetricDispatch = createOpenMetricDispatch(dispatch);
            openMetricDispatch({
              metricName: 'event-handle-time',
              payload: { eventId, duration: getEventInQueueDuration(eventId) },
            });
          }
        },
      }
    );
  }, [isDisarmed, eventId]);
}
