import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core';
import base from 'src/theme';
import { paletteLight, paletteDark } from 'src/theme/palette';

const CustomThemeContext = React.createContext();
CustomThemeContext.displayName = 'CustomThemeContext';

// eslint-disable-next-line react/prop-types
function CustomThemeProvider({ children }) {
  // THEME
  const savedThemeName = localStorage.getItem('appTheme') || 'dark';
  const [themeName, _setThemeName] = useState(savedThemeName);
  const setThemeName = () => {
    const newThemeName = themeName === 'dark' ? 'light' : 'dark';
    localStorage.setItem('appTheme', newThemeName);
    _setThemeName(newThemeName);
  };
  const palette = themeName === 'dark' ? paletteDark : paletteLight;
  const customTheme = { ...base, palette };
  const theme = createTheme(customTheme);

  const value = { themeName, setThemeName };
  return (
    <CustomThemeContext.Provider value={value}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
}

function useAppTheme() {
  const context = React.useContext(CustomThemeContext);
  if (context === undefined) {
    throw new Error(`Provider not defined?`);
  }
  return { themeName: context.themeName, setThemeName: context.setThemeName };
}

export { CustomThemeProvider, useAppTheme };
