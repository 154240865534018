import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  Select,
  MenuItem,
  makeStyles,
  InputLabel,
  FormControl,
  Button,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import FlagEventButton from './FlagEventButton';
import { useGetRepMe } from 'src/hooks/repHooks';
import { useGetSecondaryEvents } from 'src/hooks/eventHooks';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  formControl: {
    minWidth: 216,
  },
}));

const Toolbar = ({
  className,
  searchValue,
  setSearchValue,
  filterValues,
  handleChangeFilterInfo,
  filtersError,
  handleChangeDate,
  eventQuery,
  searchQueriesForAuditList,
  setSearchQueriesForAuditList,
  ...rest
}) => {
  const classes = useStyles();
  const { data: repData } = useGetRepMe();

  const secondaryEvents = useGetSecondaryEvents(
    eventQuery.data?.sid,
    eventQuery.data?.eventId,
    eventQuery.data?.eventTimestamp
  );

  const handleStartViewedDate = (date) => {
    handleChangeDate(date, 'startDatetime');
    setSearchQueriesForAuditList({
      ...searchQueriesForAuditList,
      startDatetime: date,
    });
  };

  const handleEndViewedDate = (date) => {
    handleChangeDate(date, 'endDatetime');
    setSearchQueriesForAuditList({
      ...searchQueriesForAuditList,
      endDatetime: date,
    });
  };

  const handleChangeVideo = (eventId) => {
    if (eventId === eventQuery.data?.eventId) {
      setSearchQueriesForAuditList({
        ...searchQueriesForAuditList,
        secondaryEvent: undefined,
      });
      return;
    }

    const secondaryEvent = secondaryEvents.find(
      (event) => event.eventId === eventId
    );
    setSearchQueriesForAuditList({
      ...searchQueriesForAuditList,
      secondaryEvent,
    });
  };

  if (repData?.role === 'engineer' || repData?.role === 'agent_ops')
    return <></>;

  const triggerVideoData = eventQuery.data?.video;
  const triggerVideoKey = triggerVideoData
    ? Object.keys(triggerVideoData)[0]
    : undefined;
  const triggerCameraName = triggerVideoKey
    ? triggerVideoData[triggerVideoKey].cameraName
    : 'unknown';

  return (
    <div {...rest}>
      <Box mt={0} display="inline">
        <Box display="inline-flex" width={350}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder="camera_uuid, edit_uid, user_id, sid, type"
            variant="outlined"
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setSearchValue(e.target.value);
              }
            }}
          />
        </Box>
        <Box>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <DatePicker
              name="startDatetime"
              clearable
              inputVariant="outlined"
              label="Date start"
              value={searchQueriesForAuditList.startDatetime ?? null}
              onChange={handleStartViewedDate}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <DatePicker
              name="endDatetime"
              clearable
              inputVariant="outlined"
              label="Date end"
              value={searchQueriesForAuditList.endDatetime ?? null}
              onChange={handleEndViewedDate}
            />
          </MuiPickersUtilsProvider>

          <TextField
            error={filtersError.edit_uid}
            label="EditUID"
            name="edit_uid"
            onChange={handleChangeFilterInfo}
            value={
              searchQueriesForAuditList.edit_uid
                ? searchQueriesForAuditList.edit_uid
                : ''
            }
            variant="outlined"
            onBlur={handleChangeFilterInfo}
          />
          <TextField
            error={filtersError.event_id}
            label="EventID"
            name="event_id"
            onChange={handleChangeFilterInfo}
            value={
              searchQueriesForAuditList.event_id
                ? searchQueriesForAuditList.event_id
                : ''
            }
            variant="outlined"
            onBlur={handleChangeFilterInfo}
          />
        </Box>
        <Box>
          <TextField
            error={filtersError.user_id}
            label="UserID"
            name="user_id"
            onChange={handleChangeFilterInfo}
            value={
              searchQueriesForAuditList.user_id
                ? searchQueriesForAuditList.user_id
                : ''
            }
            variant="outlined"
            onBlur={handleChangeFilterInfo}
          />
          <TextField
            error={filtersError.sid}
            label="SID"
            name="sid"
            onChange={handleChangeFilterInfo}
            value={
              searchQueriesForAuditList.sid ? searchQueriesForAuditList.sid : ''
            }
            variant="outlined"
            onBlur={handleChangeFilterInfo}
          />
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Event type</InputLabel>
            <Select
              value={searchQueriesForAuditList.type}
              label="Type"
              name="type"
              onChange={handleChangeFilterInfo}
            >
              <MenuItem value={'event'}>Event</MenuItem>
              <MenuItem value={'camera'}>Camera</MenuItem>
              <MenuItem value={'people'}>People</MenuItem>
              <MenuItem value={'service'}>Service</MenuItem>
            </Select>
          </FormControl>
          <Box display="inline-flex" flexGrow={1} />
          <Box display="inline-flex" justifyContent="flex-end">
            <Box>
              {eventQuery?.data ? (
                <FlagEventButton event={eventQuery.data} />
              ) : (
                <span></span>
              )}
            </Box>
          </Box>
          <Box display="inline-flex" justifyContent="flex-end">
            <Box>
              <Button>Export</Button>
            </Box>
          </Box>
        </Box>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Videos</InputLabel>
          <Select
            value={searchQueriesForAuditList.secondary_id}
            label="Video"
            name="video"
            onChange={({ target: { value } }) => handleChangeVideo(value)}
          >
            {secondaryEvents.length === 0 ? (
              <MenuItem>NO ADDITIONAL VIDEOS IN EVENT</MenuItem>
            ) : (
              <MenuItem value={eventQuery.data?.eventId}>
                TRIGGERING VIDEO - {triggerCameraName} -{' '}
                {moment
                  .unix(eventQuery.data?.eventTimestamp)
                  .format('h:mm:ss a')}
              </MenuItem>
            )}
            {secondaryEvents.map((event) => {
              const videoData = event.pcsMetadata?.video ?? event.video;
              const firstVideoKey = videoData ? Object.keys(videoData)[0] : '';
              const cameraName = firstVideoKey
                ? videoData[firstVideoKey].cameraName
                : 'unknown';

              return (
                <MenuItem key={event.eventId} value={event.eventId}>
                  {`SECONDARY ${
                    event.eventCid === 1171 ? 'AGENT VIEWED' : 'RECORDING'
                  } - ${cameraName} - ${moment
                    .unix(event.eventTimestamp)
                    .format('h:mm:ss a')}`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
