import { useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { getInitials } from '../../utils/getInitials';
import moment from 'moment';
import { useGetStatusService } from '../../hooks/subscriberHooks';
import { getAuthenticated, getLastAuthFailure } from './ExportLink';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

export const SubsTable = (props) => {
  const { customer, selectedCustomerIds, handleSelectOne, userRole } = props;

  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <TableRow
      hover
      key={customer.user_id}
      selected={selectedCustomerIds.indexOf(customer.id) !== -1}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedCustomerIds.indexOf(customer.id) !== -1}
          onChange={(event) => handleSelectOne(event, customer.id)}
          value="true"
        />
      </TableCell>
      <TableCell>
        <Box alignItems="center" display="flex">
          <Avatar className={classes.avatar}>
            {getInitials(customer.email)}
          </Avatar>
          <Typography className={'fs-exclude'}>{customer.user_id}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Button
          onClick={() => navigate(`/app/subs/${customer.user_id}`)}
          variant="contained"
          color="primary"
        >
          Manage Service
        </Button>
      </TableCell>
      <TableCell className={'fs-exclude'}>{customer.email}</TableCell>
      <TableCell>{getAuthenticated(customer)}</TableCell>
      <TableCell>{customer.auth_fail_count}</TableCell>
      <TableCell>{getLastAuthFailure(customer)}</TableCell>
      <TableCell>{customer.service}</TableCell>
    </TableRow>
  );
};
