import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  TextareaAutosize,
  Typography,
  makeStyles,
} from '@material-ui/core';
import NotesImg from './images/notes.png';
import HistoryImg from './images/history.png';
import EditImg from './images/edit.png';
import {
  useLocationHistory,
  useLocationHistoryQuery,
} from 'src/hooks/locationHooks';
import moment from 'moment';

const useStyles = makeStyles({
  scroll: {
    height: '30vh',
  },
  notes: {
    paddingTop: '5px',
  },
  notesIcon: {
    height: '16px',
    width: '16px',
    cursor: 'pointer',
  },
});

type LocationNotesProps = {
  sid: string;
  eventId: number;
};

const LocationNotes: FC<LocationNotesProps> = ({ sid, eventId }) => {
  const classes = useStyles();
  const [viewType, setViewType] = useState<'view' | 'edit' | 'history'>('view');
  const [note, setNote] = useState('');

  const { data } = useLocationHistoryQuery({ sid });

  const handleViewType = (type: 'view' | 'edit' | 'history') => {
    setViewType(type);
  };
  const locationNotesMutation = useLocationHistory({ sid });

  const handleNotesSave = async () => {
    locationNotesMutation.mutate({ notes: note, eventId });
    setNote('');
    setViewType('view');
  };

  useEffect(() => {
    if (data && data.notes) {
      setNote(data.notes);
    }
  }, [data]);

  const sortedHistory = data?.history.sort((a, b) => {
    if (Date.parse(a.updated_at) > Date.parse(b.updated_at)) {
      return -1;
    } else if (Date.parse(a.updated_at) < Date.parse(b.updated_at)) {
      return 1;
    } else {
      return 0;
    }
  });

  return (
    <Card className={classes.scroll} key="locationNotes">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="overline">
          {viewType === 'history' ? 'Location History' : 'Location Notes'}
        </Typography>
        <Grid item style={{ display: 'flex' }}>
          <img
            onClick={() => handleViewType('view')}
            className={classes.notesIcon}
            src={NotesImg}
            alt="notes view"
          />
          <img
            onClick={() => handleViewType('edit')}
            className={classes.notesIcon}
            src={EditImg}
            alt="edit notes view"
          />
          <img
            onClick={() => handleViewType('history')}
            className={classes.notesIcon}
            src={HistoryImg}
            alt="history view"
          />
        </Grid>
      </Grid>
      <Divider />
      {viewType === 'view' && (
        <Box
          className="fs-exclude"
          style={{ height: '78%', overflowY: 'auto', whiteSpace: 'pre-wrap' }}
        >
          <Typography className={classes.notes}>
            [{data?.eventId ?? 'X'}]: {data?.notes}
          </Typography>
        </Box>
      )}
      {viewType === 'edit' && (
        <Grid
          container
          className="fs-exclude"
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item style={{ height: '75%', width: '100%' }}>
            <TextareaAutosize
              onChange={(e) => setNote(e.target.value)}
              aria-label="Enter Your Note"
              value={note}
              minRows={3}
              maxRows={7}
              placeholder="Minimum 3 rows"
              style={{
                width: '100%',
                background: '#424242',
                color: 'white',
                border: 'none',
              }}
            />
          </Grid>
          <Grid
            item
            style={{
              justifyContent: 'flex-end',
              width: '100%',
              display: 'flex',
            }}
          >
            <Button onClick={() => setViewType('view')} variant="outlined">
              Close
            </Button>
            <Button
              style={{ marginLeft: '12px' }}
              variant="contained"
              color="primary"
              disabled={!note}
              onClick={handleNotesSave}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      )}
      {viewType === 'history' && (
        <Grid
          className="fs-exclude"
          container
          direction="row"
          justifyContent="space-between"
          style={{ height: '78%', overflowY: 'auto' }}
        >
          {sortedHistory?.map((item, index) => (
            <React.Fragment key={`${index}${data.sid}`}>
              <Grid item xs={4}>
                <Typography className={classes.notes}>
                  {moment(moment.utc(item.updated_at))
                    .local()
                    .format('MM/DD/YYYY hh:mm:ss A')}
                </Typography>
              </Grid>
              <Grid
                onClick={() => {
                  setNote(item.notes);
                  setViewType('edit');
                }}
                item
                xs={6}
                style={{ cursor: 'pointer' }}
              >
                <Typography className={classes.notes}>
                  [{item.eventId ?? 'X'}]: {item.notes}
                </Typography>
              </Grid>
              <Divider />
            </React.Fragment>
          ))}
        </Grid>
      )}
    </Card>
  );
};

export default LocationNotes;
