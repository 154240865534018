import React from 'react';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
import { Navigate } from 'react-router';
import { Role } from 'src/models';
import RepsList from './PeopleList';
import ManageReps from './ManageRepsPage';
import { useGetRepMe } from 'src/hooks/repHooks';
import { LoadingSpinner } from 'src/components/LoadingSpinner';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const RepsListView = () => {
  const { data: repData, isLoading } = useGetRepMe();
  const classes = useStyles();

  if (isLoading) return <LoadingSpinner />;

  if (repData?.role === Role.QA || repData.role === Role.AGENT_OPS) {
    return <Navigate to="/app/dashboard" />;
  }

  return (
    <Page className={classes.root} title="Manage people">
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary">
          Reps list
        </Typography>

        <Box mt={3}>
          <RepsList />
        </Box>
      </Container>
    </Page>
  );
};

const RepsDetailsView = () => {
  const classes = useStyles();
  return (
    <Page className={classes.root} title="Rep Details">
      <Container maxWidth="lg">
        <Typography variant="h3" color="textPrimary">
          Manage people
        </Typography>
        <Box mt={3}>
          <ManageReps />
        </Box>
      </Container>
    </Page>
  );
};

export { RepsListView, RepsDetailsView };
