import React, { useEffect, useMemo, useState } from 'react';
import {
  apiURL,
  SCALE_SMALL_FACE,
  WIDTH_SMALL_FACE,
  SCALE_MEDIUM_FACE,
  WIDTH_MEDIUM_FACE,
  SCALE_LARGE_FACE,
  WIDTH_LARGE_FACE,
  LARGE_SIZE_BREAKPOINT_GREATER_THAN,
  MEDIUM_SIZE_BREAKPOINT_GREATER_THAN,
} from '../../constants';
import { ITrack } from '../../types';

const ZoomedOutAvatar = ({
  track,
  avatarComponent,
}: {
  track: ITrack;
  avatarComponent: React.ReactElement;
}) => {
  const [showZoomedOutAvatar, setShowZoomedOutAvatar] = useState(false);
  const [originalDimensions, setOriginalDimensions] = useState({
    width: 1920,
    height: 1080,
  });
  const { x, y, w, h } = track.bbox;
  const scale =
    h > LARGE_SIZE_BREAKPOINT_GREATER_THAN
      ? SCALE_LARGE_FACE
      : h < MEDIUM_SIZE_BREAKPOINT_GREATER_THAN
      ? SCALE_SMALL_FACE
      : SCALE_MEDIUM_FACE;
  const faceWidthAndHeight =
    h > LARGE_SIZE_BREAKPOINT_GREATER_THAN
      ? WIDTH_LARGE_FACE
      : h < MEDIUM_SIZE_BREAKPOINT_GREATER_THAN
      ? WIDTH_SMALL_FACE
      : WIDTH_MEDIUM_FACE;
  const positionY =
    y - h <= 0
      ? 0
      : y + h >= originalDimensions.height
      ? originalDimensions.height - h - faceWidthAndHeight / 2
      : (y - faceWidthAndHeight / 2) * -1;
  const positionX =
    x - w <= 0
      ? 0
      : x + w >= originalDimensions.width
      ? originalDimensions.width - w - faceWidthAndHeight / 2
      : (x - faceWidthAndHeight / 2) * -1;

  useEffect(() => {
    const image = new Image();
    image.src = `${apiURL}/${track.frame_file}`;
    image.onload = () => {
      setOriginalDimensions({
        width: image.width,
        height: image.height,
      });
    };
  }, [track.frame_file]);

  const zoomedOutAvatar = useMemo(
    () => (
      <div
        role="img"
        aria-label="Zoomed out face avatar"
        style={{
          height: 100,
          width: 100,
          position: 'absolute',
        }}
      >
        <div
          aria-hidden="true"
          style={{
            backgroundImage: `url(${apiURL}/${track.frame_file})`,
            backgroundColor: '#e7e7e7',
            transform: `scale(${scale})`,
            transformOrigin: 'top left',
            height: `${faceWidthAndHeight}px`,
            width: `${faceWidthAndHeight}px`,
            backgroundPosition: `${positionX}px ${positionY}px`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto',
            borderRadius: 4,
          }}
        ></div>
      </div>
    ),
    [track.frame_file, originalDimensions.width, originalDimensions.height]
  );

  return (
    <div onMouseLeave={() => setShowZoomedOutAvatar(false)}>
      {zoomedOutAvatar}
      <div
        onMouseEnter={() => setShowZoomedOutAvatar(true)}
        style={{
          opacity: showZoomedOutAvatar ? '0' : '1',
          visibility: showZoomedOutAvatar ? 'hidden' : 'visible',
          transition: 'opacity 0.6s, visibility 0.3s',
          width: 'fit-content',
        }}
      >
        {avatarComponent}
      </div>
    </div>
  );
};

export default ZoomedOutAvatar;
