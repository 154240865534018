import { Card, Grid, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';

import { ICommonProps, ITrack } from '../../types';
import Details from '../Details';

interface IProps extends ICommonProps {
  eventId: number;
  track: ITrack;
  onImportant: (img_file) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
  },
}));

const Moves: FC<IProps> = ({
  eventId,
  track,
  isSelectingPeople,
  onItemClick,
  onImportant,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Grid container spacing={1}>
        <Details
          eventId={eventId}
          track={track}
          isSelectingPeople={isSelectingPeople}
          onItemClick={onItemClick}
          onImportant={onImportant}
          title="Motion"
        />
      </Grid>
    </Card>
  );
};

export default Moves;
