import React, { useState } from 'react';
import clsx from 'clsx';

import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import InputIcon from '@material-ui/icons/Input';
import Brightness4Icon from '@material-ui/icons/Brightness4';

import { useAppTheme } from 'src/context/theme-context';
import KeyboardDialog from 'src/components/KeyboardDialog';
import { usePcsAuthRep } from 'src/context/pcs-auth-context';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 2rem',
    color: theme.palette.text.secondary,
    background: '#111',
  },
}));

const TopBar = ({ title }) => {
  const classes = useStyles();
  const { setThemeName } = useAppTheme();
  const { logout } = usePcsAuthRep();

  return (
    <AppBar className={clsx(classes.root)} elevation={0}>
      <Toolbar disableGutters>
        <Typography>{title}</Typography>
        <Box flexGrow={1} />
        <Box flexGrow={1} />
        <Hidden xsDown>
          <IconButton
            size="small"
            color="inherit"
            onClick={logout}
            title="Logout"
          >
            <InputIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            color="inherit"
            onClick={setThemeName}
            title="Theme"
          >
            <Brightness4Icon fontSize="small" />
          </IconButton>
          <KeyboardDialog />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
