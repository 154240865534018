import React, {useContext, useEffect, useState} from 'react';
import { useRoutes } from 'react-router-dom';
import routes from 'src/routes';
import { ScreenContext } from 'src/context/ScreenRecordingContext';
import { useGetRepMe } from 'src/hooks/repHooks';
import { Role } from 'src/models';

const Route = () => {
  const routing = useRoutes(routes);
  const { stream, setStream } = useContext(ScreenContext);
  const repQuery = useGetRepMe();

  useEffect(() => {
    // use the following function to record display as well.
    /*
    const getRecordingPermission = async () => {
      try {
        await navigator.mediaDevices
          .getUserMedia({
            audio: true,
          })
          .then(async (audioStream) => {
            await navigator.mediaDevices //@ts-ignore
              .getDisplayMedia({
                video: true,
              })
              .then((videoStream) => {
                setStream([
                  ...audioStream.getTracks(),
                  ...videoStream.getTracks(),
                ])
              });
          });
      } catch (error) {
        console.error(error);
      }
    };
     */

    const getRecordingPermission = async () => {
      try {
        await navigator.mediaDevices
          .getUserMedia({
            audio: true,
          })
          .then(async (audioStream) => {
            setStream([
              ...audioStream.getTracks(),
            ])
          });
      } catch (error) {
        console.error(error);
      }
    };

    // If role is agent after login, only then we should show the permission prompt
    if (repQuery?.data?.role === Role.AGENT_OPS && !stream) {
      getRecordingPermission();
    }
  });

  return routing;
};
export default Route;
