// Setup the state and callbacks that can be passed to and used by any number of VideoPlayer's

// import React from 'react';
import { useReducer } from 'react';
import { useKey } from 'src/context/hotkey-context';
import { useSubscriber } from 'src/context/sub-auth-context';

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_PLAYING':
      return {
        ...state,
        isPlaying: !state.isPlaying,
      };
    case 'TOGGLE_MUTE':
      return {
        ...state,
        volume: state.volume > 0 ? 0 : 1,
      };
    case 'SEEK':
      // By incrementing, cause useEffect in player to go to videoDuration
      return {
        ...state,
        seekCommand: action,
      };

    case 'TOGGLE_STATS':
      return {
        ...state,
        showStats: !state.showStats,
      };
    case 'TOGGLE_AI_OVERLAY':
      return {
        ...state,
        predicting: !state.predicting,
      };
    case 'TOGGLE_MOTION_ZONE_OVERLAY':
      return {
        ...state,
        showImgMask: state.showImgMask ? 0 : 1,
      };
    case 'FORCE_MOTION_ZONE_OVERLAY':
      return {
        ...state,
        showImgMask: Math.random(),
      };
    case 'TOGGLE_TALKING':
      return {
        ...state,
        isTalking: !state.isTalking,
      };

    case 'SPEED_UP':
      return {
        ...state,
        playbackRate:
          state.playbackRate > 1
            ? state.playbackRate + 1
            : state.playbackRate * 2,
      };
    case 'SLOW_DOWN':
      return {
        ...state,
        playbackRate:
          state.playbackRate > 1
            ? state.playbackRate - 0.5
            : state.playbackRate / 2,
      };
    default:
      throw new Error();
  }
};

function useVideoPlayer(userId = null, playerState = {}) {
  // Default state overridden by playerState
  const initialState = {
    isPlaying: true,
    isTalking: false,
    showStats: false,
    predicting: false,
    volume: 0,
    showImgMask: 0,
    playbackRate: 1.0,
    seekCommand: {},
    controls: ['play', 'time', 'progress', 'volume', 'siren', 'talk'],
    ...playerState,
    // handlePlay,
    // handlePause
  };

  // STATE
  const [state, dispatch] = useReducer(reducer, initialState);
  const { authHeader } = useSubscriber(userId);

  // KEYBOARD
  useKey('space', () => dispatch({ type: 'TOGGLE_PLAYING' }), 'Play/Pause All'); // prettier-ignore
  useKey('e', () => dispatch({ type: 'SEEK', detail: 'END' }), 'Go Live (end of video)'); // prettier-ignore
  useKey('ctrl+shift+right', () => dispatch({ type: 'SEEK', detail: 0.05 }), 'Go forward 0.05 sec)'); // prettier-ignore
  useKey('ctrl+shift+left', () => dispatch({ type: 'SEEK', detail: -0.05 }), 'Go backward 0.05 sec'); // prettier-ignore
  useKey('shift+right', () => dispatch({ type: 'SEEK', detail: 0.5 }), 'Go forward 0.5 sec)'); // prettier-ignore
  useKey('shift+left', () => dispatch({ type: 'SEEK', detail: -0.5 }), 'Go backward 0.5 sec'); // prettier-ignore
  useKey('right', () => dispatch({ type: 'SEEK', detail: 5 }), 'Go forward 5 sec)'); // prettier-ignore
  useKey('left', () => dispatch({ type: 'SEEK', detail: -5 }), 'Go backward 5 sec'); // prettier-ignore
  useKey('alt+right', () => dispatch({ type: 'SEEK', detail: 'NEXT_MARKER' }), 'Go forward to next marker)'); // prettier-ignore
  useKey('alt+left', () => dispatch({ type: 'SEEK', detail: 'PREV_MARKER' }), 'Go back to previous marker)'); // prettier-ignore
  useKey('<', () => dispatch({ type: 'SLOW_DOWN' }), 'Slow down');
  useKey('>', () => dispatch({ type: 'SPEED_UP' }), 'Speed up');
  useKey('m', () => dispatch({ type: 'TOGGLE_MUTE' }), 'Toggle listen-in muted'); // prettier-ignore
  useKey('s', () => dispatch({ type: 'TOGGLE_STATS' }), 'Toggle render stats');
  useKey('a', () => dispatch({ type: 'TOGGLE_AI_OVERLAY' }), 'Toggle AI overlay'); // prettier-ignore
  useKey('z', () => dispatch({ type: 'TOGGLE_MOTION_ZONE_OVERLAY' }), 'Toggle Motion Zone overlay'); // prettier-ignore
  useKey('t', () => dispatch({ type: 'TOGGLE_TALKING' }), 'Toggle 2-way audio (talking)'); // prettier-ignore

  return { ...state, dispatch, authHeader };
}

export { useVideoPlayer };
