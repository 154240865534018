import {
  makeStyles,
  Container,
  Typography,
  Card,
  TableCell,
  TableRow,
  TableHead,
  Table,
  Box,
  TableBody,
  Paper,
  TablePagination,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextareaAutosize,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';
import { Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import Page from 'src/components/Page';
import { useInviteUsers, useListInvites } from 'src/hooks/inviteHooks';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  textarea: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    resize: 'vertical',
  },
}));

const InviteListView = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState('');
  const [searchInputValue, setSearchInputValue] = React.useState('');

  const { data, isLoading, error } = useListInvites({
    page: page,
    page_size: rowsPerPage,
    search: search,
  });
  const { mutateAsync: inviteUsers } = useInviteUsers();

  const handlePageChange = (_: unknown, newPage: number) => {
    if (newPage >= 0) {
      setPage(newPage);
    }
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDialogClose = () => {
    setDialogIsOpen(false);
  };

  const handleInviteSubmit = async (values, { setSubmitting }) => {
    try {
      await inviteUsers(values);
    } finally {
      setPage(0);
      setSubmitting(false);
      setDialogIsOpen(false);
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchInputValue(event.target.value);
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearch(searchInputValue);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchInputValue]);

  return (
    <Page className={classes.root} title="Invite List">
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary">
          Invites
        </Typography>
        {!error && (
          <>
            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={() => setDialogIsOpen(true)}
                color="primary"
                variant="contained"
              >
                Invite users
              </Button>
            </Box>
            <Paper>
              <Box mt={3} p={1}>
                <Card>
                  <Box minWidth={1050}>
                    <Grid item xs={6}>
                      <Box maxWidth={500} mb={1}>
                        <TextField
                          key="key"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SvgIcon fontSize="small" color="action">
                                  <SearchIcon />
                                </SvgIcon>
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Search email. Press enter to fire search."
                          variant="outlined"
                          value={searchInputValue}
                          onChange={handleSearchInputChange}
                        />
                      </Box>
                    </Grid>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Email</TableCell>
                          <TableCell>Hash</TableCell>
                          <TableCell>Created by</TableCell>
                          <TableCell>Invited at</TableCell>
                        </TableRow>
                      </TableHead>
                      {isLoading ? (
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={4}>
                              <LoadingSpinner />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        <TableBody>
                          {data.data.length === 0 && (
                            <TableRow>
                              <TableCell colSpan={4}>
                                No invites found
                              </TableCell>
                            </TableRow>
                          )}
                          {data.data.map((invite, i) => (
                            <TableRow hover key={i}>
                              <TableCell className={'fs-exclude'}>{invite.rawEmail}</TableCell>
                              <TableCell className={'fs-exclude'}>{invite.email}</TableCell>
                              <TableCell>{invite.createdBy?.email}</TableCell>
                              <TableCell>
                                {invite.createdAt
                                  ? moment
                                    .utc(invite.createdAt)
                                    .local()
                                    .format('MM/DD/YYYY HH:mm')
                                  : ''}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      )}
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={data?.total}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleRowsPerPageChange}
                    />
                  </Box>
                </Card>
              </Box>
            </Paper>
          </>
        )}
      </Container>
      <Dialog
        open={dialogIsOpen}
        fullWidth
        maxWidth={'md'}
        onClose={handleDialogClose}
      >
        <Formik initialValues={{ emails: '' }} onSubmit={handleInviteSubmit}>
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle>Invite users</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Type in the email address list of the users you want to
                  invite. Up to 1000 emails can be invited at once.
                </DialogContentText>
                <TextareaAutosize
                  className={classes.textarea}
                  autoFocus
                  name="emails"
                  value={values.emails}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  placeholder="Comma separated list of emails"
                  minRows={5}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Invite
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </Page>
  );
};

export default InviteListView;
