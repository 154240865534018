import React, { useReducer } from 'react';
import { useQuery } from 'react-query';
import { usePcsClient } from 'src/context/pcs-auth-context';

// *** Context Provider ***
const SubscribersState = React.createContext();
SubscribersState.displayName = 'SubscribersState';
const SubscribersDispatch = React.createContext();
SubscribersDispatch.displayName = 'SubscribersDispatch';

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD':
      return {
        ...state,
        subscribers: [...state.subscribers, action.payload],
      };
    case 'REMOVE':
      return {
        ...state,
        subscribers: state.subscribers.filter(
          ({ user_id: existingKey }) => action.payload.key !== existingKey
        ),
      };
    default:
      throw new Error();
  }
};

// eslint-disable-next-line react/prop-types
function SubscriberProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {
    subscribers: [],
  });

  // TODO: install SubscriberProvider into the main App... not currently using, so not there
  return (
    <SubscribersState.Provider value={state.subscribers}>
      <SubscribersDispatch.Provider value={dispatch}>
        {children}
      </SubscribersDispatch.Provider>
    </SubscribersState.Provider>
  );
}

/**
 * Hook to get subscriber auth token for Yoda/SS Media calls (gets token from PCS server)
 *
 * @returns react-query object; data contains {user_id, access_token}
 *    Also includes authHeader as a convenience*
 * @throws
 * @todo
 */
function useSubscriber(userId) {
  const pcsClient = usePcsClient();
  const [expiresIn, setExpiresIn] = React.useState(60); // SECONDS (because *1000 below).
  const pcsQuery = useQuery({
    queryKey: ['subs', { userId }],
    queryFn: () => pcsClient(`sub/get/${userId}`),
    enabled: userId > 0,

    // Get latest token that PCS server has _at least_ every 5 mins, faster if expires < 5 mins, but no more often than 10 secs
    staleTime: Math.min(60 * 5, Math.max(expiresIn, 10)) * 1000,
    refetchInterval: Math.min(60 * 5, Math.max(expiresIn, 10)) * 1000,
    refetchIntervalInBackground: true,
  });
  if (pcsQuery.isSuccess && expiresIn !== pcsQuery.data.expires_in) {
    // console.log('Setting Auth expiresIn ', pcsQuery.data.expires_in);
    setExpiresIn(pcsQuery.data.expires_in);
  }
  const access_token = localStorage.getItem('token');
  const authHeader = pcsQuery.isSuccess ? `Bearer ${access_token}` : null;

  return { ...pcsQuery, authHeader, access_token };
}

export { SubscriberProvider, useSubscriber };
