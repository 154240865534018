export const Icon = {
  daggerBlack: 'DaggerIconBlack',
  daggerWhite: 'DaggerIconWhite',
  shieldBlack: 'Shield_Icon_Black',
  shieldWhite: 'Shield_Icon_White',
  doorbellBlack: 'DBP_Icon_Black',
  doorbellWhite: 'DBP_Icon_White',
  simplicamBlack: 'SimpliCam_DrkLines',
  simplicamWhite: 'SimpliCam_WhiteLines',
};
