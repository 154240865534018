import {
  Box,
  Card,
  Container,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
} from '@material-ui/core';
import React, { useState } from 'react';
import DenseCardHeader from 'src/components/DenseCardHeader';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import Page from 'src/components/Page';
import VideoPlayer from 'src/components/VideoPlayer/index';
// import FlvPlayer from 'src/components/VideoPlayer/FlvPlayer';
// import Overlay from 'src/components/Overlay/Overlay';
import { useKey } from 'src/context/hotkey-context';
import { useSubscriber } from 'src/context/sub-auth-context';
import { WebSocketTest } from './WebsocketTest';
import { useQueryParams } from 'src/utils/useQueryParams';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(0),
  },
}));

const VideoView = () => {
  const classes = useStyles();

  // TODO: hardcoded
  // const camera = { uuid: '04e15ed05b81737512c3c2d0a0094310' }; // Front door
  // const camera = { uuid: '29322af849fb4eb22866c8b5400005af' }; // Kitchen
  const camera = { uuid: '540debc49ac0691098a6b499f003a473' }; // Den
  const userId = 154;

  const [showStats, setShowStats] = useState(false);
  const [predicting, setPredicting] = useState(false);
  const [isPlaying, setPlaying] = useState(true);
  const [volume, setVolume] = useState(0);
  // const handlePlay = React.useCallback(() => {}, []);
  // const handlePause = React.useCallback(() => {}, []);
  const markers = [
    {
      id: 1,
      time: 5,
      color: '#ff0000',
      title: 'Marker 1',
    },
    {
      id: 2,
      time: 10,
      color: '#ffc837',
      title: 'Marker 2',
    },
  ];

  // KEYBOARD
  useKey('space', () => setPlaying(!isPlaying), 'Play All');
  useKey('s', () => setShowStats(!showStats), 'Show playback rate stats');
  useKey('a', () => setPredicting(!predicting), 'Toggle AI overlay');

  console.log('VideoView outer');

  const [live, setLive] = React.useState(false);
  let url =
    'ws://local.pcs.com:8000/video/ws?file_path=outputs/76246/29322af849fb4eb22866c8b5400005af/video/1606745538.740551.flv';
  if (live) {
    url = `https://media.simplisafe.com/v1/${camera.uuid}/flv?x=944&audioEncoding=AAC`;
  }
  // url={`https://media.simplisafe.com/v1/${camera.uuid}/flv?x=944&audioEncoding=AAC`}
  // url={`http://local.pcs.com:8000/video/video`}
  // url="ws://local.pcs.com:8000/video/ws?file_path=outputs/76246/29322af849fb4eb22866c8b5400005af/video/1606745538.740551.flv"
  // url={`ws://local.pcs.com:8000/video/ws/1606745538.740551.flv`}

  const { authHeader } = useSubscriber(userId);
  const { eventId } = useQueryParams() ?? {};

  if (!authHeader) return <LoadingSpinner />;
  return (
    <Page className={classes.root} title="Video">
      <Container maxWidth={false}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Card>
              <DenseCardHeader title="Options" />
              <FormControlLabel
                control={
                  <Switch
                    checked={live}
                    onChange={(e) => {
                      setLive(e.target.checked);
                    }}
                    // color="primary"
                    // name="acceptNew"
                  />
                }
                label="Live"
              />
            </Card>
          </Grid>

          <Grid item xs={12} md={12}>
            <Card>
              <DenseCardHeader title="SS Video Player" />
              <Box id={`player-${camera.uuid}`}>
                <VideoPlayer
                  authHeader={authHeader}
                  controls={[
                    'play',
                    'time',
                    'progress',
                    'volume',
                    'talk',
                    'siren',
                    // 'full-screen'
                  ]}
                  eventId={eventId}
                  height="100%"
                  isLive
                  isPlaying={isPlaying}
                  key={url}
                  markers={markers}
                  onVolume={(value) => setVolume(value)}
                  // onPause={handlePause}
                  // onPlay={handlePlay}
                  type="flv"
                  url={url}
                  uuid={camera.uuid}
                  volume={volume}
                  width="100%"
                />
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <Box height="10vh">
                <DenseCardHeader title="Websocket" />
                <WebSocketTest />
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <Box height="10vh">
                <DenseCardHeader title="Webcam" />
                {/* <WebCamera id="player2" /> */}
                {/* <Overlay videoId="player2" defaultModelName={modelName} /> */}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default VideoView;
