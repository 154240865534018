type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  user_id: string;
  role?: string;
  is_active: boolean;
  is_verified: boolean;
  last_login: string;
};

/**
 * Save user data in local storage
 * @param data
 * @param data.user User data
 * @param data.token JSON web token
 * @returns
 */
export const saveUserDataInLocalStorage = (data: {
  user: User;
  token: string;
}): User => {
  localStorage.setItem('token', data.token);
  if (data.user) {
    localStorage.setItem('id', data.user.id);
    localStorage.setItem('user_id', data.user.user_id);
    localStorage.setItem('role', data.user.role);
  }
  return data.user;
};

/**
 * Clear local storage of all user data
 */
export const clearLocalStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  localStorage.removeItem('id');
  localStorage.removeItem('user_id');
  localStorage.removeItem('auth:error');
};

export const getCurrentDomain = () => {

  let hn = window.location.hostname;
  if (hn.includes('qa') || hn.includes('pcs-web')) {
    return hn.substring(hn.indexOf('.') + 1) // hack for ephemeral urls
  }
  return hn;
};

export const saveAuthCookie = (token: string) => {
  document.cookie = `auth0.token=${token}; path=/; domain=.${getCurrentDomain()}; max-age=86400`;
};

export const clearAuthCookie = () => {
  document.cookie = 'auth0.token=; Path=/; Max-Age=-99999999;';
};

type RegisterBodyData = {
  email: string;
  firstName: string;
  lastName: string;
  user_id: number;
};
/**
 * Sign up via backend and store JSON web token on success
 *
 * @param {RegisterBodyData} body User data
 * @param {string} token JSON web token from Auth0
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const register = async (
  body: RegisterBodyData,
  token: string
): Promise<User> => {
  const request = new Request(
    `${process.env.REACT_APP_PCS_ADMIN_URL}/auth/register`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify(body),
    }
  );

  const response = await fetch(request);

  if (response.status === 500) {
    throw new Error('Internal server error');
  }

  const data = await response.json();
  if (response.status >= 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  return data;
};
