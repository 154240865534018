import React, { createContext, useReducer } from 'react';
import { getPCSstatusByLocation } from '../utils/utils';
import axios from 'axios';
import MetricsModule from 'src/utils/MetricsModule';

export const MetricsContext = createContext();

async function sendMetrics(payload) {
  const token = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.post(
    `${process.env.REACT_APP_PCS_ADMIN_URL}/front-metrics/`,
    payload,
    config
  );
  response.status === 200
    ? console.log('*** METRICS SENT SUCCESSFULY ***')
    : console.log('*** METRICS FAILED TO BE SENT ***');
}

async function sendOpenMetrics(payload) {
  const token = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  await axios.post(
    `${process.env.REACT_APP_PCS_ADMIN_URL}/metrics/open`,
    payload,
    config
  );
}

export const getEventInQueueDuration = (eventId) => {
  const event_in_queue_start = parseInt(
    sessionStorage.getItem(`event_in_queue_start_${eventId}`)
  );
  return event_in_queue_start
    ? Math.round((new Date().getTime() - event_in_queue_start) / 1000)
    : -1;
};

export const createOpenMetricDispatch =
  (dispatch) =>
  ({ metricName, payload }) => {
    const metric = new MetricsModule(metricName);
    dispatch({
      type: 'METRIC',
      payload: {
        ...metric,
        metricName,
        ...payload,
      },
    });
  };

const reducer = (state, action) => {
  switch (action.type) {
    case 'EVENTSCOMING':
      return {
        ...state,
        send: true,
      };
    case 'RESET':
      return {
        send: false,
        payload: {},
      };
    case 'SEND':
      sendMetrics({
        ...action.payload,
        PCSstatus: action.payload.PCSstatus ?? getPCSstatusByLocation(),
      });
      return {
        send: true,
        payload: {},
      };
    case 'METRIC':
      sendOpenMetrics({
        timeseries_index: 'agent-index',
        timestamp_unix: Date.now(),
        data: {
          ...action.payload,
          PCSstatus: action.payload.PCSstatus ?? getPCSstatusByLocation(),
        },
      });
      return {
        send: true,
        payload: {},
      };
  }
};

const MetricsContextProvider = ({ children }) => {
  const state = useReducer(reducer, { send: true, payload: {} });
  return (
    <MetricsContext.Provider value={state}>{children}</MetricsContext.Provider>
  );
};

export default MetricsContextProvider;
