import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  TextField,
  makeStyles,
  Switch,
} from '@material-ui/core';
import {
  useGetFacialRecognition,
  useUpdateFacialRecognition,
} from 'src/hooks/settingsHooks';

const useStyles = makeStyles({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const FacialRecognition = ({ className, ...rest }) => {
  const classes = useStyles();

  const [settings, setSettings] = useState({
    noFaceStates: '',
    noFaceCities: '',
    noFaceZips: '',
    optInStates: '',
    optInCities: '',
    optInZips: '',
  });

  const {
    noFaceStates,
    noFaceCities,
    noFaceZips,
    optInStates,
    optInCities,
    optInZips,
  } = settings;

  const settingsQuery = useGetFacialRecognition();
  const updateQuery = useUpdateFacialRecognition();

  const handleSave = () => {
    updateQuery.mutate({
      noFaceStates: noFaceStates.split(',').filter((x) => !!x),
      noFaceCities: noFaceCities.split(',').filter((x) => !!x),
      noFaceZips: noFaceZips.split(',').filter((x) => !!x),
      optInStates: optInStates.split(',').filter((x) => !!x),
      optInCities: optInCities.split(',').filter((x) => !!x),
      optInZips: optInZips.split(',').filter((x) => !!x),
    });
  };

  useEffect(() => {
    if (settingsQuery.isSuccess) {
      setSettings({
        noFaceStates: settingsQuery.data.no_face_states.join(','),
        noFaceCities: settingsQuery.data.no_face_cities.join(','),
        noFaceZips: settingsQuery.data.no_face_zips.join(','),
        optInStates: settingsQuery.data.opt_in_states.join(','),
        optInCities: settingsQuery.data.opt_in_cities.join(','),
        optInZips: settingsQuery.data.opt_in_zips.join(','),
      });
    }
  }, [settingsQuery.data]);

  return (
    <form className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          subheader="Manage facial recognition settings"
          title="Facial Recognition"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="No Face States"
            margin="normal"
            name="no-face-states"
            onChange={(e) =>
              setSettings({ ...settings, ...{ noFaceStates: e.target.value } })
            }
            value={noFaceStates ?? ''}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="No Face Cities"
            margin="normal"
            name="no-face-cities"
            onChange={(e) =>
              setSettings({ ...settings, ...{ noFaceCities: e.target.value } })
            }
            value={noFaceCities ?? ''}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="No Face Zips"
            margin="normal"
            name="no-face-zips"
            onChange={(e) =>
              setSettings({ ...settings, ...{ noFaceZips: e.target.value } })
            }
            value={noFaceZips ?? ''}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Opt In States"
            margin="normal"
            name="opt-in-states"
            onChange={(e) =>
              setSettings({ ...settings, ...{ optInStates: e.target.value } })
            }
            value={optInStates ?? ''}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Opt In Cities"
            margin="normal"
            name="opt-in-cities"
            onChange={(e) =>
              setSettings({ ...settings, ...{ optInCities: e.target.value } })
            }
            value={optInCities ?? ''}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Opt In Zips"
            margin="normal"
            name="opt-in-zips"
            onChange={(e) =>
              setSettings({ ...settings, ...{ optInZips: e.target.value } })
            }
            value={optInZips ?? ''}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSave} color="primary" variant="contained">
            save
          </Button>
        </Box>
      </Card>
    </form>
  );
};

FacialRecognition.propTypes = {
  className: PropTypes.string,
};

export default FacialRecognition;
