import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: 175,
  },
  eventText: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    '& .MuiInputBase-root': {
      backgroundColor: 'transparent',
      '& .MuiInputBase-input': {
        padding: 0,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },
});


export function Documentation(
  {documentation, setDocumentation }: 
  {documentation: string; setDocumentation: (documentation: string) => void}
) {
  const classes = useStyles();

  return (
    <TextField
      id="event-doc-textfield"
      type="text"
      className={classes.eventText}
      inputProps={{ maxLength: 500 }}
      InputProps={{ style: { backgroundColor: 'transparent', height: '90%', overflow: 'auto', alignItems: 'flex-start' } }}
      variant="outlined"
      multiline
      rows={18}
      value={documentation}
      onChange={(e) => setDocumentation(e.target.value)}
      placeholder="Describe the Event"
    />
  );
}
