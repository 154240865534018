import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useTimer } from 'src/utils/useTimer';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ReplayIcon from '@material-ui/icons/Replay';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#111',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '1',
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex',
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const NoVideoCard = ({
  retryTime,
  message,
  onTimeOver = () => {},
  onRetry = () => {},
  onNoVideoClick = () => {},
  className = '',
  ...rest
}) => {
  const classes = useStyles();

  const { time, start, reset } = useTimer({
    initialTime: retryTime,
    timerType: 'DECREMENTAL',
    autostart: true,
    endTime: 0,
    onTimeOver: onTimeOver,
  });
  React.useEffect(() => {
    start();
  }, [start, retryTime]);

  const handleRetry = useCallback(
    (e) => {
      e.stopPropagation();
      reset();
      start();
      onRetry();
    },
    [reset, start, onRetry]
  );

  return (
    <Card
      onClick={onNoVideoClick}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box display="flex" justifyContent="center" mb={3}>
          <VideocamOffIcon />
        </Box>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          {message ? `${message}` : 'Camera Offline'}
        </Typography>
        <Typography align="center" color="textPrimary" variant="body1">
          Retrying in {time} seconds
        </Typography>
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
      <Box p={2} onClick={(e) => e.stopPropagation()}>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid className={classes.statsItem} item>
            <AccessTimeIcon className={classes.statsIcon} color="action" />
            <Typography color="textSecondary" display="inline" variant="body2">
              Updated
            </Typography>
          </Grid>
          <Grid className={classes.statsItem} item onClick={handleRetry}>
            <ReplayIcon className={classes.statsIcon} color="action" />
            <Typography color="textSecondary" display="inline" variant="body2">
              Retry
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

NoVideoCard.propTypes = {
  className: PropTypes.string,
  retryTime: PropTypes.number,
  message: PropTypes.string.isRequired,
  onTimeOver: PropTypes.func,
};

export default NoVideoCard;
