import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  CardHeader,
  Divider,
  styled,
} from '@material-ui/core';
import { useGetAllRepEventStats } from 'src/hooks/reportHooks';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { getInitials } from 'src/utils/getInitials';
import Label from 'src/components/Label';
import { formatHandlerFromEmail } from 'src/utils/format';
import { HandledStatuses } from 'src/models';

const ScrollableList = styled(List)(() => ({
  maxHeight: 'calc(35vh - 60px)',
  overflow: 'auto',
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  avatar: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
  },
}));

const RepList = ({ className, ...rest }) => {
  const classes = useStyles();

  const eventStats = useGetAllRepEventStats(0, 60 * 60 * 12, 0);
  if (!eventStats.isSuccess)
    return <LoadingSpinner message="Getting rep stats" />;

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="PCS Agent Event Stats" />
      <Divider />
      <ScrollableList disablePadding>
        {Object.entries(eventStats.data).map(
          ([handler_email, pcs_status_object]) => (
            <ListItem key={handler_email}>
              <ListItemAvatar>
                <Avatar
                  className={classes.avatar}
                  // style={{ backgroundColor: referral.color }}
                >
                  {handler_email && getInitials(handler_email)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={formatHandlerFromEmail(handler_email)}
                primaryTypographyProps={{ variant: 'h6' }}
              />
              <RepStats pcs_status_object={pcs_status_object} />
            </ListItem>
          )
        )}
      </ScrollableList>
    </Card>
  );
};

const RepStats = ({ pcs_status_object }) => {
  const handled = Object.keys(pcs_status_object)
    .filter((key) => HandledStatuses.includes(parseInt(key, 10)))
    .reduce((sum, key) => {
      sum += parseInt(pcs_status_object[key], 10);
      return sum;
    }, 0);

  const newEvents = pcs_status_object['0'] || 0;
  const watching = pcs_status_object['10'] || 0;
  const verifying = pcs_status_object['20'] || 0;
  const dispatching = pcs_status_object['30'] || 0;
  const finishing = pcs_status_object['40'] || 0;

  const vdfColor =
    verifying + dispatching + finishing > 1 ? 'error' : 'primary';

  return (
    <>
      <Label color="primary">N: {newEvents}</Label>{' '}
      <Label color={watching > 9 ? 'error' : 'primary'}>W: {watching}</Label>{' '}
      <Label color={vdfColor}>V: {verifying}</Label>{' '}
      <Label color={vdfColor}>D: {dispatching}</Label>{' '}
      <Label color={vdfColor}>F: {finishing}</Label>{' '}
      <Label color="primary">H: {handled}</Label>{' '}
    </>
  );
};

RepStats.propTypes = {
  pcs_status_object: PropTypes.object,
};
RepList.propTypes = {
  className: PropTypes.string,
};

export default RepList;
