import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  TextField,
  makeStyles,
  Switch,
} from '@material-ui/core';
import ExportLink from './ParticipantExportLink';

const useStyles = makeStyles({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const ExportParticipantEmailLog = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <form className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          subheader="Export Participant Email Logs"
          title="Export Logs"
        />
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <ExportLink />
        </Box>
      </Card>
    </form>
  );
};

ExportParticipantEmailLog.propTypes = {
  className: PropTypes.string,
};

export default ExportParticipantEmailLog;
