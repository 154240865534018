import React from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Box,
  Card,
  Button,
  Typography,
  List,
  ListItem,
  Link,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Navigate } from 'react-router';
import Page from 'src/components/Page';
import DenseCardHeader from 'src/components/DenseCardHeader';
import VideoPlayer from 'src/components/VideoPlayer/index';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { useQueryParams } from 'src/utils/useQueryParams';
import { useVideoPlayer } from 'src/components/VideoPlayer/useVideoPlayer';
import { useSubscription } from 'src/hooks/useSubscription';
import { getCameraFromSubscription } from 'src/utils/subscription';
import { useGetCamera } from 'src/hooks/subscriberHooks';
import { cameraIsPrivate, cameraStreamType } from 'src/utils/camera';
import CameraStatus from './CameraStatus';
import MotionZone from 'src/components/MotionZone';
import PrivateCameraCard from 'src/views/verify-respond/PrivateCameraCard';
import { useGetRepMe } from 'src/hooks/repHooks';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(0),
  },
}));

const CameraView = () => {
  const classes = useStyles();

  const { userId, sid, uuid, eventId } = useQueryParams();
  const { authHeader, ...playerState } = useVideoPlayer(userId, {
    isPlaying: true,
    showImgMask: 1,
  });
  const { subscription } = useSubscription({ userId, sid });
  const camera = getCameraFromSubscription(subscription, uuid);
  const pcsCameraQuery = useGetCamera(uuid);
  const { data: repData } = useGetRepMe();

  const timestamp = '';

  // MOTION ZONE
  const mzRef = React.useRef();
  const playerElRef = React.useRef();
  const [playerReady, setPlayerReady] = React.useState(false);
  const handleReady = (playerEl) => {
    playerElRef.current = playerEl.current;
    setPlayerReady(true);
  };
  const [videoAction, setVideoAction] = React.useState(null);
  const handleVideoAction = () => {
    mzRef.current.capture();
    setVideoAction(true);
    setPlayerReady(false);
  };
  const handleZoneDone = () => {
    setVideoAction(null);
  };

  if (!authHeader) return <LoadingSpinner message="Authorizing" />;
  if (!pcsCameraQuery.isSuccess)
    return <LoadingSpinner message="Loading PCS Cameras" />;
  if (!subscription)
    return <LoadingSpinner message="Getting Subscription from Yoda" />;

  if (repData?.role === 'engineer' || repData?.role === 'agent_supervisor')
    return <Navigate to="/app/dashboard" />;

  function cameraView() {
    // Private or Not Montiored
    if (
      cameraIsPrivate(
        camera,
        'ANY' // subscription?.location?.system?.alarmState
      )
    ) {
      return <PrivateCameraCard />;
    }
    if (pcsCameraQuery.data?.monitored !== true) {
      return <PrivateCameraCard message="Not monitored" />;
    }

    // Otherwise show video
    return (
      <VideoPlayer
        {...playerState}
        authHeader={authHeader}
        eventId={eventId}
        height="100%"
        liveIndicator={true}
        onReady={handleReady}
        showLiveIndicator={true}
        sid={sid}
        type={cameraStreamType(camera)}
        url={`${process.env.REACT_APP_SS_MEDIA_URL}/v1/${uuid}/flv?audioEncoding=AAC`}
        uuid={uuid}
        width="1280px"
        controls={[
          'play',
          'time',
          'progress',
          'volume',
          'siren',
          'talk',
          ...(cameraStreamType(camera) === 'kvs' ? ['reload'] : []),
        ]}
        fullDuplexAudio={camera?.supportedFeatures?.fullDuplexAudio}
      />
    );
  }

  return (
    <Page className={classes.root} title="Video">
      <Container maxWidth={false}>
        <Box mt={3} mb={3}>
          <Typography color="textPrimary" variant="h2">
            Camera Setup
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body2">
            Camera: {`${camera?.cameraSettings?.cameraName}`}, for User:{' '}
            <Link component={RouterLink} to={`/app/subs/${userId}`}>
              {userId}
            </Link>
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Card>
              <Box>
                <DenseCardHeader title="Motion Zoning" />

                <Box m={1}>
                  <Typography>
                    Setup motion zoning for this camera using the live view
                    below. Motion and people will be ignored in any region
                    marked [Exclude].
                  </Typography>

                  <Box m={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      m={2}
                      disabled={!playerReady}
                      onClick={handleVideoAction}
                    >
                      Edit Motion Zone
                    </Button>
                    &nbsp;
                    <Button
                      variant="contained"
                      color="primary"
                      m={2}
                      onClick={() => {}}
                    >
                      Create HeatMap
                    </Button>
                  </Box>
                </Box>

                <MotionZone
                  mzRef={mzRef}
                  playerElRef={playerElRef}
                  onDone={handleZoneDone}
                />

                {!videoAction && (
                  <>
                    <Box width={1280} m={1} position="relative">
                      {cameraView()}
                    </Box>
                  </>
                )}
              </Box>
            </Card>
          </Grid>

          <CameraStatus camera={camera} />

          {/* End */}
        </Grid>
      </Container>
    </Page>
  );
};

export default CameraView;
